define("rd-basic-client/controllers/categoryfeedcosts", ["exports", "rd-basic-client/config/environment", "rd-basic-client/mixins/editable-months", "rd-basic-client/mixins/notification", "moment"], function (_exports, _environment, _editableMonths, _notification, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_notification.default, _editableMonths.default, {
    session: Ember.inject.service(),
    init: function init() {
      this._super();

      this.set('updateDailyAmount', 0);
      this.set('updateFeedType', null);
      this.set('updateDailyRatio', false);
      this.set('updateCostPerTon', 0);
      this.set('updateCostType', 'Spot Price');
      this.set('updateFeedCategory', 'Other Feed');
      this.set('updateBasisContract', null);
      this.set('currentFeed', null);
      this.set('updateBasicFeedCost', null);
      this.set('updateBasicFeedInCWT', null);
      this.set('feedCategories', ['Energy', 'Protein', 'Other Feed']);
      this.set('feedPriceTypes', ['Spot Price', 'Fixed', 'Basis CME (%)', 'Basis CME ($)']);
    },
    checkedMonths: Ember.computed.filterBy('model.selectableMonths', 'checked', true),
    partialMonthsSelected: Ember.computed('checkedMonths.@each.checked', function () {
      return this.checkedMonths.length > 0 && this.checkedMonths.length < this.model.months.length;
    }),
    allMonthsSelected: Ember.computed('checkedMonths.@each.checked', function () {
      return this.checkedMonths.length == this.model.months.length;
    }),
    activeIngredients: function () {
      return this.get('model.ingredients').filter(function (ingredient) {
        return ingredient.get('active') ? 1 : 0;
      });
    }.property('model.ingredients.@each', 'model.ingredients.@each.active'),
    feedHedgeOptions: function () {
      var a = [];
      a.pushObject({
        id: 'ZC',
        auto: true,
        name: 'AUTO ZC FUTURE'
      });
      this.get('model.cornFutures').forEach(function (f) {
        a.pushObject(f);
      });
      a.pushObject({
        id: 'ZM',
        auto: true,
        name: 'AUTO ZM FUTURE'
      });
      this.get('model.sbmFutures').forEach(function (f) {
        a.pushObject(f);
      });
      return a;
    }.property('cornFutures', 'sbmFutures', 'soybeanFutures', 'wheatFutures'),
    updateFeedTypeIsFixedOrSpot: function () {
      return this.get('updateFeedTypeIsFixed') || this.get('updateFeedTypeIsSpotPrice');
    }.property('updateFeedTypeIsFixed', 'updateFeedTypeIsSpotPrice'),
    updateFeedTypeIsFixed: function () {
      return this.get('updateCostType') === 'Fixed';
    }.property('updateCostType'),
    updateFeedTypeIsDollar: function () {
      return this.get('updateCostType') === 'Basis CME ($)';
    }.property('updateCostType'),
    updateFeedTypeIsPercentage: function () {
      return this.get('updateCostType') === 'Basis CME (%)';
    }.property('updateCostType'),
    updateFeedTypeIsSpotPrice: function () {
      return this.get('updateCostType') === 'Spot Price';
    }.property('updateCostType'),
    modelLabel: function () {
      var state = 'Static Feed Cost';

      if (this.get('model.category.firstObject.staticFeedUsage') && this.get('model.category.firstObject.ingredientFeedUsage')) {
        state = 'Combined';
      } else if (this.get('model.category.firstObject.ingredientFeedUsage')) {
        state = 'Ingredient Feed Cost';
      }

      return state;
    }.property('model.category.firstObject.ingredientFeedUsage', 'model.category.firstObject.staticFeedUsage'),
    isCombined: function () {
      return this.get('model.category.firstObject.ingredientFeedUsage') && this.get('model.category.firstObject.staticFeedUsage');
    }.property('model.category.firstObject.ingredientFeedUsage', 'model.category.firstObject.staticFeedUsage'),
    showAdvanced: function () {
      var m = this.get('model.category.firstObject');
      return m.get('ingredientFeedUsage') || !m.get('staticFeedUsage');
    }.property('model.category.firstObject.ingredientFeedUsage', 'model.category.firstObject.staticFeedUsage'),
    ingredientCost: function () {
      return this.get('model.category.firstObject.totalFeedCosts') - this.get('model.category.firstObject.flatFeedCosts');
    }.property('model.category.firstObject.ingredientFeedUsage', 'model.category.firstObject.staticFeedUsage', 'model.category.firstObject.totalProductionCWT', 'model.category.firstObject.flatFeedCosts', 'model.category.firstObject.totalFeedCosts'),
    isStaticFeedCWT: function () {
      return this.get('updateStaticFeedType') === 'CWT';
    }.property('updateStaticFeedType'),
    isStaticTotalCost: function () {
      return this.get('updateStaticFeedType') === 'TOTAL';
    }.property('updateStaticFeedType'),
    isStaticPerCow: function () {
      return this.get('updateStaticFeedType') === 'PER_COW';
    }.property('updateStaticFeedType'),
    staticFeedTypeLabel: function () {
      var label = 'Total $';

      if (this.get('updateStaticFeedType') === 'CWT') {
        label = 'Per CWT';
      } else if (this.get('updateStaticFeedType') === 'PER_COW') {
        label = 'Per $/Cow/Day';
      }

      return label;
    }.property('updateStaticFeedType'),
    isValidaFeedToUpdate: function isValidaFeedToUpdate() {
      if (!this.get('editedFeedIngredient')) {
        this.showErrorMessage('Feed Type cannot be empty!');
        return false;
      }

      if (this.get('updateDailyAmount') === null) {
        this.showErrorMessage('Daily Dry Matter Intake cannot be empty!');
        return false;
      }

      return true;
    },
    getNextAvailableFuture: function getNextAvailableFuture(monthDate, type) {
      var instrument = null;
      var list = null;

      if (type === 'ZC') {
        list = this.get('model.cornFutures.content');
      } else {
        list = this.get('model.sbmFutures.content');
      }

      list.forEach(function (i) {
        if (instrument == null && !_moment.default.utc(i.get('displayExpiresAt')).isBefore(monthDate)) {
          instrument = i;
        }
      });
      return instrument;
    },
    actions: {
      gotoIngredientsEditPage: function gotoIngredientsEditPage() {
        Ember.$('#add-feed-modal').modal('hide');
        this.transitionToRoute('ingredients');
      },
      setPerCowDMI: function setPerCowDMI(val) {
        var rec = this.get('model.category.firstObject');
        rec.set('perCowDMI', val);
        rec.save();
      },
      setEditedFeedIngredient: function setEditedFeedIngredient(val) {
        this.set('editedFeedIngredient', val);
      },
      toggleStaticFeedUsage: function toggleStaticFeedUsage() {
        var rec = this.get('model.category.firstObject');
        rec.set('staticFeedUsage', !rec.get('staticFeedUsage'));

        if (!rec.get('staticFeedUsage') && !rec.get('ingredientFeedUsage')) {
          // this condition will make sure that at least one button is selected
          rec.set('ingredientFeedUsage', true);
        }

        rec.save();
      },
      toggleIngredientFeedUsage: function toggleIngredientFeedUsage() {
        var rec = this.get('model.category.firstObject');
        rec.set('ingredientFeedUsage', !rec.get('ingredientFeedUsage'));

        if (!rec.get('staticFeedUsage') && !rec.get('ingredientFeedUsage')) {
          // this condition will make sure that at least one button is selected
          rec.set('staticFeedUsage', true);
        }

        rec.save();
      },
      changeStaticFeedType: function changeStaticFeedType(type) {
        this.set('updateStaticFeedType', type);
        this.set('updateStaticFeedCost', '');
      },
      editStaticFeed: function editStaticFeed() {
        var rec = this.get('model.category.firstObject');
        this.set('updateStaticFeedCost', rec.get('staticFeedCost'));
        this.set('updateStaticFeedType', rec.get('staticFeedType'));
        Ember.$('#static-modal').modal('show');
      },
      saveStaticFeed: function saveStaticFeed() {
        var rec = this.get('model.category.firstObject');
        rec.set("staticFeedCost", this.get("updateStaticFeedCost"));
        rec.set("staticFeedType", this.get("updateStaticFeedType"));
        rec.save();
        Ember.$('#static-modal').modal('hide');
      },
      showAddFeedModal: function showAddFeedModal() {
        this.set('editedFeed', {
          id: null,
          feed: null,
          ingredient: null,
          priceInTons: 0
        });
        this.set('updateCostType', 'Spot Price');
        this.set('updateDailyRatio', '');
        this.set('updateDailyAmount', '');
        this.set('updateCostPerTon', 0);
        this.set('editedFeedIngredient', null);
        this.set('updateBasisContract', null);
        Ember.$('#add-feed-modal').modal('show');
      },
      showEditFeedModal: function showEditFeedModal(feed) {
        var self = this;

        if (feed.get('feedRollover') !== null) {
          self.get('feedHedgeOptions').forEach(function (el) {
            if (el.future.id === feed.get('feedRollover')) {
              self.set("updateBasisContract", el.future);
            }
          });
        } else {
          feed.get("basisInstrument").then(function (feed) {
            self.set("updateBasisContract", feed);
          });
        }

        feed.get("ingredient").then(function (ingredient) {
          self.set("editedFeedIngredient", ingredient);
        });
        this.set('updateFeedType', feed.get('feedType'));
        this.set('updateDailyAmount', feed.get('dailyAmount'));
        this.set('updateDailyRatio', feed.get('dailyRatio'));
        this.set('updateCostType', feed.get('costType'));
        this.set('updateCostPerTon', feed.get('priceInTons'));
        this.set('editedFeed', feed);
        Ember.$('#add-feed-modal').modal('show');
      },
      setUpdateCostType: function setUpdateCostType(type) {
        this.set('updateCostType', type);
      },
      updateFeedIngredient: function updateFeedIngredient(ingredient) {
        this.set('editedFeed.ingredient', ingredient);

        if (!this.get('editedFeed.priceInTons')) {
          this.set('editedFeed.priceInTons', ingredient.get('spotPrice'));
        }
      },
      deleteFeed: function deleteFeed(feed) {
        var m = this.get("model.category.firstObject");
        m.get("feeds").removeObject(feed);
        m.save();
        feed.deleteRecord();
        feed.save();
        Ember.$('.ui-tooltip').remove();
      },
      setUpdateBasisContract: function setUpdateBasisContract(contract) {
        this.set('updateBasisContract', contract);
      },
      saveFeed: function saveFeed() {
        if (!this.isValidaFeedToUpdate()) {
          // Something is invalid, abort
          return;
        }

        var instrument = null;

        if (this.get('updateBasisContract.auto')) {
          var currentCategory = this.get("model.category.firstObject");
          var currentMonth = currentCategory.get("month");

          var currentMonthDate = _moment.default.utc(currentMonth.get('startDate'));

          instrument = this.getNextAvailableFuture(currentMonthDate, this.get('updateBasisContract.id'));
        } else {
          instrument = this.get('updateBasisContract');
        }

        var feed;
        var cowCategory = this.get('model.category.firstObject');

        if (this.get('editedFeed.id') === null) {
          feed = this.store.createRecord('feed', {
            feedCategory: this.get('updateFeedCategory'),
            feedType: this.get('updateFeedType'),
            dailyAmount: this.get('updateDailyAmount'),
            dailyRatio: this.get('updateDailyRatio'),
            // priceInTons     : this.get('editedFeed.priceInTons'),
            basisInstrument: instrument,
            feedRollover: this.get('updateBasisContract.auto') ? this.get('updateBasisContract.id') : null,
            costType: this.get('updateCostType'),
            ingredient: this.get('editedFeedIngredient'),
            cowCategory: cowCategory
          });

          if (this.get('updateFeedTypeIsPercentage')) {
            feed.set('priceInTons', this.get('updateCostPerTon') / 100);
          } else {
            feed.set('priceInTons', this.get('updateCostPerTon'));
          }

          feed.save().then(function (f) {// cowCategory.get('feeds').addObject(f);
            // cowCategory.save();
          });
        } else {
          feed = this.get('editedFeed');
          feed.set('feedCategory', this.get('updateFeedCategory'));
          feed.set('feedType', this.get('updateFeedType'));
          feed.set('dailyAmount', this.get('updateDailyAmount'));
          feed.set('dailyRatio', this.get('updateDailyRatio'));
          feed.set('basisInstrument', instrument);
          feed.set('feedRollover', this.get('updateBasisContract.auto') ? this.get('updateBasisContract.id') : null);
          feed.set('costType', this.get('updateCostType'));
          feed.set('ingredient', this.get('editedFeedIngredient'));

          if (this.get('updateFeedTypeIsPercentage')) {
            feed.set('priceInTons', this.get('updateCostPerTon') / 100);
          } else {
            feed.set('priceInTons', this.get('updateCostPerTon'));
          }

          feed.save();
        }

        Ember.$('#add-feed-modal').modal('hide');
      },
      showCopyRation: function showCopyRation() {
        Ember.$(".monthCheckbox").prop('checked', false);
        this.checkedMonths.forEach(function (m) {
          m.set('checked', false);
        });
        Ember.$("#copy-feed-modal").modal("show");
      },
      updateAllSelectedMonths: function updateAllSelectedMonths() {
        if (this.checkedMonths.length > 0 && !this.partialMonthsSelected) {
          this.checkedMonths.forEach(function (m) {
            m.set('checked', false);
          });
        } else {
          this.model.selectableMonths.forEach(function (m) {
            m.set('checked', true);
          });
        }
      },
      saveCopiedRation: function saveCopiedRation() {
        var _this = this;

        var self = this;
        var currentCategory = this.get("model.category.firstObject");
        var currentMonth = currentCategory.get("month_id");
        var monthsToCopy = this.checkedMonths.mapBy('month');
        var ids = [];
        var rollovers = [];
        currentCategory.get('feeds').forEach(function (originFeed) {
          if (originFeed.get("feedRollover")) {
            monthsToCopy.forEach(function (month) {
              month.get('cowCategories').forEach(function (category) {
                if (category.get('cowCategoryLabel.id') === currentCategory.get('cowCategoryLabel.id') && category.get('id') !== currentCategory.get('id')) {
                  var currentMonthDate = _moment.default.utc(month.get('startDate'));

                  var instrument = self.getNextAvailableFuture(currentMonthDate, originFeed.get("feedRollover"));
                  rollovers.push({
                    category: category.get('id'),
                    validId: instrument.get('id'),
                    actualId: originFeed.get('basisInstrument.id')
                  });
                }
              });
            });
          }
        });
        monthsToCopy.forEach(function (month) {
          month.get('cowCategories').forEach(function (category) {
            if (category.get('cowCategoryLabel.id') === currentCategory.get('cowCategoryLabel.id') && category.get('id') !== currentCategory.get('id')) {
              ids.push(category.get('id'));
            }
          });
        });
        Ember.$.ajax({
          url: _environment.default.APP.HOST + '/feeds_copy',
          method: 'POST',
          context: this,
          contentType: 'application/json',
          headers: {
            "Authorization": "Bearer ".concat(this.get("session.data.authenticated.access_token"))
          },
          data: JSON.stringify({
            copy_params: {
              category_id: currentCategory.get('id'),
              categories: ids.join(','),
              rollovers: JSON.stringify(rollovers)
            }
          }),
          success: function success(response, textStatus, XHR) {
            _this.send("refreshModel");

            Ember.$("#copy-feed-modal").modal("hide");
          }
        });
      }
    }
  });

  _exports.default = _default;
});