define("rd-basic-client/mixins/class-iv", ["exports", "rd-basic-client/mixins/common", "rd-basic-client/utils/option-expiration-pl-in-ticks", "rd-basic-client/utils/points-to-dollars", "rd-basic-client/utils/vela-to-barchart-price", "rd-basic-client/utils/barchart-to-vela-price"], function (_exports, _common, _optionExpirationPlInTicks, _pointsToDollars, _velaToBarchartPrice, _barchartToVelaPrice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_common.default, {
    classFourCashValueProduced: function () {
      return this.classesTwoAndFourProduction / 100 * this.classFourPrice;
    }.property('classesTwoAndFourProduction', 'classFourPrice'),
    nonfatDryMilkAndCashSettledButterCashValueHedged: function () {
      return this.nonfatDryMilkCashValueHedged + this.cashSettledButterCashValueHedged;
    }.property('nonfatDryMilkCashValueHedged', 'cashSettledButterCashValueHedged'),
    classFourNetPercentageHedged: function () {
      var percentage = this.nonfatDryMilkAndCashSettledButterCashValueHedged / this.classFourCashValueProduced;

      if (percentage > 1) {
        return 1;
      } else if (percentage > 0) {
        return percentage;
      }

      return 0;
    }.property('nonfatDryMilkAndCashSettledButterCashValueHedged', 'classFourCashValueProduced'),
    classFourNetPercentageUnhedged: function () {
      return 1 - this.classFourNetPercentageHedged;
    }.property('classFourNetPercentageHedged'),
    classesTwoAndFourProduction: function () {
      return this.classFourUtilization * this.totalProduction + this.classTwoUtilization * this.totalProduction;
    }.property('classFourUtilization', 'classTwoUtilization', 'totalProduction'),
    nonfatDryMilkPoundsFromClassFourHedged: function () {
      return this.classFourOnlyPoundsHedged * 0.0859815;
    }.property('classFourOnlyPoundsHedged'),
    cashSettledButterPoundsFromClassFourHedged: function () {
      return this.classFourOnlyPoundsHedged * 0.042385;
    }.property('classFourOnlyPoundsHedged'),
    classFourProduction: function () {
      return this.classFourUtilization * this.totalProduction;
    }.property('classFourUtilization', 'totalProduction'),
    classFourPoundsHedged: function () {
      var lbs = this.classFourOnlyPoundsHedged;

      if (this.user.get("pricingModel") === 'fmmo' || this.user.get("pricingModel") === 'california') {
        lbs += this.classFourButterPounds;
        lbs += this.classFourNonFatPounds;
      }

      return lbs;
    }.property('classFourOnlyPoundsHedged', 'nonfatDryMilkPoundsHedged', 'user.pricingModel'),
    classFourOnlyPoundsHedged: function () {
      return this.getPoundsHedged('GDK');
    }.property('physicalPositions.@each.quantity', 'positions.@each.quantity'),
    classFourButterContractsProduced: function () {
      var poundsOfFat = 20000 * 0.35 * 1.211 / 200000;
      var classFourButterProduced = this.classesTwoAndFourProduction * poundsOfFat;
      return classFourButterProduced / 20000;
    }.property('classesTwoAndFourProduction'),
    nonfatDryMilkProduced: function () {
      return this.classesTwoAndFourProduction * 0.08685;
    }.property('classesTwoAndFourProduction'),
    nonfatDryMilkContractsProduced: function () {
      return this.nonfatDryMilkProduced / 44000;
    }.property('nonfatDryMilkProduced'),
    classFourButterPounds: function () {
      var poundsOfFat = 20000 * 0.35 * 1.211;
      return this.cashSettledButterPoundsHedged / poundsOfFat * 200000;
    }.property('cashSettledButterPoundsHedged'),
    classFourNonFatPounds: function () {
      return this.getPoundsHedged('GNF') / 0.08685;
    }.property('positions.@each.quantity', 'physicalPositions.@each.quantity'),
    classFourPercentageHedged: function () {
      var next_month = this.next_month_id;
      return this.getByUserTypePercentageHedged({
        FMMO: this.classFourProduction + this.classTwoProduction + (next_month ? next_month.get('classOneProduction') / 2 : 0),
        CALIFORNIA: this.classFourAProduction + this.classTwoProduction + this.classThreeProduction + (this.classFourBestPrice > this.classThreeBestPrice ? this.classOneProduction : 0),
        hedged: this.classFourPoundsHedged
      });
    }.property('classFourPoundsHedged', 'classFourProduction', 'classTwoProduction', 'classFourBestPrice', 'classThreeBestPrice', 'classFourAProduction', 'classTwoProduction', 'classThreeProduction', 'classOneProduction', 'next_month_id'),
    classFourOptionPremium: function () {
      return this.getPositionsValue('GDK', 'Option', 'premiumInDollars');
    }.property('positions.@each.premiumInDollars'),
    classFourFuturePL: function () {
      return this.getPositionsValue('GDK', 'Future', 'plInDollars');
    }.property('positions.@each.plInDollars'),
    classFourOptionPL: function () {
      return this.getPositionsValue('GDK', 'Option', 'plInDollars');
    }.property('positions.@each.plInDollars'),
    scenarioClassFourFuturePL: function () {
      var exchangePositions = this.positions.filter(function (p) {
        return p.get('instrument.symbol') && p.get('instrument.symbol').indexOf('GDK') > -1 && p.get('instrument.instrument_type').toUpperCase() === 'FUTURE';
      });
      var futurePLinTicks = 0;
      var futurePL = 0;
      exchangePositions.forEach(function (pos) {
        futurePLinTicks = 0;
        pos.tradePrices.forEach(function (tp) {
          if (this.rawScenarioFmmoClassFourPrice === undefined) {
            return 0;
          }

          if (tp.quantity > 0) {
            futurePLinTicks += (this.rawScenarioFmmoClassFourPrice - tp.price) * tp.quantity;
          }

          if (tp.quantity < 0) {
            futurePLinTicks += (tp.price - this.rawScenarioFmmoClassFourPrice) * Math.abs(tp.quantity);
          }
        }, this);
        futurePL += (0, _pointsToDollars.default)('GDK', (0, _velaToBarchartPrice.default)('GDK', futurePLinTicks));
      }, this);
      return futurePL;
    }.property('positions.@each.instrument', 'rawScenarioFmmoClassFourPrice'),
    scenarioClassFourOptionPL: function () {
      var self = this;
      var exchangePositions = this.positions.filter(function (p) {
        return p.get('instrument.symbol') && p.get('instrument.symbol').indexOf('GDK') > -1 && p.get('instrument.instrument_type').toUpperCase() === 'OPTION';
      });
      var optionPLinTicks = 0;
      var optionPL = 0;
      var isCall;
      exchangePositions.forEach(function (pos) {
        optionPLinTicks = 0;
        isCall = pos.get('instrument.option_type').toUpperCase() === 'CALL';
        optionPLinTicks = (0, _optionExpirationPlInTicks.default)(self.rawScenarioFmmoClassFourPrice, (0, _barchartToVelaPrice.default)('GDK', pos.get('instrument.strike')), pos.quantity, isCall);
        optionPL += (0, _pointsToDollars.default)('GDK', (0, _velaToBarchartPrice.default)('GDK', optionPLinTicks));
      });
      return optionPL;
    }.property('positions.@each.instrument', 'rawScenarioFmmoClassFourPrice'),
    offExchangeClassFourPL: function () {
      return this.getOffExchangePL('GDK', this.classFourBestPriceFormatted, 100);
    }.property('physicalPositions.@each', 'classFourBestPrice', 'classFourMilkInstrument.product.tick_size'),
    scenarioOffExchangeClassFourPL: function () {
      var classFourPrice = this.rawScenarioFmmoClassFourPrice / 100; // Price per CWT

      return this.getScenarioOffExchangePL('GDK', classFourPrice, 100);
    }.property('physicalPositions.@each', 'rawScenarioFmmoClassFourPrice', 'classFourMilkInstrument.product.tick_size', 'hypotheticalPositions.@each', 'isCurrentMonth'),
    classFourNetPL: function () {
      return this.offExchangeClassFourPL + this.classFourFuturePL + this.classFourOptionPL;
    }.property('offExchangeClassFourPL', 'classFourFuturePL', 'classFourOptionPL'),
    classFourSkim: function () {
      return this.nonfatSolidsPrice * 9;
    }.property('nonfatSolidsPrice'),
    scenarioClassFourSkim: function () {
      return this.scenarioNonfatSolidsPrice * 9;
    }.property('scenarioNonfatSolidsPrice'),
    classFourBPrice: function () {
      return this.get("caClassFourBPrice");
    }.property("caClassFourBPrice"),
    caClassFourBPrice: function () {
      return 3.5 * this.get("caFourBButterfat") + 8.7 * this.get("caFourBNonfatSolids");
    }.property("caFourBButterfat", "caFourBNonfatSolids"),
    classFourBProduction: function () {
      return this.classFourBUtilization * this.totalProduction;
    }.property('classFourBUtilization', 'totalProduction'),
    classFourBProductionInContracts: function () {
      return this.classFourBProduction / 200000;
    }.property('classFourBProduction'),
    classFourPrice: function () {
      var val = -1;

      switch (this.user.get("pricingModel")) {
        case 'fmmo':
          val = this.fmmoClassFourPrice;
          break;

        case 'uda':
          val = this.udaClassFourPrice;
          break;

        default:
          val = 0;
      }

      return val;
    }.property('user.pricingModel', 'fmmoClassFourPrice', 'udaClassFourPrice'),
    scenarioClassFourPrice: function () {
      return this.rawScenarioFmmoClassFourPrice / 100;
    }.property('rawScenarioFmmoClassFourPrice'),
    rawScenarioFmmoClassFourPrice: function () {
      return Math.round((this.scenarioClassFourSkim * 0.965 + this.scenarioButterfatPrice * 3.5) * 100); // In cents, to the nearest cent.
    }.property('scenarioClassFourSkim', 'scenarioButterfatPrice'),
    fmmoClassFourPrice: Ember.computed.alias('classFourBestPriceFormatted')
  });

  _exports.default = _default;
});