define("rd-basic-client/templates/partials/dataimport/filetype", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xQrGTioO",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[10,\"style\",\"margin-top: 15px;\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-12\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"panel panel-default\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"panel-heading\"],[8],[0,\"\\n                \"],[7,\"h3\",true],[10,\"class\",\"panel-title\"],[8],[0,\"\\n                    \"],[7,\"i\",true],[10,\"aria-hidden\",\"true\"],[10,\"class\",\"fa fa-filter\"],[8],[9],[0,\"  Import Type\"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"ul\",true],[10,\"class\",\"list-group\"],[8],[0,\"\\n                \"],[7,\"li\",true],[10,\"class\",\"list-group-item\"],[8],[0,\"Please select the type of data you're going to import:\"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"panel-body import-type\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"types\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"div\",true],[10,\"class\",\"col-md-4\"],[8],[0,\"\\n                        \"],[7,\"div\",false],[12,\"class\",\"btn btn-info col-md-12\"],[12,\"style\",\"margin-bottom:5px\"],[3,\"action\",[[23,0,[]],\"setImportType\",[23,1,[]]]],[8],[1,[23,1,[\"label\"]],false],[9],[0,\"\\n                    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/partials/dataimport/filetype.hbs"
    }
  });

  _exports.default = _default;
});