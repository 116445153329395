define("rd-basic-client/models/physical-position", ["exports", "ember-data", "rd-basic-client/mixins/notification", "rd-basic-client/utils/vela-product-group-to-symbol"], function (_exports, _emberData, _notification, _velaProductGroupToSymbol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-function-prototype-extensions */
  var _default = _emberData.default.Model.extend(_notification.default, {
    instrumentType: Ember.computed.alias("instrument_type"),
    instrument_type: _emberData.default.attr("string"),
    isLong: Ember.computed.alias("is_long"),
    is_long: _emberData.default.attr("boolean"),
    dateEntered: Ember.computed.alias('date_entered'),
    date_entered: _emberData.default.attr("date"),
    contractDate: Ember.computed.alias('contract_date'),
    contract_date: _emberData.default.attr("date"),
    quantity: _emberData.default.attr("number"),
    price: _emberData.default.attr("number"),
    month: Ember.computed.alias('month_id'),
    month_id: _emberData.default.belongsTo("month", {
      async: true
    }),
    productGroup: Ember.computed.alias('product_group_id'),
    product_group_id: _emberData.default.attr("number"),
    rootSymbol: Ember.computed('product_group_id', function () {
      return (0, _velaProductGroupToSymbol.default)(this.product_group_id);
    }),
    side: function () {
      return this.get("isLong") ? "Bought" : "Sold";
    }.property("isLong"),
    optionType: Ember.computed.alias("option_type"),
    option_type: _emberData.default.attr("string"),
    strike: _emberData.default.attr("number"),
    plInstrument: function () {
      var i;

      switch (this.get("rootSymbol")) {
        case "DC":
          i = this.get("month.classThreeMilkInstrument");
          break;

        case "GDK":
          i = this.get("month.classFourMilkInstrument");
          break;

        case "ZC":
          i = this.get("month.cornInstrument");
          break;

        case "CSC":
          i = this.get("month.cashSettledCheeseInstrument");
          break;

        case "ZM":
          i = this.get("month.soybeanMealInstrument");
          break;

        case "CB":
          i = this.get("month.cashSettledButterInstrument");
          break;

        case "GNF":
          i = this.get("month.nonfatDryMilkInstrument");
          break;

        case "DY":
          i = this.get("month.dryWheyInstrument");
          break;
      }

      return i;
    }.property("rootSymbol", "month"),
    historicalPrice: function () {
      var i;

      switch (this.get("rootSymbol")) {
        case "DC":
          i = this.get("month.historical_class_three_price");
          break;

        case "GDK":
          i = this.get("month.historical_class_four_price");
          break;

        case "ZC":
          i = this.get("month.historical_corn_price");
          break;

        case "CSC":
          i = this.get("month.historical_cash_settled_cheese_price");
          break;

        case "ZM":
          i = this.get("month.historical_soybean_meal_price");
          break;

        case "CB":
          i = this.get("month.historical_cash_settled_butter_price");
          break;

        case "GNF":
          i = this.get("month.historical_nonfat_dry_milk_price");
          break;

        case "DY":
          i = this.get("month.historical_dry_whey_price");
          break;
      }

      return i;
    }.property("rootSymbol", "month"),
    plInDollars: function () {
      var current_price = this.get("plInstrument.lastPrice") ? this.get("plInstrument.lastPrice") : this.get("historicalPrice");
      var instrumentPrice = current_price;
      var v = 0;
      var quantity = this.get("quantity");

      if (this.get("rootSymbol") === "DC" || this.get("rootSymbol") === "GDK") {
        quantity = quantity / 100;
      }

      if (this.get("instrumentType") === "Forward") {
        v += quantity * (this.get("price") - instrumentPrice) * (this.get("isLong") ? -1 : 1);
      }

      if (this.get("instrumentType") === "Put" || this.get("instrumentType") === "Call") {
        var strikePriceDiff = this.get("strike") - instrumentPrice;

        if (this.get("instrumentType") === "Put" && strikePriceDiff > 0) {
          v += quantity * strikePriceDiff * (this.get("isLong") ? 1 : -1);
        }

        if (this.get("instrumentType") === "Call" && strikePriceDiff < 0) {
          v += quantity * strikePriceDiff * (this.get("isLong") ? -1 : 1);
        }

        var price = this.get("price") * quantity;

        if (this.get("isLong")) {
          v -= price;
        } else {
          v += price;
        }
      }

      return v;
    }.property("price", "quantity", "plInstrument", "plInstrument.lastPrice", "historicalPrice")
  });

  _exports.default = _default;
});