define("rd-basic-client/utils/option-expiration-pl-in-ticks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = optionExpirationPLinTicks;

  function optionExpirationPLinTicks(underlyingPriceInTicks, strikePriceInTicks, quantity, isCall) {
    if (underlyingPriceInTicks === undefined || strikePriceInTicks === undefined || quantity === undefined || isCall === undefined) {
      return 0;
    }

    var optionPLinTicks = 0;
    var diff = underlyingPriceInTicks - strikePriceInTicks;

    if (diff < 0 && !isCall || diff > 0 && isCall) {
      optionPLinTicks += Math.abs(diff) * quantity;
    }

    return optionPLinTicks;
  }
});