define("rd-basic-client/mixins/reconciliation", ["exports", "ember-data", "rd-basic-client/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /****************************** CONSTANTS *****************************/

  /* THE SCOPE OF FOLLOWING VARIABLES IS TO MAKE THE CODE MORE READABLE */
  // Pricing Models
  var PM = _environment.default.APP.CONSTANTS.PRICING_MODELS;
  /************************** END CONSTANTS *****************************/

  var _default = Ember.Mixin.create({
    blendedActual: _emberData.default.attr('number'),
    premiumActual: _emberData.default.attr('number'),
    grossProceedsActual: _emberData.default.attr('number'),
    eppActual: _emberData.default.attr('number'),
    quotaActual: _emberData.default.attr('number'),
    overQuotaActual: _emberData.default.attr('number'),
    glanbiaCheese: _emberData.default.attr('number'),
    glanbiaButter: _emberData.default.attr('number'),
    glanbiaWpc: _emberData.default.attr('number'),
    glanbiaLactose: _emberData.default.attr('number'),
    reconciliationTickSize: 100,
    reconciliationMultiple: 1,
    reconciliationTickValue: 1,
    reconciliationFormat: '0,0.00',

    /*
    classOneActual   : DS.attr('number'),
    classTwoActual   : DS.attr('number'),
    classThreeActual : DS.attr('number'),
    classFouraActual : DS.attr('number'),
    classFourbActual : DS.attr('number'),
    overbaseActual   : DS.attr('number'),
    cheeseActual     : DS.attr('number'),
    butterActual     : DS.attr('number'),
    wpcActual        : DS.attr('number'),
    lactoseActual    : DS.attr('number'),
    blendedActual    : DS.attr('number'),
    priceAdjActual   : DS.attr('number'),
    */
    classOneActual: function () {
      var val = null;

      switch (this.get('user.pricingModel')) {
        case PM.FMMO:
          val = this.get('fmmoClassOneActualPrice');
          break;

        case PM.CALIFORNIA:
          var bIsNorth = this.get('user.location.considerNorthernCalifornia');
          val = bIsNorth ? this.get('historical_ca_n_class_one') : this.get('historical_ca_s_class_one');
          break;

        case PM.UDA:
          val = this.get('fmmoClassOneActualPrice'); //this.get('historicalUdaClassOneWithDifferential');

          break;

        default:
          val = this.get('fmmoClassOneActualPrice');
          break;
      }

      return val || 0;
    }.property('user.pricingModel', 'user.location.considerNorthernCalifornia', 'fmmoClassOneActualPrice', 'historical_ca_class_one', 'historicalUdaClassOneWithDifferential'),
    historicalUdaClassOneWithDifferential: function () {
      var classOneDifferential = Ember.isNone(this.get('user.classOneDifferential')) ? 0 : this.get('user.classOneDifferential');
      return this.get('historicalUdaClassOne') + classOneDifferential;
    }.property('historicalUdaClassOne', 'user.classOneDifferential'),
    classTwoActual: function () {
      var val = null;

      switch (this.get('user.pricingModel')) {
        case PM.FMMO:
          val = this.get('historical_fmmo_class_two');
          break;

        case PM.CALIFORNIA:
          var bIsNorth = this.get('user.location.considerNorthernCalifornia');
          val = bIsNorth ? this.get('historical_ca_n_class_two') : this.get('historical_ca_s_class_two');
          break;

        case PM.UDA:
          val = this.get('historical_fmmo_class_two'); //this.get('historicalUdaClassTwo');

          break;
      }

      return val || 0;
    }.property('user.pricingModel', 'user.location.considerNorthernCalifornia', 'historical_fmmo_class_two', 'historical_ca_class_two', 'historical_uda_class_two'),
    classThreeActual: function () {
      var val = null;

      switch (this.get('user.pricingModel')) {
        case PM.FMMO:
        case PM.UDA:
          val = this.get('historical_fmmo_class_three');
          break;

        case PM.CALIFORNIA:
          val = this.get('historical_ca_class_three');
          break;
      }

      return val || 0;
    }.property('user.pricingModel', 'historical_fmmo_class_three', 'historical_ca_class_three'),
    classFourActual: function () {
      var val = null;

      switch (this.get('user.pricingModel')) {
        case PM.FMMO:
        case PM.UDA:
          val = this.get('historical_fmmo_class_four');
          break;
      }

      return val || 0;
    }.property('user.pricingModel', 'historical_fmmo_class_four'),
    classFourAActual: function () {
      var val = null;

      switch (this.get('user.pricingModel')) {
        case PM.CALIFORNIA:
          val = this.get('historicalCaClassFoura');
          break;
      }

      return val || 0;
    }.property('user.pricingModel', 'historicalCaClassFoura'),
    classFourAActualBest: function () {
      return (this.get('classFourAActual') / this.get('reconciliationTickSize')).toFixed(2);
    }.property('classFourAActual'),
    classFourBActual: function () {
      var val = null;

      switch (this.get('user.pricingModel')) {
        case PM.CALIFORNIA:
          val = this.get('historicalCaClassFourb');
          break;
      }

      return val || 0;
    }.property('user.pricingModel', 'historicalCaClassFourb'),
    classFourBActualBest: function () {
      return (this.get('classFourBActual') / this.get('reconciliationTickSize')).toFixed(2);
    }.property('classFourBActual'),
    fmmoClassOneActualPrice: function () {
      var classOneDifferential = Ember.isNone(this.get('user.classOneDifferential')) ? 0 : this.get('user.classOneDifferential');
      return this.get('historical_fmmo_class_one') + classOneDifferential;
    }.property('user.classOneDifferential', 'historical_fmmo_class_one'),
    bestMilkcheckActual: function () {
      return this.get('milkcheckActual');
    }.property('milkcheckActual'),
    totalBlendedActual: function () {
      return this.get('blendedActual') * this.get('totalProduction') / 100;
    }.property('bestMilkcheckActual', 'totalProduction'),
    totalOverbaseActual: function () {
      return this.get('overbaseActual') * this.get('totalProduction') / 100;
    }.property('overbaseActual', 'totalProduction'),
    vaultFmmoBasis: function () {
      var val = 0;

      switch (this.get('user.basisType')) {
        case 0:
          val = this.get('blendedActual') - this.get('blendedMilkPrice');
          break;

        case 1:
          val = this.get('blendedActual') - this.get('classThreePrice');
          break;

        case 2:
          val = this.get('blendedActual') - this.get('classFourPrice');
          break;
      }

      return val;
    }.property('user.basisType', 'blendedMilkPrice', 'blendedActual', 'classThreePrice', 'classFourPrice'),
    vaultCaBasis: function () {
      var val = 0;

      switch (this.get('user.basisType')) {
        case 0:
          // totalNonQuotaPrice
          // caTotalGrossProceeds
          // blendedMilkPrice
          val = this.get('grossProceedsActual') - this.get('caGrossProceeds');
          break;

        case 1:
          val = this.get('grossProceedsActual') - this.get('classFourAPrice');
          break;

        case 2:
          val = this.get('grossProceedsActual') - this.get('classFourBPrice');
          break;

        case 3:
          val = this.get('grossProceedsActual') - this.get('fmmoClassThreePrice');
          break;

        case 4:
          val = this.get('grossProceedsActual') - this.get('fmmoClassFourPrice');
          break;
      }

      return val;
    }.property('user.basisType', 'grossProceedsActual', 'caGrossProceeds', 'classFourAActualBest', 'classFourAPrice', 'classFourBActualBest', 'classFourBPrice', 'fmmoClassThreePrice', 'fmmoClassFourPrice'),
    vaultGlanbiaBasis: function () {
      var val = 0;

      switch (this.get('user.basisType')) {
        case 0:
          val = this.get('eppActual') - this.get('blendedMilkPrice');
          break;

        case 1:
          val = this.get('eppActual') - this.get('classFourAPrice');
          break;

        case 2:
          val = this.get('eppActual') - this.get('classFourBPrice');
          break;
      }

      return val;
    }.property('user.basisType', 'blendedMilkPrice', 'eppActual', 'classFourAPrice', 'classFourBPrice'),
    vaultUdaBasis: function () {
      var val = 0;

      switch (this.get('user.basisType')) {
        case 0:
          val = this.get('blendedActual') - this.get('udaBlendedMilkPriceAndProtein');
          break;

        case 1:
          val = this.get('blendedActual') - this.get('classThreePrice');
          break;

        case 2:
          val = this.get('blendedActual') - this.get('classFourPrice');
          break;
      }

      return val;
    }.property('user.basisType', 'udaBlendedMilkPriceAndProtein', 'blendedActual', 'classThreePrice', 'classFourPrice'),
    udaActualMilkCheck: function () {
      return this.get('blendedActual') + this.get('premiumActual') + this.get('udaProtein');
    }.property('blendedActual', 'premiumActual'),
    glanbiaActualMilkCheck: function () {
      return this.get('eppActual') + this.get('premiumActual');
    }.property('eppActual', 'premiumActual'),
    vaultBasisPrice: function () {
      var val = -1;

      switch (this.get('user.pricingModel')) {
        case PM.CALIFORNIA:
          val = this.get('vaultCaBasis');
          break;

        case PM.FMMO:
          val = this.get('vaultFmmoBasis');
          break;

        case PM.GLANBIA:
          val = this.get('vaultGlanbiaBasis');
          break;

        case PM.UDA:
          val = this.get('vaultUdaBasis');
          break;

        default:
          val = 0;
      }

      return val;
    }.property('user.pricingModel', 'vaultFmmoBasis', 'vaultCaBasis', 'vaultGlanbiaBasis'),
    totalVaultBasisPrice: function () {
      return this.get('vaultBasisPrice') * this.get('totalProduction') / 100;
    }.property('vaultBasisPrice', 'totalProduction'),
    classFourBasisPrice: function () {
      return this.get('classFourPrice') - this.get('bestMilkcheckActual');
    }.property('classFourPrice', 'bestMilkcheckActual'),
    totalClassFourBasisPrice: function () {
      return this.get('classFourBasisPrice') * this.get('totalProduction') / 100;
    }.property('classFourBasisPrice', 'totalProduction'),
    classThreeBasisPrice: function () {
      return this.get('classThreePrice') - this.get('bestMilkcheckActual');
    }.property('classThreePrice', 'bestMilkcheckActual'),
    totalClassThreeBasisPrice: function () {
      return this.get('classThreeBasisPrice') * this.get('totalProduction') / 100;
    }.property('classThreeBasisPrice', 'totalProduction'),
    fmmoActualMilkCheck: function () {
      return this.get('blendedActual') + this.get('premiumActual');
    }.property('blendedActual', 'premiumActual'),
    caActualMilkCheck: function () {
      return this.get('grossProceedsActual') + this.get('premiumActual');
    }.property('blendedActual', 'premiumActual'),
    totalHistoricalCaOverbase: function functionName() {
      return this.get('historicalCaOverbase') / this.get('reconciliationTickSize') * this.get('totalProduction') / 100;
    }.property('historicalCaOverbase', 'totalProduction', 'reconciliationTickSize'),
    vaultActualMilkCheck: function () {
      var val = -1;

      switch (this.get('user.pricingModel')) {
        case PM.FMMO:
          val = this.get('fmmoActualMilkCheck');
          break;

        case PM.CALIFORNIA:
          val = this.get('caActualMilkCheck');
          break;

        case PM.GLANBIA:
          val = this.get('glanbiaActualMilkCheck');
          break;

        case PM.UDA:
          val = this.get('udaActualMilkCheck');
          break;
      }

      return val;
    }.property('user.pricingModel', 'caActualMilkCheck', 'fmmoActualMilkCheck', 'udaActualMilkCheck', 'glanbiaActualMilkCheck'),
    totalVaultActualMilkCheck: function () {
      return this.get('vaultActualMilkCheck') * this.get('totalProduction') / 100;
    }.property('vaultActualMilkCheck', 'totalProduction'),
    totalEppActual: function () {
      return this.get('eppActual') * this.get('totalProduction') / 100;
    }.property('eppActual'),
    totalGrossProceedsActual: function () {
      return this.get('grossProceedsActual') * this.get('totalProduction') / 100;
    }.property('grossProceedsActual', 'totalProduction'),
    totalOverQuotaActual: function () {
      return this.get('overQuotaActual') * this.get('totalProduction') / 100;
    }.property('overQuotaActual'),
    totalQuotaActual: function () {
      return this.get('quotaActual') * this.get('totalProduction') / 100;
    }.property('quotaActual'),
    getBasisAvg: function getBasisAvg(numberOfMonths) {
      var value = 0; // eslint-disable-next-line no-unused-vars

      var countOfValidMonth = 0;
      var prevMonth = this.get('previous_month_id');
      var pricingModel = this.get('user.pricingModel');
      var blendedFieldName = 'blendedActual';

      if (pricingModel === PM.GLANBIA) {
        blendedFieldName = 'eppActual';
      } else if (pricingModel === PM.CALIFORNIA) {
        blendedFieldName = 'grossProceedsActual';
      }

      if (this.get('isPast')) {
        if (!isNaN(this.get('vaultBasisPrice')) && this.get(blendedFieldName) > 0) {
          value = this.get('vaultBasisPrice');
          numberOfMonths--;
        }
      }

      for (var i = 0; i < numberOfMonths;) {
        if (prevMonth && prevMonth.get != null) {
          if (prevMonth.get('isPast')) {
            if (!isNaN(prevMonth.get('vaultBasisPrice')) && prevMonth.get(blendedFieldName) > 0) {
              value += prevMonth.get('vaultBasisPrice');
              countOfValidMonth++;
            } else {//console.warn('Unable to get the value for Basis Avgerage', numberOfMonths, moment.utc(prevMonth.get('startDate')).format('YYYY-MM'), prevMonth.get(blendedFieldName), blendedFieldName);
            }

            i++;
          }

          prevMonth = prevMonth.get('previous_month_id');
        } else {
          console.error('Months must be preloaded to calculate vaultBasis!');
          break;
        }
      }

      return value / numberOfMonths;
    },
    //vaultBasisPrice
    vaultBasis: function () {
      var val = 0;

      switch (this.get('user.vaultBasis')) {
        case 0:
          val = this.getBasisAvg(12);
          break;

        case 1:
          val = this.getBasisAvg(24);
          break;

        case 2:
          val = this.getBasisAvg(36);
          break;

        case 3:
          val = this.get('user.vaultBasisValue');
          break;

        default:
          val = -1;
      }

      return val;
    }.property('user.vaultBasis', 'user.vaultBasisValue', 'previous_month_id.vaultBasisPrice', 'vaultBasisPrice', 'grossProceedsActual', 'blendedActual', 'eppActual'),
    totalVaultBasis: function () {
      return this.get('vaultBasis') * this.get('totalProduction') / 100;
    }.property('vaultBasis', 'totalProduction')
  });

  _exports.default = _default;
});