define("rd-basic-client/mixins/dry-whey", ["exports", "rd-basic-client/mixins/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_common.default, {
    dryWheyCashValueHedged: function () {
      return this.totalDryWheyPoundsHedged * (this.dryWheyBestPriceFormatted - 0.1991);
    }.property('totalDryWheyPoundsHedged', 'dryWheyBestPriceFormatted'),
    totalDryWheyPoundsHedged: function () {
      var wheyPoundsFromClassThreeHedged = this.classThreePoundsHedgedWithoutCheese * 0.05864305;
      return this.dryWheyPoundsHedged + wheyPoundsFromClassThreeHedged;
    }.property('dryWheyPoundsHedged', 'classThreePoundsHedgedWithoutCheese'),
    dryWheyPercentageHedged: function () {
      return this.dryWheyCashValueHedged / this.dryWheyCashValueProduced;
    }.property('dryWheyCashValueHedged', 'dryWheyCashValueProduced'),
    dryWheyCashValueProduced: function () {
      return this.dryWheyProduced * (this.dryWheyBestPriceFormatted - 0.1991);
    }.property('dryWheyProduced', 'dryWheyBestPriceFormatted'),
    dryWheyPoundsHedged: function () {
      return this.getPoundsHedged('DY');
    }.property('physicalPositions.@each.quantity', 'positions.@each.quantity'),
    dryWheyProduced: function () {
      return this.classThreeProduction * 0.058643;
    }.property('classThreeProduction'),
    dryWheyContractsProduced: function () {
      return this.dryWheyProduced / 44000;
    }.property('dryWheyProduced'),
    dryWheyOptionPremium: function () {
      return this.getPositionsValue('DY', 'Option', 'premiumInDollars');
    }.property('positions.@each.premiumInDollars'),
    dryWheyFuturePL: function () {
      return this.getPositionsValue('DY', 'Future', 'plInDollars');
    }.property('positions.@each.plInDollars'),
    scenarioDryWheyFuturePL: function () {
      return this.getPositionsValue('DY', 'Future', 'scenarioPlInDollars');
    }.property('positions.@each.scenarioPlInDollars'),
    dryWheyOptionPL: function () {
      return this.getPositionsValue('DY', 'Option', 'plInDollars');
    }.property('positions.@each.plInDollars'),
    scenarioDryWheyOptionPL: function () {
      return this.getPositionsValue('DY', 'Option', 'scenarioPlInDollars');
    }.property('positions.@each.scenarioPlInDollars'),
    offExchangeDryWheyPL: function () {
      return this.getOffExchangePL('DY', this.dryWheyBestPriceFormatted);
    }.property('physicalPositions.@each', 'dryWheyBestPrice', 'dryWheyInstrument.product.tick_size'),
    scenarioOffExchangeDryWheyPL: function () {
      return this.getScenarioOffExchangePL('DY', this.scenarioDryWheyBestPriceFormatted);
    }.property('physicalPositions.@each', 'scenarioDryWheyBestPriceFormatted', 'dryWheyInstrument.product.tick_size', 'isCurrentMonth', 'hypotheticalPositions.@each'),
    dryWheyNetPL: function () {
      return this.offExchangeDryWheyPL + this.dryWheyFuturePL + this.dryWheyOptionPL;
    }.property('offExchangeDryWheyPL', 'dryWheyFuturePL', 'dryWheyOptionPL')
  });

  _exports.default = _default;
});