define("rd-basic-client/models/barchart-market-datum", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    close: _emberData.default.attr("number"),
    dayCode: _emberData.default.attr("string"),
    dollarVolume: _emberData.default.attr("number"),
    flag: _emberData.default.attr("string"),
    high: _emberData.default.attr("number"),
    lastPrice: _emberData.default.attr("number"),
    low: _emberData.default.attr("number"),
    mode: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    netChange: _emberData.default.attr("number"),
    numTrades: _emberData.default.attr("number"),
    open: _emberData.default.attr("number"),
    percentChange: _emberData.default.attr("number"),
    previousVolume: _emberData.default.attr("number"),
    serverTimestamp: _emberData.default.attr("date"),
    symbol: _emberData.default.attr("string"),
    tradeTimestamp: _emberData.default.attr("date"),
    unitCode: _emberData.default.attr("string"),
    volume: _emberData.default.attr("number"),
    instrument: _emberData.default.belongsTo("vault-instrument"),
    openInterest: _emberData.default.attr("number"),
    settlement: _emberData.default.attr("number"),
    previousSettlement: _emberData.default.attr("number")
  });

  _exports.default = _default;
});