define("rd-basic-client/components/vd-integrations", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    provider: function () {
      return this.get('integration.firstObject.name');
    }.property('integration'),
    date: function () {
      var date = (0, _moment.default)(this.get('integration.firstObject').get('lastSuccessfullImportLog.datetime'));
      return date.format('MMM DD, YYYY') + ' at ' + date.format('hh:mm A');
    }.property('integration')
  });

  _exports.default = _default;
});