define("rd-basic-client/mixins/uda", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    udaCheese: _emberData.default.attr('number'),
    udaSpread: _emberData.default.attr('number'),
    udaPowder: _emberData.default.attr('number'),
    udaBestPowder: function () {
      return this.udaPowder ? this.udaPowder : 1 - this.udaCheese;
    }.property('udaCheese', 'udaPowder'),
    udaProtein: function () {
      var protein = this.protein * 100;
      var price = 0;

      if (protein >= 2.66 && protein <= 2.7) {
        price = -0.4;
      } else if (protein > 2.7 && protein <= 2.75) {
        price = -0.35;
      } else if (protein > 2.75 && protein <= 2.8) {
        price = -0.30;
      } else if (protein > 2.8 && protein <= 2.85) {
        price = -0.25;
      } else if (protein > 2.85 && protein <= 2.9) {
        price = -0.20;
      } else if (protein > 2.9 && protein <= 2.95) {
        price = -0.15;
      } else if (protein > 2.95 && protein <= 3) {
        price = -0.10;
      } else if (protein > 3 && protein <= 3.05) {
        price = -0.5;
      } else if (protein > 3.05 && protein <= 3.1) {
        price = 0;
      } else if (protein > 3.1 && protein <= 3.15) {
        price = 0.05;
      } else if (protein > 3.15 && protein <= 3.2) {
        price = 0.1;
      } else if (protein > 3.2 && protein <= 3.25) {
        price = 0.15;
      } else if (protein > 3.25 && protein <= 3.3) {
        price = 0.2;
      } else if (protein > 3.3 && protein <= 3.35) {
        price = 0.25;
      } else if (protein > 3.35 && protein <= 3.4) {
        price = 0.3;
      } else if (protein > 3.4 && protein <= 3.45) {
        price = 0.4;
      } else if (protein > 3.45 && protein <= 3.5) {
        price = 0.5;
      } else if (protein > 3.5 && protein <= 3.55) {
        price = 0.6;
      } else if (protein > 3.55 && protein <= 3.6) {
        price = 0.7;
      } else if (protein > 3.6 && protein <= 3.65) {
        price = 0.8;
      } else if (protein > 3.65 && protein <= 3.7) {
        price = 0.9;
      } else if (protein > 3.7 && protein <= 3.75) {
        price = 1.0;
      } else if (protein > 3.75 && protein <= 3.8) {
        price = 1.1;
      } else if (protein > 3.8 && protein <= 3.85) {
        price = 1.2;
      } else if (protein > 3.85 && protein <= 3.9) {
        price = 1.3;
      } else if (protein > 3.9 && protein <= 3.95) {
        price = 1.4;
      } else if (protein > 3.95 && protein <= 4) {
        price = 1.5;
      } else if (protein > 4 && protein <= 4.05) {
        price = 1.6;
      } else if (protein > 4.05 && protein <= 4.1) {
        price = 1.7;
      } else if (protein > 4.1 && protein <= 4.15) {
        price = 1.8;
      } else if (protein > 4.15 && protein <= 4.2) {
        price = 1.9;
      } else if (protein > 4.2 && protein <= 4.25) {
        price = 2;
      } else if (protein > 4.25 && protein <= 4.3) {
        price = 2.1;
      } else if (protein > 4.3 && protein <= 4.35) {
        price = 2.2;
      }

      return price;
    }.property('protein'),
    totalUdaProtein: function () {
      return this.udaProtein * this.totalProduction / 100;
    }.property('udaProtein', 'totalProduction'),
    udaClassOneSkim: function () {
      var previous_month = this.previous_month_id;
      var val = 0;

      if (previous_month !== null) {
        val = previous_month.get('classThreeSkim') > previous_month.get('classFourSkim') ? previous_month.get('classThreeSkim') : previous_month.get('classFourSkim');
      } else {
        val = this.classThreeSkim > this.classFourSkim ? this.classThreeSkim : this.classFourSkim;
      }

      return val + 2.35;
    }.property('classThreeSkim', 'classFourSkim', 'previous_month_id.classThreeSkim', 'previous_month_id.classFourSkim', 'previous_month_id'),
    udaScenarioClassOneSkim: function () {
      var previous_month = this.previous_month_id;
      var val = 0;

      if (previous_month !== null) {
        val = previous_month.get('scenarioClassThreeSkim') > previous_month.get('scenarioClassFourSkim') ? previous_month.get('scenarioClassThreeSkim') : previous_month.get('scenarioClassFourSkim');
      } else {
        val = this.scenarioClassThreeSkim > this.scenarioClassFourSkim ? this.scenarioClassThreeSkim : this.scenarioClassFourSkim;
      }

      return val + 2.35;
    }.property('scenarioClassThreeSkim', 'scenarioClassFourSkim', 'previous_month_id.scenarioClassThreeSkim', 'previous_month_id.scenarioClassFourSkim', 'previous_month_id'),
    udaClassOnePrice: function () {
      return this.classOneSkim * 0.965 + this.udaClassOneButterfatPrice * 3.5;
    }.property('classOneSkim', 'udaClassOneButterfatPrice'),
    udaClassOneButterfatPrice: function () {
      var previous_month = this.get('previous_month_id');

      if (previous_month !== null) {
        return previous_month.get('classThreeAndFourButterfatPrice') + 0.0235;
      } else {
        return 0;
      }
    }.property('previous_month_id', 'previous_month_id.classThreeAndFourButterfatPrice'),
    udaClassTwoSkim: function () {
      var previous_month = this.previous_month_id;

      if (previous_month !== null) {
        return previous_month.get('classFourSkim') + 0.7;
      } else {
        return 0;
      }
    }.property('previous_month_id', 'classFourSkim'),
    scenarioUdaClassTwoSkim: function () {
      var previous_month = this.previous_month_id;

      if (previous_month !== null) {
        return previous_month.get('scenarioClassFourSkim') + 0.7;
      } else {
        return 0;
      }
    }.property('previous_month_id', 'scenarioClassFourSkim'),
    udaClassThreeSkim: function () {
      return (this.get('classThreeBestPrice') - this.get('classThreeAndFourButterfatPrice') * 3.5) / 0.965;
    }.property('classThreeBestPrice', 'classThreeAndFourButterfatPrice'),
    scenarioUdaClassThreeSkim: function () {
      return (this.scenarioClassThreePrice - this.scenarioClassThreeAndFourButterfatPrice * 3.5) / 0.965;
    }.property('scenarioClassThreePrice', 'scenarioClassThreeAndFourButterfatPrice'),
    udaClassTwoPrice: function () {
      return this.classTwoSkim * 0.965 + this.udaClassTwoButterfat * 3.5;
    }.property('classTwoSkim', 'udaClassTwoButterfat'),
    udaClassTwoButterfat: function () {
      return this.classThreeAndFourButterfatPrice + 0.007;
    }.property('classThreeAndFourButterfatPrice'),
    udaClassFourPrice: function () {
      return this.classFourSkim * 0.965 + this.classThreeAndFourButterfatPrice * 3.5;
    }.property('classFourSkim', 'classThreeAndFourButterfatPrice'),
    scenarioUdaClassFourPrice: function () {
      return this.scenarioClassFourSkim * 0.965 + this.scenarioClassThreeAndFourButterfatPrice * 3.5;
    }.property('scenarioClassFourSkim', 'scenarioClassThreeAndFourButterfatPrice'),
    classThreeAndFourButterfatPrice: function () {
      return (this.cashSettledButterBestPriceFormatted / 100 - 0.1715) * 1.211;
    }.property('cashSettledButterBestPriceFormatted'),
    scenarioClassThreeAndFourButterfatPrice: function () {
      return (this.scenarioButterBestPriceFormatted / 100 - 0.1715) * 1.211;
    }.property('scenarioButterBestPriceFormatted'),
    udaOverQuota: function () {
      return this.classThreePrice * this.udaCheese + this.udaClassFourPrice * this.udaBestPowder;
    }.property('classThreePrice', 'udaCheese', 'udaClassFourPrice', 'udaBestPowder'),
    udaUserOverQuota: function () {
      var overQuotaSkim = this.udaOverQuota - this.classThreeAndFourButterfatPrice * 3.5;
      var fatProduced = this.classThreeAndFourButterfatPrice * this.butterfat * 100;
      return overQuotaSkim + fatProduced;
    }.property('classThreeAndFourButterfatPrice', 'butterfat', 'udaOverQuota'),
    scenarioUdaUserOverQuota: function () {
      var overQuotaSkim = this.scenarioUdaOverQuota - this.scenarioClassThreeAndFourButterfatPrice * 3.5;
      var fatProduced = this.scenarioClassThreeAndFourButterfatPrice * this.butterfat * 100;
      return overQuotaSkim + fatProduced;
    }.property('scenarioUdaOverQuota', 'scenarioClassThreeAndFourButterfatPrice', 'butterfat'),
    udaUserQuota: function () {
      var bf = this.classThreeAndFourButterfatPrice + 0.005;
      var quotaSkim = this.udaQuota - bf * 3.5;
      return quotaSkim + bf * this.butterfat * 100;
    }.property('classThreeAndFourButterfatPrice', 'udaQuota', 'butterfat'),
    scenarioUdaUserQuota: function () {
      var bf = this.scenarioClassThreeAndFourButterfatPrice + 0.005;
      var quotaSkim = this.scenarioUdaQuota - bf * 3.5;
      return quotaSkim + bf * this.butterfat * 100;
    }.property('scenarioClassThreeAndFourButterfatPrice', 'scenarioUdaQuota', 'butterfat'),
    totalUdaUserOverQuota: function () {
      return this.udaUserOverQuota * this.totalProduction / 100;
    }.property('udaUserOverQuota'),
    scenarioUdaOverQuota: function () {
      return this.scenarioClassThreePrice * this.udaCheese + this.scenarioUdaClassFourPrice * this.udaBestPowder;
    }.property('scenarioClassThreePrice', 'udaCheese', 'scenarioUdaClassFourPrice', 'udaBestPowder'),
    udaQuota: function () {
      return this.udaOverQuota + this.udaSpread;
    }.property('udaOverQuota', 'udaSpread'),
    udaTotalSpread: function () {
      return this.udaSpread * this.totalProduction / 100;
    }.property('totalProduction', 'udaSpread'),
    udaTotalUserQuota: function () {
      return this.udaUserQuota * this.totalProduction / 100;
    }.property('udaUserQuota', 'totalProduction'),
    scenarioUdaQuota: function () {
      return this.scenarioUdaOverQuota + this.udaSpread;
    }.property('scenarioUdaOverQuota', 'udaSpread'),
    udaBlendedMilkPrice: function () {
      var totalProduction = this.totalProduction;
      var quota = this.get('user.quota');
      var overquota = totalProduction - quota;
      return quota / totalProduction * this.udaUserQuota + overquota / totalProduction * this.udaUserOverQuota;
    }.property('user.quota', 'udaUserQuota', 'udaUserOverQuota', 'totalProduction'),
    udaTotalBlendedMilkPrice: function () {
      return this.udaBlendedMilkPriceAndProtein * this.totalProduction / 100;
    }.property('udaBlendedMilkPriceAndProtein', 'totalProduction'),
    udaBlendedMilkPriceAndProtein: function () {
      return this.udaBlendedMilkPrice + this.udaProtein;
    }.property('udaBlendedMilkPrice', 'udaProtein'),
    scenarioUdaBlendedMilkPrice: function () {
      var totalProduction = this.totalProduction;
      var quota = this.get('user.quota');
      var overquota = totalProduction - quota;
      return quota / totalProduction * this.scenarioUdaUserQuota + overquota / totalProduction * this.scenarioUdaUserOverQuota;
    }.property('scenarioUdaUserOverQuota', 'scenarioUdaUserQuota', 'totalProduction', 'user.quota'),
    scenarioUdaBlendedMilkPriceAndProtein: function () {
      return this.scenarioUdaBlendedMilkPrice + this.udaProtein;
    }.property('scenarioUdaBlendedMilkPrice', 'udaProtein'),
    udaPowderProduction: function () {
      return this.totalProduction * this.udaBestPowder;
    }.property('classFourUtilization', 'totalProduction', 'udaBestPowder'),
    udaPowderProductionInContracts: function () {
      return this.udaPowderProduction / 200000;
    }.property('udaPowderProduction'),
    udaPowderContractsUnhedged: function () {
      var poundHedged = this.classFourPoundsHedged + this.nonfatDryMilkPoundsHedged;
      var production = this.udaPowderProduction;

      if (poundHedged < production) {
        return (production - poundHedged) / 200000;
      } else {
        return 0;
      }
    }.property('udaPowderProduction', 'classFourPoundsHedged', 'nonfatDryMilkPoundsHedged'),
    udaTotalMilkHedged: function () {
      var totalProduction = this.udaCheeseProduction + this.udaPowderProduction;

      if (totalProduction > 0) {
        return (this.nonfatDryMilkPoundsHedged + this.classFourPoundsHedged + this.classThreePoundsHedged) / totalProduction;
      } else {
        return 0;
      }
    }.property('udaCheeseProduction', 'udaPowderProduction', 'nonfatDryMilkPoundsHedged', 'classFourPoundsHedged', 'classThreePoundsHedged'),
    udaCheeseProduction: function () {
      return this.totalProduction * this.udaCheese;
    }.property('classThreeUtilization', 'totalProduction', 'udaCheese'),
    udaCheeseProductionInContracts: function () {
      return this.udaCheeseProduction / 200000;
    }.property('udaCheeseProduction'),
    udaCheeseContractsUnhedged: function () {
      if (this.classThreePoundsHedged < this.udaCheeseProduction) {
        return (this.udaCheeseProduction - this.classThreePoundsHedged) / 200000;
      } else {
        return 0;
      }
    }.property('classThreePoundsHedged', 'udaCheeseProduction')
  });

  _exports.default = _default;
});