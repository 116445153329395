define("rd-basic-client/adapters/application", ["exports", "ember-data", "rd-basic-client/config/environment", "ember-simple-auth/mixins/data-adapter-mixin", "ember-inflector"], function (_exports, _emberData, _environment, _dataAdapterMixin, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.HOST,
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
          access_token = _this$get.access_token;

      if (Ember.isPresent(access_token)) {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
      }
    },
    pathForType: function pathForType(modelName) {
      var decamelized = Ember.String.underscore(modelName);
      return (0, _emberInflector.pluralize)(decamelized);
    }
  });

  _exports.default = _default;
});