define("rd-basic-client/templates/partials/dataimport/importcompleted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xq2dkEzF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[10,\"style\",\"margin-top: 10px;\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"loading-message\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[7,\"i\",true],[10,\"class\",\"fa fa-check\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Congratulations!\"],[9],[0,\"\\n    \"],[7,\"h5\",true],[8],[0,\"Data imported successfully\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[10,\"style\",\"margin-top: 10px;\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group col-md-6\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"btn btn-success\"],[3,\"action\",[[23,0,[]],\"gotToStep\",1]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-magic\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" New Import\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group col-md-6 text-right\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[[24,[\"importType\",\"link\",\"path\"]]]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"btn btn-primary\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-external-link\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \"],[1,[24,[\"importType\",\"link\",\"label\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/partials/dataimport/importcompleted.hbs"
    }
  });

  _exports.default = _default;
});