define("rd-basic-client/routes/markets", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "moment"], function (_exports, _authenticatedRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    marketData: Ember.inject.service('market-data'),
    model: function model() {
      var instruments = this.store.peekAll('vault-instrument');
      var now = (0, _moment.default)();
      return Ember.RSVP.hash({
        classThreeFutures: instruments.filter(function (i) {
          return i.name.includes("Class III") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt').slice(0, 12),
        classFourFutures: instruments.filter(function (i) {
          return i.name.includes("Class IV") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt').slice(0, 12),
        dryWheyFutures: instruments.filter(function (i) {
          return i.name.includes("Dry Whey") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt').slice(0, 12),
        cashSettledCheeseFutures: instruments.filter(function (i) {
          return i.name.includes("Cheese") && !i.name.includes("Block") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt').slice(0, 12),
        nonfatDryMilkFutures: instruments.filter(function (i) {
          return i.name.includes("Nonfat Milk") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt').slice(0, 12),
        cashSettledButterFutures: instruments.filter(function (i) {
          // This isn't very robust.
          return i.name.includes("Butter") && !i.name.includes('European') && !i.name.includes('Global') && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt').slice(0, 12),
        cornFutures: instruments.filter(function (i) {
          return i.name.includes("Corn") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt').slice(0, 7),
        soybeanMealFutures: instruments.filter(function (i) {
          return i.name.includes("Soybean Meal") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt').slice(0, 7),
        soybeanFutures: instruments.filter(function (i) {
          return i.name.includes("Soybeans") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt').slice(0, 7),
        wheatFutures: instruments.filter(function (i) {
          return i.name.includes("Chicago Soft Red Winter Wheat") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt').slice(0, 7),
        liveCattleFutures: instruments.filter(function (i) {
          return i.name.includes("Live Cattle") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt').slice(0, 7),
        cheeseBarrel: this.store.findRecord('barchart-market-datum', 'C3Y00'),
        cheeseBlock: this.store.findRecord('barchart-market-datum', 'C4Y00'),
        spotButter: this.store.findRecord('barchart-market-datum', 'C5Y00'),
        spotNonfat: this.store.findRecord('barchart-market-datum', 'C2Y00')
      });
    },
    afterModel: function afterModel(model) {
      this.marketData.start();
      var instruments = [].concat(model.cheeseBarrel, model.cheeseBlock, model.spotButter, model.spotNonfat, model.classThreeFutures, model.classFourFutures, model.dryWheyFutures, model.cashSettledCheeseFutures, model.nonfatDryMilkFutures, model.cashSettledButterFutures, model.cornFutures, model.soybeanMealFutures, model.wheatFutures, model.soybeanFutures);
      this.marketData.subscribeTo(instruments);
    }
  });

  _exports.default = _default;
});