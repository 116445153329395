define("rd-basic-client/mixins/california", ["exports", "rd-basic-client/utils/whey-value-per-cwt"], function (_exports, _wheyValuePerCwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Class Four A
    caClassOneUtilizationSNF: function () {
      return this.snfClassOneUtilization === 0 ? this.snfClassOneUtilizationAvg : this.snfClassOneUtilization;
    }.property('snfClassOneUtilization', 'snfClassOneUtilizationAvg'),
    caClassTwoUtilizationSNF: function () {
      return this.snfClassTwoUtilization === 0 ? this.snfClassTwoUtilizationAvg : this.snfClassTwoUtilization;
    }.property('snfClassTwoUtilization', 'snfClassTwoUtilizationAvg'),
    caClassThreeUtilizationSNF: function () {
      return this.snfClassThreeUtilization === 0 ? this.snfClassThreeUtilizationAvg : this.snfClassThreeUtilization;
    }.property('snfClassThreeUtilization', 'snfClassThreeUtilizationAvg'),
    caClassFourAUtilizationSNF: function () {
      return this.snfClassFouraUtilization === 0 ? this.snfClassFouraUtilizationAvg : this.snfClassFouraUtilization;
    }.property('snfClassFouraUtilization', 'snfClassFouraUtilizationAvg'),
    caClassFourBUtilizationSNF: function () {
      return this.snfClassFourbUtilization === 0 ? this.snfClassFourbUtilizationAvg : this.snfClassFourbUtilization;
    }.property('snfClassFourbUtilization', 'snfClassFourbUtilizationAvg'),
    classOneSNFProduced: function () {
      return this.nonQuotaSolidsNonFat * this.caClassOneUtilizationSNF;
    }.property('nonQuotaSolidsNonFat', 'caClassOneUtilizationSNF'),
    quotaPrice: function () {
      var caQuotaSNFPoolPrice = this.caNonQuotaSNFPoolPrice + 0.195;
      return 3.5 * this.caTotalButterfat + caQuotaSNFPoolPrice * 8.7;
    }.property('caTotalButterfat', 'caNonQuotaSNFPoolPrice'),
    totalQuotaPrice: function () {
      return this.quotaPrice * (this.totalProduction / 100);
    }.property('quotaPrice', 'totalProduction'),
    nonQuotaPrice: function () {
      return 3.5 * this.caTotalButterfat + this.caNonQuotaSNFPoolPrice * 8.7;
    }.property('caTotalButterfat', 'caNonQuotaSNFPoolPrice'),
    totalNonQuotaPrice: function () {
      return this.nonQuotaPrice * (this.totalProduction / 100);
    }.property('nonQuotaPrice', 'totalProduction'),
    totalClassSNFProduced: function () {
      return this.classOneSNFProduced + this.classTwoSNFProduced + this.classThreeSNFProduced + this.classFourASNFProduced + this.classFourBSNFProduced;
    }.property('classOneSNFProduced', 'classTwoSNFProduced', 'classThreeSNFProduced', 'classFourASNFProduced', 'classFourBSNFProduced'),
    caNonQuotaSNFPoolPrice: function () {
      var classOneSNFRevenue = this.classOneSNFProduced * this.caOneNonfatSolids;
      var classTwoSNFRevenue = this.classTwoSNFProduced * this.caTwoNonfatSolids;
      var classThreeSNFRevenue = this.classThreeSNFProduced * this.caThreeNonfatSolids;
      var classFourASNFRevenue = this.classFourASNFProduced * this.caFourANonfatSolids;
      var classFourBSNFRevenue = this.classFourBSNFProduced * this.caFourBNonfatSolids;
      var totalClassSNFRevenue = classOneSNFRevenue + classTwoSNFRevenue + classThreeSNFRevenue + classFourASNFRevenue + classFourBSNFRevenue;
      return totalClassSNFRevenue / this.totalClassSNFProduced;
    }.property('totalClassSNFProduced', 'classOneSNFProduced', 'caOneNonfatSolids', 'classTwoSNFProduced', 'caTwoNonfatSolids', 'classThreeSNFProduced', 'caThreeNonfatSolids', 'classFourASNFProduced', 'caFourANonfatSolids', 'classFourBSNFProduced', 'caFourBNonfatSolids'),
    caGrossProceeds: function () {
      return this.quotaPercentage * this.quotaPrice + this.nonQuotaPercentage * this.nonQuotaPrice;
    }.property('quotaPercentage', 'nonQuotaPercentage', 'quotaPrice', 'nonQuotaPrice'),
    caTotalGrossProceeds: function () {
      return this.caGrossProceeds * (this.totalProduction / 100);
    }.property('caGrossProceeds', 'totalProduction'),
    classTwoSNFProduced: function () {
      return this.nonQuotaSolidsNonFat * this.caClassTwoUtilizationSNF;
    }.property('nonQuotaSolidsNonFat', 'caClassTwoUtilizationSNF'),
    classThreeSNFProduced: function () {
      return this.nonQuotaSolidsNonFat * this.caClassThreeUtilizationSNF;
    }.property('nonQuotaSolidsNonFat', 'caClassThreeUtilizationSNF'),
    classFourASNFProduced: function () {
      return this.nonQuotaSolidsNonFat * this.caClassFourAUtilizationSNF;
    }.property('nonQuotaSolidsNonFat', 'caClassFourAUtilizationSNF'),
    classFourBSNFProduced: function () {
      return this.nonQuotaSolidsNonFat * this.caClassFourBUtilizationSNF;
    }.property('nonQuotaSolidsNonFat', 'caClassFourBUtilizationSNF'),
    classOneFatProduced: function () {
      return this.classOneUtilizationFat * this.totalFatProduction;
    }.property('classOneUtilizationFat', 'totalFatProduction'),
    classOneFatRevenue: function () {
      return this.classOneFatProduced * this.caOneButterfat;
    }.property('classOneFatProduced', 'caOneButterfat'),
    classTwoFatProduced: function () {
      return this.classTwoUtilizationFat * this.totalFatProduction;
    }.property('classTwoUtilizationFat', 'totalFatProduction'),
    classTwoFatRevenue: function () {
      return this.classTwoFatProduced * this.caTwoButterfat;
    }.property('classTwoFatProduced', 'caTwoButterfat'),
    classThreeFatProduced: function () {
      return this.classThreeUtilizationFat * this.totalFatProduction;
    }.property('classThreeUtilizationFat', 'totalFatProduction'),
    classThreeFatRevenue: function () {
      return this.classThreeFatProduced * this.caThreeButterfat;
    }.property('classThreeFatProduced', 'caThreeButterfat'),
    classFourAFatProduced: function () {
      return this.classFourAUtilizationFat * this.totalFatProduction;
    }.property('classFourAUtilizationFat', 'totalFatProduction'),
    classFourAFatRevenue: function () {
      return this.classFourAFatProduced * this.caFourAButterfat;
    }.property('classFourAFatProduced', 'caFourAButterfat'),
    classFourBFatRevenue: function () {
      return this.classFourBFatProduced * this.caFourBButterfat;
    }.property('classFourBFatProduced', 'caFourBButterfat'),
    caTotalButterfat: function () {
      return this.totalClassFatRevenue / this.totalClassFatProduced;
    }.property('totalClassFatProduced', 'totalClassFatRevenue'),
    classFourBFatProduced: function () {
      return this.classFourBUtilizationFat * this.totalFatProduction;
    }.property('classFourBUtilizationFat', 'totalFatProduction'),
    totalClassFatRevenue: function () {
      return this.classOneFatRevenue + this.classTwoFatRevenue + this.classThreeFatRevenue + this.classFourAFatRevenue + this.classFourBFatRevenue;
    }.property('classOneFatRevenue', 'classTwoFatRevenue', 'classThreeFatRevenue', 'classFourAFatRevenue', 'classFourBFatRevenue'),
    totalClassFatProduced: function () {
      return this.classOneFatProduced + this.classTwoFatProduced + this.classThreeFatProduced + this.classFourAFatProduced + this.classFourBFatProduced;
    }.property('classOneFatProduced', 'classTwoFatProduced', 'classThreeFatProduced', 'classFourAFatProduced', 'classFourBFatProduced'),
    classFourAPrice: function () {
      return 3.5 * this.caFourAButterfat + 8.7 * this.caFourANonfatSolids;
    }.property('caFourAButterfat', 'caFourANonfatSolids'),
    caClassFourBPrice: function () {
      return 3.5 * this.caFourBButterfat + 8.7 * this.caFourBNonfatSolids;
    }.property('caFourBButterfat', 'caFourBNonfatSolids'),
    caFourAButterfat: function () {
      return (this.butterPricePerPound / 100 - 0.0485 - 0.1635) * 1.2;
    }.property('butterPricePerPound'),
    caFourBProductValue: function () {
      var wheyPricePerPound = this.dryWheyPricePerPound;
      var wheyFactor = (0, _wheyValuePerCwt.default)(wheyPricePerPound);
      return (this.cheesePricePerPound - 0.0252 - 0.1988) * 10.2 + (this.butterPricePerPound / 100 - 0.10 - 0.1635) * 0.27 + wheyFactor;
    }.property('cheesePricePerPound', 'butterPricePerPound', 'dryWheyPricePerPound'),
    caFourBNonfatSolids: function () {
      return (this.caFourBProductValue - 3.72 * this.caFourBButterfat) / 8.8;
    }.property('caFourBProductValue', 'caFourBButterfat'),
    caThreeButterfat: function () {
      var previous_month = this.get('previous_month_id');
      var two_months_back = this.get('previous_month_id.previous_month_id');

      if (previous_month !== null && two_months_back !== null && two_months_back !== undefined && previous_month.get('startDate') > new Date()) {
        if (two_months_back.get('startDate') > new Date()) {
          return (previous_month.get('caFourAButterfat') + two_months_back.get('caFourAButterfat')) / 2;
        } else {
          return (previous_month.get('caFourAButterfat') + this.caFourAButterfat) / 2;
        }
      } else {
        return this.caFourAButterfat;
      }
    }.property('caFourAButterfat', 'previous_month_id', 'previous_month_id.caFourAButterfat', 'previous_month_id.previous_month_id.caFourAButterfat'),
    scenarioCaThreeButterfat: function () {
      var previous_month = this.get('previous_month_id');
      var two_months_back = this.get('previous_month_id.previous_month_id');

      if (previous_month !== null && two_months_back !== null && two_months_back !== undefined && previous_month.get('startDate') > new Date()) {
        if (two_months_back.get('startDate') > new Date()) {
          return (previous_month.get('scenarioCaFourAButterfat') + two_months_back.get('scenarioCaFourAButterfat')) / 2;
        } else {
          return (previous_month.get('scenarioCaFourAButterfat') + this.scenarioCaFourAButterfat) / 2;
        }
      } else {
        return this.scenarioCaFourAButterfat;
      }
    }.property('scenarioCaFourAButterfat', 'previous_month_id', 'previous_month_id.scenarioCaFourAButterfat', 'previous_month_id.previous_month_id.scenarioCaFourAButterfat'),
    caThreeNonfatSolids: function () {
      var previous_month = this.get('previous_month_id');
      var two_months_back = this.get('previous_month_id.previous_month_id');
      var nonfatSolids;

      if (previous_month !== null && two_months_back !== null && two_months_back !== undefined && previous_month.get('startDate') > new Date()) {
        if (two_months_back.get('startDate') > new Date()) {
          nonfatSolids = (previous_month.get('caFourANonfatSolids') + two_months_back.get('caFourANonfatSolids')) / 2;
        } else {
          nonfatSolids = (previous_month.get('caFourANonfatSolids') + this.caFourANonfatSolids) / 2;
        }
      } else {
        nonfatSolids = this.caFourANonfatSolids;
      }

      return nonfatSolids + 0.0433;
    }.property('caFourANonfatSolids', 'previous_month_id', 'previous_month_id.caFourANonfatSolids', 'previous_month_id.previous_month_id.caFourANonfatSolids'),
    scenarioCaThreeNonfatSolids: function () {
      var previous_month = this.get('previous_month_id');
      var two_months_back = this.get('previous_month_id.previous_month_id');
      var nonfatSolids;

      if (previous_month !== null && two_months_back !== null && two_months_back !== undefined && previous_month.get('startDate') > new Date()) {
        if (two_months_back.get('startDate') > new Date()) {
          nonfatSolids = (previous_month.get('scenarioCaFourANonfatSolids') + two_months_back.get('scenarioCaFourANonfatSolids')) / 2;
        } else {
          nonfatSolids = (previous_month.get('scenarioCaFourANonfatSolids') + this.scenarioCaFourANonfatSolids) / 2;
        }
      } else {
        nonfatSolids = this.scenarioCaFourANonfatSolids;
      }

      return nonfatSolids + 0.0433;
    }.property('scenarioCaFourANonfatSolids', 'previous_month_id', 'previous_month_id.scenarioCaFourANonfatSolids', 'previous_month_id.previous_month_id.scenarioCaFourANonfatSolids'),
    caClassThreePrice: function () {
      return this.caThreeButterfat * 3.5 + 8.7 * this.caThreeNonfatSolids;
    }.property('caThreeButterfat', 'caThreeNonfatSolids'),
    scenarioCaClassThreePrice: function () {
      return this.scenarioCaThreeButterfat * 3.5 + 8.7 * this.scenarioCaThreeNonfatSolids;
    }.property('scenarioCaThreeButterfat', 'scenarioCaThreeNonfatSolids'),
    caTwoButterfat: function () {
      var previous_month = this.get('previous_month_id');
      var two_months_back = this.get('previous_month_id.previous_month_id');

      if (previous_month !== null && two_months_back !== null && two_months_back !== undefined && previous_month.get('startDate') > new Date()) {
        if (two_months_back.get('startDate') > new Date()) {
          return (previous_month.get('caFourAButterfat') + two_months_back.get('caFourAButterfat')) / 2;
        } else {
          return (previous_month.get('caFourAButterfat') + this.caFourAButterfat) / 2;
        }
      } else {
        return this.caFourAButterfat;
      }
    }.property('caFourAButterfat', 'previous_month_id', 'previous_month_id.caFourAButterfat', 'previous_month_id.previous_month_id.caFourAButterfat'),
    scenarioCaTwoButterfat: function () {
      var previous_month = this.get('previous_month_id');
      var two_months_back = this.get('previous_month_id.previous_month_id');

      if (previous_month !== null && two_months_back !== null && two_months_back !== undefined && previous_month.get('startDate') > new Date()) {
        if (two_months_back.get('startDate') > new Date()) {
          return (previous_month.get('scenarioCaFourAButterfat') + two_months_back.get('scenarioCaFourAButterfat')) / 2;
        } else {
          return (previous_month.get('scenarioCaFourAButterfat') + this.scenarioCaFourAButterfat) / 2;
        }
      } else {
        return this.scenarioCaFourAButterfat;
      }
    }.property('scenarioCaFourAButterfat', 'previous_month_id', 'previous_month_id.scenarioCaFourAButterfat', 'previous_month_id.previous_month_id.scenarioCaFourAButterfat'),
    caTwoNonfatSolids: function () {
      var previous_month = this.get('previous_month_id');
      var two_months_back = this.get('previous_month_id.previous_month_id');
      var nonfatSolids = 0;

      if (previous_month !== null && two_months_back !== null && two_months_back !== undefined && previous_month.get('startDate') > new Date()) {
        if (two_months_back.get('startDate') > new Date()) {
          nonfatSolids = (previous_month.get('caFourANonfatSolids') + two_months_back.get('caFourANonfatSolids')) / 2;
        } else {
          nonfatSolids = (previous_month.get('caFourANonfatSolids') + this.caFourANonfatSolids) / 2;
        }
      } else {
        nonfatSolids = this.caFourANonfatSolids;
      }

      return nonfatSolids + (this.get('user.location.considerNorthernCalifornia') ? 0.0490 : 0.0757);
    }.property('caFourANonfatSolids', 'user.location.considerNorthernCalifornia', 'previous_month_id', 'previous_month_id.caFourANonfatSolids', 'previous_month_id.previous_month_id.caFourANonfatSolids'),
    scenarioCaTwoNonfatSolids: function () {
      var previous_month = this.get('previous_month_id');
      var two_months_back = this.get('previous_month_id.previous_month_id');
      var nonfatSolids;

      if (previous_month !== null && two_months_back !== null && two_months_back !== undefined && previous_month.get('startDate') > new Date()) {
        if (two_months_back.get('startDate') > new Date()) {
          nonfatSolids = (previous_month.get('scenarioCaFourANonfatSolids') + two_months_back.get('scenarioCaFourANonfatSolids')) / 2;
        } else {
          nonfatSolids = (previous_month.get('scenarioCaFourANonfatSolids') + this.scenarioCaFourANonfatSolids) / 2;
        }
      } else {
        nonfatSolids = this.scenarioCaFourANonfatSolids;
      }

      return nonfatSolids + (this.get('user.location.considerNorthernCalifornia') ? 0.0490 : 0.0757);
    }.property('scenarioCaFourANonfatSolids', 'user.location.considerNorthernCalifornia', 'previous_month_id', 'previous_month_id.scenarioCaFourANonfatSolids', 'previous_month_id.previous_month_id.scenarioCaFourANonfatSolids'),
    caClassTwoPrice: function () {
      return 3.5 * this.caTwoButterfat + 8.7 * this.caTwoNonfatSolids;
    }.property('caTwoButterfat', 'caTwoNonfatSolids'),
    caOneButterfat: function () {
      return (this.butterPricePerPound / 100 - 0.1315) * 1.2;
    }.property('butterPricePerPound'),
    scenarioCaOneButterfat: function () {
      return (this.scenarioButterPricePerPound / 100 - 0.1315) * 1.2;
    }.property('scenarioButterPricePerPound'),
    scenarioCaFourAButterfat: function () {
      var v = (this.scenarioButterPricePerPound / 100 - 0.0485 - 0.1635) * 1.2;
      return v;
    }.property('scenarioButterPricePerPound'),
    caFourANonfatSolids: function () {
      return this.nonfatDryMilkPricePerPound - 0.1763;
    }.property('nonfatDryMilkPricePerPound'),
    scenarioCaFourANonfatSolids: function () {
      return this.scenarioNonfatDryMilkPricePerPound - 0.1763;
    }.property('scenarioNonfatDryMilkPricePerPound'),
    caFourBButterfat: function () {
      return (this.butterPricePerPound / 100 - 0.0485 - 0.1635) * 1.2;
    }.property('butterPricePerPound'),
    scenarioCaFourBButterfat: function () {
      return (this.scenarioButterPricePerPound / 100 - 0.0485 - 0.1635) * 1.2;
    }.property('scenarioButterPricePerPound'),
    caCRP: function () {
      var optionOne = this.cheesePricePerPound * 9.8 + (this.butterPricePerPound / 100 - 0.10) * 0.27 + (this.dryWheyPricePerPound * 5.8 - 0.85);
      var optionTwo = this.butterPricePerPound / 100 * 1.2 * 3.5 + this.nonfatDryMilkPricePerPound * 0.99 * 8.7;
      return Math.max(optionOne, optionTwo);
    }.property('cheesePricePerPound', 'butterPricePerPound', 'dryWheyPricePerPound', 'nonfatDryMilkPricePerPound'),
    scenarioCaCRP: function () {
      var optionOne = this.scenarioCheesePricePerPound * 9.8 + (this.scenarioButterPricePerPound / 100 - 0.10) * 0.27 + (this.scenarioDryWheyPricePerPound * 5.8 - 0.85);
      var optionTwo = this.scenarioButterPricePerPound / 100 * 1.2 * 3.5 + this.scenarioNonfatDryMilkPricePerPound * 0.99 * 8.7;
      return Math.max(optionOne, optionTwo);
    }.property('scenarioCheesePricePerPound', 'scenarioButterPricePerPound', 'scenarioDryWheyPricePerPound', 'scenarioNonfatDryMilkPricePerPound'),
    caOneNonfatSolids: function () {
      return (this.caCRP - 0.203 - this.caOneButterfat * 3.5) * 0.76 / 8.7;
    }.property('caCRP', 'caOneButterfat'),
    scenarioCaOneNonfatSolids: function () {
      return (this.scenarioCaCRP - 0.203 - this.scenarioCaOneButterfat * 3.5) * 0.76 / 8.7;
    }.property('scenarioCaCRP', 'scenarioCaOneButterfat'),
    caOneFluidCarrier: function () {
      return (this.caCRP - 0.203 - this.caOneButterfat * 3.5) * 0.24 / 87.8;
    }.property('caCRP', 'caOneButterfat'),
    caClassOnePrice: function () {
      return 3.5 * this.caOneButterfat + 8.7 * this.caOneNonfatSolids + 87.8 * this.caOneFluidCarrier;
    }.property('caOneButterfat', 'caOneNonfatSolids', 'caOneFluidCarrier')
  });

  _exports.default = _default;
});