define("rd-basic-client/templates/partials/menu/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3C34l8pp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[10,\"class\",\"dropdown-menu\"],[8],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"historicalpriceregistry\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-usd\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Historical Prices\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"pricescenariosgrid\"]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"fa fa-blind\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Price Scenario Grid\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/partials/menu/admin.hbs"
    }
  });

  _exports.default = _default;
});