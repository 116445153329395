define("rd-basic-client/controllers/milkreconciliation", ["exports", "rd-basic-client/mixins/editable-months", "rd-basic-client/mixins/pricingModel", "rd-basic-client/config/environment", "moment"], function (_exports, _editableMonths, _pricingModel, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Pricing Models
  var PM = _environment.default.APP.CONSTANTS.PRICING_MODELS;

  var _default = Ember.Controller.extend(_editableMonths.default, _pricingModel.default, {
    chartType: function (name, value) {
      if (arguments.length > 1) {
        return parseInt(value, 10);
      }
    }.property(),
    basisAverageMonth: function () {
      return this.get('model.prevMonths.lastObject');
    }.property('model.prevMonths.@each', 'model.prevMonths.@each.vaultBasis', 'model.prevMonths.@each.vaultBasisPrice'),
    oneYearAvg: function () {
      return this.get('basisAverageMonth').getBasisAvg(12);
    }.property('basisAverageMonth', 'model.user.basisType', 'basisAverageMonth.vaultBasisPrice', 'basisAverageMonth.vaultBasis'),
    twoYearsAvg: function () {
      return this.get('basisAverageMonth').getBasisAvg(24);
    }.property('basisAverageMonth', 'model.user.basisType', 'basisAverageMonth.vaultBasisPrice', 'basisAverageMonth.vaultBasis'),
    threeYearsAvg: function () {
      return this.get('basisAverageMonth').getBasisAvg(36);
    }.property('basisAverageMonth', 'model.user.basisType', 'basisAverageMonth.vaultBasisPrice', 'basisAverageMonth.vaultBasis'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('selectQuery', '');
      this.set('marketDataInstruments', []);
      this.set('updateMilkPriceAdjustments', 0);
      this.set('updateClassOneDifferential', 0);
      this.set('updateFmmoDairyPrices', true);
      this.set('perCWT', true);
      this.set('perCow', false);
      this.set('perTotal', false);
      this.set('chartType', 0);
      this.changeCurrentYear();
    },
    classOneChart: function () {
      var config = {};
      config.columns = [['x'].concat(this.get('currentMonths').map(function (m) {
        return _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD');
      })), ['data1'].concat(this.get('currentMonths').map(function (m) {
        return m.get('classOnePrice');
      })), ['data2'].concat(this.get('currentMonths').map(function (m) {
        return m.get('classOneActual');
      }))];
      config.names = {
        data1: 'Projected',
        data2: 'Actual'
      };
      return config;
    }.property('currentMonths', 'classOnePrice', 'classOneActual'),
    classTwoChart: function () {
      var config = {};
      config.columns = [['x'].concat(this.get('currentMonths').map(function (m) {
        return _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD');
      })), ['data1'].concat(this.get('currentMonths').map(function (m) {
        return m.get('classTwoPrice');
      })), ['data2'].concat(this.get('currentMonths').map(function (m) {
        return m.get('classTwoActual');
      }))];
      config.names = {
        data1: 'Projected',
        data2: 'Actual'
      };
      return config;
    }.property('currentMonths', 'currentMonths.@each.classTwoPrice', 'currentMonths.@each.classTwoActual'),
    mailboxPriceChart: function () {
      var config = {};
      config.columns = [['x'].concat(this.get('currentMonths').map(function (m) {
        return _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD');
      })), ['data1'].concat(this.get('currentMonths').map(function (m) {
        return m.get('milkCheckWithoutBasis');
      })), ['data2'].concat(this.get('currentMonths').map(function (m) {
        return m.get('vaultActualMilkCheck');
      }))];
      config.names = {
        data1: 'Projected',
        data2: 'Actual'
      };
      return config;
    }.property('currentMonths', 'currentMonths.@each.vaultActualMilkCheck', 'currentMonths.@each.milkCheckWithoutBasis'),
    grossProceedsChart: function () {
      var config = {};
      config.columns = [['x'].concat(this.get('currentMonths').map(function (m) {
        return _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD');
      })), ['data1'].concat(this.get('currentMonths').map(function (m) {
        return m.get('caGrossProceeds');
      })), ['data2'].concat(this.get('currentMonths').map(function (m) {
        return m.get('grossProceedsActual');
      }))];
      config.names = {
        data1: 'Projected',
        data2: 'Actual'
      };
      return config;
    }.property('currentMonths', 'currentMonths.@each.grossProceedsActual', 'currentMonths.@each.caGrossProceeds'),

    /**************************************************************/

    /**************************** FMMO ****************************/

    /**************************************************************/
    fmmoChart: function () {
      var data = {};
      var unload = null;

      if (this.get('chartUnload')) {
        unload = this.get('chartUnload');
        this.set('chartUnload', null);
      }

      var i = parseInt(this.get('chartType'));

      switch (i) {
        case 0:
          data = this.get('mailboxPriceChart');
          break;

        case 1:
          data = this.get('classTwoChart');
          break;

        case 2:
          data = this.get('fmmoBasisChart');
          this.set('chartUnload', ['data3', 'data4']);
          break;

        case 3:
          data = this.get('classOneChart');
          break;
      }

      if (unload) {
        data.unload = unload;
      }

      return data;
    }.property('classOneChart', 'classTwoChart', 'chartType', 'mailboxPriceChart', 'fmmoBasisChart'),
    fmmoBasisChart: function () {
      var config = {};
      var basisType = this.get('model.user.basisType') * 1;
      config.columns = [['x'].concat(this.get('currentMonths').map(function (m) {
        return _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD');
      })), ['data1'].concat(this.get('currentMonths').map(function (m) {
        return m.get('blendedActual') - m.get('classThreePrice');
      })), ['data2'].concat(this.get('currentMonths').map(function (m) {
        return m.get('blendedActual') - m.get('classFourPrice');
      })), ['data3'].concat(this.get('currentMonths').map(function (m) {
        return m.get('blendedActual') - m.get('blendedMilkPrice');
      }))];
      config.names = {
        data1: 'Class III',
        data2: 'Class IV',
        data3: 'ever.ag Blend'
      };
      return config;
    }.property('currentMonths', 'currentMonths.@each.blendedMilkPrice', 'currentMonths.@each.blendedActual', 'currentMonths.@each.vaultBasisPrice'),

    /**************************************************************/

    /***************************** UDA ****************************/

    /**************************************************************/
    udaChart: function () {
      var data = null;
      var unload = null;

      if (this.get('chartUnload')) {
        unload = this.get('chartUnload');
        this.set('chartUnload', null);
      }

      var i = parseInt(this.get('chartType'));

      switch (i) {
        case 0:
          data = this.get('mailboxPriceChart');
          break;

        case 1:
          data = this.get('udaBasisChart');
          this.set('chartUnload', ['data3']);
          break;

        default:
          data = {};
      }

      if (unload) {
        data.unload = unload;
      }

      return data;
    }.property('chartType', 'mailboxPriceChart', 'udaBasisChart'),
    udaBasisChart: function () {
      var config = {};
      var basisType = this.get('model.user.basisType') * 1;
      config.columns = [['x'].concat(this.get('currentMonths').map(function (m) {
        return _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD');
      })), ['data1'].concat(this.get('currentMonths').map(function (m) {
        return m.get('blendedActual') - m.get('classThreePrice');
      })), ['data2'].concat(this.get('currentMonths').map(function (m) {
        return m.get('blendedActual') - m.get('classFourPrice');
      })), ['data3'].concat(this.get('currentMonths').map(function (m) {
        return m.get('blendedActual') - m.get('udaBlendedMilkPriceAndProtein');
      }))];
      config.names = {
        data1: 'Class III',
        data2: 'Class IV',
        data3: 'ever.ag Blend'
      };
      return config;
    }.property('currentMonths', 'currentMonths.@each.blendedMilkPrice', 'currentMonths.@each.blendedActual', 'currentMonths.@each.vaultBasisPrice', 'udaBlendedMilkPriceAndProtein'),

    /**************************************************************/

    /************************* CALIFORNIA *************************/

    /**************************************************************/
    caChart: function () {
      var data = null;
      var unload = null;

      if (this.get('chartUnload')) {
        unload = this.get('chartUnload');
        this.set('chartUnload', null);
      }

      var i = parseInt(this.get('chartType'));

      switch (i) {
        case 0:
          data = this.get('mailboxPriceChart');
          break;

        case 1:
          data = this.get('grossProceedsChart');
          break;

        case 2:
          data = this.get('caBasisChart');
          this.set('chartUnload', ['data3']);
          break;

        default:
          data = this.get('mailboxPriceChart');
      }

      if (unload) {
        data.unload = unload;
      }

      return data;
    }.property('chartType', 'mailboxPriceChart', 'caBasisChart'),
    caBasisChart: function () {
      var config = {};
      var basisType = this.get('model.user.basisType') * 1;
      config.columns = [['x'].concat(this.get('currentMonths').map(function (m) {
        return _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD');
      })), ['data1'].concat(this.get('currentMonths').map(function (m) {
        return m.get('classFourAActualBest') - m.get('classFourAPrice');
      })), ['data2'].concat(this.get('currentMonths').map(function (m) {
        return m.get('classFourBActualBest') - m.get('classFourBPrice');
      })), ['data3'].concat(this.get('currentMonths').map(function (m) {
        return m.get('grossProceedsActual') - m.get('caGrossProceeds');
      }))];
      config.names = {
        data1: 'Class IVa',
        data2: 'Class IVb',
        data3: 'ever.ag Blend'
      };
      return config;
    }.property('currentMonths', 'currentMonths.@each.classFourAActualBest', 'currentMonths.@each.classFourBActualBest', 'currentMonths.@each.caGrossProceeds', 'currentMonths.@each.classFourAPrice', 'currentMonths.@each.classFourBPrice'),

    /**************************************************************/

    /*************************** GLANBIA **************************/

    /**************************************************************/
    glanbiaChart: function () {
      var data = null;
      var unload = null;

      if (this.get('chartUnload')) {
        unload = this.get('chartUnload');
        this.set('chartUnload', null);
      }

      var i = parseInt(this.get('chartType'));

      switch (i) {
        case 0:
          data = this.get('mailboxPriceChart');
          break;

        default:
          data = this.get('mailboxPriceChart');
      }

      if (unload) {
        data.unload = unload;
      }

      return data;
    }.property('chartType', 'mailboxPriceChart'),
    reconciliationPrices: function () {
      var data = null;

      switch (this.get('model.user.pricingModel')) {
        case PM.CALIFORNIA:
          data = this.get('caChart');
          break;

        case PM.FMMO:
          data = this.get('fmmoChart');
          break;

        case PM.GLANBIA:
          data = this.get('glanbiaChart');
          break;

        case PM.UDA:
          data = this.get('udaChart');
          break;
      }

      return data;
    }.property('model.user.pricingModel', 'chartType', 'fmmoChart', 'caChart', 'udaChart', 'glanbiaChart'),
    changeCurrentYear: function changeCurrentYear() {
      var afterDate = _moment.default.utc().date(1);

      if ((0, _moment.default)().utc().date() < _environment.default.APP.MONTH.ROLLBACK_DATE) {
        afterDate.subtract(1, 'months');
      }

      afterDate = _moment.default.utc(afterDate).subtract(_environment.default.APP.MONTH.SHOW, 'months');
      afterDate.subtract(1, 'days');
      this.set('currentYear', afterDate.format('YYYY'));
    },
    chartTypes: function () {
      if (this.get('isFmmo')) {
        return [{
          id: 0,
          label: 'Mailbox Price'
        }, {
          id: 3,
          label: 'Class I'
        }, {
          id: 1,
          label: 'Class II'
        }, {
          id: 2,
          label: 'Blended Milk Price Basis'
        }];
      } else if (this.get('isCalifornia')) {
        return [{
          id: 0,
          label: 'Mailbox Price'
        }, {
          id: 2,
          label: 'Blended Milk Price Basis'
        }];
      } else if (this.get('isGlanbia')) {
        return [{
          id: 0,
          label: 'Mailbox Price'
        }];
      } else {
        return [{
          id: 0,
          label: 'Mailbox Price'
        }, {
          id: 1,
          label: 'Blended Milk Price'
        }];
      }
    }.property('isFmmo', 'isCalifornia', 'isGlanbia', 'isUda'),
    basisTypes: function () {
      if (this.get('isFmmo')) {
        return [{
          id: 0,
          label: 'ever.ag Basis'
        }, {
          id: 1,
          label: 'Class III Basis'
        }, {
          id: 2,
          label: 'Class IV Basis'
        }];
      } else if (this.get('isCalifornia')) {
        return [{
          id: 0,
          label: 'ever.ag Basis'
        }, {
          id: 1,
          label: 'Class 4a Basis'
        }, {
          id: 2,
          label: 'Class 4b Basis'
        }, {
          id: 3,
          label: 'Class III Basis'
        }, {
          id: 4,
          label: 'Class IV Basis'
        }];
      } else if (this.get('isGlanbia')) {
        return [{
          id: 0,
          label: 'ever.ag Basis'
        }
        /*, {
           id: 1,
           label: 'Class IV Basis'
         }, {
           id: 2,
           label: 'Class III Basis'
         }*/
        ];
      } else {
        return [{
          id: 0,
          label: 'ever.ag Basis'
        }, {
          id: 1,
          label: 'Class III Basis'
        }, {
          id: 2,
          label: 'Class IV Basis'
        }];
      }
    }.property('isFmmo', 'isGlanbia', 'isCalifornia', 'isUda'),
    isVaultBasis: function () {
      return parseInt(this.get('model.user.basisType'), 10) === 0;
    }.property('model.user.basisType'),
    isClassThreeBasis: function () {
      return parseInt(this.get('model.user.basisType'), 10) === 1;
    }.property('model.user.basisType'),
    isClassFourBasis: function () {
      return parseInt(this.get('model.user.basisType'), 10) === 2;
    }.property('model.user.basisType'),
    marketDataInstruments: null,
    actions: {
      openSystemModel: function openSystemModel() {
        this.set('updateFmmoDairyPrices', this.get('model.user.pricingModel') === PM.FMMO);
        Ember.$('#order-system-modal').modal('show');
      },
      saveSystemModel: function saveSystemModel() {
        this.set('model.user.pricingModel', this.get('tmpPricingModel'));
        this.get('model.user').save();
        Ember.$('#order-system-modal').modal('hide');
      },
      openClassIModel: function openClassIModel() {
        this.set('updateClassOneDifferential', this.get('model.user.manualClassOneDifferential'));
        Ember.$('#classI-modal').modal('show');
      },
      saveClassIModel: function saveClassIModel() {
        this.set('model.user.manualClassOneDifferential', this.get('updateClassOneDifferential'));
        this.get('model.user').save();
        Ember.$('#classI-modal').modal('hide');
      },
      setCWT: function setCWT() {
        this.set('perCWT', true);
        this.set('perCow', false);
        this.set('perTotal', false);
      },
      setTotal: function setTotal() {
        this.set('perCWT', false);
        this.set('perCow', false);
        this.set('perTotal', true);
      },
      setCow: function setCow() {
        this.set('perCWT', false);
        this.set('perCow', true);
        this.set('perTotal', false);
      },

      /**
       * Updating the tmpPricingModel.
       * Model value will be updated on 'Save' button click
      **/
      updatePricingModel: function updatePricingModel(type) {
        this.set('tmpPricingModel', type);
      }
    }
  });

  _exports.default = _default;
});