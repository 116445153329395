define("rd-basic-client/services/market-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    pollTimer: null,
    pollFrequency: 2500,
    subscribedInstruments: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.subscribedInstruments = [];
    },
    start: function start()
    /*subscriber, */
    {
      if (this.get("pollTimer") !== null) {
        Ember.run.cancel(this.get("pollTimer"));
      }

      this.marketDataPoll();
    },
    stop: function stop()
    /*subscriber*/
    {
      Ember.run.cancel(this.get("pollTimer"));
    },
    subscribeTo: function subscribeTo(instruments) {
      this.set('subscribedInstruments', instruments);
    },
    marketDataPoll: function marketDataPoll() {
      var _this = this;

      var instruments = this.get('subscribedInstruments');
      var instrumentId;
      instruments.forEach(function (i) {
        instrumentId = i.get('barchartSymbol.id');

        if (instrumentId) {
          _this.store.findRecord("barchart-market-datum", instrumentId);
        }
      });
      this.set("pollTimer", Ember.run.later(this, this.marketDataPoll.bind(this), this.get("pollFrequency")));
    }
  });

  _exports.default = _default;
});