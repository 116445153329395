define("rd-basic-client/routes/pricescenarios", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "moment", "rd-basic-client/utils/month-market-data-instruments"], function (_exports, _authenticatedRouteMixin, _moment, _monthMarketDataInstruments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    marketData: Ember.inject.service('market-data'),
    model: function model() {
      var application = this.modelFor('application');
      return Ember.RSVP.hash({
        months: application.months,
        hypotheticalPositions: this.store.findAll("hypothetical_position", {
          reload: true
        }),
        instruments: this.store.peekAll('vault-instrument')
      });
    },
    afterModel: function afterModel(model) {
      var afterDate = _moment.default.utc().startOf('month');

      if (afterDate.date() < 8) {
        afterDate.subtract(1, "months");
      } else {
        afterDate.subtract(1, "days");
      }

      var beforeDate = _moment.default.utc(afterDate).add(25, "months");

      model.classThreeFutures = model.instruments.filter(function (i) {
        return i.get('rootSymbol') == 'DC' && !i.get('option_type') && _moment.default.utc(i.get('displayExpiresAt')).isBefore(beforeDate) && _moment.default.utc(i.get('displayExpiresAt')).isAfter(afterDate);
      }).sortBy('displayExpiresAt');
      model.classFourFutures = model.instruments.filter(function (i) {
        return i.get('rootSymbol') == 'GDK' && !i.get('option_type') && _moment.default.utc(i.get('displayExpiresAt')).isBefore(beforeDate) && _moment.default.utc(i.get('displayExpiresAt')).isAfter(afterDate);
      }).sortBy('displayExpiresAt');
      model.dryWheyFutures = model.instruments.filter(function (i) {
        return i.get('rootSymbol') == 'DY' && !i.get('option_type') && _moment.default.utc(i.get('displayExpiresAt')).isBefore(beforeDate) && _moment.default.utc(i.get('displayExpiresAt')).isAfter(afterDate);
      }).sortBy('displayExpiresAt');
      model.cashSettledCheeseFutures = model.instruments.filter(function (i) {
        return i.get('rootSymbol') == 'CSC' && !i.get('option_type') && _moment.default.utc(i.get('displayExpiresAt')).isBefore(beforeDate) && _moment.default.utc(i.get('displayExpiresAt')).isAfter(afterDate);
      }).sortBy('displayExpiresAt');
      model.nonfatDryMilkFutures = model.instruments.filter(function (i) {
        return i.get('rootSymbol') == 'GNF' && !i.get('option_type') && _moment.default.utc(i.get('displayExpiresAt')).isBefore(beforeDate) && _moment.default.utc(i.get('displayExpiresAt')).isAfter(afterDate);
      }).sortBy('displayExpiresAt');
      model.cashSettledButterFutures = model.instruments.filter(function (i) {
        return i.get('rootSymbol') == 'CB' && !i.get('option_type') && _moment.default.utc(i.get('displayExpiresAt')).isBefore(beforeDate) && _moment.default.utc(i.get('displayExpiresAt')).isAfter(afterDate);
      }).sortBy('displayExpiresAt');
      model.soybeanMealFutures = model.instruments.filter(function (i) {
        return i.get('rootSymbol') == 'ZM' && !i.get('option_type') && _moment.default.utc(i.get('displayExpiresAt')).isBefore(beforeDate) && _moment.default.utc(i.get('displayExpiresAt')).isAfter(afterDate);
      }).sortBy('displayExpiresAt');
      model.cornFutures = model.instruments.filter(function (i) {
        return i.get('rootSymbol') == 'ZC' && !i.get('option_type') && _moment.default.utc(i.get('displayExpiresAt')).isBefore(beforeDate) && _moment.default.utc(i.get('displayExpiresAt')).isAfter(afterDate);
      }).sortBy('displayExpiresAt');
      var months = model.months;
      var current = months.filter(function (record) {
        if (record.get("startDate") === undefined) {
          return false;
        }

        var v = _moment.default.utc(record.get('startDate')).isAfter(afterDate) && _moment.default.utc(record.get('startDate')).isBefore(beforeDate);

        return v;
      });
      model.selectableMonths = current.sortBy("startDate").map(function (m) {
        return Ember.Object.create({
          month: m,
          checked: false
        });
      });
      this.marketData.start();
      var instruments = (0, _monthMarketDataInstruments.default)(model.months, true);
      this.marketData.subscribeTo(instruments);
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});