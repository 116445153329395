define("rd-basic-client/mixins/nonfat-dry-milk", ["exports", "rd-basic-client/mixins/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_common.default, {
    nonfatDryMilkProduced: function () {
      return this.classesTwoAndFourProduction * 0.0859815;
    }.property('classesTwoAndFourProduction'),
    nonfatDryMilkCashValueProduced: function () {
      return this.nonfatDryMilkProduced * (this.nonfatDryBestPriceFormatted - 0.1678);
    }.property('nonfatDryMilkProduced', 'nonfatDryBestPriceFormatted'),
    totalNonfatDryMilkPoundsHedged: function () {
      return this.nonfatDryMilkPoundsHedged + this.nonfatDryMilkPoundsFromClassFourHedged;
    }.property('nonfatDryMilkPoundsHedged', 'nonfatDryMilkPoundsFromClassFourHedged'),
    nonfatDryMilkCashValueHedged: function () {
      return this.totalNonfatDryMilkPoundsHedged * (this.nonfatDryBestPriceFormatted / 100 - 0.1678);
    }.property('totalNonfatDryMilkPoundsHedged', 'nonfatDryBestPriceFormatted'),
    nonfatDryMilkPercentageHedged: function () {
      return this.nonfatDryMilkCashValueHedged / this.nonfatDryMilkCashValueProduced || 0;
    }.property('nonfatDryMilkCashValueHedged', 'nonfatDryMilkCashValueProduced'),
    nonfatDryMilkPoundsHedged: function () {
      return this.getPoundsHedged('GNF');
    }.property('physicalPositions.@each.quantity'),
    nonfatDryMilkOptionPremium: function () {
      return this.getPositionsValue('GNF', 'Option', 'premiumInDollars');
    }.property('positions.@each.premiumInDollars'),
    nonfatDryMilkFuturePL: function () {
      return this.getPositionsValue('GNF', 'Future', 'plInDollars');
    }.property('positions.@each.plInDollars'),
    scenarioNonfatDryMilkFuturePL: function () {
      return this.getPositionsValue('GNF', 'Future', 'scenarioPlInDollars');
    }.property('positions.@each.scenarioPlInDollars'),
    nonfatDryMilkOptionPL: function () {
      return this.getPositionsValue('GNF', 'Option', 'plInDollars');
    }.property('positions.@each.plInDollars'),
    scenarioNonfatDryMilkOptionPL: function () {
      return this.getPositionsValue('GNF', 'Option', 'scenarioPlInDollars');
    }.property('positions.@each.scenarioPlInDollars'),
    offExchangeNonfatDryMilkPL: function () {
      return this.getOffExchangePL('GNF', this.nonfatDryBestPriceFormatted);
    }.property('physicalPositions.@each', 'nonfatDryBestPrice', 'nonfatDryMilkInstrument.product.tick_size'),
    scenarioOffExchangeNonfatDryMilkPL: function () {
      return this.getScenarioOffExchangePL('GNF', this.scenarioNonfatDryMilkBestPriceFormatted);
    }.property('physicalPositions.@each', 'scenarioNonfatDryMilkBestPriceFormatted', 'nonfatDryMilkInstrument.product.tick_size', 'isCurrentMonth', 'hypotheticalPositions.@each'),
    nonfatDryMilkNetPL: function () {
      return this.offExchangeNonfatDryMilkPL + this.nonfatDryMilkFuturePL + this.nonfatDryMilkOptionPL;
    }.property('offExchangeNonfatDryMilkPL', 'nonfatDryMilkFuturePL', 'nonfatDryMilkOptionPL')
  });

  _exports.default = _default;
});