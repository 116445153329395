define("rd-basic-client/routes/callback", ["exports", "ember-simple-auth/mixins/oauth2-implicit-grant-callback-route-mixin"], function (_exports, _oauth2ImplicitGrantCallbackRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_oauth2ImplicitGrantCallbackRouteMixin.default, {
    authenticator: 'authenticator:oauth2-implicit-grant',
    actions: {
      didTransition: function didTransition() {
        // If Ember-Simple-Auth interprets the request as the user
        // wanting to go to this route, redirect the user.
        //
        // This happens when the user is already logged in, but is given a link
        // to the OAuth flow
        //this.transitionTo('/');
        // This is required due to the application structure (i.e. getting data at the application level)
        // This forces a reload, which allows all of the data to load correctly.
        window.location.replace("/");
      }
    }
  });

  _exports.default = _default;
});