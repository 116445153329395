define("rd-basic-client/components/month-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSelected: false,
    updateMonths: function () {
      var isSelected = this.get("isSelected");

      if (isSelected) {
        this.get("selectedMonths").add(this.get("month"));
      } else {
        this.get("selectedMonths").remove(this.get("month"));
      }
    }.observes("isSelected"),
    updateSelected: function () {
      if (this.get("selectedMonths").contains(this.get("month"))) {
        this.set("isSelected", true);
      } else {
        this.set("isSelected", false);
      }
    }.observes("selectedMonths.[]")
  });

  _exports.default = _default;
});