define("rd-basic-client/components/display-percentile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inverted: false,
    percentile: function () {
      var i = null;
      var v = this.get("value");
      var range = this.get("range");

      if (!Ember.isNone(v)) {
        range.forEach(function (element, index) {
          if (element >= v && i === null) {
            i = index;
          }
        });
      }

      if (i === null) {
        return 1;
      } else {
        var val = i / range.length;
        return val;
      }
    }.property("value", "range.@each"),
    color: function () {
      var color1;
      var color2;

      if (this.get("inverted")) {
        color2 = "008000";
        color1 = "FF0000";
      } else {
        color1 = "008000";
        color2 = "FF0000";
      }

      var ratio = this.get("percentile");

      var hex = function hex(x) {
        x = x.toString(16);
        return x.length === 1 ? '0' + x : x;
      };

      var r = Math.ceil(parseInt(color1.substring(0, 2), 16) * ratio + parseInt(color2.substring(0, 2), 16) * (1 - ratio));
      var g = Math.ceil(parseInt(color1.substring(2, 4), 16) * ratio + parseInt(color2.substring(2, 4), 16) * (1 - ratio));
      var b = Math.ceil(parseInt(color1.substring(4, 6), 16) * ratio + parseInt(color2.substring(4, 6), 16) * (1 - ratio));
      return Ember.String.htmlSafe("color: #" + hex(r) + hex(g) + hex(b) + " !important");
    }.property("percentile", "inverted"),
    valueIsKnown: function () {
      return !Ember.isNone(this.get("value") && this.get("range"));
    }.property("value")
  });

  _exports.default = _default;
});