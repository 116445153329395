define("rd-basic-client/utils/format-number-cwt", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatNumberCwt;

  function formatNumberCwt(rawValue, format, showPerCWT, productionInCWT, showPerCow, numberOfCows, perFormat) {
    var finalFormat = (showPerCWT || showPerCow) && perFormat ? perFormat : format;
    var value;

    if (showPerCWT & productionInCWT == 0 || showPerCow && numberOfCows == 0) {
      value = 0;
    } else {
      var divisor = showPerCWT ? productionInCWT : showPerCow ? numberOfCows : 1;
      value = rawValue / divisor;
    }

    if (value > -0.01 && value < 0) {
      value = 0;
    }

    if (value < 0.01 && value > 0) {
      value = 0;
    }

    return (0, _numeral.default)(value).format(finalFormat);
  }
});