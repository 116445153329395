define("rd-basic-client/models/hypothetical-position", ["exports", "ember-data", "rd-basic-client/mixins/notification", "rd-basic-client/utils/vela-product-group-to-name"], function (_exports, _emberData, _notification, _velaProductGroupToName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-function-prototype-extensions */
  var _default = _emberData.default.Model.extend(_notification.default, {
    instrumentType: Ember.computed.alias('instrument_type'),
    instrument_type: _emberData.default.attr("string"),
    isLong: Ember.computed.alias('is_long'),
    is_long: _emberData.default.attr("boolean"),
    quantity: _emberData.default.attr("number"),
    price: _emberData.default.attr("number"),
    month: Ember.computed.alias('month_id'),
    month_id: _emberData.default.belongsTo("month"),
    productGroup: Ember.computed.alias('product_group_id'),
    product_group_id: _emberData.default.attr("number"),
    symbol: _emberData.default.attr("string"),
    productGroupName: Ember.computed('product_group_id', function () {
      return (0, _velaProductGroupToName.default)(this.product_group_id);
    }),
    side: function () {
      return this.get("isLong") ? "Bought" : "Sold";
    }.property("isLong"),
    optionType: Ember.computed.alias('option_type'),
    option_type: _emberData.default.attr("string"),
    strike: _emberData.default.attr("number"),
    plInstrument: function () {
      var i;

      switch (this.symbol) {
        case "DC":
          i = this.get("month.classThreeMilkInstrument");
          break;

        case "GDK":
          i = this.get("month.classFourMilkInstrument");
          break;

        case "ZC":
          i = this.get("month.cornInstrument");
          break;

        case "CSC":
          i = this.get("month.cashSettledCheeseInstrument");
          break;

        case "ZM":
          i = this.get("month.soybeanMealInstrument");
          break;

        case "CB":
          i = this.get("month.cashSettledButterInstrument");
          break;

        case "GNF":
          i = this.get("month.nonfatDryMilkInstrument");
          break;

        case "DY":
          i = this.get("month.dryWheyInstrument");
          break;
      }

      return i;
    }.property("symbol", "month"),
    plInDollars: function () {
      // DGC: Need to verify this.
      var instrumentPrice = this.get("plInstrument.lastPrice");

      if (instrumentPrice === null) {
        return 0;
      }

      var v = 0;
      var quantity = this.get("quantity");

      if (this.get("symbol") === "DC" || this.get("symbol") === "DC") {
        quantity = quantity / 100;
      }

      if (this.get("instrumentType") === "Forward") {
        v += quantity * (this.get("price") - instrumentPrice) * (this.get("isLong") ? -1 : 1);
      }

      if (this.get("instrumentType") === "Put" || this.get("instrumentType") === "Call") {
        var strikePriceDiff = this.get("strike") - instrumentPrice;

        if (this.get("instrumentType") === "Put" && strikePriceDiff > 0) {
          v += quantity * strikePriceDiff * (this.get("isLong") ? 1 : -1);
        }

        if (this.get("instrumentType") === "Call" && strikePriceDiff < 0) {
          v += quantity * strikePriceDiff * (this.get("isLong") ? 1 : -1);
        }

        if (this.get("isLong")) {
          v -= this.get("price");
        } else {
          v += this.get("price");
        }
      }

      return v;
    }.property("plInstrument.best.price", "plInstrument.product.tick_size"),
    quantityTitle: function () {
      if (this.symbol === "ZC") {
        return "Bushels";
      } else if (this.symbol === "ZM") {
        return "Tons";
      } else {
        return "Pounds";
      }
    }.property("symbol"),
    rdFormat: function () {
      var fmt = "0,0.00";

      switch (this.symbol) {
        case "DC":
          fmt = "0,0.00";
          break;

        case "GDK":
          fmt = "0,0.00";
          break;

        case "ZC":
          fmt = "0,0.0000";
          break;

        case "ZM":
          fmt = "0,0.0";
          break;
      }

      return fmt;
    }.property("symbol")
  });

  _exports.default = _default;
});