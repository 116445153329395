define("rd-basic-client/utils/month-market-data-instruments", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = monthMarketDataInstruments;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function monthMarketDataInstruments(months, includePositions) {
    var _ref2;

    var filteredMonths = months.filter(function (m) {
      return (0, _moment.default)().subtract(1, 'month').isBefore(m.get('startDate'));
    });
    var positionInstruments;
    var monthInstruments = filteredMonths.map(function (m) {
      var _ref;

      if (includePositions) {
        positionInstruments = m.get('positions').mapBy('instrument_id');
      } else {
        positionInstruments = [];
      }

      return (_ref = []).concat.apply(_ref, _toConsumableArray(positionInstruments).concat([m.get('classThreeMilkInstrument'), m.get('classFourMilkInstrument'), m.get('dryWheyInstrument'), m.get('cashSettledCheeseInstrument'), m.get('nonfatDryMilkInstrument'), m.get('cashSettledButterInstrument'), m.get('cornInstrument'), m.get('soybeanMealInstrument'), m.get('wheatInstrument'), m.get('soybeanInstrument')]));
    });
    return (_ref2 = []).concat.apply(_ref2, _toConsumableArray(monthInstruments));
  }
});