define("rd-basic-client/components/rd-session-change", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layoutName: 'src/templates/components/rd-session-change',
    sessionChangeStyle: function () {
      var sessionPriceChange = this.get('sessionPriceChange');

      if (sessionPriceChange !== undefined && sessionPriceChange !== null && !isNaN(sessionPriceChange)) {
        if (sessionPriceChange.toNumber() < 0) {
          return 'color: red;';
        }

        if (sessionPriceChange.toNumber() > 0) {
          return 'color: green;';
        }
      }

      return '';
    }.property('sessionPriceChange'),
    arrow: function () {
      if (this.get('sessionPriceChange') !== null) {
        var sessionPriceChange = this.get('sessionPriceChange').toNumber();

        if (sessionPriceChange !== undefined && sessionPriceChange !== null && !isNaN(sessionPriceChange)) {
          if (sessionPriceChange < 0) {
            return '<i class="fa fa-arrow-down" aria-hidden="true"></i>';
          }

          if (sessionPriceChange > 0) {
            return '<i class="fa fa-arrow-up" aria-hidden="true"></i>';
          }

          if (sessionPriceChange === 0) {
            return '';
          }
        }
      } else {
        return '';
      }
    }.property('sessionPriceChange'),
    haveChange: function () {
      return this.get('sessionPriceChange') === null ? false : true;
    }.property('sessionPriceChange'),
    lastValue: function () {
      if (Ember.isNone(this.get('instrument.last.price')) || Ember.isNone(this.get('instrument.product.tick_size')) || Ember.isNone(this.get('instrument.product.tick_value'))) {
        return null;
      } else {
        var last = new BigNumber(this.get('instrument.last.price')).dividedBy(this.get('instrument.product.tick_size')).times(this.get('instrument.product.tick_value'));
        return last;
      }
    }.property('instrument.last.price', 'instrument.product.tick_size', 'instrument.product.tick_value'),
    settlementValue: function () {
      if (Ember.isNone(this.get('instrument.close.price')) || Ember.isNone(this.get('instrument.product.tick_size')) || Ember.isNone(this.get('instrument.product.tick_value'))) {
        return null;
      } else {
        var close = new BigNumber(this.get('instrument.close.price')).dividedBy(this.get('instrument.product.tick_size')).times(this.get('instrument.product.tick_value'));
        return close;
      }
    }.property('instrument.close.price', 'instrument.product.tick_size', 'instrument.product.tick_value'),
    sessionPriceChange: function () {
      var multiple = this.get('multiple') === undefined ? 1 : this.get('multiple');

      if (this.get('lastValue') !== null && this.get('settlementValue') !== null) {
        return this.get('lastValue').minus(this.get('settlementValue')).times(multiple);
      } else {
        return null;
      }
    }.property('lastValue', 'settlementValue', 'multiple'),
    formattedSessionPriceChange: function () {
      var fmt = this.get('format') === undefined ? '0,0.00' : this.get('format');

      if (this.get('sessionPriceChange') !== null) {
        return (0, _numeral.default)(this.get('sessionPriceChange').toNumber()).format(fmt);
      }
    }.property('sessionPriceChange', 'format'),
    sessionPriceChangePercentage: function () {
      if (this.get('lastValue') !== null && this.get('settlementValue') !== null) {
        return this.get('lastValue').minus(this.get('settlementValue')).dividedBy(this.get('settlementValue')).toNumber();
      }
    }.property('lastValue', 'settlementValue')
  });

  _exports.default = _default;
});