define("rd-basic-client/mixins/non-feed-expenses", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    costColumnsCount: 40,
    // Needed for the calculation of total cost
    nonFeedExpsensesCWT: false,
    cost_0: _emberData.default.attr('number'),
    cost_1: _emberData.default.attr('number'),
    cost_2: _emberData.default.attr('number'),
    cost_3: _emberData.default.attr('number'),
    cost_4: _emberData.default.attr('number'),
    cost_5: _emberData.default.attr('number'),
    cost_6: _emberData.default.attr('number'),
    cost_7: _emberData.default.attr('number'),
    cost_8: _emberData.default.attr('number'),
    cost_9: _emberData.default.attr('number'),
    cost_10: _emberData.default.attr('number'),
    cost_11: _emberData.default.attr('number'),
    cost_12: _emberData.default.attr('number'),
    cost_13: _emberData.default.attr('number'),
    cost_14: _emberData.default.attr('number'),
    cost_15: _emberData.default.attr('number'),
    cost_16: _emberData.default.attr('number'),
    cost_17: _emberData.default.attr('number'),
    cost_18: _emberData.default.attr('number'),
    cost_19: _emberData.default.attr('number'),
    cost_20: _emberData.default.attr('number'),
    cost_21: _emberData.default.attr('number'),
    cost_22: _emberData.default.attr('number'),
    cost_23: _emberData.default.attr('number'),
    cost_24: _emberData.default.attr('number'),
    cost_25: _emberData.default.attr('number'),
    cost_26: _emberData.default.attr('number'),
    cost_27: _emberData.default.attr('number'),
    cost_28: _emberData.default.attr('number'),
    cost_29: _emberData.default.attr('number'),
    cost_30: _emberData.default.attr('number'),
    cost_31: _emberData.default.attr('number'),
    cost_32: _emberData.default.attr('number'),
    cost_33: _emberData.default.attr('number'),
    cost_34: _emberData.default.attr('number'),
    cost_35: _emberData.default.attr('number'),
    cost_36: _emberData.default.attr('number'),
    cost_37: _emberData.default.attr('number'),
    cost_38: _emberData.default.attr('number'),
    cost_39: _emberData.default.attr('number'),
    cost_value_0: null,
    cost_value_1: null,
    cost_value_2: null,
    cost_value_3: null,
    cost_value_4: null,
    cost_value_5: null,
    cost_value_6: null,
    cost_value_7: null,
    cost_value_8: null,
    cost_value_9: null,
    cost_value_10: null,
    cost_value_11: null,
    cost_value_12: null,
    cost_value_13: null,
    cost_value_14: null,
    cost_value_15: null,
    cost_value_16: null,
    cost_value_17: null,
    cost_value_18: null,
    cost_value_19: null,
    cost_value_20: null,
    cost_value_21: null,
    cost_value_22: null,
    cost_value_23: null,
    cost_value_24: null,
    cost_value_25: null,
    cost_value_26: null,
    cost_value_27: null,
    cost_value_28: null,
    cost_value_29: null,
    cost_value_30: null,
    cost_value_31: null,
    cost_value_32: null,
    cost_value_33: null,
    cost_value_34: null,
    cost_value_35: null,
    cost_value_36: null,
    cost_value_37: null,
    cost_value_38: null,
    cost_value_39: null,
    costItems: function () {
      var aItems = [];

      for (var i = 0; i < this.get('costColumnsCount'); i++) {
        aItems.push(this.get('cost_' + i));
      }

      return aItems;
    }.property().volatile(),
    totalOtherCosts: function () {
      var sum = 0;

      for (var i = 0; i < this.get('costColumnsCount'); i++) {
        sum += this.get('cost_' + i);
      }

      return sum;
    }.property('cost_0', 'cost_1', 'cost_2', 'cost_3', 'cost_4', 'cost_5', 'cost_6', 'cost_7', 'cost_8', 'cost_9', 'cost_10', 'cost_11', 'cost_12', 'cost_13', 'cost_14', 'cost_15', 'cost_16', 'cost_17', 'cost_18', 'cost_19', 'cost_20', 'cost_21', 'cost_22', 'cost_23', 'cost_24', 'cost_25', 'cost_26', 'cost_27', 'cost_28', 'cost_29', 'cost_30', 'cost_31', 'cost_32', 'cost_33', 'cost_34', 'cost_35', 'cost_36', 'cost_37', 'cost_38', 'cost_39'),
    displayFormat: function () {
      return this.get('nonFeedExpsensesCWT') ? '$0,00.00' : '$0,0';
    }.property('nonFeedExpsensesCWT'),
    updateNonFeed: function updateNonFeed() {
      var hasChanges = false;

      for (var i = 0; i < this.get('costColumnsCount'); i++) {
        if (this.get('cost_value_' + i) !== null && this.get('cost_value_' + i) !== this.get('cost_' + i)) {
          hasChanges = true;
          this.setTotalValue(i);
        }
      }

      if (hasChanges) {
        this.save();
      }
    },
    setTotalValue: function setTotalValue(idx) {
      var value = this.get('cost_value_' + idx);
      var totalProduction = this.get('totalProduction');

      if (this.get('nonFeedExpsensesCWT')) {
        if (totalProduction) {
          value *= totalProduction / 100;
          value = value * 1;
        } else {
          value = 0;
        }
      }

      this.set('cost_' + idx, value);
      this.set('cost_value_' + idx, null);
    },
    getNonFeedExpenseColValue: function getNonFeedExpenseColValue(idx) {
      var value = this.get('cost_' + idx) * 1;
      var totalProduction = this.get('totalProduction');

      if (value !== 0 && this.get('nonFeedExpsensesCWT')) {
        if (totalProduction) {
          value = new BigNumber(value).dividedBy(totalProduction / 100).toFixed(4);
        } else {
          value = 0;
        }
      }

      if (this.get('cost_value_' + idx) === null) {
        this.set('cost_value_' + idx, value);
      }

      return value;
    },
    getValue_0: function () {
      return this.getNonFeedExpenseColValue(0);
    }.property('nonFeedExpsensesCWT', 'cost_0'),
    getValue_1: function () {
      return this.getNonFeedExpenseColValue(1);
    }.property('nonFeedExpsensesCWT', 'cost_1'),
    getValue_2: function () {
      return this.getNonFeedExpenseColValue(2);
    }.property('nonFeedExpsensesCWT', 'cost_2'),
    getValue_3: function () {
      return this.getNonFeedExpenseColValue(3);
    }.property('nonFeedExpsensesCWT', 'cost_3'),
    getValue_4: function () {
      return this.getNonFeedExpenseColValue(4);
    }.property('nonFeedExpsensesCWT', 'cost_4'),
    getValue_5: function () {
      return this.getNonFeedExpenseColValue(5);
    }.property('nonFeedExpsensesCWT', 'cost_5'),
    getValue_6: function () {
      return this.getNonFeedExpenseColValue(6);
    }.property('nonFeedExpsensesCWT', 'cost_6'),
    getValue_7: function () {
      return this.getNonFeedExpenseColValue(7);
    }.property('nonFeedExpsensesCWT', 'cost_7'),
    getValue_8: function () {
      return this.getNonFeedExpenseColValue(8);
    }.property('nonFeedExpsensesCWT', 'cost_8'),
    getValue_9: function () {
      return this.getNonFeedExpenseColValue(9);
    }.property('nonFeedExpsensesCWT', 'cost_9'),
    getValue_10: function () {
      return this.getNonFeedExpenseColValue(10);
    }.property('nonFeedExpsensesCWT', 'cost_10'),
    getValue_11: function () {
      return this.getNonFeedExpenseColValue(11);
    }.property('nonFeedExpsensesCWT', 'cost_11'),
    getValue_12: function () {
      return this.getNonFeedExpenseColValue(12);
    }.property('nonFeedExpsensesCWT', 'cost_12'),
    getValue_13: function () {
      return this.getNonFeedExpenseColValue(13);
    }.property('nonFeedExpsensesCWT', 'cost_13'),
    getValue_14: function () {
      return this.getNonFeedExpenseColValue(14);
    }.property('nonFeedExpsensesCWT', 'cost_14'),
    getValue_15: function () {
      return this.getNonFeedExpenseColValue(15);
    }.property('nonFeedExpsensesCWT', 'cost_15'),
    getValue_16: function () {
      return this.getNonFeedExpenseColValue(16);
    }.property('nonFeedExpsensesCWT', 'cost_16'),
    getValue_17: function () {
      return this.getNonFeedExpenseColValue(17);
    }.property('nonFeedExpsensesCWT', 'cost_17'),
    getValue_18: function () {
      return this.getNonFeedExpenseColValue(18);
    }.property('nonFeedExpsensesCWT', 'cost_18'),
    getValue_19: function () {
      return this.getNonFeedExpenseColValue(19);
    }.property('nonFeedExpsensesCWT', 'cost_19'),
    getValue_20: function () {
      return this.getNonFeedExpenseColValue(20);
    }.property('nonFeedExpsensesCWT', 'cost_20'),
    getValue_21: function () {
      return this.getNonFeedExpenseColValue(21);
    }.property('nonFeedExpsensesCWT', 'cost_21'),
    getValue_22: function () {
      return this.getNonFeedExpenseColValue(22);
    }.property('nonFeedExpsensesCWT', 'cost_22'),
    getValue_23: function () {
      return this.getNonFeedExpenseColValue(23);
    }.property('nonFeedExpsensesCWT', 'cost_23'),
    getValue_24: function () {
      return this.getNonFeedExpenseColValue(24);
    }.property('nonFeedExpsensesCWT', 'cost_24'),
    getValue_25: function () {
      return this.getNonFeedExpenseColValue(25);
    }.property('nonFeedExpsensesCWT', 'cost_25'),
    getValue_26: function () {
      return this.getNonFeedExpenseColValue(26);
    }.property('nonFeedExpsensesCWT', 'cost_26'),
    getValue_27: function () {
      return this.getNonFeedExpenseColValue(27);
    }.property('nonFeedExpsensesCWT', 'cost_27'),
    getValue_28: function () {
      return this.getNonFeedExpenseColValue(28);
    }.property('nonFeedExpsensesCWT', 'cost_28'),
    getValue_29: function () {
      return this.getNonFeedExpenseColValue(29);
    }.property('nonFeedExpsensesCWT', 'cost_29'),
    getValue_30: function () {
      return this.getNonFeedExpenseColValue(30);
    }.property('nonFeedExpsensesCWT', 'cost_30'),
    getValue_31: function () {
      return this.getNonFeedExpenseColValue(31);
    }.property('nonFeedExpsensesCWT', 'cost_31'),
    getValue_32: function () {
      return this.getNonFeedExpenseColValue(32);
    }.property('nonFeedExpsensesCWT', 'cost_32'),
    getValue_33: function () {
      return this.getNonFeedExpenseColValue(33);
    }.property('nonFeedExpsensesCWT', 'cost_33'),
    getValue_34: function () {
      return this.getNonFeedExpenseColValue(34);
    }.property('nonFeedExpsensesCWT', 'cost_34'),
    getValue_35: function () {
      return this.getNonFeedExpenseColValue(35);
    }.property('nonFeedExpsensesCWT', 'cost_35'),
    getValue_36: function () {
      return this.getNonFeedExpenseColValue(36);
    }.property('nonFeedExpsensesCWT', 'cost_36'),
    getValue_37: function () {
      return this.getNonFeedExpenseColValue(37);
    }.property('nonFeedExpsensesCWT', 'cost_37'),
    getValue_38: function () {
      return this.getNonFeedExpenseColValue(38);
    }.property('nonFeedExpsensesCWT', 'cost_38'),
    getValue_39: function () {
      return this.getNonFeedExpenseColValue(39);
    }.property('nonFeedExpsensesCWT', 'cost_39')
  });

  _exports.default = _default;
});