define("rd-basic-client/mixins/glanbia-cheese", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
    * WPC34 Price (previous month)-0.0105+((DY(previous month)-DY(2 months prior))/100)*0.642+((NF-NF(prev month))/100) * 0.44
    */
    glWpcFormula: function () {
      if (this.get('previous_month_id') !== null && this.get('previous_month_id.previous_month_id') != null) {
        var DY_diff = (this.get('previous_month_id.dryWheyBestPriceFormatted') / 100 - this.get('previous_month_id.previous_month_id.dryWheyBestPriceFormatted') / 100) * 0.642;
        var NF_diff = (this.nonfatDryBestPriceFormatted / 100 - this.get('previous_month_id.nonfatDryBestPriceFormatted') / 100) * 0.44;
        return this.get('previous_month_id.glWpc') - 0.0105 + DY_diff + NF_diff;
      }

      return 0;
    }.property('nonfatDryBestPriceFormatted', 'previous_month_id.glWpc', 'previous_month_id.historicalGlanbiaWpc', 'previous_month_id.dryWheyBestPriceFormatted', 'previous_month_id.nonfatDryBestPriceFormatted', 'previous_month_id.previous_month_id.dryWheyBestPriceFormatted'),
    glWpc: function () {
      if (this.isPastWithRollback && this.historicalGlanbiaWpc != null) {
        return this.historicalGlanbiaWpc;
      }

      return this.glWpcFormula;
    }.property('isPastWithRollback', 'historicalGlanbiaWpc', 'glWpcFormula'),
    glScenarioWpc: function () {
      if (this.isPastWithRollback) {
        return this.glWpc;
      }
      /**
       * Requires at least 2 months of history
       */


      if (this.get('previous_month_id') !== null && this.get('previous_month_id.previous_month_id') != null) {
        var previous_month = this.get('previous_month_id');
        var two_months_back = this.get('previous_month_id.previous_month_id');
        var DY_diff = (previous_month.get('scenarioDryWheyBestPriceFormatted') / 100 - two_months_back.get('scenarioDryWheyBestPriceFormatted') / 100) * 0.642;
        var NF_diff = (this.scenarioNonfatDryBestPriceFormatted / 100 - previous_month.get('scenarioNonfatDryBestPriceFormatted') / 100) * 0.44;
        return previous_month.get('glScenarioWpc') - 0.0105 + DY_diff + NF_diff;
      } else {
        return 0;
      }
    }.property('glWpc', 'isPastWithRollback', 'previous_month_id.glScenarioWpc', 'previous_month_id.scenarioDryWheyBestPriceFormatted', 'previous_month_id.previous_month_id.scenarioDryWheyBestPriceFormatted'),
    glLactosePriceFormula: function () {
      if (this.get('previous_month_id.dryWheyBestPriceFormatted') !== null) {
        return this.dryWheyBestPriceFormatted / this.get('previous_month_id.dryWheyBestPriceFormatted') * this.get('previous_month_id.glLactosePrice');
      } else {
        return 0;
      }
    }.property('dryWheyBestPriceFormatted', 'previous_month_id.glLactosePrice', 'previous_month_id.dryWheyBestPriceFormatted'),
    glLactosePrice: function () {
      /**
       * The value of the current month  has precedence on calculated value
       */
      if (this.isPastWithRollback && this.historicalGlanbiaLactose != null) {
        return this.historicalGlanbiaLactose;
      }

      return this.glLactosePriceFormula;
    }.property('historicalGlanbiaLactose', 'isPastWithRollback', 'glLactosePriceFormula'),
    glLactoseFormula: function () {
      if (this.get('previous_month_id.glLactose') !== null && this.get('previous_month_id.dryWheyBestPriceFormatted') !== null) {
        var dryWhey = this.dryWheyBestPriceFormatted / 100;
        var previousGlLactose = this.get('previous_month_id.glLactose');
        var previousDryWhey = this.get('previous_month_id.dryWheyBestPriceFormatted') / 100;

        if (previousDryWhey) {
          return dryWhey * (previousGlLactose / previousDryWhey);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }.property('dryWheyBestPriceFormatted', 'previous_month_id.glLactose', 'previous_month_id.dryWheyBestPriceFormatted'),
    glLactose: function () {
      /**
       * The value of the current month  has precedence on calculated value
       */
      if (this.isPastWithRollback && this.historicalGlanbiaLactose != null) {
        return this.historicalGlanbiaLactose;
      }

      return this.glLactoseFormula;
    }.property('glLactoseCalculated', 'historicalGlanbiaLactose', 'isPastWithRollback'),
    glButterFat: function () {
      return this.cashSettledButterBestPriceFormatted / 100 * 1.25;
    }.property('cashSettledButterBestPriceFormatted'),
    glLactoseComponent: function () {
      return this.glLactose - 0.19;
    }.property('glLactose'),
    glButterFatCalculation: function () {
      return this.glButterFat * (this.butterfat * 100);
    }.property('butterfat', 'glButterFat'),
    glCheeseProteinCalculation: function () {
      var cheese = this.cashSettledCheeseBestPriceFormatted - 0.17;
      var cheeseProtein = (10.1235 * cheese - 3.5 * this.glButterFat) / 2.99;
      return cheeseProtein * (this.protein * 100);
    }.property('protein', 'cashSettledCheeseBestPriceFormatted', 'glButterFat'),
    glWpc34Component: function () {
      return this.glWpc - 0.45;
    }.property('glWpc'),
    glWpc34MilkValue: function () {
      return 1.57 * (this.protein * 100 / 2.99) * this.glWpc34Component;
    }.property('protein', 'glWpc34Component'),
    glLactoseMilkValue: function () {
      return 1.7763 * (this.protein * 100 / 2.99) * (this.otherSolids * 100 / 5.69) * this.glLactoseComponent;
    }.property('protein', 'otherSolids', 'glLactoseComponent'),
    glTotalPrice: function () {
      if (this.isPastWithRollback && this.historicalGlanbiaEpp != null && this.historicalGlanbiaEpp > 0) {
        return this.historicalGlanbiaEpp;
      }

      return this.glCheeseProteinCalculation + this.glButterFatCalculation + this.glWpc34MilkValue + this.glLactoseMilkValue;
    }.property('glCheeseProteinCalculation', 'glButterFatCalculation', 'glWpc34MilkValue', 'glLactoseMilkValue', 'historicalGlanbiaEpp', 'isPastWithRollback'),
    scenarioGlCheeseProteinCalculation: function () {
      var cheeseProtein = (10.1235 * (this.scenarioCheeseBestPriceFormatted - 0.17) - 3.5 * (this.scenarioButterBestPriceFormatted / 100 * 1.25)) / 2.99;
      return cheeseProtein * (this.protein * 100);
    }.property('protein', 'scenarioCheeseBestPriceFormatted', 'scenarioButterBestPriceFormatted'),
    scenarioGlButterFatCalculation: function () {
      return this.scenarioButterBestPriceFormatted / 100 * 1.25 * (this.butterfat * 100);
    }.property('butterfat', 'scenarioButterBestPriceFormatted'),
    scenarioGlWpc34MilkValue: function () {
      return 1.57 * (this.protein * 100 / 2.99) * (this.glScenarioWpc - 0.45);
    }.property('scenarioNonfatDryBestPriceFormatted', 'protein', 'scenarioDryWheyBestPriceFormatted', 'glScenarioWpc'),
    glScenarioLactose: function () {
      /**
       * The value of the current month  has precedence on calculated value
       */
      if (this.isPastWithRollback) {
        return this.glLactose;
      }

      if (this.get('previous_month_id.glScenarioLactose') !== null && this.get('previous_month_id.scenarioDryWheyBestPriceFormatted')) {
        var val = this.scenarioDryWheyBestPriceFormatted / 100 * (this.get('previous_month_id.glScenarioLactose') / (this.get('previous_month_id.scenarioDryWheyBestPriceFormatted') / 100));

        if (val === Number.POSITIVE_INFINITY) {
          val = 0;
        }

        return val;
      } else {
        return 0;
      }
    }.property('scenarioDryWheyBestPriceFormatted', 'previous_month_id.scenarioDryWheyBestPriceFormatted', 'previous_month_id.glScenarioLactose', 'isPastWithRollback'),
    glScenarioLactoseComponent: function () {
      return this.glScenarioLactose - 0.19;
    }.property('glScenarioLactose'),
    scenarioGlLactoseMilkValue: function () {
      return 1.7763 * (this.protein * 100 / 2.99) * (this.otherSolids * 100 / 5.69) * this.glScenarioLactoseComponent;
    }.property('protein', 'otherSolids', 'glScenarioLactoseComponent', 'scenarioDryWheyBestPriceFormatted', 'previous_month_id.historicalGlanbiaLactose', 'previous_month_id.scenarioDryWheyBestPriceFormatted'),
    scenarioGlBlendedMilkPrice: function () {
      return this.scenarioGlCheeseProteinCalculation + this.scenarioGlButterFatCalculation + this.scenarioGlWpc34MilkValue + this.scenarioGlLactoseMilkValue;
    }.property('scenarioGlCheeseProteinCalculation', 'scenarioGlButterFatCalculation', 'scenarioGlWpc34MilkValue', 'scenarioGlLactoseMilkValue'),
    glTotalPriceCWT: function () {
      return this.totalProductionCWT * this.glTotalPrice;
    }.property('glTotalPrice', 'totalProductionCWT'),

    /******************************************************************/

    /*************************** HEDGED MILK **************************/

    /******************************************************************/
    glUnhedged: function () {
      var diff = this.numberOfContracts - this.classThreeContractsHedgedWithoutCheese - this.cashSettledCheeseContractsHedged;
      return diff > 0 ? diff : 0;
    }.property('cashSettledCheeseContractsHedged', 'classThreeContractsHedgedWithoutCheese', 'numberOfContracts'),
    glTotalUnhedged: function () {
      var unhedged = this.get("glUnhedged");

      if (this.numberOfContracts > 0) {
        return unhedged / this.numberOfContracts;
      } else {
        return 0;
      }
    }.property("glUnhedged", 'numberOfContracts'),
    glTotalHedged: function () {
      var total_hedged = this.classThreeContractsHedgedWithoutCheese + this.cashSettledCheeseContractsHedged;

      if (this.numberOfContracts > 0) {
        return total_hedged / this.numberOfContracts;
      } else {
        return 1;
      }
    }.property('numberOfContracts', 'cashSettledCheeseContractsHedged', 'classThreeContractsHedgedWithoutCheese')
  });

  _exports.default = _default;
});