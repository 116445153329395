define("rd-basic-client/mixins/non-milk-revenue", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    revenueA: _emberData.default.attr('number'),
    revenueB: _emberData.default.attr('number'),
    revenueC: _emberData.default.attr('number'),
    revenueD: _emberData.default.attr('number'),
    revenueE: _emberData.default.attr('number'),
    revenueF: _emberData.default.attr('number'),
    revenueG: _emberData.default.attr('number'),
    revenueA_value: null,
    revenueB_value: null,
    revenueC_value: null,
    revenueD_value: null,
    revenueE_value: null,
    revenueF_value: null,
    revenueG_value: null,
    nonMilkRevenueCWT: false,
    otherRevenueDisplayFormat: function () {
      return this.get('nonMilkRevenueCWT') ? '$0,00.00' : '$0,0';
    }.property('nonMilkRevenueCWT'),
    updateOtherRevenue: function updateOtherRevenue() {
      var hasChanges = false;
      ['A', 'B', 'C', 'D', 'E', 'F', 'G'].forEach(function (idx) {
        if (this.get('revenue' + idx + '_value') !== null && this.get('revenue' + idx + '_value') !== this.get('revenue' + idx)) {
          hasChanges = true;
          this.setTotalNonMilkRevenueValue(idx);
        }
      }.bind(this));

      if (hasChanges) {
        this.save();
      }
    },
    setTotalNonMilkRevenueValue: function setTotalNonMilkRevenueValue(idx) {
      var value = this.get('revenue' + idx + '_value');
      var totalProduction = this.get('totalProduction');

      if (this.get('nonMilkRevenueCWT')) {
        if (totalProduction) {
          value *= totalProduction / 100;
          value = value * 1;
        } else {
          value = 0;
        }
      }

      this.set('revenue' + idx, value);
      this.set('revenue' + idx + '_value', null);
    },
    totalOtherRevenue: function () {
      return this.get('revenueA') + this.get('revenueB') + this.get('revenueC') + this.get('revenueD') + this.get('revenueE') + this.get('revenueF') + this.get('revenueG');
    }.property('revenueA', 'revenueB', 'revenueC', 'revenueD', 'revenueE', 'revenueF', 'revenueG'),
    getColValue: function getColValue(attr) {
      var value = this.get(attr) * 1;
      var totalProduction = this.get('totalProduction');

      if (value !== 0 && this.get('nonMilkRevenueCWT')) {
        if (totalProduction) {
          value = new BigNumber(value).dividedBy(totalProduction / 100).toFixed(4);
        } else {
          value = 0;
        }
      }

      return value;
    },
    getRevenueA: function () {
      return this.getColValue('revenueA');
    }.property('nonMilkRevenueCWT', 'revenueA'),
    getRevenueB: function () {
      return this.getColValue('revenueB');
    }.property('nonMilkRevenueCWT', 'revenueB'),
    getRevenueC: function () {
      return this.getColValue('revenueC');
    }.property('nonMilkRevenueCWT', 'revenueC'),
    getRevenueD: function () {
      return this.getColValue('revenueD');
    }.property('nonMilkRevenueCWT', 'revenueD'),
    getRevenueE: function () {
      return this.getColValue('revenueE');
    }.property('nonMilkRevenueCWT', 'revenueE'),
    getRevenueF: function () {
      return this.getColValue('revenueF');
    }.property('nonMilkRevenueCWT', 'revenueF'),
    getRevenueG: function () {
      return this.getColValue('revenueG');
    }.property('nonMilkRevenueCWT', 'revenueG'),
    totalOtherRevenuePerCWT: function () {
      if (this.get('totalProductionCWT') > 0) {
        return this.get('totalOtherRevenue') / this.get('totalProductionCWT');
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'totalOtherRevenue')
  });

  _exports.default = _default;
});