define("rd-basic-client/serializers/position", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    primaryKey: 'id',
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    extractId: function extractId(modelClass, resourceHash) {
      resourceHash.id = resourceHash.instrument_id + resourceHash.acct_id;
      return this._super(modelClass, resourceHash);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload) {
      var jsonDocument = {
        data: [],
        included: []
      };

      for (var i = 0; i < payload.length; i++) {
        var position = payload[i];

        if (position.instrument) {
          jsonDocument.included.push(store.serializerFor('vault-instrument').normalize(store.modelFor('vault-instrument'), position.instrument)['data']);
          delete position.instrument;
        }

        jsonDocument.data.push(this.normalize(primaryModelClass, position)['data']);
      }

      return jsonDocument;
    }
  });

  _exports.default = _default;
});