define("rd-basic-client/components/quarter", ["exports", "rd-basic-client/config/environment", "rd-basic-client/mixins/quarterValidation", "rd-basic-client/mixins/month-utils", "moment"], function (_exports, _environment, _quarterValidation, _monthUtils, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_quarterValidation.default, _monthUtils.default, {
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    months: [],
    expanded: false,
    month: -1,
    year: -1,
    total: false,
    revenue: function () {
      return this._getSum('totalDairyRevenueAndOtherRev');
    }.property('months.@each.totalDairyRevenueAndOtherRev'),
    costs: function () {
      return this._getSum('totalCostOfProduction');
    }.property('months.@each.totalCostOfProduction'),
    totalCostOfProduction: Ember.computed.alias('costs'),
    totalDairyRevenue: function () {
      return this._getSum('totalDairyRevenue');
    }.property('months.@each.totalDairyRevenue'),
    totalOtherRevenue: function () {
      return this._getSum('totalOtherRevenue');
    }.property('months.@each.totalOtherRevenue'),
    unhedgingNetIncome: function () {
      return this._getSum('unhedgingNetIncome');
    }.property('months.@each.unhedgingNetIncome'),
    scenarioUnhedgedNetIncome: function () {
      return this._getSum('scenarioUnhedgedNetIncome');
    }.property('months.@each.scenarioUnhedgedNetIncome'),
    marginObjective: function () {
      return this._getSum('marginObjective');
    }.property('months.@each.marginObjective'),
    totalMilkCheck: function () {
      return this._getSum('totalMilkCheck');
    }.property('months.@each.totalMilkCheck'),
    milkCheckBE: function () {
      return this._getSum('milkCheckBE');
    }.property('months.@each.milkCheckBE'),
    scenarioNetIncome: function () {
      return this._getSum('scenarioNetIncome');
    }.property('months.@each.scenarioNetIncome'),
    cows: function () {
      return this._getSum('cows');
    }.property('months.@each.cows'),
    production: function () {
      return this._getSum('totalProductionCWT');
    }.property('months.@each.totalProductionCWT'),
    totalFeedCosts: function () {
      return this._getSum('totalFeedCosts');
    }.property('months.@each.totalFeedCosts'),
    totalOtherCosts: function () {
      return this._getSum('totalOtherCosts');
    }.property('months.@each.totalOtherCosts'),
    glTotalUnhedged: function () {
      return this._getAvg('glTotalUnhedged');
    }.property('months.@each.glTotalUnhedged'),
    totalMilkUnhedged: function () {
      return this._getAvg('totalMilkUnhedged');
    }.property('months.@each.totalMilkUnhedged'),
    feedPercentageUnhedged: function () {
      return this._getAvg('feedPercentageUnhedged');
    }.property('months.@each.feedPercentageUnhedged'),
    cornPercentageUnhedged: function () {
      return this._getAvg('cornPercentageUnhedged');
    }.property('months.@each.cornPercentageUnhedged'),
    soybeanMealPercentageUnhedged: function () {
      return this._getAvg('soybeanMealPercentageUnhedged');
    }.property('months.@each.soybeanMealPercentageUnhedged'),
    netIncome: function () {
      return this.get('revenue') - this.get('costs');
    }.property('revenue', 'costs'),
    shouldHedge: function () {
      return this.get('netIncome') > this.get('marginObjective');
    }.property('netIncome', 'marginObjective'),
    shouldUnhedge: function () {
      return this.get('unhedgingNetIncome') > this.get('marginObjective');
    }.property('unhedgingNetIncome', 'marginObjective'),

    /** HEDGED **/
    totalMilkHedged: function () {
      return this._getAvg('totalMilkHedged');
    }.property('months.@each.totalMilkHedged'),
    glTotalHedged: function () {
      return this._getAvg('glTotalHedged');
    }.property('months.@each.glTotalHedged'),
    totalDairyHedgePL: function () {
      return this._getSum('totalDairyHedgePL');
    }.property('months.@each.totalDairyHedgePL'),
    cornPercentageHedged: function () {
      return this._getAvg('cornPercentageHedged');
    }.property('months.@each.cornPercentageHedged'),
    cornHedgedPrice: function () {
      return this._getAvg('cornHedgedPrice');
    }.property('months.@each.cornHedgedPrice'),
    soybeanMealHedgedPrice: function () {
      return this._getAvg('soybeanMealHedgedPrice');
    }.property('months.@each.soybeanMealHedgedPrice'),
    soybeanMealPercentageHedged: function () {
      return this._getAvg('soybeanMealPercentageHedged');
    }.property('months.@each.soybeanMealPercentageHedged'),
    totalFeedHedgePL: function () {
      return this._getSum('totalFeedHedgePL');
    }.property('months.@each.totalFeedHedgePL'),

    /* This method is only used to hide expired months from the view */
    visibleMonths: function () {
      var afterDate = _moment.default.utc().date(1).subtract(1, "days");

      var beforeDate = _moment.default.utc(afterDate).add(_environment.default.APP.MONTH.SHOW, "months");

      if ((0, _moment.default)().utc().date() < _environment.default.APP.MONTH.ROLLBACK_DATE) {
        afterDate.subtract(1, "months");
      }

      return this.get('months').filter(function (record) {
        return _moment.default.utc(record.get('startDate')).isAfter(afterDate) && _moment.default.utc(record.get('startDate')).isBefore(beforeDate);
      });
    }.property('months.@each'),
    label: function () {
      var label = 'Q' + this.get('quarter') + ' ' + this.get('year');

      if (this.get('total')) {
        label = 'Total ' + this.get('year');
      }

      return label;
    }.property('quarter', 'year'),
    _getLogicalOR: function _getLogicalOR(attr) {
      var condition = false;
      this.get('months').forEach(function (m) {
        condition = condition || m.get(attr);
      });
      return condition;
    },
    _getSum: function _getSum(attr) {
      var total = 0;
      this.get('months').forEach(function (m) {
        total += m.get(attr);
      });
      return total;
    },
    _getAvg: function _getAvg(attr) {
      return this._getSum(attr) / this.get('months').length;
    }
  });

  _exports.default = _default;
});