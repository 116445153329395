define("rd-basic-client/components/month-usage", ["exports", "numeral", "moment"], function (_exports, _numeral, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    usage: function () {
      if (this.get('category') !== null && this.get('category') !== undefined && this.get('feed')) {
        if (this.get('feed.dailyRatio')) {
          return (0, _numeral.default)(this.get('feed.usageInTons') * this.get('category.totalProduction') / 100 / 2000).format('0,0.[00]');
        } else {
          return (0, _numeral.default)(this.get('feed.usageInTons') * this.get('category.totalDMICows') * _moment.default.utc(this.get('category.month.startDate')).daysInMonth() / 2000).format('0,0.00');
        }
      }
    }.property('feed.usageInTons', 'feed.dailyRatio', 'category.totalProduction', 'category.month.startDate', 'feed', 'category.totalDMICows')
  });

  _exports.default = _default;
});