define("rd-basic-client/controllers/markets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    instrumentSorting: Object.freeze(['displayExpiresAt']),
    classThree: Ember.computed.sort('model.classThreeFutures', 'instrumentSorting'),
    classFour: Ember.computed.sort('model.classFourFutures', 'instrumentSorting'),
    dryWhey: Ember.computed.sort('model.dryWheyFutures', 'instrumentSorting'),
    cashSettledCheese: Ember.computed.sort('model.cashSettledCheeseFutures', 'instrumentSorting'),
    nonfatDryMilk: Ember.computed.sort('model.nonfatDryMilkFutures', 'instrumentSorting'),
    cashSettledButter: Ember.computed.sort('model.cashSettledButterFutures', 'instrumentSorting'),
    soybeanMeal: Ember.computed.sort('model.soybeanMealFutures', 'instrumentSorting'),
    corn: Ember.computed.sort('model.cornFutures', 'instrumentSorting'),
    soybean: Ember.computed.sort('model.soybeanFutures', 'instrumentSorting'),
    wheat: Ember.computed.sort('model.wheatFutures', 'instrumentSorting'),
    liveCattle: Ember.computed.sort('model.liveCattleFutures', 'instrumentSorting')
  });

  _exports.default = _default;
});