define("rd-basic-client/controllers/dataimport", ["exports", "rd-basic-client/config/environment", "rd-basic-client/mixins/notification"], function (_exports, _environment, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_notification.default, {
    types: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('types', [{
        id: 0,
        refObj: 'month',
        code: 'import_milk_production',
        validate: 'validate_milk_production',
        label: 'Milk Production',
        link: {
          label: 'Milk Production',
          path: 'production'
        },
        templates: [{
          name: 'Milk Production',
          path: _environment.default.APP.AWS.ASSETS_HOST + 'templates/0_0.csv'
        }],
        fields: [{
          id: -1,
          label: 'Ignore',
          icon: 'fa fa-ban',
          required: false,
          unique: false,
          type: 'value'
        }, {
          id: 0,
          label: 'Date',
          icon: 'fa fa-calendar',
          required: true,
          unique: true,
          name: 'start_date',
          type: 'date',
          match: ['month', 'date']
        }, {
          id: 1,
          label: 'Cows in Milk',
          icon: 'fa fa-area-chart',
          required: false,
          unique: true,
          name: 'cows',
          type: 'integer',
          conflicts: [2],
          match: ['cows', 'cows in milk']
        }, {
          id: 2,
          label: 'Pound per Cow/Day',
          icon: 'fa fa-balance-scale',
          required: false,
          unique: true,
          name: 'daily_production',
          type: 'decimal',
          conflicts: [1],
          match: ['cows', 'cows in milk', 'pounds']
        }, {
          id: 3,
          label: 'Butterfat',
          icon: 'fa fa-percent',
          required: false,
          unique: true,
          name: 'butterfat',
          type: 'percentage',
          match: ['fat', 'butter', 'butterfat', 'bft', 'butter fat']
        }, {
          id: 4,
          label: 'Protein',
          icon: 'fa fa-percent',
          //fa-tint',
          required: false,
          unique: true,
          name: 'protein',
          type: 'percentage',
          match: ['protein', 'ptn']
        }, {
          id: 5,
          label: 'Other Solids',
          icon: 'fa fa-percent',
          required: false,
          unique: true,
          name: 'other_solids',
          type: 'percentage',
          match: ['solids', 'other', 'other solids']
        }, {
          id: 6,
          label: 'Total Production',
          icon: 'fa fa-tachometer',
          required: false,
          unique: true,
          name: 'total_production',
          type: 'integer',
          match: ['total pound', 'total production', 'monthly pounds']
        }]
      }, {
        id: 1,
        refObj: 'cow-category',
        code: 'import_cow_categories',
        validate: 'validate_cow_categories',
        label: 'Cow Categories',
        items: 'items',
        link: {
          label: 'Cow Categories',
          path: 'cowflow'
        },
        templates: [{
          name: 'Cow Categories - Months Vertical',
          path: _environment.default.APP.AWS.ASSETS_HOST + 'templates/1_0.csv'
        }, {
          name: 'Cow Categories - Months Horizontal',
          path: _environment.default.APP.AWS.ASSETS_HOST + 'templates/1_1.csv'
        }],
        fields: [{
          id: -1,
          label: 'Ignore',
          icon: 'fa fa-ban',
          required: false,
          unique: false,
          name: '',
          type: 'value'
        }, {
          id: 0,
          label: 'Date',
          icon: 'fa fa-calendar',
          required: true,
          unique: true,
          name: 'date',
          type: 'date',
          match: ['month', 'date']
        }, {
          id: 1,
          label: 'Cows',
          icon: 'fa fa-area-chart',
          required: true,
          unique: false,
          name: 'cows',
          type: 'integer',
          match: ['cows']
        }]
      }, {
        id: 2,
        refObj: 'month',
        code: 'import_non_feed',
        validate: 'validate_non_feed',
        label: 'Non Feed Expenses',
        items: 'items',
        link: {
          label: 'Non Feed Expenses',
          path: 'operatingcosts'
        },
        templates: [{
          name: 'Non Feed Expenses - Months Vertical',
          path: _environment.default.APP.AWS.ASSETS_HOST + 'templates/2_0.csv'
        }, {
          name: 'Non Feed Expenses - Months Horizontal',
          path: _environment.default.APP.AWS.ASSETS_HOST + 'templates/2_1.csv'
        }],
        fields: [{
          id: -1,
          label: 'Ignore',
          icon: 'fa fa-ban',
          required: false,
          unique: false,
          name: '',
          type: 'value'
        }, {
          id: 0,
          label: 'Date',
          icon: 'fa fa-calendar',
          required: true,
          unique: true,
          name: 'date',
          type: 'date',
          match: ['month', 'date']
        }, {
          id: 1,
          label: 'Total',
          icon: 'fa fa-usd',
          required: false,
          unique: false,
          name: 'total',
          type: 'price',
          match: []
        }, {
          id: 2,
          label: 'CWT',
          icon: 'fa fa-usd',
          required: false,
          unique: false,
          name: 'cwt',
          type: 'price',
          match: []
        }]
      }, {
        id: 3,
        label: 'Feed Expenses - Ingredients',
        code: 'import_feed_expenses_spot',
        validate: 'validate_feed_expenses_spot',
        link: {
          label: 'Feed Expenses',
          path: 'costs'
        },
        templates: [{
          name: 'Feed Expenses',
          path: _environment.default.APP.AWS.ASSETS_HOST + 'templates/3_0.csv'
        }],
        fields: [{
          id: -1,
          label: 'Ignore',
          icon: 'fa fa-ban',
          required: false,
          unique: false,
          name: '',
          type: 'value'
        }, {
          id: 0,
          label: 'Date',
          icon: 'fa fa-calendar',
          required: true,
          unique: true,
          name: 'date',
          type: 'date',
          match: ['month', 'date']
        }, {
          id: 1,
          label: 'Cow Group Name',
          icon: 'fa fa-pencil-square-o',
          required: true,
          unique: true,
          name: 'cow_category_name',
          type: 'string',
          match: ['cow', 'group']
        }, {
          id: 2,
          label: 'Ingredient Name',
          icon: 'fa fa-pencil-square-o',
          required: true,
          unique: true,
          name: 'ingredient_name',
          type: 'string',
          match: ['name', 'ingredient']
        }, {
          id: 3,
          label: 'Pounds per Cow per Day',
          icon: 'fa fa-balance-scale',
          required: true,
          unique: true,
          name: 'pounds_cow_day',
          type: 'string',
          match: ['pounds', 'per cow']
        }, {
          id: 4,
          label: 'Fixed Price',
          icon: 'fa fa-usd',
          required: true,
          unique: true,
          name: 'price_in_tons',
          type: 'string',
          match: ['price', 'fixed']
        }]
      }, {
        id: 4,
        refObj: 'month',
        code: 'import_non_milk',
        validate: 'validate_non_milk',
        label: 'Non Milk Revenue',
        link: {
          label: 'Non Milk Revenue',
          path: 'otherrevenue'
        },
        templates: [{
          name: 'Non Milk Revenue - Months Vertical',
          path: _environment.default.APP.AWS.ASSETS_HOST + 'templates/4_0.csv'
        }, {
          name: 'Non Milk Revenue - Months Horizontal',
          path: _environment.default.APP.AWS.ASSETS_HOST + 'templates/4_1.csv'
        }],
        items: 'items',
        fields: [{
          id: -1,
          label: 'Ignore',
          icon: 'fa fa-ban',
          required: false,
          unique: false,
          name: '',
          type: 'value'
        }, {
          id: 0,
          icon: 'fa fa-calendar',
          label: 'Date',
          required: true,
          unique: true,
          name: 'date',
          type: 'date',
          match: ['month', 'date']
        }, {
          id: 1,
          icon: 'fa fa-usd',
          label: 'Total',
          required: false,
          unique: false,
          name: 'total',
          type: 'price',
          match: []
        }, {
          id: 2,
          icon: 'fa fa-usd',
          label: 'CWT',
          required: false,
          unique: false,
          name: 'cwt',
          type: 'price',
          match: []
        }]
      }, {
        id: 5,
        refObj: 'ingredient',
        label: 'Ingredients',
        code: 'import_ingredients',
        validate: 'validate_ingredients',
        link: {
          label: 'Ingredients',
          path: 'ingredients'
        },
        templates: [{
          name: 'Ingredients',
          path: _environment.default.APP.AWS.ASSETS_HOST + 'templates/5_0.csv'
        }],
        fields: [{
          id: -1,
          label: 'Ignore',
          icon: 'fa fa-ban',
          required: false,
          unique: false,
          type: 'value'
        }, {
          id: 0,
          label: 'Name',
          icon: 'fa fa-pencil-square-o',
          required: true,
          unique: true,
          name: 'label',
          type: 'string',
          match: ['name', 'ingredient']
        }, {
          id: 1,
          label: 'Vendor',
          icon: 'fa fa-pencil-square-o',
          required: false,
          unique: true,
          name: 'provider',
          type: 'string',
          match: ['vendor', 'provider']
        }, {
          id: 2,
          label: 'Spot Price',
          icon: 'fa fa-usd',
          required: true,
          unique: true,
          name: 'spot_price',
          type: 'price',
          match: ['spot', 'price']
        }]
      }, {
        id: 6,
        label: 'Feed Expenses - Static Cost',
        code: 'import_feed_expenses_static',
        validate: 'validate_feed_expenses_static',
        items: 'items',
        link: {
          label: 'Feed Expenses',
          path: 'costs'
        },
        templates: [{
          name: 'Feed Expenses - Months Vertical',
          path: _environment.default.APP.AWS.ASSETS_HOST + 'templates/6_0.csv'
        }, {
          name: 'Feed Expenses - Months Horizontal',
          path: _environment.default.APP.AWS.ASSETS_HOST + 'templates/6_1.csv'
        }],
        fields: [{
          id: -1,
          label: 'Ignore',
          icon: 'fa fa-ban',
          required: false,
          unique: false,
          name: '',
          type: 'value'
        }, {
          id: 0,
          label: 'Date',
          icon: 'fa fa-calendar',
          required: true,
          unique: true,
          name: 'date',
          type: 'date',
          match: ['month', 'date']
        }, {
          id: 1,
          label: 'Total',
          icon: 'fa fa-usd',
          required: false,
          unique: false,
          name: 'static_feed_cost',
          type: 'price',
          match: ['total']
        }, {
          id: 2,
          label: 'CWT',
          icon: 'fa fa-usd',
          required: true,
          unique: false,
          name: 'static_feed_cost',
          type: 'price',
          match: ['cwt']
        }, {
          id: 3,
          label: '$ / Cow / Day',
          icon: 'fa fa-usd',
          required: true,
          unique: false,
          name: 'static_feed_cost',
          type: 'price',
          match: ['per cow']
        }]
      }]);
      this.set('firstStep', true);
      this.set('uploadStarted', false);
      this.set('secondStep', false);
      this.set('thirdStep', false);
      this.set('fourthStep', false);
      this.set('lastStep', false);
      this.set('fileData', null);
      this.set('importType', null);
      this.set('controller', this);

      if (localStorage.getItem('import-completed')) {
        var id = parseInt(localStorage.getItem('import-type'), 10);
        this.types.forEach(function (type) {
          if (id === type.id) {
            this.set('importType', type);
          }
        }.bind(this));
        localStorage.removeItem('import-completed');
        localStorage.removeItem('import-type');
        this.set('firstStep', false);
        this.set('lastStep', true);
      }

      this.set('fileUploadUrl', _environment.default.APP.HOST + '/csv_importer');
    },
    isColumnMapIsAvailable: function isColumnMapIsAvailable(val) {
      var value = parseInt(val, 10);
      var available = true;
      var fields = this.get('importType.fields');
      var isUnique = true;
      fields.forEach(function (el) {
        if (el.id === value) {
          isUnique = el.unique;
        }
      });

      if (isUnique) {
        //Check if it is already assigned
        this.get('fileData.mappings').forEach(function (el) {
          if (parseInt(el.fieldId, 10) === value) {
            available = false;
            this.showErrorMessage('This field is already used for \'' + el.label + '\' mapping!');
          }
        }.bind(this));
      }

      return available;
    },
    onUploadSuccess: function onUploadSuccess(evt, data) {
      this.set('uploadStarted', false);
      this.set('firstStep', false);
      this.set('secondStep', true);
      this.uploadDone(data.result);
    },
    onUploadDone: function onUploadDone(evt, data) {
      if (!data.result) {
        this.set('uploadStarted', false);
        this.showErrorMessage('Oops, something went wrong. :( ');
      }
    },
    onUploadStart: function onUploadStart() {
      this.set('uploadStarted', true);
    },
    tryToFindColumnMatch: function tryToFindColumnMatch(colName, type) {
      var colId = -1;
      type.fields.forEach(function (field) {
        if (field.match && colName !== null && colName !== '') {
          field.match.forEach(function (label) {
            if (label.indexOf(colName.toLowerCase()) >= 0 && colId < 0) {
              colId = field.id;
            }
          });
        }
      });
      return colId;
    },
    uploadDone: function uploadDone(resp) {
      if (resp.success) {
        this.set('fileData', resp.data);
        this.showSuccessMessage('File uploaded successfully');
        var importType = this.get('importType');
        var self = this;
        var mappings = [];
        var tempAlreadyAssigned = {};
        Ember.$(this.get('fileData').header).each(function (idx, val) {
          var id = self.tryToFindColumnMatch(val, importType);

          if (tempAlreadyAssigned[id] === null) {
            tempAlreadyAssigned[id] = 1;
          } else {
            id = -1;
          }

          mappings.push({
            index: idx,
            fieldId: id,
            label: val
          });
        });
        tempAlreadyAssigned = null;
        this.set('fileData.mappings', mappings);
        this.set('secondStep', false);
        this.set('thirdStep', true);
      } else {
        this.showErrorMessage('Oops, something went wrong. :( ');
      }
    },
    serverValidationDone: function serverValidationDone(resp) {
      var importType = this.get('importType');
      var headers = [];
      var errors = [];
      var infos = [];
      Ember.$.each(this.get('fileData.mappings'), function (idx, el) {
        if (el.fieldId !== null && el.fieldId >= 0) {
          if (resp.mappings && resp.mappings[el.index] && resp.mappings[el.index].type === 'ERROR' && resp.mappings[el.index].mapping === null && el.fieldId > 0) {
            errors.push({
              message: 'Mapping not found for "' + el.label + '" column, all the data of the column will be ignored!',
              type: 'MAPPING_NOT_FOUND',
              field: el
            });
          }

          this.get('importType.fields').forEach(function (header) {
            if (parseInt(header.id, 10) === parseInt(el.fieldId, 10)) {
              headers.push(Ember.$.extend({}, header));
            }
          });
        }
      }.bind(this));
      var rows = [];

      for (var i = 0; i < resp.data.length; i++) {
        var row = [];

        for (var j = 0; j < headers.length; j++) {
          if (importType.items != null) {
            if (headers[j].name === 'date') {
              row.push({
                type: headers[j].type,
                value: resp.data[i][headers[j].name]
              });
            } else {
              if (!headers[j].colName && resp.data[i][importType.items][j - 1].name) {
                headers[j].colName = resp.data[i][importType.items][j - 1].name;
                headers[j].label = resp.data[i][importType.items][j - 1].name;
              }

              row.push({
                type: headers[j].type,
                // j - 1 because the item list index starts from 0, and at the moment j value is 1 because of date column
                value: resp.data[i][importType.items][j - 1][headers[j].name]
              });
            }
          } else {
            row.push({
              type: headers[j].type,
              value: resp.data[i][headers[j].name]
            });
          }
        }

        rows.push(row);
      }

      this.set('previewData', {
        headers: headers,
        data: rows,
        errors: errors,
        raw: resp.data
      });
    },
    simulateDataUpload: function simulateDataUpload() {
      var resp = {
        success: true,
        errors: [],
        data: {
          rows: [["1/1/2016", "10", "60", "3.5%", "3.1%", "2.8%"], ["2/1/2016", "10", "60", "3.5%", "3.1%", "2.8%"], ["3/1/2016", "10", "60", "3.5%", "3.1%", "2.8%"], ["4/1/2016", "10", "60", "3.5%", "3.1%", "2.8%"], ["5/1/2016", "10", "60", "3.5%", "3.1%", "2.8%"], ["6/1/2016", "10", "60", "3.5%", "3.1%", "2.8%"], ["7/1/2016", "10", "60", "3.5%", "3.1%", "2.8%"], ["8/1/2016", "10", "60", "3.5%", "3.1%", "2.8%"], ["9/1/2016", "10", "60", "3.5%", "3.1%", "2.8%"], ["10/1/2016", "10", "60", "3.5%", "3.1%", "2.8%"], ["11/1/2016", "10", "60", "3.5%", "3.1%", "2.8%"], ["12/1/2016", "10", "60", "3.5%", "3.1%", "2.8%"]],
          header: ["Month", "Cows", "Pounds cow/day", "Butterfat", "Protein", "Other"],
          total: 12,
          file: false
        }
      };
      this.uploadDone(resp);
    },
    simulateDataVerify: function simulateDataVerify() {
      var resp = {
        success: true,
        errors: [],
        data: [{
          "start_date": "2016-01-01",
          "butterfat": "0.035",
          "protein": "0.031",
          "other_solids": "0.028",
          "daily_production": 60.0,
          "cows": "10"
        }, {
          "start_date": "2016-02-01",
          "butterfat": "0.035",
          "protein": "0.031",
          "other_solids": "0.028",
          "daily_production": 60.0,
          "cows": "10"
        }, {
          "start_date": "2016-03-01",
          "butterfat": "0.035",
          "protein": "0.031",
          "other_solids": "0.028",
          "daily_production": 60.0,
          "cows": "10"
        }, {
          "start_date": "2016-04-01",
          "butterfat": "0.035",
          "protein": "0.031",
          "other_solids": "0.028",
          "daily_production": 60.0,
          "cows": "10"
        }, {
          "start_date": "2016-05-01",
          "butterfat": "0.035",
          "protein": "0.031",
          "other_solids": "0.028",
          "daily_production": 60.0,
          "cows": "10"
        }, {
          "start_date": "2016-06-01",
          "butterfat": "0.035",
          "protein": "0.031",
          "other_solids": "0.028",
          "daily_production": 60.0,
          "cows": "10"
        }, {
          "start_date": "2016-07-01",
          "butterfat": "0.035",
          "protein": "0.031",
          "other_solids": "0.028",
          "daily_production": 60.0,
          "cows": "10"
        }, {
          "start_date": "2016-08-01",
          "butterfat": "0.035",
          "protein": "0.031",
          "other_solids": "0.028",
          "daily_production": 60.0,
          "cows": "10"
        }, {
          "start_date": "2016-09-01",
          "butterfat": "0.035",
          "protein": "0.031",
          "other_solids": "0.028",
          "daily_production": 60.0,
          "cows": "10"
        }, {
          "start_date": "2016-10-01",
          "butterfat": "0.035",
          "protein": "0.031",
          "other_solids": "0.028",
          "daily_production": 60.0,
          "cows": "10"
        }, {
          "start_date": "2016-11-01",
          "butterfat": "0.035",
          "protein": "0.031",
          "other_solids": "0.028",
          "daily_production": 60.0,
          "cows": "10"
        }, {
          "start_date": "2016-12-01",
          "butterfat": "0.035",
          "protein": "0.031",
          "other_solids": "0.028",
          "daily_production": 60.0,
          "cows": "10"
        }],
        mappings: ["Month", "Cows", "Pounds cow/day", "Butterfat", "Protein", "Other"]
      };
      var mappings = [{
        "index": 0,
        "fieldId": "0",
        "label": "Month"
      }, {
        "index": 1,
        "fieldId": "1",
        "label": "Cows"
      }, {
        "index": 2,
        "fieldId": "2",
        "label": "Pounds cow/day"
      }, {
        "index": 3,
        "fieldId": "3",
        "label": "Butterfat"
      }, {
        "index": 4,
        "fieldId": "4",
        "label": "Protein"
      }, {
        "index": 5,
        "fieldId": "5",
        "label": "Other"
      }];
      this.set('fileData.mappings', mappings);
      this.set('thirdStep', false);
      this.set('fourthStep', true);
      this.serverValidationDone(resp);
    },
    changeStep: function changeStep(step) {
      this.set('firstStep', step === 1);
      this.set('uploadStarted', false);
      this.set('secondStep', step === 2);
      this.set('thirdStep', step === 3);
      this.set('fourthStep', step === 4);
      this.set('lastStep', step === 5);

      if (step === 1) {
        this.set('importType', null);
        this.set('fileData', null);
      }
    },
    actions: {
      importData: function importData() {
        var self = this;
        var mapData = this.get('fileData');
        var importType = this.get('importType');

        if (!mapData.file) {
          this.showErrorMessage('This step cannot be performed in tutorial mode.');
          return;
        }

        this.set('uploadStarted', true);
        Ember.$.post(_environment.default.APP.HOST + '/csv_importer', {
          method: importType.code,
          file: mapData.file,
          data: JSON.stringify(mapData.mappings)
        }).done(function (resp) {
          if (resp.success) {
            self.set('uploadStarted', false);
            self.set('fourthStep', false);
            self.set('importType', null);
            self.set('previewData', null);
            self.set('fileData', null);
            localStorage.setItem('import-completed', true);
            localStorage.setItem('import-type', importType.id);
            window.location.reload();
          }
        });
      },
      getImportPreview: function getImportPreview() {
        var self = this; //TBD: VALIDATE BEFORE IMPORT!!!

        var mapData = this.get('fileData');
        var importType = this.get('importType');
        this.set('uploadStarted', true);
        Ember.$.post(_environment.default.APP.HOST + '/csv_importer', {
          method: importType.validate,
          file: mapData.file,
          data: JSON.stringify(mapData.mappings)
        }).done(function (resp) {
          self.set('uploadStarted', false);

          if (resp.success) {
            self.set('thirdStep', false);
            self.set('fourthStep', true);
            self.serverValidationDone(resp);
          }
        });
      },
      gotToStep: function gotToStep(step) {
        this.changeStep(step);
      },
      setImportType: function setImportType(importType) {
        this.set('importType', importType);
        this.set('firstStep', false);
        this.set('secondStep', true);
      },
      uploadFile: function uploadFile() {}
    }
  });

  _exports.default = _default;
});