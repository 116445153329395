define("rd-basic-client/routes/dataimport", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(parms) {
      return Ember.RSVP.hash({
        integration: ''
      });
    },
    actions: {
      startTour: function startTour() {
        var controller = this.controllerFor('dataimport');
        var route = this;
        var tour = null;
        var dashboardTour = {
          id: 'dataimport-tutorial',
          showPrevButton: true,
          scrollDuration: 200,
          arrowWidth: 5,
          steps: [{
            title: 'Data Type',
            content: 'Select which type of data to enter.',
            target: '.import-type',
            placement: 'top',
            ctaLabel: 'Show',
            showNextButton: false,
            showCTAButton: true,
            onCTA: function onCTA() {
              if (Ember.$('.import-type > div:first-child .btn')[0]) {
                Ember.$('.import-type > div:first-child .btn')[0].click();
                setTimeout(tour.nextStep.bind(tour), 100);
              }
            }
          }, {
            title: 'Selected Import Type',
            target: '.import-type-label',
            yOffset: 12,
            content: 'Here you see the import type previously selected.',
            placement: 'bottom',
            showPrevButton: false
          }, {
            title: 'Upload File',
            content: 'Click here to select the file you would like to upload. You can also drag and drop the file from a folder.',
            target: '#fileupload',
            placement: 'bottom'
          }, {
            title: 'Date Format',
            content: 'A common error is to upload a file with the wrong date format. Make sure your file is using one of these formats for the cells that show which month the data corresponds to.',
            target: '.dataformat-info',
            yOffset: -12,
            placement: 'top'
          }, {
            title: 'File Template',
            yOffset: -12,
            content: 'If you would like a guide to make sure you are entering data in the correct formula, download the template in CSV format by clicking the button. You can copy / paste into this template or edit your own spreadsheet to match this format.',
            target: '.templates-container',
            placement: 'top',
            ctaLabel: 'Simulate upload',
            showNextButton: false,
            showCTAButton: true,
            onCTA: function onCTA() {
              controller.simulateDataUpload();
              setTimeout(tour.nextStep.bind(tour), 100);
            }
          }, {
            title: 'File preview',
            target: '.panel-default',
            yOffset: -12,
            ctaLabel: 'Next',
            showNextButton: false,
            showCTAButton: true,
            content: ['This view will allow you to preview and map your data before it is uploaded into ever.ag system.', '<br /><b>Note:</b> the system will display up to first 20 rows. The import will be performed on entire file.'].join(''),
            placement: 'top',
            showPrevButton: false,
            onCTA: function onCTA() {
              if (!Ember.$('.select ul:visible > li:first-child').length) {
                Ember.$('.select-white:first-child > span').first().click();
                setTimeout(tour.nextStep.bind(tour), 100);
              } else {
                tour.nextStep();
              }
            }
          }, {
            title: 'Column mappings',
            content: 'Select an option from the drop down above each column for the columns that you would like uploaded. If there is a column that you do not want to be uploaded, simply leave it as "Ignore".',
            target: '.select ul:visible > li:first-child',
            xOffset: 12,
            placement: 'right'
          }, {
            title: 'Verify',
            content: 'When you are ready, click this button to see if the system correctly maps the columns.',
            target: '.verify-mappings-btn',
            xOffset: -12,
            yOffset: -24,
            showNextButton: false,
            showCTAButton: true,
            ctaLabel: 'Verify',
            placement: 'left',
            onCTA: function onCTA() {
              controller.simulateDataVerify();
              setTimeout(tour.nextStep.bind(tour), 100);
            }
          }, {
            title: 'Data import preview',
            content: 'Verify if the date is recognized correctly by the system and if the formatting of the values is valid.',
            target: '.import-preview',
            yOffset: -12,
            placement: 'top',
            showPrevButton: false
          }, {
            title: 'Back to Mappings',
            content: 'Make sure everything looks right. Note that you can always go back to the previous page by clicking this button.',
            target: '.back-btn',
            yOffset: -12,
            placement: 'top'
          }, {
            title: 'Import File',
            content: ['If everything looks good, you can go ahead and import the data.', '<br /><b>Note:</b>This step cannot be undone.'].join(''),
            target: '.import-btn',
            xOffset: -12,
            yOffset: -24,
            placement: 'left',
            showCTAButton: true,
            showNextButton: false,
            ctaLabel: 'Done',
            onCTA: function onCTA() {
              controller.changeStep(1);
              tour.endTour(true);
            }
          }]
        }; //tour = hopscotch.startTour(dashboardTour);
      }
    }
  });

  _exports.default = _default;
});