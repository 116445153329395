define("rd-basic-client/models/cow-category", ["exports", "ember-data", "rd-basic-client/mixins/notification", "moment"], function (_exports, _emberData, _notification, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-function-prototype-extensions */
  var _default = _emberData.default.Model.extend(_notification.default, {
    cow_category_label_id: _emberData.default.belongsTo("cow-category-label"),
    cowCategoryLabel: Ember.computed.alias("cow_category_label_id"),
    feeds: _emberData.default.hasMany("feed"),
    cows: _emberData.default.attr("number"),
    dailyProduction: _emberData.default.attr("number"),
    perCowDMI: _emberData.default.attr("boolean"),
    staticFeedUsage: _emberData.default.attr("boolean"),
    staticFeedCost: _emberData.default.attr("number"),
    staticFeedType: _emberData.default.attr("string"),
    ingredientFeedUsage: _emberData.default.attr("boolean"),
    month: Ember.computed.alias('month_id'),
    month_id: _emberData.default.belongsTo("month"),
    totalDMICows: function () {
      return this.get("perCowDMI") ? Math.abs(this.get("cows")) : 1;
    }.property("cows", "perCowDMI"),
    totalProduction: function () {
      return this.get("month.totalProduction");
    }.property("month.totalProduction"),
    totalProductionCWT: Ember.computed.alias("month.totalProductionCWT"),
    order: function () {
      return this.get("cowCategoryLabel.order");
    }.property("cowCategoryLabel"),
    isStaticFeedCWT: function () {
      return this.get("staticFeedType") === "CWT";
    }.property("staticFeedType"),
    isStaticTotalCost: function () {
      return this.get("staticFeedType") === "TOTAL";
    }.property("staticFeedType"),
    isStaticPerCow: function () {
      return this.get("staticFeedType") === "PER_COW";
    }.property("staticFeedType"),
    // totalFeedCosts: function() {
    //     return this.get('totalFeedCosts') - this.get('month.totalFeedHedgePL');
    //   }.property('totalFeedCosts', 'month.totalFeedHedgePL'),
    flatFeedCosts: function () {
      var value = 0;

      if (this.get("staticFeedUsage")) {
        if (this.get("isStaticFeedCWT")) {
          value = this.get("staticFeedCost") / 100 * this.get("totalProduction");
        } else if (this.get("isStaticTotalCost")) {
          value = this.get("staticFeedCost");
        } else if (this.get("isStaticPerCow")) {
          value = this.get("staticFeedCost") * this.get("cows") * _moment.default.utc(this.get("month.startDate")).daysInMonth();
        }
      }

      return value * 1;
    }.property("staticFeedUsage", "staticFeedCost", "totalProduction", "isStaticPerCow", "isStaticTotalCost", "isStaticFeedCWT", "cows", "month.startDate"),
    energyUsage: function () {
      var feeds = this.get("feeds").filterBy("feedCategory", "Energy");
      var tons = 0;

      if (feeds === undefined) {
        return 0;
      }

      feeds.forEach(function (feed) {
        tons += feed.get("monthlyUsage") / 2000;
      }, this);
      return tons;
    }.property("feeds.@each.feedCategory", "feeds.@each.monthlyUsage"),
    energyCostPerTon: function () {
      var feeds = this.get("feeds").filterBy("feedCategory", "Energy");
      var tons = 0;
      var cost = 0;
      var u;

      if (feeds === undefined || feeds.length === 0) {
        return 0;
      }

      feeds.forEach(function (e) {
        u = e.get("monthlyUsage") / 2000;
        cost += u * e.get("costPerTon");
        tons += u;
      });

      if (tons === 0) {
        return 0;
      }

      return cost / tons;
    }.property("feeds.@each.feedCategory", "feeds.@each.monthlyUsage", "feeds.@each.costPerTon"),
    totalEnergyCost: function () {
      if (this.get("energyUsage") !== undefined && this.get("energyCostPerTon") !== undefined) {
        return this.get("energyUsage") * this.get("energyCostPerTon");
      }

      return 0;
    }.property("energyCostPerTon", "energyUsage"),
    totalEnergyCostPerCWT: function () {
      if (this.get("totalProductionCWT") > 0) {
        return this.get("totalEnergyCost") / this.get("totalProductionCWT");
      } else {
        return 0;
      }
    }.property("totalProductionCWT", "totalEnergyCost"),
    scenarioEnergyCostPerTon: function () {
      var feeds = this.get("feeds").filterBy("feedCategory", "Energy");
      var tons = 0;
      var cost = 0;
      var u;

      if (feeds === undefined || feeds.length === 0) {
        return 0;
      }

      feeds.forEach(function (e) {
        u = e.get("monthlyUsage") / 2000;
        cost += u * e.get("scenarioCostPerTon");
        tons += u;
      });

      if (tons === 0) {
        return 0;
      }

      return cost / tons;
    }.property("feeds.@each.feedCategory", "feeds.@each.monthlyUsage", "feeds.@each.scenarioCostPerTon"),
    scenarioTotalEnergyCost: function () {
      if (this.get("energyUsage") !== undefined && this.get("scenarioEnergyCostPerTon") !== undefined) {
        return this.get("energyUsage") * this.get("scenarioEnergyCostPerTon");
      }

      return 0;
    }.property("scenarioEnergyCostPerTon", "energyUsage"),
    proteinUsage: function () {
      var feeds = this.get("feeds").filterBy("feedCategory", "Protein");
      var tons = 0;

      if (feeds === undefined) {
        return 0;
      }

      feeds.forEach(function (e) {
        tons += e.get("monthlyUsage") / 2000;
      });
      return tons;
    }.property("feeds.@each.feedCategory", "feeds.@each.monthlyUsage"),
    totalProteinCost: function () {
      if (this.get("proteinUsage") !== undefined && this.get("proteinCostPerTon") !== undefined) {
        return this.get("proteinUsage") * this.get("proteinCostPerTon");
      }
    }.property("proteinCostPerTon", "proteinUsage"),
    proteinCostPerTon: function () {
      var feeds = this.get("feeds").filterBy("feedCategory", "Protein");
      var tons = 0;
      var cost = 0;
      var u;

      if (feeds === undefined || feeds.length === 0) {
        return 0;
      }

      feeds.forEach(function (e) {
        u = e.get("monthlyUsage") / 2000;
        cost += u * e.get("costPerTon");
        tons += u;
      });

      if (tons === 0) {
        return 0;
      }

      return cost / tons;
    }.property("feeds.@each.feedCategory", "feeds.@each.monthlyUsage", "feeds.@each.costPerTon"),
    totalProteinCostPerCWT: function () {
      if (this.get("totalProductionCWT") > 0) {
        return this.get("totalProteinCost") / this.get("totalProductionCWT");
      } else {
        return 0;
      }
    }.property("totalProductionCWT", "totalProteinCost"),
    scenarioProteinCostPerTon: function () {
      var feeds = this.get("feeds").filterBy("feedCategory", "Protein");
      var tons = 0;
      var cost = 0;
      var u;

      if (feeds === undefined || feeds.length === 0) {
        return 0;
      }

      feeds.forEach(function (e) {
        u = e.get("monthlyUsage") / 2000;
        cost += u * e.get("scenarioCostPerTon");
        tons += u;
      });

      if (tons === 0) {
        return 0;
      }

      return cost / tons;
    }.property("feeds.@each.feedCategory", "feeds.@each.monthlyUsage", "feeds.@each.scenarioCostPerTon"),
    scenarioTotalProteinCost: function () {
      if (this.get("proteinUsage") !== undefined && this.get("scenarioProteinCostPerTon") !== undefined) {
        return this.get("proteinUsage") * this.get("scenarioProteinCostPerTon");
      }
    }.property("scenarioProteinCostPerTon", "proteinUsage"),
    totalOtherFeedCosts: function () {
      var feeds = this.get("feeds").filterBy("feedCategory", "Other Feed");
      var cost = 0;
      var u;

      if (feeds === undefined || feeds.length === 0) {
        return 0;
      }

      feeds.forEach(function (feed) {
        u = feed.get("monthlyUsage") * this.get("totalDMICows") / 2000;
        cost += u * feed.get('costPerTon');
      }, this);
      return cost;
    }.property("feeds.@each.feedCategory", "feeds.@each.costPerTon", "feeds.@each.monthlyUsage", "totalDMICows"),
    scenarioTotalOtherFeedCosts: function () {
      var feeds = this.get("feeds").filterBy("feedCategory", "Other Feed");
      var cost = 0;
      var u;

      if (feeds === undefined || feeds.length === 0) {
        return 0;
      }

      feeds.forEach(function (feed) {
        u = feed.get("monthlyUsage") * this.get("totalDMICows") / 2000;
        cost += u * feed.get("scenarioCostPerTon");
      }, this);
      return cost;
    }.property("feeds.@each.feedCategory", "feeds.@each.scenarioCostPerTon", "feeds.@each.monthlyUsage", "totalDMICows"),
    totalFeedCostsPerCWT: function () {
      if (this.get("totalFeedCosts") > 0) {
        return this.get("totalFeedCosts") / this.get("totalProductionCWT");
      } else {
        return 0;
      }
    }.property("totalProductionCWT", "totalFeedCosts"),
    totalFeedCosts: function () {
      var value = this.get("flatFeedCosts");

      if (this.get("ingredientFeedUsage")) {
        if (this.get("totalEnergyCost") !== undefined) {
          value += this.get("totalEnergyCost");
        }

        if (this.get("totalProteinCost") !== undefined) {
          value += this.get("totalProteinCost");
        }

        if (this.get("totalOtherFeedCosts") !== undefined) {
          value += this.get("totalOtherFeedCosts");
        }
      }

      return value;
    }.property("ingredientFeedUsage", "flatFeedCosts", "totalEnergyCost", "totalProteinCost", "totalOtherFeedCosts"),
    scenarioTotalFeedCosts: function () {
      // other feed costs aren't affected.
      var value = this.get("flatFeedCosts");

      if (this.get("ingredientFeedUsage") || !this.get("staticFeedUsage")) {
        if (this.get("scenarioTotalEnergyCost") !== undefined) {
          value += this.get("scenarioTotalEnergyCost");
        }

        if (this.get("scenarioTotalProteinCost") !== undefined) {
          value += this.get("scenarioTotalProteinCost");
        }

        if (this.get("scenarioTotalOtherFeedCosts") !== undefined) {
          value += this.get("scenarioTotalOtherFeedCosts");
        }
      }

      return value;
    }.property("ingredientFeedUsage", "scenarioTotalEnergyCost", "scenarioTotalProteinCost", "scenarioTotalOtherFeedCosts", "staticFeedCost", "staticFeedUsage", "flatFeedCosts", "totalProduction")
  });

  _exports.default = _default;
});