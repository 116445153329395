define("rd-basic-client/controllers/revenue", ["exports", "rd-basic-client/mixins/editable-months"], function (_exports, _editableMonths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editableMonths.default, {
    init: function init() {
      this._super();

      this.set("perCWT", true);
      this.set("perCow", false);
      this.set('perTotal', false);
    },
    actions: {
      setCWT: function setCWT() {
        this.set("perCWT", true);
        this.set("perCow", false);
        this.set('perTotal', false);
      },
      setTotal: function setTotal() {
        this.set("perCWT", false);
        this.set("perCow", false);
        this.set('perTotal', true);
      },
      setCow: function setCow() {
        this.set("perCWT", false);
        this.set("perCow", true);
        this.set('perTotal', false);
      }
    }
  });

  _exports.default = _default;
});