define("rd-basic-client/components/dashboard-chart", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    id: "Y/Fyns8x",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[14,1]],\"hasEval\":false}",
    meta: {
      moduleName: "rd-basic-client/components/dashboard-chart.hbs"
    }
  });
  /* eslint-disable no-undef */

  /* eslint-disable ember/no-observers */


  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    minimumFractionDigits: 2,
    tagName: 'canvas',
    attributeBindings: ['width', 'height'],
    init: function init() {
      this._super.apply(this, arguments);

      this.plugins = this.plugins || [];
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var context = this.get('element');
      var data = this.data;
      var type = 'line';
      var options = this.chartOptions;
      var plugins = this.get('plugins'); // eslint-disable-next-line no-undef

      var chart = new Chart(context, {
        type: type,
        data: data,
        options: options,
        plugins: plugins
      });
      this.set('chart', chart);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('chart').destroy();
    },
    dataUpdated: Ember.observer('data', 'chartOptions', function () {
      this.updateChart();
    }),
    updateChart: function updateChart() {
      var chart = this.get('chart');
      var data = this.get('data');
      var options = this.get('chartOptions');
      var animate = this.get('animate');

      if (chart) {
        chart.data = data;
        chart.options = options;

        if (animate) {
          chart.update();
        } else {
          chart.update(0);
        }
      }
    },
    data: Ember.computed("currentMonths.@each.{netIncome,totalDairyRevenue,totalDairyCosts,classThreePercentageHedgedAlternative,classesTwoAndFourPercentageHedgedAlternative}", "chartPriceType", "perCWT", "perCow", "totalProductionCWT", "priceType", function () {
      var _this = this;

      var chartPriceType = this.get("chartPriceType");
      var data = {};

      if (this.priceType == "blended") {
        data = {
          datasets: [{
            label: 'Objective',
            backgroundColor: '#36B54A',
            borderColor: '#36B54A',
            data: this.currentMonths.sortBy('startDate').map(function (m) {
              var factor = 0;

              if (_this.get("perCWT") && !Ember.isEmpty(m.get("totalProductionCWT"))) {
                factor = m.get("totalProductionCWT");
              }

              if (_this.get("perCow") && !Ember.isEmpty(m.get("cows"))) {
                factor = m.get("cows");
              }

              return {
                x: _moment.default.utc(m.get("startDate")),
                y: factor !== 0 && !Ember.isEmpty(m.get("marginObjective") && !isNaN(m.get("marginObjective"))) ? m.get("marginObjective") / factor : 0
              };
            }),
            fill: false,
            dragData: false
          }, {
            label: 'Scenario',
            fill: false,
            backgroundColor: "#333",
            borderColor: "#333",
            data: this.currentMonths.sortBy('startDate').map(function (m) {
              var factor = 0;

              if (_this.get("perCWT") && !Ember.isEmpty(m.get("totalProductionCWT"))) {
                factor = m.get("totalProductionCWT");
              }

              if (_this.get("perCow") && !Ember.isEmpty(m.get("cows"))) {
                factor = m.get("cows");
              }

              return {
                x: _moment.default.utc(m.get("startDate")),
                y: factor !== 0 && !Ember.isEmpty(m.get("scenarioNetIncome")) && !isNaN(m.get("scenarioNetIncome")) ? m.get("scenarioNetIncome") / factor : 0
              };
            })
          }, {
            label: 'Current',
            backgroundColor: '#0074D9',
            borderColor: '#0074D9',
            data: this.currentMonths.sortBy('startDate').map(function (m) {
              var factor = 0;

              if (_this.get("perCWT") && !Ember.isEmpty(m.get("totalProductionCWT"))) {
                factor = m.get("totalProductionCWT");
              }

              if (_this.get("perCow") && !Ember.isEmpty(m.get("cows"))) {
                factor = m.get("cows");
              }

              return {
                x: _moment.default.utc(m.get("startDate")),
                y: factor !== 0 && !Ember.isEmpty(m.get(chartPriceType)) && !isNaN(m.get(chartPriceType)) ? m.get(chartPriceType) / factor : 0
              };
            }),
            fill: false,
            dragData: false
          }]
        };
      }

      if (this.priceType == "unhedged") {
        data = {
          datasets: [{
            label: 'Total Revenue',
            backgroundColor: '#36B54A',
            borderColor: '#36B54A',
            data: this.currentMonths.sortBy('startDate').map(function (m) {
              var factor = 0;

              if (_this.get("perCWT") && !Ember.isEmpty(m.get("totalProductionCWT"))) {
                factor = m.get("totalProductionCWT");
              }

              if (_this.get("perCow") && !Ember.isEmpty(m.get("cows"))) {
                factor = m.get("cows");
              }

              return {
                x: _moment.default.utc(m.get("startDate")),
                y: factor !== 0 && !Ember.isEmpty(m.get("totalRevenue") && !isNaN(m.get("totalRevenue"))) ? m.get("totalRevenue") / factor : 0
              };
            }),
            fill: false,
            dragData: false
          }, {
            label: 'Total Cost of Production',
            fill: false,
            backgroundColor: "#333",
            borderColor: "#333",
            data: this.currentMonths.sortBy('startDate').map(function (m) {
              var factor = 0;

              if (_this.get("perCWT") && !Ember.isEmpty(m.get("totalProductionCWT"))) {
                factor = m.get("totalProductionCWT");
              }

              if (_this.get("perCow") && !Ember.isEmpty(m.get("cows"))) {
                factor = m.get("cows");
              }

              return {
                x: _moment.default.utc(m.get("startDate")),
                y: factor !== 0 && !Ember.isEmpty(m.get("totalCostOfProduction")) && !isNaN(m.get("totalCostOfProduction")) ? m.get("totalCostOfProduction") / factor : 0
              };
            })
          }]
        };
      }

      if (this.priceType == "hedged") {
        data = {
          datasets: [{
            label: 'Class III',
            backgroundColor: '#36B54A',
            borderColor: '#36B54A',
            data: this.currentMonths.sortBy('startDate').map(function (m) {
              var price;

              if (_this.get("isGlanbia")) {
                price = isNaN(m.get("glTotalHedged")) ? 0 : m.get("glTotalHedged");
              } else {
                price = !Ember.isEmpty(m.get("classThreePercentageHedgedAlternative") && !isNaN(m.get("classThreePercentageHedgedAlternative"))) ? m.get("classThreePercentageHedgedAlternative") * 100 : 0;
              }

              return {
                x: _moment.default.utc(m.get("startDate")),
                y: price
              };
            }),
            fill: false,
            dragData: false
          }, {
            label: 'Class IV',
            fill: false,
            backgroundColor: "#333",
            borderColor: "#333",
            data: this.currentMonths.sortBy('startDate').map(function (m) {
              return {
                x: _moment.default.utc(m.get("startDate")),
                y: !Ember.isEmpty(m.get("classesTwoAndFourPercentageHedgedAlternative") && !isNaN(m.get("classesTwoAndFourPercentageHedgedAlternative"))) ? m.get("classesTwoAndFourPercentageHedgedAlternative") * 100 : 0
              };
            })
          }, {
            label: 'Corn',
            backgroundColor: '#0074D9',
            borderColor: '#0074D9',
            data: this.currentMonths.sortBy('startDate').map(function (m) {
              return {
                x: _moment.default.utc(m.get("startDate")),
                y: !Ember.isEmpty(m.get("cornPercentageHedged") && !isNaN(m.get("cornPercentageHedged"))) ? m.get("cornPercentageHedged") * 100 : 0
              };
            }),
            fill: false,
            dragData: false
          }, {
            label: 'Soybean Meal',
            backgroundColor: '#7FDBFF',
            borderColor: '#7FDBFF',
            data: this.currentMonths.sortBy('startDate').map(function (m) {
              return {
                x: _moment.default.utc(m.get("startDate")),
                y: !Ember.isEmpty(m.get("soybeanMealPercentageHedged") && !isNaN(m.get("soybeanMealPercentageHedged"))) ? m.get("soybeanMealPercentageHedged") * 100 : 0
              };
            }),
            fill: false,
            dragData: false
          }]
        };
      }

      return data;
    }),
    chartOptions: Ember.computed("minimumFractionDigits", function () {
      var _this2 = this;

      return {
        responsive: true,
        dragData: false,
        legend: {
          display: true,
          position: 'bottom'
        },
        scales: {
          xAxes: [{
            display: true,
            type: 'time'
          }],
          yAxes: [{
            display: true,
            ticks: {
              // Include a dollar sign in the ticks
              callback: function callback(value) {
                if (_this2.priceType != 'hedged') {
                  return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: _this2.minimumFractionDigits
                  }).format(value);
                }

                if (_this2.priceType == 'hedged') {
                  return new Intl.NumberFormat('en-US', {
                    style: 'percent',
                    minimumFractionDigits: _this2.minimumFractionDigits
                  }).format(value / 100);
                }

                return value;
              }
            }
          }]
        },
        tooltips: {
          mode: 'x',
          position: 'nearest',
          callbacks: {
            title: function title(tooltipItem) {
              return (0, _moment.default)(tooltipItem[0].xLabel).utc().format("MMM YYYY");
            },
            label: function label(tooltipItem, datasets) {
              var label = datasets['datasets'][tooltipItem['datasetIndex']].label;
              var value = tooltipItem['yLabel'];

              if (_this2.priceType != 'hedged') {
                value = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: _this2.minimumFractionDigits
                }).format(tooltipItem['yLabel']);
              }

              if (_this2.priceType === 'hedged') {
                value = new Intl.NumberFormat('en-US', {
                  style: 'percent',
                  minimumFractionDigits: _this2.minimumFractionDigits
                }).format(tooltipItem['yLabel']);
              }

              return "".concat(label, ":  ").concat(value);
            }
          }
        }
      };
    })
  }));

  _exports.default = _default;
});