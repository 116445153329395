define("rd-basic-client/routes/allpositions", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "rd-basic-client/mixins/market-data-subscription"], function (_exports, _authenticatedRouteMixin, _marketDataSubscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _marketDataSubscription.default, {
    model: function model() {
      return this.store.findAll("position");
    },
    afterModel: function afterModel() {
      this.subscribeToMarketData(2500);
    }
  });

  _exports.default = _default;
});