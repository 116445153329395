define("rd-basic-client/utils/whey-value-per-cwt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = wheyValuePerCwt;

  /**
         * This method will analyze the object array devined in environment.js
         * It will compare each object criteria with passed value.
         * Returns the object's 'value' and -1 if any of the items match
         * Attention:
         *     lte has priority over lt as the gte has priority over gt
         *     so, if lte and lt are specified, only lte will be validated
         * lt  - lower than
         * lte - lower than or equal
         * gt  - greater than
         * gte - greater than or equal
        **/
  function wheyValuePerCwt(parameter) {
    var wheyValuePerCWT = [{
      lt: 0.21,
      value: 0
    }, {
      gte: 0.21,
      lt: 0.24,
      value: 0.250
    }, {
      gte: 0.24,
      lt: 0.27,
      value: 0.385
    }, {
      gte: 0.27,
      lt: 0.3,
      value: 0.520
    }, {
      gte: 0.3,
      lt: 0.33,
      value: 0.655
    }, {
      gte: 0.33,
      lt: 0.36,
      value: 0.790
    }, {
      gte: 0.36,
      lt: 0.39,
      value: 0.925
    }, {
      gte: 0.39,
      lt: 0.42,
      value: 1.060
    }, {
      gte: 0.42,
      lt: 0.45,
      value: 1.195
    }, {
      gte: 0.45,
      lt: 0.48,
      value: 1.330
    }, {
      gte: 0.48,
      lt: 0.51,
      value: 1.465
    }, {
      gte: 0.51,
      lt: 0.54,
      value: 1.600
    }, {
      gte: 0.54,
      lt: 0.57,
      value: 1.735
    }, {
      gte: 0.57,
      lt: 0.6,
      value: 1.870
    }, {
      gte: 0.6,
      value: 2.005
    }];

    for (var i = 0; i < wheyValuePerCWT.length; i++) {
      var obj = wheyValuePerCWT[i];

      if (obj.gte && !obj.lt && !obj.lte && parameter >= obj.gte) {
        return obj.value;
      }

      if (obj.gt && !obj.lt && !obj.lte && parameter > obj.gt) {
        return obj.value;
      }

      if (obj.lte && !obj.gt && !obj.gte && parameter <= obj.lte) {
        return obj.value;
      }

      if (obj.lt && !obj.gt && !obj.gte && parameter < obj.lt) {
        return obj.value;
      }

      if (obj.lte && obj.gte && parameter >= obj.gte && parameter <= obj.lte) {
        return obj.value;
      }

      if (obj.lte && obj.gt && parameter > obj.gt && parameter <= obj.lte) {
        return obj.value;
      }

      if (obj.lt && obj.gte && parameter >= obj.gte && parameter < obj.lt) {
        return obj.value;
      }

      if (obj.lt && obj.gt && parameter > obj.gt && parameter < obj.lt) {
        return obj.value;
      }
    }

    return -1;
  }
});