define("rd-basic-client/controllers/positions", ["exports", "rd-basic-client/mixins/pricingModel", "moment", "rd-basic-client/mixins/editable-months"], function (_exports, _pricingModel, _moment, _editableMonths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pricingModel.default, _editableMonths.default, {
    init: function init() {
      this._super();

      this.set("perCWT", true);
      this.set("perCow", false);
      this.set('perTotal', false);
      this.set("updateMonths", new Set());
      this.set('otcPositionTypes', ['Forward', 'Call', 'Put']);
      this.set('otcSideTypes', ['Bought', 'Sold']);
    },
    isGlanbia: Ember.computed('model.user.pricingModel', function () {
      return this.get("model.user.pricingModel") === 'glanbia';
    }),
    user: Ember.computed.alias('model.user'),
    checkedMonths: Ember.computed.filterBy('model.selectableMonths', 'checked', true),
    partialMonthsSelected: Ember.computed('checkedMonths.@each.checked', function () {
      return this.checkedMonths.length > 0 && this.checkedMonths.length < this.model.months.length;
    }),
    allMonthsSelected: Ember.computed('checkedMonths.@each.checked', function () {
      return this.checkedMonths.length == this.model.months.length;
    }),
    topInstrument: function () {
      var isDairy = ["DC", "GDK", "DY", "CSC", "CB", "GNF"].indexOf(this.get("model.productGroup.symbol")) >= 0;
      var futures = this.model.future_instruments.sortBy('displayExpiresAt');
      var instrument = futures[0];

      if (isDairy && (0, _moment.default)().diff((0, _moment.default)(instrument.displayExpiresAt), "days") > 15) {
        instrument = futures[1];
      }

      return instrument;
    }.property("model.future_instruments.@each", "model.productGroup.symbol"),
    historicalPriceArray: function () {
      var productGroupSymbol = this.get("model.productGroup.symbol");
      var historicalPrices = this.get("model.historicalPrices").filter(function (p) {
        return (0, _moment.default)(p.get('month')).isAfter((0, _moment.default)().subtract(5, 'years'));
      });

      if (productGroupSymbol === "DC") {
        return historicalPrices.sortBy("classIii").mapBy("classIii");
      }

      if (productGroupSymbol === "GDK") {
        return historicalPrices.sortBy("classIv").mapBy("classIv");
      }

      if (productGroupSymbol === "ZC") {
        return historicalPrices.sortBy("corn").mapBy("corn");
      }

      if (productGroupSymbol === "ZM") {
        return historicalPrices.sortBy("soybeanMeal").mapBy("soybeanMeal");
      }

      return null;
    }.property("model.historicalPrices", "model.productGroup"),
    useRDDisplayFactor: function () {
      return ["GNF", "DY", "ZC", "CB"].indexOf(this.get("model.productGroup.symbol")) >= 0;
    }.property("model.productGroup.symbol"),
    quantityTitle: function () {
      if (this.get("model.productGroup.symbol") === "ZC") {
        return "Bushels";
      } else if (this.get("model.productGroup.symbol") === "ZM") {
        return "Tons";
      } else {
        return "Pounds";
      }
    }.property("model.productGroup"),
    showHistoricalPercentiles: function () {
      return ["DC", "GDK", "ZC", "ZM"].indexOf(this.get("model.productGroup.symbol")) >= 0;
    }.property("model.productGroup.symbol"),
    showPercentageHedged: function () {
      return ["DC", "GDK", "ZC", "ZM", "CSC", "CB", "GNF", "DY"].indexOf(this.get("model.productGroup.symbol")) >= 0;
    }.property("model.productGroup.symbol"),
    monthsWithPositions: function () {
      var self = this;
      var compositeMonths = [];
      var isGlanbia = this.get("isGlanbia");
      var isFmmo = this.get("isFmmo");
      this.get("currentMonths").forEach(function (m) {
        var obj = {};
        var productGroup = self.get("model.productGroup.symbol");

        switch (productGroup) {
          case "DC":
            obj = {
              percentageHedged: isGlanbia ? m.get("glTotalHedged") : m.get("classThreePercentageHedged"),
              quantity: isFmmo ? m.get('classThreePoundsHedgedWithoutCheese') : m.get("classThreePoundsHedged"),
              future: m.get("classThreeMilkInstrument"),
              futureBestPrice: m.get("classThreeBestPrice"),
              futureBestPriceFormatted: m.get("classThreeBestPriceFormatted"),
              optionPremium: m.get("classThreeOptionPremium"),
              cashPL: m.get("offExchangeClassThreePL"),
              netPL: m.get("classThreeNetPL"),
              futurePL: m.get("classThreeFuturePL"),
              optionPL: m.get("classThreeOptionPL"),
              priceFormat: "$0.00"
            };
            break;

          case "GDK":
            obj = {
              percentageHedged: m.get("classFourPercentageHedged"),
              quantity: isFmmo ? m.get('classFourOnlyPoundsHedged') : m.get("classFourPoundsHedged"),
              future: m.get("classFourMilkInstrument"),
              futureBestPrice: m.get("classFourBestPrice"),
              futureBestPriceFormatted: m.get("classFourBestPriceFormatted"),
              optionPremium: m.get("classFourOptionPremium"),
              cashPL: m.get("offExchangeClassFourPL"),
              netPL: m.get("classFourNetPL"),
              futurePL: m.get("classFourFuturePL"),
              optionPL: m.get("classFourOptionPL"),
              priceFormat: "$0.00"
            };
            break;

          case "DY":
            obj = {
              percentageHedged: m.get("dryWheyPercentageHedged"),
              quantity: m.get("dryWheyPoundsHedged"),
              future: m.get("dryWheyInstrument"),
              futureBestPrice: m.get("dryWheyBestPrice"),
              futureBestPriceFormatted: m.get("dryWheyBestPriceFormatted"),
              optionPremium: m.get("dryWheyOptionPremium"),
              cashPL: m.get("offExchangeDryWheyPL"),
              netPL: m.get("dryWheyNetPL"),
              futurePL: m.get("dryWheyFuturePL"),
              optionPL: m.get("dryWheyOptionPL"),
              priceFormat: "$0.0000"
            };
            break;

          case "CSC":
            obj = {
              percentageHedged: m.get("cashSettledCheesePercentageHedged"),
              quantity: m.get("cashSettledCheesePoundsHedged"),
              future: m.get("cashSettledCheeseInstrument"),
              futureBestPrice: m.get("cashSettledCheeseBestPrice"),
              futureBestPriceFormatted: m.get("cashSettledCheeseBestPriceFormatted"),
              optionPremium: m.get("cashSettledCheeseOptionPremium"),
              cashPL: m.get("offExchangeCashSettledCheesePL"),
              netPL: m.get("cashSettledCheeseNetPL"),
              futurePL: m.get("cashSettledCheeseFuturePL"),
              optionPL: m.get("cashSettledCheeseOptionPL"),
              priceFormat: "$0.0000"
            };
            break;

          case "GNF":
            obj = {
              percentageHedged: m.get("nonfatDryMilkPercentageHedged"),
              quantity: m.get("nonfatDryMilkPoundsHedged"),
              future: m.get("nonfatDryMilkInstrument"),
              futureBestPrice: m.get("nonfatDryBestPrice"),
              futureBestPriceFormatted: m.get("nonfatDryBestPriceFormatted"),
              optionPremium: m.get("nonfatDryMilkOptionPremium"),
              cashPL: m.get("offExchangeNonfatDryMilkPL"),
              netPL: m.get("nonfatDryMilkNetPL"),
              futurePL: m.get("nonfatDryMilkFuturePL"),
              optionPL: m.get("nonfatDryMilkOptionPL"),
              priceFormat: "$0.0000"
            };
            break;

          case "CB":
            obj = {
              percentageHedged: m.get("cashSettledButterPercentageHedged"),
              quantity: m.get("cashSettledButterPoundsHedged"),
              future: m.get("cashSettledButterInstrument"),
              futureBestPrice: m.get("cashSettledButterBestPrice"),
              futureBestPriceFormatted: m.get("cashSettledButterBestPriceFormatted"),
              optionPremium: m.get("cashSettledButterOptionPremium"),
              cashPL: m.get("offExchangeCashSettledButterPL"),
              netPL: m.get("offExchangeCashSettledButterPL") + m.get("cashSettledButterFuturePL") + m.get("cashSettledButterOptionPL"),
              futurePL: m.get("cashSettledButterFuturePL"),
              optionPL: m.get("cashSettledButterOptionPL"),
              priceFormat: "$0.0000"
            };
            break;

          case "ZC":
            obj = {
              percentageHedged: m.get("cornPercentageHedged"),
              quantity: m.get("cornPoundsHedged"),
              future: m.get("cornInstrument"),
              futureBestPrice: m.get("cornBestPrice"),
              futureBestPriceFormatted: m.get("cornBestPriceFormatted"),
              optionPremium: m.get("cornOptionPremium"),
              cashPL: m.get("offExchangeCornPL"),
              netPL: m.get("cornNetPL"),
              futurePL: m.get("cornFuturePL"),
              optionPL: m.get("cornOptionPL"),
              priceFormat: "$0.0000"
            };
            break;

          case "ZM":
            obj = {
              percentageHedged: m.get("soybeanMealPercentageHedged"),
              quantity: m.get("soybeanMealPoundsHedged"),
              future: m.get("soybeanMealInstrument"),
              futureBestPrice: m.get("soybeanMealBestPrice"),
              futureBestPriceFormatted: m.get("soybeanMealBestPriceFormatted"),
              optionPremium: m.get("soybeanMealOptionPremium"),
              cashPL: m.get("offExchangeSoybeanMealPL"),
              netPL: m.get("soybeanMealNetPL"),
              futurePL: m.get("soybeanMealFuturePL"),
              optionPL: m.get("soybeanMealOptionPL"),
              priceFormat: "$0.0"
            };
            break;

          default:
            throw 'Product group symbol is not recognized: ', self.get("model.productGroup.symbol");
        }

        obj.month = m;
        compositeMonths.pushObject(obj);
      });
      return compositeMonths;
    }.property('currentMonths.@each.classThreeNetPL', 'currentMonths.@each.offExchangeCashSettledButterPL', 'currentMonths.@each.cashSettledButterFuturePL', 'currentMonths.@each.cashSettledButterOptionPL', "currentMonths.@each", "positions.@each.instrument", "currentMonths.@each.classFourNetPL", "currentMonths.@each.cashSettledCheeseNetPL", "currentMonths.@each.classThreeNetPL", "currentMonths.@each.dryWheyNetPL", "currentMonths.@each.nonfatDryMilkNetPL", "currentMonths.@each.cornNetPL", "currentMonths.@each.soybeanMealNetPL", "isGlanbia", "isFmmo", "currentMonths.@each.glTotalHedged"),
    otcPositionTypes: null,
    otcSideTypes: null,
    updateIsOption: function () {
      return this.get("updatePositionType") === 'Forward' ? false : true;
    }.property("updatePositionType"),
    clicksCount: 0,
    actions: {
      enableHistorical: function enableHistorical() {
        if (this.get('clicksCount') >= 10) {
          // Reset the current year to make the life easier ;)
          this.set('currentYear', 2015);
          this.set('enabled', true);
          console.warn("Historical positions enabled!");
        } else {
          this.set('clicksCount', this.get('clicksCount') + 1);
        }
      },
      setCWT: function setCWT() {
        this.set("perCWT", true);
        this.set("perCow", false);
        this.set('perTotal', false);
      },
      setTotal: function setTotal() {
        this.set("perCWT", false);
        this.set("perCow", false);
        this.set('perTotal', true);
      },
      setCow: function setCow() {
        this.set("perCWT", false);
        this.set("perCow", true);
        this.set('perTotal', false);
      },
      openTradeModal: function openTradeModal() {
        this.set("updatePositionType", 'Forward');
        this.set("updateSide", 'Bought');
        this.set("updateQuantity", null);
        this.set("updatePrice", null);
        this.set("updateStrike", null);
        this.checkedMonths.forEach(function (m) {
          m.set('checked', false);
        });
        Ember.$('#trade-pos-modal').modal('show');
      },
      updateAllSelectedMonths: function updateAllSelectedMonths() {
        if (this.checkedMonths.length > 0 && !this.partialMonthsSelected) {
          this.checkedMonths.forEach(function (m) {
            m.set('checked', false);
          });
        } else {
          this.model.selectableMonths.forEach(function (m) {
            m.set('checked', true);
          });
        }
      },
      createNewTrade: function createNewTrade() {
        var self = this;
        var months = this.get("checkedMonths").mapBy('month');
        var quantity = self.get("updateQuantity");

        if (typeof quantity === 'string' || quantity instanceof String) {
          quantity = parseInt(quantity.replace(/,/g, ""));
        }

        var physical_position;
        months.forEach(function (m) {
          physical_position = self.store.createRecord("physicalPosition", {
            instrument_type: self.get("updatePositionType"),
            is_long: self.get("updateSide") === 'Bought' ? true : false,
            quantity: quantity,
            price: self.get("updatePrice"),
            month: m,
            product_group_id: self.get("model.productGroup.id"),
            strike: self.get("updatePositionType") === 'Forward' ? null : self.get("updateStrike"),
            option_type: self.get("updatePositionType") === 'Forward' ? null : self.get("updatePositionType")
          });
          physical_position.save();
        });
        Ember.$('#trade-pos-modal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});