define("rd-basic-client/components/rd-chart", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    containerId: 'main-chart',
    getColorPattern: function getColorPattern() {
      return this.get('colorPattern') ? this.get('colorPattern') : ['#36B54A', '#333', '#0074D9', '#7FDBFF'];
    },
    init: function init() {
      this._super(arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super();

      this.renderData();
    },
    _initChart: function _initChart() {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        // DGC: Temp
        if (!this.get('chart')
        /*&& $('#' + this.get('containerId')).size() > 0*/
        ) {
            // eslint-disable-next-line no-undef
            this.set('chart', c3.generate({
              bindto: '#' + this.get('containerId'),
              oninit: resolve,
              data: {
                drag: {
                  enabled: this.get('draggable'),
                  tick: this.get('drag_tick'),
                  callback: this.get('onDragEnd'),
                  scope: this.get('scope')
                },
                keys: {
                  x: 'x',
                  value: ['value'] // not working

                },
                selection: {
                  draggable: false,
                  enabled: false,
                  multiple: false
                },
                onclick: function onclick() {},
                x: 'x',
                columns: [],
                type: 'spline',
                empty: {
                  label: {
                    text: 'Loading data...'
                  }
                }
              },
              color: {
                pattern: this.getColorPattern()
              },
              spline: {
                interpolation: {
                  type: 'monotone'
                }
              },
              transition: {
                duration: 500
              },
              zoom: {
                enabled: false
              },
              point: {
                show: this.get('showPoints'),
                r: this.get('pointRadius')
              },
              grid: {
                lines: {
                  front: false
                },
                x: {
                  show: true
                },
                y: {
                  show: true,
                  lines: [{
                    value: 0,
                    class: 'baseline'
                  }]
                }
              },
              legend: {
                show: this.get('showlegend') !== undefined ? this.get('showlegend') : true,
                position: 'bottom',
                inset: {
                  anchor: 'top-right',
                  step: 1,
                  x: 0,
                  y: 0
                }
              },
              axis: {
                x: {
                  type: 'timeseries',
                  tick: {
                    count: this.get('xTickCount'),
                    culling: {
                      max: this.get('xMaxCulling')
                    },
                    format: '%b %Y' // format string is also available for timeseries data

                  }
                },
                y: {
                  max: this.get('maxYValue'),
                  min: this.get('minYValue'),
                  tick: {
                    count: this.get('yTickCount'),
                    culling: {
                      min: 10,
                      max: 100
                    },
                    fit: true,
                    format: function (d) {
                      if (this.get('showPercentage')) {
                        return d.toFixed(0) + '%';
                      } else {
                        if (d === 0 || d > 100 || d < -100) {
                          return (0, _numeral.default)(d).format('$0,0');
                        } else {
                          return (0, _numeral.default)(d).format('$0,0.00');
                        }
                      }
                    }.bind(this)
                  }
                }
              }
            }));
          } else {
          reject();
        }
      }.bind(this));
    },
    renderData: function () {
      if (this.get('chart')) {
        this.get('chart').load(this.get('data'));
      } else {
        this._initChart().then(function () {
          this.renderData();
        }.bind(this));
      }
    }.observes('data.[]')
  });

  _exports.default = _default;
});