define("rd-basic-client/controllers/milkpositions", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var sortFunc = function sortFunc(a, b) {
    if (a.get("instrument_id.displayExpiresAt") > b.get("instrument_id.displayExpiresAt")) {
      return 1;
    } else if (a.get("instrument_id.displayExpiresAt") < b.get("instrument_id.displayExpiresAt")) {
      return -1;
    }

    if (a.get("instrument_id.rootSymbol") > b.get("instrument_id.rootSymbol")) {
      return 1;
    } else if (a.get("instrument_id.rootSymbol") < b.get("instrument_id.rootSymbol")) {
      return -1;
    }

    if (a.get("isLong") && !b.get("isLong")) {
      return -1;
    } else if (!a.get("isLong") && b.get("isLong")) {
      return 1;
    }

    if (a.get("instrument_id.instrument_type") === "Future" && b.get("instrument_id.instrument_type") !== "Future") {
      return 1;
    } else if (a.get("instrument_id.instrument_type") !== "Future" && b.get("instrument_id.instrument_type") === "Future") {
      return -1;
    }

    if (a.get("instrument_id.option_type") === "Put" && b.get("instrument_id.option_type") === "Call") {
      return 1;
    } else if (a.get("instrument_id.option_type") === "Call" && b.get("instrument_id.option_type") === "Put") {
      return -1;
    }

    return 0;
  }; // This will filter all expired positions


  var filterFunc = function filterFunc(position) {
    // This will mantail prevous month position for the first X days
    var date = (0, _moment.default)().utc().date() < 8 ? _moment.default.utc().subtract(1, "month").startOf('month') : _moment.default.utc().startOf('month');
    var ins_exp_date = position.get('instrument_id.displayExpiresAt');
    return date.isSame(ins_exp_date) || date.isBefore(ins_exp_date);
  };

  var _default = Ember.Controller.extend({
    marketDataInstruments: Ember.computed.mapBy('model', 'instrument_id'),
    classThreePositions: Ember.computed.filterBy("model", "parent_symbol", "DC"),
    classFourPositions: Ember.computed.filterBy("model", "parent_symbol", "GDK"),
    cashSettledCheesePositions: Ember.computed.filterBy("model", "parent_symbol", "CSC"),
    cashSettledButterPositions: Ember.computed.filterBy("model", "parent_symbol", "CB"),
    nonfatDryMilkPositions: Ember.computed.filterBy("model", "parent_symbol", "GNF"),
    dryWheyPositions: Ember.computed.filterBy("model", "parent_symbol", "DY"),
    allPositions: Ember.computed.uniq("classThreePositions", "classFourPositions", "cashSettledCheesePositions", "cashSettledButterPositions", "nonfatDryMilkPositions", "dryWheyPositions"),
    allFilteredPositions: Ember.computed.filter('allPositions', filterFunc),
    allSortedPositions: Ember.computed.sort("allFilteredPositions", sortFunc),
    positionPLs: Ember.computed.mapBy("allFilteredPositions", "plInDollars"),
    positionTotalPL: Ember.computed.sum("positionPLs")
  });

  _exports.default = _default;
});