define("rd-basic-client/controllers/cowflow", ["exports", "rd-basic-client/mixins/editable-months"], function (_exports, _editableMonths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editableMonths.default, {
    cowCategories: Ember.computed.mapBy("model.months.firstObject", "cowCategories"),
    newCategoryLabel: null,
    sortedLabels: function () {
      return this.get("model.labels").sortBy("order");
    }.property("model.labels.@each.order"),
    actions: {
      showCategoryCreateModal: function showCategoryCreateModal() {
        this.set("newCategoryLabel", this.get("store").createRecord("cow-category-label"));
        this.set("newCategoryLabel.order");
        Ember.$("#create-cow-category-modal").modal("show");
      },
      createCowCategory: function createCowCategory() {
        var _this = this;

        this.newCategoryLabel.save().then(function () {
          Ember.$("#create-cow-category-modal").modal("hide");

          _this.send("refreshModel");
        }, function () {
          _this.send("refreshModel");
        });
      },
      showDeleteConfirmation: function showDeleteConfirmation(cowCategoryLabel) {
        var count = 0;
        this.get("model.months.firstObject.cowCategories").forEach(function () {
          count++;
        });

        if (count > 1) {
          this.set("categoryToDelete", cowCategoryLabel);
          Ember.$("#delete-cow-flow-modal").modal("show");
        } else {
          noty({
            layout: "topRight",
            theme: "relax",
            type: "error",
            timeout: 5000,
            killer: true,
            text: "You cannot delete the cow group, you must have at least 1 group!"
          });
        }
      },
      deleteCowCategory: function deleteCowCategory(cowCategoryLabel) {
        Ember.$("#delete-cow-flow-modal").modal("hide");
        var categories = [];
        cowCategoryLabel.get("cowCategories").forEach(function (rec) {
          categories.push(rec);
        });

        for (var i = 0; i < categories.length; i++) {
          this.store.unloadRecord(categories[i]);
        }

        cowCategoryLabel.destroyRecord();
        this.send("refreshModel");
      }
    }
  });

  _exports.default = _default;
});