define("rd-basic-client/routes/otherrevenue", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        months: this.store.findAll('month'),
        users: this.store.findAll('user')
      });
    },
    afterModel: function afterModel(model) {
      model.user = model.users.firstObject;
      return model;
    }
  });

  _exports.default = _default;
});