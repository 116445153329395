define("rd-basic-client/models/region", ["exports", "ember-data", "rd-basic-client/mixins/notification"], function (_exports, _emberData, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_notification.default, {
    name: _emberData.default.attr("string"),
    classOne: _emberData.default.attr("raw"),
    classTwo: _emberData.default.attr("raw"),
    classThree: _emberData.default.attr("raw"),
    classFour: _emberData.default.attr("raw")
  });

  _exports.default = _default;
});