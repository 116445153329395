define("rd-basic-client/mixins/market-data-subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      this._super();
    },
    onMdPoll: function onMdPoll() {
      console.log('Poll.');
      var ins = this.get("controller.marketDataInstruments");
      var self = this;

      if (ins !== undefined) {
        var ids = this.get("controller.marketDataInstruments").mapBy("id");
        var length = ids.length;
        var current = 0;
        var groups = [];
        var end;

        while (current < length) {
          end = length < current + 49 ? length : current + 49;
          groups.pushObject(ids.slice(current, end));
          current += 50;
        }

        groups.forEach(function (g) {
          self.store.find("marketdatum", {
            instrument_ids: g,
            sides: ["Last"]
          });
        });
      }

      this.set("mdTimer", Ember.run.later(this, this.onMdPoll.bind(this), this.get("mdDuration")));
    },
    subscribeToMarketData: function subscribeToMarketData(milliseconds) {
      this.set("mdDuration", milliseconds);

      if (this.get("mdTimer") !== undefined) {
        Ember.run.cancel(this.get("mdTimer"));
      }

      this.set("mdTimer", Ember.run.later(this, this.onMdPoll.bind(this), this.get("mdDuration")));
    },
    deactivate: function deactivate() {
      this._super();

      Ember.run.cancel(this.get("mdTimer"));
    }
  });

  _exports.default = _default;
});