define("rd-basic-client/serializers/barchart-market-datum", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONSerializer = _emberData.default.JSONSerializer;

  var _default = JSONSerializer.extend({
    extractId: function extractId(modelClass, resourceHash) {
      resourceHash.id = resourceHash.symbol;
      return this._super(modelClass, resourceHash);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload = payload.results;
      var cutoffTime;

      if ((0, _moment.default)().utc().hour() >= 22) {
        cutoffTime = _moment.default.utc().hour(22).minute(0).second(0).millisecond(0);
      } else {
        cutoffTime = _moment.default.utc().hour(22).minute(0).second(0).millisecond(0).subtract(1, 'day');
      }

      payload.forEach(function (quote) {
        if (quote.flag == "s" || (0, _moment.default)(quote.tradeTimestamp).isBefore(cutoffTime)) {
          quote.netChange = 0;
          quote.percentChange = 0;
          quote.high = 0;
          quote.low = 0;
        }
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      if (Array.isArray(payload)) {
        payload = payload[0];
      }

      if (payload === undefined) {
        payload = {};
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});