define("rd-basic-client/routes/milkpositions", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    marketData: Ember.inject.service('market-data'),
    model: function model() {
      return this.store.query("position", {
        embed_instrument: true
      });
    },
    afterModel: function afterModel(model) {
      this.marketData.start();
      var instruments = model.mapBy('instrument_id');
      this.marketData.subscribeTo(instruments);
    }
  });

  _exports.default = _default;
});