define("rd-basic-client/components/all-month-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSelected: false,
    updateMonths: function () {
      var isSelected = this.get("isSelected");

      if (isSelected) {
        this.get("selectedMonths").addObjects(this.get("availableMonths"));
        Ember.$(".monthCheckbox").prop('checked', true);
      } else {
        Ember.$(".monthCheckbox").prop('checked', false);
        this.get("selectedMonths").clear();
      }
    }.observes("isSelected")
  });

  _exports.default = _default;
});