define("rd-basic-client/controllers/hedges", ["exports", "rd-basic-client/mixins/pricingModel", "rd-basic-client/mixins/editable-months"], function (_exports, _pricingModel, _editableMonths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pricingModel.default, _editableMonths.default, {});

  _exports.default = _default;
});