define("rd-basic-client/utils/vela-product-group-to-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = velaProductGroupToName;

  function velaProductGroupToName(productGroupId) {
    switch (productGroupId) {
      case 32:
        return 'Class IV Milk';

      case 28:
        return 'Class III Milk';

      case 37:
        return 'Cash-Settled Cheese';

      case 31:
        return 'Corn';

      case 38:
        return 'Soybean Meal';

      case 34:
        return 'Cash-Settled Butter';

      case 35:
        return 'ZS';

      case 42:
        return 'LE';

      case 30:
        return 'Nonfat Dry Milk';

      case 33:
        return 'Dry Whey';

      default:
        return '?';
    }
  }
});