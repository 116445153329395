define("rd-basic-client/mixins/common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getPercentageHedgedValue: function getPercentageHedgedValue(future, divider, tonsHedged) {
      var feeds = this.get('allFeed');
      var applicableFeeds = feeds.filter(function (f) {
        return f.get('basisInstrument.content') === future;
      });
      var tonsUsed = 0;
      applicableFeeds.forEach(function (f) {
        tonsUsed += f.get('totalMonthProduction') / divider;
      });

      if (tonsUsed > 0) {
        return tonsHedged / tonsUsed;
      } else {
        return 0;
      }
    },
    getPoundsHedged: function getPoundsHedged(type, physicalPositions, exchangePositions) {
      var data = {
        val: 0,
        putVal: 0,
        callVal: 0
      };

      if (!physicalPositions) {
        physicalPositions = this.get('physicalPositions').filter(function (p) {
          return p.rootSymbol === type && (p.instrumentType === 'Forward' && !p.isLong || p.instrumentType === 'Put' && p.isLong || p.instrumentType === 'Call' && !p.isLong);
        });
      }

      physicalPositions.forEach(function (p) {
        var quantity = p.get('quantity');
        var instrumentType = p.get('instrumentType');

        if (instrumentType === 'Forward') {
          this.val += quantity;
        }

        if (instrumentType === 'Put') {
          this.putVal += quantity;
        }

        if (instrumentType === 'Call') {
          this.callVal += quantity;
        }
      }, data);

      if (!exchangePositions) {
        exchangePositions = this.get('positions').filter(function (p) {
          return p.get('instrument.symbol') && p.get('instrument.symbol').indexOf(type) > -1 && (p.get('instrument.instrument_type') && p.get('instrument.instrument_type').toUpperCase() === 'FUTURE' && !p.get('isLong') || p.get('instrument.option_type') && p.get('instrument.option_type').toUpperCase() === 'PUT' && p.get('isLong') || p.get('instrument.option_type') && p.get('instrument.option_type').toUpperCase() === 'CALL' && !p.get('isLong'));
        });
      }

      exchangePositions.forEach(function (p) {
        var quantityInUnits = Math.abs(p.get('quantityInUnits'));

        if (p.get('instrument.instrument_type') && p.get('instrument.instrument_type').toUpperCase() === 'FUTURE') {
          this.val += quantityInUnits;
        }

        if (p.get('instrument.option_type') && p.get('instrument.option_type').toUpperCase() === 'PUT') {
          this.putVal += quantityInUnits;
        }

        if (p.get('instrument.option_type') && p.get('instrument.option_type').toUpperCase() === 'CALL') {
          this.callVal += quantityInUnits;
        }
      }, data);
      data.val += data.putVal > data.callVal ? data.putVal : data.callVal;
      return data.val;
    },

    /**
       * This method returns totla production value based on user type
       * Input: Object
       * {
       *    FMMO: Number,
       *    CALIFORNIA: Number,
       *    hedged: Number
       * }
       * All object attributed are mandatory
       * Returns: Number
      **/
    getByUserTypePercentageHedged: function getByUserTypePercentageHedged(data) {
      var totalProduction;

      switch (this.get('user.pricingModel')) {
        case 'fmmo':
          totalProduction = data.FMMO;
          break;

        case 'california':
          totalProduction = data.CALIFORNIA;
          break;

        default:
          totalProduction = 0;
      }

      if (totalProduction > 0) {
        return data.hedged / totalProduction;
      } else {
        return 0;
      }
    },
    getPositionsValue: function getPositionsValue(group, type, attr_value) {
      var exchangePositions = this.get('positions').filter(function (p) {
        return p.get('instrument.symbol') && p.get('instrument.symbol').indexOf(group) > -1 && p.get('instrument.instrument_type').toUpperCase() === type.toUpperCase();
      });
      var value = 0;
      exchangePositions.forEach(function (pos) {
        value += pos.get(attr_value);
      });
      return value;
    },
    getOffExchangePL: function getOffExchangePL(group, pricePerTon, quantityDivider) {
      var v = 0;
      var quantity;
      var price;
      this.get('physicalPositions').forEach(function (p) {
        quantity = p.get('quantity') / (quantityDivider ? quantityDivider : 1);
        price = p.get('price');

        if (p.get('rootSymbol') === group && p.get('instrumentType') === 'Forward') {
          v += quantity * (price - pricePerTon) * (p.get('isLong') ? -1 : 1);
        }

        if ((p.get('instrumentType') === 'Put' || p.get('instrumentType') === 'Call') && p.get('rootSymbol') === group) {
          var strikePriceDiff = p.get('strike') - pricePerTon;

          if (p.get('instrumentType') === 'Put' && strikePriceDiff > 0) {
            v += quantity * strikePriceDiff * (p.get('isLong') ? 1 : -1);
          }

          if (p.get('instrumentType') === 'Call' && strikePriceDiff < 0) {
            v += quantity * strikePriceDiff * (p.get('isLong') ? -1 : 1);
          }

          if (p.get('month.isPast') && p.get('rootSymbol') === 'CSC' && group === 'CSC') {
            quantityDivider = true;
          }

          if (quantityDivider) {
            // Add premium
            if (p.get('isLong')) {
              v -= price * quantity;
            } else {
              v += price * quantity;
            }
          } else {
            if (p.get('isLong')) {
              v -= price;
            } else {
              v += price;
            }
          }
        }
      });
      return v;
    },
    getScenarioOffExchangePL: function getScenarioOffExchangePL(group, pricePerTon, quantityDivider) {
      var v = this.getOffExchangePL(group, pricePerTon, quantityDivider);
      var quantity;
      var price;
      this.get('hypotheticalPositions').forEach(function (p) {
        quantity = p.get('quantity') / (quantityDivider ? quantityDivider : 1);
        price = p.get('price');

        if (p.get('symbol') === group && p.get('instrumentType') === 'Futures (Forward)') {
          v += quantity * (price - pricePerTon) * (p.get('isLong') ? -1 : 1);
        }

        if ((p.get('instrumentType') === 'Put' || p.get('instrumentType') === 'Call') && p.get('symbol') === group) {
          var strikePriceDiff = p.get('strike') - pricePerTon;

          if (p.get('instrumentType') === 'Put' && strikePriceDiff > 0) {
            v += quantity * strikePriceDiff * (p.get('isLong') ? 1 : -1);
          }

          if (p.get('instrumentType') === 'Call' && strikePriceDiff < 0) {
            v += quantity * strikePriceDiff * (p.get('isLong') ? -1 : 1);
          }

          if (p.get('isLong')) {
            v -= price * quantity; // Prices are now in tons
          } else {
            v += price * quantity;
          }
        }
      });
      return v;
    }
  });

  _exports.default = _default;
});