define("rd-basic-client/controllers/operatingcosts", ["exports", "rd-basic-client/mixins/editable-months"], function (_exports, _editableMonths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editableMonths.default, {
    nonFeedExpsensesCWT: false,
    init: function init() {
      this._super();
    },
    filteredMonths: function () {
      var flag = this.get('nonFeedExpsensesCWT');
      this.get('currentMonths').forEach(function (m) {
        m.set('nonFeedExpsensesCWT', flag);
      });
      return this.get('currentMonths');
    }.property('currentMonths.@each', 'nonFeedExpsensesCWT'),
    actions: {
      updateDisplayMode: function updateDisplayMode(flag) {
        this.set('nonFeedExpsensesCWT', flag);
        this.get('currentMonths').forEach(function (m) {
          m.set('nonFeedExpsensesCWT', flag);
        });
      }
    }
  });

  _exports.default = _default;
});