define("rd-basic-client/templates/components/vd-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gIo5n238",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"select select-white\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"placeholder\"],[8],[0,\"Select value\"],[9],[0,\"\\n    \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\",true],[11,\"data-value\",[23,1,[\"id\"]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"icon\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"em\",true],[11,\"class\",[23,1,[\"icon\"]]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" \\n\"]],\"parameters\":[]},null],[0,\"                \"],[1,[23,1,[\"label\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"input\",true],[10,\"name\",\"changeme\"],[10,\"type\",\"hidden\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/components/vd-select.hbs"
    }
  });

  _exports.default = _default;
});