define("rd-basic-client/routes/dashboard", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // marketDataPolling: service('market-data'),
    apollo: Ember.inject.service(),
    marketData: Ember.inject.service('market-data'),
    beforeModel: function beforeModel() {
      if (!this.get("session").isAuthenticated) {
        this.transitionTo("login");
      }
    },
    model: function model() {
      var application = this.modelFor('application');

      if (application.classThreeFutureId) {
        return Ember.RSVP.hash({
          months: application.months,
          classThreeFuture: this.store.peekRecord('vault-instrument', application.classThreeFutureId),
          classFourFuture: this.store.peekRecord('vault-instrument', application.classFourFutureId),
          dryWheyFuture: this.store.peekRecord('vault-instrument', application.dryWheyFutureId),
          cashSettledCheeseFuture: this.store.peekRecord('vault-instrument', application.cashSettledCheeseFutureId),
          nonfatDryMilkFuture: this.store.peekRecord('vault-instrument', application.nonfatDryMilkFutureId),
          cashSettledButterFuture: this.store.peekRecord('vault-instrument', application.cashSettledButterFutureId),
          soybeanMealFuture: this.store.peekRecord('vault-instrument', application.soybeanMealFutureId),
          cornFuture: this.store.peekRecord('vault-instrument', application.cornFutureId),
          soybeanFuture: this.store.peekRecord('vault-instrument', application.soybeansFutureId),
          wheatFuture: this.store.peekRecord('vault-instrument', application.wheatFutureId),
          historicalPrices: this.store.findAll("historicalPrice"),
          positions: this.store.findAll("position"),
          physicalPositions: this.store.findAll("physical-position"),
          // // TO BE REMOVED
          users: application.users,
          user: this.store.findAll('user').get("firstObject")
        });
      }
    },
    afterModel: function afterModel(model) {
      this.marketData.start();
      var instruments = [].concat(model.classThreeFuture, model.classFourFuture, model.dryWheyFuture, model.cashSettledCheeseFuture, model.nonfatDryMilkFuture, model.cashSettledButterFuture, model.cornFuture, model.soybeanMealFuture, model.wheatFuture, model.soybeanFuture);
      var positionInstruments = model.positions.mapBy('instrument_id');
      instruments = instruments.concat(positionInstruments);
      this.marketData.subscribeTo(instruments);
    }
  });

  _exports.default = _default;
});