define("rd-basic-client/components/resizable-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super(arguments);

      Ember.$(window).resize(this._resizeGridContainer.bind(this));

      this._resizeGridContainer();

      Ember.run.later(this._resizeGridContainer, 1);
    },
    didRender: function didRender() {
      this._resizeGridContainer();
    },
    _resizeGridContainer: function _resizeGridContainer() {
      Ember.$('#content').width(Ember.$('#page-content').width() - Ember.$('#right-column').width() - Ember.$('#left-column').width() - 6);
    }
  });

  _exports.default = _default;
});