define("rd-basic-client/utils/vela-product-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = velaProductGroups;

  function velaProductGroups() {
    return [{
      id: 32,
      name: 'Class IV Milk',
      symbol: 'GDK'
    }, {
      id: 28,
      name: 'Class III Milk',
      symbol: 'DC'
    }, {
      id: 37,
      name: 'Cash-Settled Cheese',
      symbol: 'CSC'
    }, {
      id: 31,
      name: 'Corn',
      symbol: 'ZC'
    }, {
      id: 38,
      name: 'Soybean Meal',
      symbol: 'ZM'
    }, {
      id: 34,
      name: 'Cash-Settled Butter',
      symbol: 'CB'
    }, {
      id: 30,
      name: 'Nonfat Dry Milk',
      symbol: 'GNF'
    }, {
      id: 33,
      name: 'Dry Whey',
      symbol: 'DY'
    }];
  }
});