define("rd-basic-client/mixins/month-validation", ["exports", "rd-basic-client/mixins/month-utils", "moment"], function (_exports, _monthUtils, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_monthUtils.default, {
    isValidDisplayMonth: function () {
      return this.isLessThan24MonthFromNow(this);
    }.property('startDate'),
    hasInvalidRevenue: function () {
      return this.get('isValidDisplayMonth') ? this.get('cows') === 0 || this.get('totalProductionCWT') === 0 : false;
    }.property('cows', 'isValidDisplayMonth', 'totalProductionCWT'),
    invalidRevenueMessage: function invalidRevenueMessage() {
      var message = '';

      if (this.get('hasInvalidRevenue')) {
        message = '<strong>' + (0, _moment.default)(this.get('startDate')).utc().format('MMM YYYY') + '</strong> has no milk production';
      }

      return message;
    },
    hasInvalidTotalCostOfProduction: function () {
      return this.get('isValidDisplayMonth') ? this.get('totalFeedCosts') === 0 || this.get('totalOtherCosts') === 0 : false;
    }.property('totalFeedCosts', 'totalOtherCosts', 'isValidDisplayMonth'),
    hasInvalidTotalFeedCosts: function () {
      return this.get('isValidDisplayMonth') ? this.get('totalFeedCosts') === 0 : false;
    }.property('totalFeedCosts'),
    invalidTotalCostOfProductionMessage: function invalidTotalCostOfProductionMessage() {
      var message = '';

      if (this.get('hasInvalidTotalCostOfProduction')) {
        message = '<strong>' + (0, _moment.default)(this.get('startDate')).utc().format('MMM YYYY') + '</strong> has invalid cost of production';
      }

      return message;
    }
  });

  _exports.default = _default;
});