define("rd-basic-client/templates/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZIaZBzaY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"visible-xs-block\"],[8],[0,\"\\n  Dashboard\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row hidden-xs\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-8\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"style\",\"text-align: left; padding-right: 10px; margin-bottom: -5px;\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isBlendedPrices\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h4\",true],[8],[0,\"Combined Net P&L\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isUnhedgedPrices\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h4\",true],[8],[0,\"Unhedged Net P&L\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isHedgedPrices\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h4\",true],[8],[0,\"Hedged %\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"div\",true],[10,\"style\",\"height: 250px; margin-top: 10px; min-width: 520px;\"],[8],[0,\"\\n        \"],[5,\"dashboard-chart\",[[12,\"height\",\"200px\"]],[[\"@currentMonths\",\"@chartPriceType\",\"@perCWT\",\"@perCow\",\"@totalProductionCWT\",\"@priceType\"],[[23,0,[\"currentMonths\"]],[23,0,[\"chartPriceType\"]],[23,0,[\"perCWT\"]],[23,0,[\"perCow\"]],[23,0,[\"totalProductionCWT\"]],[23,0,[\"priceType\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-4\"],[10,\"id\",\"dashboard-percentile\"],[8],[0,\"\\n    \"],[15,\"partials/dashboard/percentile\",[]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-8\"],[8],[0,\"\\n    \"],[15,\"partials/dashboard/maingrid\",[]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col-md-4 hidden-xs\"],[8],[0,\"\\n    \"],[15,\"partials/dashboard/market\",[]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":true}",
    "meta": {
      "moduleName": "rd-basic-client/templates/dashboard.hbs"
    }
  });

  _exports.default = _default;
});