define("rd-basic-client/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4Gz9cmW9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"style\",\"text-align:center\"],[8],[0,\"\\n  \"],[7,\"em\",true],[10,\"class\",\"fa fa-spinner fa-spin\"],[8],[9],[0,\" Loading...\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});