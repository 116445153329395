define("rd-basic-client/templates/components/month-usage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZMu1+ZR4",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"usage\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/components/month-usage.hbs"
    }
  });

  _exports.default = _default;
});