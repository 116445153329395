define("rd-basic-client/components/vd-select", ["exports", "rd-basic-client/mixins/body-event-listener"], function (_exports, _bodyEventListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_bodyEventListener.default, {
    isShown: false,
    init: function init() {
      this._super(arguments);
    },
    bodyClick: function bodyClick() {
      this.send('hideDropdown');
    },
    _hideDropdown: function _hideDropdown() {
      if (this.get('isShown')) {
        var parent = Ember.$(this.$()[0]).find('.select');
        parent.removeClass('is-open');
        this.set('isShown', false);
      }
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.$(this.$()[0]).on('click', '.placeholder', function (evt) {
        var parent = Ember.$(this).closest('.select');

        if (!parent.hasClass('is-open')) {
          self.set('isShown', true);
          parent.addClass('is-open');
          Ember.$('.select.is-open').not(parent).removeClass('is-open');
        } else {
          self._hideDropdown();
        }

        evt.preventDefault();
      }).on('click', 'ul>li', function (evt) {
        var parent = Ember.$(this).closest('.select');
        var label = Ember.$(this).text();
        var value = Ember.$(this).attr('data-value');

        if (self.get('beforeSelect') && self.get('beforeSelect').apply(self.get('scope'), [value, self.get('target')])) {
          parent.find('.placeholder').text(label);
          parent.find('input[type=hidden]').attr('value', value);

          self._updateValue(value);
        } else if (!self.get('beforeSelect')) {
          parent.find('.placeholder').text(label);
          parent.find('input[type=hidden]').attr('value', value);

          self._updateValue(value);
        }

        self._hideDropdown();

        evt.preventDefault();
      });

      this._setComboValue();

      this._super(arguments);
    },
    _setComboValue: function _setComboValue() {
      var comboValue = -1;

      if (this.get('model')) {
        comboValue = this.get('model').get(this.get('attribute'));
      } else if (this.get('selected') != null) {
        comboValue = this.get('selected');
      }

      var el = Ember.$(this.$()[0]);
      el.find('input[type=hidden]').attr('value', comboValue);
      el.find('.placeholder').empty().text(el.find('li[data-value=' + comboValue + ']').text().trim());
    },
    _updateValue: function _updateValue(value) {
      if (this.get('model')) {
        this.get('model').set(this.get('attribute'), value);

        if (this.get('model').save) {
          this.get('model').save();
        }
      } else {
        this.set('target', value);
      }
    },
    actions: {
      hideDropdown: function hideDropdown() {
        this._hideDropdown();
      }
    }
  });

  _exports.default = _default;
});