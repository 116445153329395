define("rd-basic-client/models/user", ["exports", "ember-data", "rd-basic-client/mixins/notification"], function (_exports, _emberData, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-function-prototype-extensions */
  var _default = _emberData.default.Model.extend(_notification.default, {
    fmmoDairyPrices: _emberData.default.attr("boolean"),

    /* DEPRECATED */
    isAdmin: _emberData.default.attr("boolean"),
    classOneDifferential: function () {
      if (this.get("manualClassOneDifferential") !== null) {
        return this.get("manualClassOneDifferential");
      } else {
        return this.get("location.classOneDifferential");
      }
    }.property("manualClassOneDifferential", "location.classOneDifferential"),
    manualClassOneDifferential: _emberData.default.attr("number"),
    location: Ember.computed.alias('location_id'),
    location_id: _emberData.default.belongsTo("location"),
    revenueNameA: _emberData.default.attr('string'),
    revenueNameB: _emberData.default.attr('string'),
    revenueNameC: _emberData.default.attr('string'),
    revenueNameD: _emberData.default.attr('string'),
    revenueNameE: _emberData.default.attr('string'),
    revenueNameF: _emberData.default.attr('string'),
    revenueNameG: _emberData.default.attr('string'),
    costName_0: _emberData.default.attr('string'),
    costName_1: _emberData.default.attr('string'),
    costName_2: _emberData.default.attr('string'),
    costName_3: _emberData.default.attr('string'),
    costName_4: _emberData.default.attr('string'),
    costName_5: _emberData.default.attr('string'),
    costName_6: _emberData.default.attr('string'),
    costName_7: _emberData.default.attr('string'),
    costName_8: _emberData.default.attr('string'),
    costName_9: _emberData.default.attr('string'),
    costName_10: _emberData.default.attr('string'),
    costName_11: _emberData.default.attr('string'),
    costName_12: _emberData.default.attr('string'),
    costName_13: _emberData.default.attr('string'),
    costName_14: _emberData.default.attr('string'),
    costName_15: _emberData.default.attr('string'),
    costName_16: _emberData.default.attr('string'),
    costName_17: _emberData.default.attr('string'),
    costName_18: _emberData.default.attr('string'),
    costName_19: _emberData.default.attr('string'),
    costName_20: _emberData.default.attr('string'),
    costName_21: _emberData.default.attr('string'),
    costName_22: _emberData.default.attr('string'),
    costName_23: _emberData.default.attr('string'),
    costName_24: _emberData.default.attr('string'),
    costName_25: _emberData.default.attr('string'),
    costName_26: _emberData.default.attr('string'),
    costName_27: _emberData.default.attr('string'),
    costName_28: _emberData.default.attr('string'),
    costName_29: _emberData.default.attr('string'),
    costName_30: _emberData.default.attr('string'),
    costName_31: _emberData.default.attr('string'),
    costName_32: _emberData.default.attr('string'),
    costName_33: _emberData.default.attr('string'),
    costName_34: _emberData.default.attr('string'),
    costName_35: _emberData.default.attr('string'),
    costName_36: _emberData.default.attr('string'),
    costName_37: _emberData.default.attr('string'),
    costName_38: _emberData.default.attr('string'),
    costName_39: _emberData.default.attr('string'),
    optionshop_id: _emberData.default.attr("number"),
    region_id: _emberData.default.attr("number"),
    email: _emberData.default.attr("string"),
    first_name: _emberData.default.attr("string"),
    last_name: _emberData.default.attr("string"),
    can_trade: _emberData.default.attr("boolean"),
    policy: _emberData.default.attr("string"),
    signature: _emberData.default.attr("string"),
    key: _emberData.default.attr("string"),
    pricingModel: _emberData.default.attr('string'),

    /* this parameter will override the "fmmoDairyPrices" attribute */
    permissions: _emberData.default.attr("string"),
    quota: _emberData.default.attr("number"),
    baseAndOverbase: _emberData.default.attr("number"),
    uiStyle: _emberData.default.attr("string"),
    isTrial: _emberData.default.attr("boolean"),
    basisType: _emberData.default.attr("number"),
    vaultBasis: _emberData.default.attr("number"),
    vaultBasisValue: _emberData.default.attr("number"),
    getFullName: function () {
      if (this.get('first_name') === '') {
        return this.get('email');
      }

      return this.get('first_name') + ' ' + this.get('last_name');
    }.property('first_name', 'last_name', 'email'),
    overbase: function () {
      return 1 - this.get('quota');
    }.property('quota')
  });

  _exports.default = _default;
});