define("rd-basic-client/components/vd-contenteditable-div", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    contextMenu: function contextMenu(evt) {
      if (this.get('contextMenuAction')) {
        return this.get('contextMenuAction')(evt, this.get('object'));
      }
    },
    keyUp: function keyUp(event) {
      var inputValue = this.$().text();
      var value = 0;

      if (event.keyCode === 13 || //ENTER KEY
      event.keyCode === 39 || //LEFT ARROW
      event.keyCode === 38 || //RIGHT ARROW
      event.keyCode === 37 || //UP ARROW
      event.keyCode === 40) {
        //DOWN ARROW
        //do nothing
        return;
      }

      if (!this.get('plaintext')) {
        if (inputValue === '' || inputValue === null) {
          inputValue = '0';
        }

        inputValue = inputValue.replace(/[^\d.-]/g, '');
        inputValue = this.tryToRound(inputValue);
        value = new BigNumber(inputValue);

        if (this.isPercentValueFormatting()) {
          value = value.dividedBy(100);
        }

        value = value.dividedBy(this.get('displayFactor')).toNumber();
      } else {
        value = inputValue === '' ? ' ' : inputValue;
      }

      return this.set('setValue', value);
    },
    tryToRound: function tryToRound(value) {
      return (0, _numeral.default)(value).format('0.[00000000]');
    },
    isPercentValueFormatting: function isPercentValueFormatting() {
      return this.get('format') && this.get('format').indexOf('%') >= 0;
    },
    //
    //  Optionshop
    //
    tagName: "div",
    attributeBindings: ['contenteditable', 'draggable', "style"],
    // Variables:
    editable: false,
    isUserTyping: false,
    plaintext: false,
    contenteditable: "true",
    draggable: "true",
    style: "width: 100%; height: 100%; display: table-cell; vertical-align:middle;",
    object: null,
    format: null,
    displayFactor: 1,
    // Observers:
    valueObserver: function () {
      if (!this.get('isUserTyping') && this.get('value') !== undefined) {
        return this.setFormattedContent();
      }
    }.observes('value'),
    // Events:
    didInsertElement: function didInsertElement() {
      return this.setFormattedContent();
    },
    focusIn: function focusIn() {
      var v = this.get("value");

      if (this.get("plaintext") || Ember.isNone(v)) {
        this.$().text(v);
      } else {
        // If too many significant digits, round
        if (v.toString().replace(".", "").length > 15) {
          v = v.toPrecision(15);
        }

        var value = BigNumber(v).times(this.get("displayFactor"));

        if (this.get("format") && this.get("format").indexOf("%") > -1) {
          value = value.times(100);
        }

        return this.$().text(value.toNumber());
      }
    },
    focusOut: function focusOut() {
      var object = this.get("object");

      if (object) {
        object.save();
      }

      this.setFormattedContent();
      return this.set('isUserTyping', false);
    },
    keyDown: function keyDown(event) {
      if (!event.metaKey) {
        return this.set('isUserTyping', true);
      }
    },
    //render our own html so there are no metamorphs to get screwed up when the user changes the html
    // render: function(buffer) {
    // 	var v = this.get("value");
    // 	if(!v) return;
    // 	if(this.get("plaintext") || isNone(v)){
    // 		buffer.push(v);
    // 	} else {
    // 		// If too many significant digits, round
    // 		if(v.toString().replace(".", "").length > 15){ v = v.toPrecision(15); }
    // 		buffer.push(BigNumber(v).times(this.get("displayFactor")).toNumber());
    // 	}
    // },
    setFormattedContent: function setFormattedContent() {
      var v = this.get("value");

      if (this.get("plaintext") || Ember.isNone(v)) {
        return this.$().text(v);
      } else {
        // If too many significant digits, round
        if (v.toString().replace(".", "").length > 15) {
          v = v.toPrecision(15);
        }

        var value = BigNumber(v).times(this.get("displayFactor"));

        if (this.get("format")) {
          return this.$().text((0, _numeral.default)(value.toNumber()).format(this.get("format")));
        } else {
          return this.$().text(value.toNumber());
        }
      }
    },
    // Getting Fancy now.
    dragStart: function dragStart(e) {
      localStorage.setItem("poorSpec", this.get("value"));
      e.dataTransfer.effectAllowed = "none";
      e.dataTransfer.setData('text/plain', this.get("value"));
    },
    dragEnter: function dragEnter() {
      if (this.get("plainText")) {
        this.set("setValue", localStorage.getItem("poorSpec"));
      } else {
        this.set("setValue", parseFloat(localStorage.getItem("poorSpec")));
      }

      var object = this.get("object");

      if (object) {
        object.save();
      }
    },
    dragDrop: function dragDrop() {
      if (this.get("plainText")) {
        this.set("setValue", localStorage.getItem("poorSpec"));
      } else {
        this.set("setValue", parseFloat(localStorage.getItem("poorSpec")));
      }

      var object = this.get("object");

      if (object) {
        object.save();
      }
    }
  });

  _exports.default = _default;
});