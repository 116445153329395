define("rd-basic-client/models/feed", ["exports", "ember-data", "rd-basic-client/mixins/notification", "moment", "rd-basic-client/config/environment"], function (_exports, _emberData, _notification, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-undef */

  /* eslint-disable ember/no-function-prototype-extensions */
  var _default = _emberData.default.Model.extend(_notification.default, {
    feedCategory: _emberData.default.attr('string'),
    feedType: _emberData.default.belongsTo('feed-type'),
    usageInTons: _emberData.default.attr('number'),
    dailyAmount: Ember.computed.alias('usageInTons'),
    dailyDMICerCow: Ember.computed.alias('usageInTons'),
    costType: _emberData.default.attr('string'),
    feedRollover: _emberData.default.attr('string'),
    priceInTons: _emberData.default.attr('number'),
    basisInstrument: Ember.computed.alias('basis_instrument_id'),
    basis_instrument_id: _emberData.default.belongsTo('vault-instrument', {
      async: true
    }),
    ingredient: Ember.computed.alias('ingredient_id'),
    ingredient_id: _emberData.default.belongsTo('ingredient', {
      async: true
    }),
    cowCategory: Ember.computed.alias('cow_category_id'),
    cow_category_id: _emberData.default.belongsTo('cow-category', {
      async: true
    }),
    monthlyUsage: function () {
      var cows = 1;

      if (this.get('cowCategory.perCowDMI')) {// cows = this.get('cowCategory.cows');
      }

      return this.get('usageInTons') * cows * _moment.default.utc(this.get('cowCategory.month.startDate')).daysInMonth();
    }.property('usageInTons', 'cowCategory.month.startDate'),
    totalMonthProduction: function () {
      if (this.get('dailyRatio')) {
        return this.get('usageInTons') * this.get('totalProduction') / 100;
      } else {
        return this.get('usageInTons') * this.get('cowCategory.totalDMICows') * _moment.default.utc(this.get('cowCategory.month.startDate')).daysInMonth();
      }
    }.property('dailyRatio', 'usageInTons', 'totalProduction', 'cowCategory', 'cowCategory.month.startDate', 'cowCategory.totalDMICows'),
    costPerTon: function () {
      if (this.get('isSpotPrice')) {
        return this.get('ingredient.spotPrice');
      }

      if (this.get('priceInTons') === undefined) {
        return 0;
      }

      if (this.get("isFixedCost")) {
        return this.get("priceInTons");
      }

      var priceFormat = _environment.default.APP.PRICE_FORMAT[this.get('basisInstrument.rootSymbol')];

      var modifiedPrice = priceFormat ? this.get('basisInstrument.lastPrice') * priceFormat.displayFactor : this.get('basisInstrument.lastPrice');

      if (this.get('isPercentageBasis')) {
        if (this.get('basisInstrument.rootSymbol') === 'ZC') {
          return modifiedPrice / 56 * 2000 * this.get('priceInTons');
        } else {
          return modifiedPrice * this.get('priceInTons');
        }
      }

      if (this.get('isDollarBasis')) {
        if (this.get('basisInstrument.rootSymbol') === 'ZC') {
          return (modifiedPrice + this.get('priceInTons')) / 56 * 2000;
        } else {
          return modifiedPrice + this.get('priceInTons');
        }
      }
    }.property('priceInTons', 'costType', 'basisInstrument.lastPrice', 'ingredient.spotPrice', 'basisInstrument.rootSymbol', 'basisInstrument', 'basisInstrument.barchartSymbol', 'ingredient', 'ingredient.spotPrice'),
    scenarioCostPerTon: function () {
      if (this.get('isSpotPrice')) {
        return this.get('ingredient.spotPrice');
      }

      if (this.get('priceInTons') === undefined) {
        return 0;
      }

      if (this.get('isFixedCost')) {
        return this.get('priceInTons');
      }

      var priceFormat = _environment.default.APP.PRICE_FORMAT[this.get('basisInstrument.rootSymbol')];

      var modifiedPrice = priceFormat ? this.get('basisInstrument.scenarioPrice') * priceFormat.displayFactor : this.get('basisInstrument.scenarioPrice');

      if (this.get('isPercentageBasis')) {
        if (modifiedPrice === undefined) {
          return 0;
        }

        if (this.get('basisInstrument.rootSymbol') === 'ZC') {
          return modifiedPrice / 56 * 2000 * this.get('priceInTons');
        } else {
          return modifiedPrice * this.get('priceInTons');
        }
      }

      if (this.get('isDollarBasis')) {
        if (modifiedPrice === undefined) {
          return 0;
        }

        if (this.get('basisInstrument.rootSymbol') === 'ZC') {
          return (modifiedPrice + this.get('priceInTons')) / 56 * 2000;
        } else {
          return modifiedPrice + this.get('priceInTons');
        }
      }
    }.property('priceInTons', 'costType', 'basisInstrument.scenarioPrice', 'basisInstrument.rootSymbol', 'basisInstrument.barchartSymbol', 'ingredient', 'ingredient.spotPrice'),
    isFixedCost: function () {
      return this.get('costType') === 'Fixed';
    }.property('costType'),
    isSpotPrice: function () {
      return this.get('costType') === 'Spot Price';
    }.property('costType'),
    isPercentageBasis: function () {
      return this.get('costType') === 'Basis CME (%)';
    }.property('costType'),
    isDollarBasis: function () {
      return this.get('costType') === 'Basis CME ($)';
    }.property('costType')
  });

  _exports.default = _default;
});