define("rd-basic-client/mixins/soybean-meal", ["exports", "rd-basic-client/mixins/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_common.default, {
    soybeanMealPoundsHedged: function () {
      var physicalPositions = this.get('physicalPositions').filter(function (p) {
        return p.get('productGroup.symbol') === 'ZM' && (p.get('instrumentType') === 'Forward' && p.get('isLong') || p.get('instrumentType') === 'Call' && p.get('isLong') || p.get('instrumentType') === 'Put' && !p.get('isLong'));
      });
      var exchangePositions = this.get('positions').filter(function (p) {
        return p.get('instrument.symbol') && p.get('instrument.symbol').indexOf('ZM') > -1 && (p.get('instrument.instrument_type').toUpperCase() === 'FUTURE' && p.get('isLong') || p.get('instrument.option_type').toUpperCase() === 'CALL' && p.get('isLong') || p.get('instrument.option_type').toUpperCase() === 'PUT' && !p.get('isLong'));
      });
      return this.getPoundsHedged('ZM', physicalPositions, exchangePositions);
    }.property('physicalPositions.@each.quantity'),
    soybeanMealPercentageHedged: function () {
      return this.getPercentageHedgedValue(this.get('soybeanMealInstrument'), 2000, this.get('soybeanMealPoundsHedged'));
    }.property('soybeanMealPoundsHedged', 'allFeed.@each.monthlyUsage', 'allFeed.@each.basisInstrument'),
    soybeanMealPercentageUnhedged: function () {
      return 1 - (this.get('soybeanMealPercentageHedged') > 1 ? 1 : this.get('soybeanMealPercentageHedged'));
    }.property('soybeanMealPercentageHedged'),
    soybeanMealOptionPremium: function () {
      return this.getPositionsValue('ZM', 'Option', 'premiumInDollars');
    }.property('positions.@each.premiumInDollars'),
    soybeanMealFuturePL: function () {
      return this.getPositionsValue('ZM', 'Future', 'plInDollars');
    }.property('positions.@each.plInDollars'),
    scenarioSoybeanMealFuturePL: function () {
      return this.getPositionsValue('ZM', 'Future', 'scenarioPlInDollars');
    }.property('positions.@each.scenarioPlInDollars'),
    soybeanMealOptionPL: function () {
      return this.getPositionsValue('ZM', 'Option', 'plInDollars');
    }.property('positions.@each.plInDollars'),
    scenarioSoybeanMealOptionPL: function () {
      return this.getPositionsValue('ZM', 'Option', 'scenarioPlInDollars');
    }.property('positions.@each.scenarioPlInDollars'),
    offExchangeSoybeanMealPL: function () {
      return this.getOffExchangePL('ZM', this.get('soybeanMealBestPriceFormatted'));
    }.property('physicalPositions.@each', 'soybeanMealBestPriceFormatted', 'soybeanMealInstrument.product.tick_size'),
    scenarioOffExchangeSoybeanMealPL: function () {
      return this.getScenarioOffExchangePL('ZM', this.get('scenarioSoybeanMealBestPriceFormatted'));
    }.property('physicalPositions.@each', 'scenarioSoybeanMealBestPriceFormatted', 'soybeanMealInstrument.product.tick_size', 'isCurrentMonth', 'hypotheticalPositions.@each'),
    soybeanMealNetPL: function () {
      return this.get('offExchangeSoybeanMealPL') + this.get('soybeanMealFuturePL') + this.get('soybeanMealOptionPL');
    }.property('offExchangeSoybeanMealPL', 'soybeanMealFuturePL', 'soybeanMealOptionPL'),
    soybeanMealHedgedPrice: function () {
      var v = 0;
      var q = 0;
      this.get('physicalPositions').forEach(function (p) {
        if (p.get('productGroup.symbol') === 'ZM') {
          if (p.get('instrumentType') === 'Forward' && p.get('isLong')) {
            q += p.get('quantity');
            v += p.get('quantity') * p.get('price');
          }

          if (p.get('instrumentType') === 'Call' && p.get('isLong')) {
            q += p.get('quantity');
            v += p.get('quantity') * p.get('strike');
          }

          if (p.get('instrumentType') === 'Put' || p.get('instrumentType') === 'Call') {
            if (p.get('isLong')) {
              v += p.get('price');
            } else {
              v -= p.get('price');
            }
          }
        }
      });
      this.get('positions').forEach(function (p) {
        if (p.get('instrument.symbol') && p.get('instrument.symbol').indexOf('ZM') > -1) {
          if (p.get('instrument.instrument_type') === 'Future' && p.get('isLong')) {
            q += p.get('quantityInUnits');
            p.get('tradePrices').forEach(function (tp) {
              v += tp.price * tp.quantity / p.get('instrument.product.tick_size') * p.get('instrument.product.tick_value');
            });
          }

          if (p.get('instrument.option_type') === 'Call' && p.get('isLong')) {
            q += p.get('quantityInUnits');
            v += p.get('quantity') * p.get('instrument.strike') / p.get('instrument.product.tick_size') * p.get('instrument.product.tick_value');
          }

          if (p.get('instrument.option_type') === 'PUT' || p.get('instrument.option_type') === 'CALL') {
            p.get('tradePrices').forEach(function (tp) {
              v += tp.price * tp.quantity / p.get('instrument.product.tick_size') * p.get('instrument.product.tick_value');
            });
          }
        }
      });

      if (q > 0) {
        return v / q;
      } else {
        return 0;
      }
    }.property('physicalPositions.@each', 'positions.@each')
  });

  _exports.default = _default;
});