define("rd-basic-client/components/number-cwt", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    getValue: function getValue(conf) {
      conf = Ember.$.extend({
        format: '($0,0)',
        perCWT: false,
        cwtFormat: '($0,0.00)',
        perCows: false,
        cows: 0
      }, conf);
      var v;
      var result = 0;

      if (conf.perCWT) {
        if (conf.value !== null && conf.value !== undefined && !isNaN(conf.value) && conf.cwt !== null && conf.cwt !== undefined && !isNaN(conf.cwt) && conf.format) {
          v = conf.cwt > 0 ? conf.value / conf.cwt : 0;
          result = (0, _numeral.default)(v).format(conf.cwtFormat);
        }
      } else {
        if (conf.perCows && conf.value !== null && conf.value !== undefined && !isNaN(conf.value) && conf.cows !== null && conf.cows !== undefined && !isNaN(conf.cows) && conf.cwtFormat) {
          v = conf.cows > 0 ? conf.value / conf.cows : 0;
          result = (0, _numeral.default)(v).format(conf.cwtFormat);
        } else {
          if (conf.value !== null && conf.value !== undefined && !isNaN(conf.value) && conf.format) {
            result = (0, _numeral.default)(conf.value).format(conf.format);
          }
        }
      }

      return result;
    }
  });

  _exports.default = _default;
});