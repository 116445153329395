define("rd-basic-client/components/rd-display-decimal-price", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layoutName: "src/templates/components/rd-display-decimal-price",
    decimalPrice: function () {
      var fmt = this.get("format") === undefined ? "0,0.00" : this.get("format");
      var multiple = this.get("multiple") === undefined ? 1 : this.get("multiple");

      if (Ember.isNone(this.get("price")) || Ember.isNone(this.get("tickSize")) || Ember.isNone(this.get("tickValue"))) {
        return null;
      } else {
        // eslint-disable-next-line no-undef
        var dec = new BigNumber(this.get("price")).dividedBy(this.get("tickSize")).times(this.get("tickValue")).times(multiple).toNumber();
        return (0, _numeral.default)(dec).format(fmt);
      }
    }.property("price", "tickSize", "tickValue", "format", "times")
  });

  _exports.default = _default;
});