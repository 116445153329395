define("rd-basic-client/models/position", ["exports", "ember-data", "rd-basic-client/config/environment", "moment", "rd-basic-client/utils/vault-display-price", "rd-basic-client/utils/vela-to-barchart-price", "rd-basic-client/utils/points-to-dollars"], function (_exports, _emberData, _environment, _moment, _vaultDisplayPrice, _velaToBarchartPrice, _pointsToDollars) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-unused-vars */

  /* eslint-disable ember/no-function-prototype-extensions */
  //
  //  Once we move to our own positions, we're going to need to rework this pretty significantly.
  //
  var _default = _emberData.default.Model.extend({
    clearing_symbol: Ember.computed.alias('instrument_id.product_id.clearing_symbol'),
    parent_symbol: Ember.computed.alias('instrument_id.rootSymbol'),
    plInPoints: Ember.computed('instrument_id', 'instrument_id.lastPrice', 'tradePrices.@each.quantity', function () {
      var _this = this;

      var pl = 0;
      var currentPrice = this.get('instrument_id.lastPrice');

      if (currentPrice) {
        this.tradePrices.forEach(function (tp) {
          if (tp.quantity > 0) {
            pl += (currentPrice - (0, _velaToBarchartPrice.default)(_this.rootSymbol, tp.price)) * tp.quantity;
          } else if (tp.quantity < 0) {
            pl += ((0, _velaToBarchartPrice.default)(_this.rootSymbol, tp.price) - currentPrice) * Math.abs(tp.quantity);
          }
        });
      }

      return pl;
    }),
    getCurrentMonth: function getCurrentMonth() {
      var currentMonth = (0, _moment.default)().utc();

      if (currentMonth.date() < _environment.default.APP.CONSTANTS.MONTH_DELAY_OFFSET) {
        currentMonth.subtract(1, 'month');
      }

      return currentMonth;
    },
    scenarioPlInPoints: function () {
      var pl = 0;
      var diff = 0;
      var option_type = this.get("instrument.option_type");
      var scenario_price;

      if (this.get("instrument.instrument_type").toUpperCase() === 'OPTION') {
        scenario_price = this.get("instrument.underlyingInstrument.scenarioPrice") ? this.get("instrument.underlyingInstrument.scenarioPrice") : this.get("instrument.underlyingInstrument.lastPrice");

        if (scenario_price === undefined) {
          return 0;
        }

        diff = scenario_price - this.get("instrument.strike"); // In the money calls are where the price at expiration (secenario_price) is greater than the strike price.

        if (diff > 0 && option_type === 'CALL') {
          pl += Math.abs(diff) * this.quantity;
        } // In the money puts are where the price at expiration (secenario_price) is less than the strike price.


        if (diff < 0 && option_type === 'PUT') {
          pl += Math.abs(diff) * this.quantity;
        }
      } else {
        scenario_price = this.get("instrument.scenarioPrice") ? this.get("instrument.scenarioPrice") : this.get("instrument.lastPrice");
        this.tradePrices.forEach(function (tp) {
          if (scenario_price === undefined) {
            return 0;
          }

          if (tp.quantity > 0) {
            pl += (scenario_price - (0, _velaToBarchartPrice.default)(this.rootSymbol, tp.price)) * tp.quantity;
          }

          if (tp.quantity < 0) {
            pl += ((0, _velaToBarchartPrice.default)(this.rootSymbol, tp.price) - scenario_price) * Math.abs(tp.quantity);
          }
        }, this);
      }

      return pl;
    }.property("instrument.lastPrice", "instrument.scenarioPrice", "tradePrices.@each", "instrument.instrument_type", "instrument.underlyingInstrument.scenarioPrice", "rootSymbol"),
    premiumInDollars: Ember.computed('premiumInPoints', 'rootSymbol', function () {
      return (0, _pointsToDollars.default)(this.rootSymbol, this.premiumInPoints);
    }),
    plInDollars: Ember.computed('plInPoints', 'rootSymbol', function () {
      return (0, _pointsToDollars.default)(this.rootSymbol, this.plInPoints);
    }),
    scenarioPlInDollars: Ember.computed('scenarioPlInPoints', 'rootSymbol', function () {
      return (0, _pointsToDollars.default)(this.rootSymbol, this.scenarioPlInPoints);
    }),
    quantityInUnits: function () {
      var units = "1";

      switch (this.get("instrument_id.rootSymbol")) {
        case "DC":
          units = "200000";
          break;

        case "GDK":
          units = "200000";
          break;

        case "OZC":
          units = "5000";
          break;

        case "ZC":
          units = "5000";
          break;

        case "CSC":
          units = "20000";
          break;

        case "KE":
          units = "5000";
          break;

        case "OKE":
          units = "5000";
          break;

        case "LE":
          units = "40000";
          break;

        case "ZL":
          units = "60000";
          break;

        case "OZL":
          units = "60000";
          break;

        case "ZM":
          units = "100";
          break;

        case "OZM":
          units = "100";
          break;

        case "CB":
          units = "20000";
          break;

        case "GNF":
          units = "44000";
          break;

        case "ZS":
          units = "5000";
          break;

        case "OZS":
          units = "5000";
          break;

        case "ZW":
          units = "5000";
          break;

        case "DY":
          units = "44000";
          break;
      }

      return units * Math.abs(this.quantity);
    }.property("quantity", "instrument_id.rootSymbol"),
    acct_id: _emberData.default.attr("string"),
    quantity: _emberData.default.attr("number"),
    instrument_id: _emberData.default.belongsTo("vault-instrument", {
      async: true
    }),
    instrument: Ember.computed.alias("instrument_id"),
    isLong: function () {
      return this.quantity >= 0 ? true : false;
    }.property("quantity"),
    tradePrices: Ember.computed.alias("trade_prices"),
    trade_prices: _emberData.default.attr("raw"),
    instrumentBestPrice: Ember.computed.alias('instrument.lastPrice'),
    premiumInPoints: function () {
      var _this2 = this;

      var premium = 0;

      if (this.get("instrument.instrument_type").toUpperCase() !== "FUTURE") {
        this.tradePrices.forEach(function (tp) {
          if (tp.quantity > 0) {
            premium += (0, _velaToBarchartPrice.default)(_this2.rootSymbol, tp.price) * tp.quantity;
          }

          if (tp.quantity < 0) {
            premium -= (0, _velaToBarchartPrice.default)(_this2.rootSymbol, tp.price) * Math.abs(tp.quantity);
          }
        });
      }

      return premium;
    }.property("tradePrices.@each", "instrument.instrument_type"),
    averagePurchasePrice: function () {
      var v = 0;
      var quantity = 0;
      this.tradePrices.forEach(function (tp) {
        v += tp.price * Math.abs(tp.quantity);
        quantity += Math.abs(tp.quantity);
      });

      if (v > 0 && quantity > 0) {
        return v / quantity;
      }

      return 0;
    }.property("tradePrices.@each"),
    rootSymbol: Ember.computed.alias('instrument.rootSymbol'),
    displayAveragePurchasePrice: Ember.computed('averagePurchasePrice', 'rootSymbol', function () {
      var barchartPurchasePrice = (0, _velaToBarchartPrice.default)(this.rootSymbol, this.averagePurchasePrice);
      return (0, _vaultDisplayPrice.default)(barchartPurchasePrice, this.rootSymbol);
    })
  });

  _exports.default = _default;
});