define("rd-basic-client/mixins/month-utils", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getLastMonthDate: function getLastMonthDate() {
      var afterDate = _moment.default.utc().date(1);

      if ((0, _moment.default)().utc().date() < 8) {
        afterDate.subtract(1, 'months');
      }

      return _moment.default.utc(afterDate).add(23, "months");
    },
    isLessThan24MonthFromNow: function isLessThan24MonthFromNow(month) {
      return _moment.default.utc(month.get('startDate')).isBefore(this.getLastMonthDate());
    }
  });

  _exports.default = _default;
});