define("rd-basic-client/controllers/monthpositions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super();

      this.set("newSymbol", null);
      this.set("newQuantity", null);
      this.set("newPrice", null);
      this.set("sessionDate", null);
      this.set("chartPrices", []);
      this.set("updatePositionType", "Forward");
      this.set("updateSide", "Bought");
      this.set("updateQuantity", null);
      this.set("updatePrice", null);
      this.set("updateStrike", null);
      this.set("updatePosition", null);
      this.set('otcPositionTypes', ["Forward", "Call", "Put"]);
      this.set('otcSideTypes', ["Bought", "Sold"]);
    },
    quantityTitle: function () {
      var parentSymbol = this.get('model.parentSymbol');

      if (parentSymbol === "ZC") {
        return "Bushels";
      } else if (parentSymbol === "ZM") {
        return "Tons";
      } else {
        return "Pounds";
      }
    }.property("model.parentSymbol"),
    marketDataInstruments: function () {
      return this.get("positions").mapBy("instrument_id");
    }.property("positions.instrument_id"),
    isCheese: function () {
      return this.get("model.parentSymbol") === "CSC" ? true : false;
    }.property("model.parentSymbol"),
    otcPositionTypes: null,
    otcSideTypes: null,
    updateIsOption: function () {
      return this.get("updatePositionType") === "Forward" ? false : true;
    }.property("updatePositionType"),
    actions: {
      openTradeModal: function openTradeModal() {
        this.set("updatePositionType", "Forward");
        this.set("updateSide", "Bought");
        this.set("updateQuantity", null);
        this.set("updatePrice", null);
        this.set("updateStrike", null);
        Ember.$('#trade-pos-modal').modal('show');
      },
      createNewTrade: function createNewTrade() {
        var self = this;
        var quantity = parseInt(self.get("updateQuantity").replace(/,/g, ""));
        var physical_position = this.store.createRecord("physicalPosition", {
          instrument_type: self.get("updatePositionType"),
          is_long: self.get("updateSide") === "Bought" ? true : false,
          quantity: quantity,
          price: self.get("updatePrice"),
          month: self.get("model.month"),
          product_group_id: self.get("model.productGroup.id"),
          strike: self.get("updatePositionType") === "Forward" ? null : self.get("updateStrike"),
          option_type: self.get("updatePositionType") === "Forward" ? null : self.get("updatePositionType")
        });
        physical_position.save();
        this.send("refreshModel");
        Ember.$('#trade-pos-modal').modal('hide');
      },
      editPosition: function editPosition(p) {
        this.set("updatePositionType", p.get("instrumentType"));
        this.set("updateSide", p.get("isLong") ? "Bought" : "Sold");
        this.set("updateQuantity", p.get("quantity"));
        this.set("updatePrice", p.get("price"));
        this.set("updateStrike", p.get("strike"));
        this.set("updatePosition", p);
        Ember.$('#edit-modal').modal('show');
      },
      updatePosition: function updatePosition() {
        var self = this;
        var physical_position = this.get("updatePosition");
        var quantity = self.get("updateQuantity");

        if (typeof quantity === 'string' || quantity instanceof String) {
          quantity = parseInt(quantity.replace(/,/g, ""));
        }

        physical_position.set("instrument_type", self.get("updatePositionType"));
        physical_position.set("is_long", self.get("updateSide") === "Bought" ? true : false);
        physical_position.set("quantity", quantity);
        physical_position.set("price", self.get("updatePrice"));
        physical_position.set("month", self.get("model.month"));
        physical_position.set("strike", self.get("updatePositionType") === "Forward" ? null : self.get("updateStrike"));
        physical_position.set("option_type", self.get("updatePositionType") === "Forward" ? null : self.get("updatePositionType"));
        physical_position.save();
        Ember.$('#edit-modal').modal('hide');
      },
      deletePosition: function deletePosition(pos) {
        var _this = this;

        pos.deleteRecord();
        pos.save().then(function () {
          _this.send("refreshModel");
        });
      }
    }
  });

  _exports.default = _default;
});