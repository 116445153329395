define("rd-basic-client/mixins/body-event-listener", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    bodyElementSelector: 'html',
    bodyClick: function bodyClick() {
      return this;
    },
    didInsertElement: function didInsertElement() {
      this._super(arguments); // It is important to setup document handlers in the next run loop.
      // Otherwise we run in to situation whenre the click that causes a popover
      // to appears will be handled right away when we attach a click handler.
      // This very same click will trigger the bodyClick to fire and thus
      // causing us to hide the popover right away


      return Ember.run.next(this, this._setupDocumentHandlers);
    },
    willDestroyElement: function willDestroyElement() {
      this._super(arguments);

      return this._removeDocumentHandlers();
    },
    _setupDocumentHandlers: function _setupDocumentHandlers() {
      var self = this;

      if (this._clickHandler || this.isDestroying) {
        return;
      }

      this._clickHandler = function (event) {
        return Ember.run(function () {
          if ((self.get('_state') || self.get('state')) === 'inDOM' && Ember.isEmpty(self.$().has(Ember.$(event.target)))) {
            // check if event.target still exists in DOM
            var checkContain = Ember.$.contains(document.body, event.target);
            var isBodyElement = event.target === document.body;

            if (checkContain || isBodyElement) {
              // bodyClick starts taking parameter "event" to make room to control
              // some special cases where there is a component added to the body
              // instead of the app (such as bootstrap date-picker).
              // If it is the case, we can check for the event target to prevent
              // the popover from being closed.
              return self.bodyClick(event);
            }
          }
        });
      };

      return Ember.$(this.get('bodyElementSelector')).on("click", this._clickHandler);
    },
    _removeDocumentHandlers: function _removeDocumentHandlers() {
      Ember.$(this.get('bodyElementSelector')).off("click", this._clickHandler);
      return this._clickHandler = null;
    }
  });

  _exports.default = _default;
});