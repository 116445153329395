define("rd-basic-client/models/month", ["exports", "ember-data", "rd-basic-client/config/environment", "rd-basic-client/mixins/class-iv", "rd-basic-client/mixins/class-iii", "rd-basic-client/mixins/dry-whey", "rd-basic-client/mixins/nonfat-dry-milk", "rd-basic-client/mixins/corn", "rd-basic-client/mixins/soybean-meal", "rd-basic-client/mixins/glanbia-cheese", "rd-basic-client/mixins/notification", "rd-basic-client/mixins/month-validation", "rd-basic-client/mixins/non-feed-expenses", "rd-basic-client/mixins/non-milk-revenue", "rd-basic-client/mixins/california", "rd-basic-client/mixins/uda", "rd-basic-client/mixins/reconciliation", "moment", "rd-basic-client/utils/vela-to-barchart-price", "rd-basic-client/utils/whey-value-per-cwt"], function (_exports, _emberData, _environment, _classIv, _classIii, _dryWhey, _nonfatDryMilk, _corn, _soybeanMeal, _glanbiaCheese, _notification, _monthValidation, _nonFeedExpenses, _nonMilkRevenue, _california, _uda, _reconciliation, _moment, _velaToBarchartPrice, _wheyValuePerCwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-function-prototype-extensions */
  var _default = _emberData.default.Model.extend(_monthValidation.default, _california.default, _uda.default, _reconciliation.default, _notification.default, _nonFeedExpenses.default, _nonMilkRevenue.default, _classIv.default, _classIii.default, _dryWhey.default, _nonfatDryMilk.default, _corn.default, _soybeanMeal.default, _glanbiaCheese.default, {
    //
    // Attributes
    //
    startDate: _emberData.default.attr('date'),
    cows: _emberData.default.attr('number'),
    dailyProduction: _emberData.default.attr('number'),
    butterfat: _emberData.default.attr('number'),
    protein: _emberData.default.attr('number'),
    otherSolids: _emberData.default.attr('number'),
    marginObjective: _emberData.default.attr('number'),
    milkPriceAdjustments: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    basicFeedCost: _emberData.default.attr('number'),
    basicFeedInCwt: _emberData.default.attr('boolean'),
    basicFeedUsage: _emberData.default.attr('boolean'),
    combinedFeedUsage: _emberData.default.attr('boolean'),
    manualClassOneUtilization: _emberData.default.attr('number'),
    manualClassTwoUtilization: _emberData.default.attr('number'),
    manualClassThreeUtilization: _emberData.default.attr('number'),
    manualClassFourUtilization: _emberData.default.attr('number'),
    manualClassFouraUtilization: _emberData.default.attr('number'),
    manualClassFourbUtilization: _emberData.default.attr('number'),
    fatClassOneUtilization: _emberData.default.attr('number'),
    fatClassTwoUtilization: _emberData.default.attr('number'),
    fatClassThreeUtilization: _emberData.default.attr('number'),
    fatClassFouraUtilization: _emberData.default.attr('number'),
    fatClassFourbUtilization: _emberData.default.attr('number'),
    snfClassOneUtilization: _emberData.default.attr('number'),
    snfClassTwoUtilization: _emberData.default.attr('number'),
    snfClassThreeUtilization: _emberData.default.attr('number'),
    snfClassFouraUtilization: _emberData.default.attr('number'),
    snfClassFourbUtilization: _emberData.default.attr('number'),
    fatClassOneUtilizationAvg: _emberData.default.attr('number'),
    fatClassTwoUtilizationAvg: _emberData.default.attr('number'),
    fatClassThreeUtilizationAvg: _emberData.default.attr('number'),
    fatClassFouraUtilizationAvg: _emberData.default.attr('number'),
    fatClassFourbUtilizationAvg: _emberData.default.attr('number'),
    snfClassOneUtilizationAvg: _emberData.default.attr('number'),
    snfClassTwoUtilizationAvg: _emberData.default.attr('number'),
    snfClassThreeUtilizationAvg: _emberData.default.attr('number'),
    snfClassFouraUtilizationAvg: _emberData.default.attr('number'),
    snfClassFourbUtilizationAvg: _emberData.default.attr('number'),
    fluidUsage: _emberData.default.attr('number'),
    historicalPrices: _emberData.default.belongsTo('historical-price'),
    historical_cash_settled_butter_price: _emberData.default.attr('number'),
    historical_cash_settled_cheese_price: _emberData.default.attr('number'),
    historical_class_four_price: _emberData.default.attr('number'),
    historical_class_three_price: _emberData.default.attr('number'),
    historical_corn_price: _emberData.default.attr('number'),
    historical_dry_whey_price: _emberData.default.attr('number'),
    historical_nonfat_dry_milk_price: _emberData.default.attr('number'),
    historical_soybean_meal_price: _emberData.default.attr('number'),
    historical_fmmo_class_one: _emberData.default.attr('number'),
    historical_fmmo_class_two: _emberData.default.attr('number'),
    // North
    historical_ca_n_class_one: _emberData.default.attr('number'),
    historical_ca_n_class_two: _emberData.default.attr('number'),
    // South
    historical_ca_s_class_one: _emberData.default.attr('number'),
    historical_ca_s_class_two: _emberData.default.attr('number'),
    historical_ca_class_three: _emberData.default.attr('number'),
    historicalCaClassFoura: _emberData.default.attr('number'),
    historicalCaClassFourb: _emberData.default.attr('number'),
    historicalCaOverbase: _emberData.default.attr('number'),
    historicalUdaClassOne: _emberData.default.attr('number'),
    historicalUdaClassTwo: _emberData.default.attr('number'),
    historicalGlanbiaCheese: _emberData.default.attr('number'),
    historicalGlanbiaButter: _emberData.default.attr('number'),
    historicalGlanbiaWpc: _emberData.default.attr('number'),
    historicalGlanbiaLactose: _emberData.default.attr('number'),
    historicalGlanbiaEpp: _emberData.default.attr('number'),
    //
    // Relationships
    //
    feedNeeds: _emberData.default.attr('number'),
    cowCategories: Ember.computed.alias('cow_category_ids'),
    cow_category_ids: _emberData.default.hasMany('cow-category'),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    cowCategoriesOrdering: ['order'],
    orderedCowCategories: Ember.computed.sort('cow_category_ids', 'cowCategoriesOrdering'),
    user: Ember.computed.alias('user_id'),
    user_id: _emberData.default.belongsTo('user'),
    next_month_id: _emberData.default.belongsTo('month', {
      inverse: 'previous_month_id'
    }),
    previous_month_id: _emberData.default.belongsTo('month', {
      inverse: 'next_month_id',
      async: true
    }),
    classFourMilkInstrument: _emberData.default.belongsTo('vault-instrument'),
    dryWheyInstrument: _emberData.default.belongsTo('vault-instrument'),
    cashSettledCheeseInstrument: _emberData.default.belongsTo('vault-instrument'),
    nonfatDryMilkInstrument: _emberData.default.belongsTo('vault-instrument'),
    cashSettledButterInstrument: _emberData.default.belongsTo('vault-instrument'),
    cornInstrument: _emberData.default.belongsTo('vault-instrument'),
    soybeanMealInstrument: _emberData.default.belongsTo('vault-instrument'),
    soybeanInstrument: _emberData.default.belongsTo('vault-instrument'),
    wheatInstrument: _emberData.default.belongsTo('vault-instrument'),
    physicalPositions: _emberData.default.hasMany('physical-position'),
    hypotheticalPositions: _emberData.default.hasMany('hypothetical-position'),
    isFirstMonthOfTheYear: function () {
      return _moment.default.utc(this.startDate).get('month') === 0;
    }.property('startDate'),
    year: function () {
      return _moment.default.utc(this.startDate).get('year');
    }.property('startDate'),
    totalCows: function () {
      var total = 0;
      this.cowCategories.forEach(function (m) {
        total += m.get('cows');
      });
      return total;
    }.property('cowCategories.@each.cows'),
    allPositions: function () {
      return this.store.peekAll('position');
    }.property(),
    allFeed: function () {
      return this.store.peekAll('feed');
    }.property(),
    solidsNonFat: function () {
      return this.otherSolids + this.protein;
    }.property('protein', 'otherSolids'),
    classOneUtilizationFat: function () {
      var monthNumber = (0, _moment.default)(this.startDate).utc().month();
      var val = -1;
      var caClassOneUtilizationFat;
      var CLASS_ONE_UTILIZZATION_FAT = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      switch (this.get('user.pricingModel')) {
        case 'california':
          caClassOneUtilizationFat = this.fatClassOneUtilization === 0 ? this.fatClassOneUtilizationAvg : this.fatClassOneUtilization;
          val = caClassOneUtilizationFat;

          if (val === 0) {
            // Fallback
            val = CLASS_ONE_UTILIZZATION_FAT[monthNumber];
          } else {
            CLASS_ONE_UTILIZZATION_FAT[monthNumber] = val;
          }

          break;
      }

      return val;
    }.property('startDate', 'user.pricingModel', 'classOneUtilizationFat', 'fatClassOneUtilization', 'fatClassOneUtilizationAvg'),
    classTwoUtilizationFat: function () {
      var val = -1;
      var caClassTwoUtilizationFat;

      switch (this.get('user.pricingModel')) {
        case 'california':
          caClassTwoUtilizationFat = this.fatClassTwoUtilization === 0 ? this.fatClassTwoUtilizationAvg : this.fatClassTwoUtilization;
          val = caClassTwoUtilizationFat;
          break;
      }

      return val;
    }.property('startDate', 'user.pricingModel', 'fatClassTwoUtilization', 'fatClassTwoUtilizationAvg'),
    classThreeUtilizationFat: function () {
      var val = -1;
      var caClassThreeUtilizationFat;

      switch (this.get('user.pricingModel')) {
        case 'california':
          caClassThreeUtilizationFat = this.fatClassThreeUtilization === 0 ? this.fatClassThreeUtilizationAvg : this.fatClassThreeUtilization;
          val = caClassThreeUtilizationFat;
          break;
      }

      return val;
    }.property('startDate', 'user.pricingModel', 'fatClassThreeUtilization', 'fatClassThreeUtilizationAvg'),
    classFourAUtilizationFat: function () {
      var val = -1;
      var caClassFourAUtilizationFat;

      switch (this.get('user.pricingModel')) {
        case 'california':
          caClassFourAUtilizationFat = this.fatClassFouraUtilization === 0 ? this.fatClassFouraUtilizationAvg : this.fatClassFouraUtilization;
          val = caClassFourAUtilizationFat;
          break;
      }

      return val;
    }.property('startDate', 'user.pricingModel', 'fatClassFouraUtilization', 'fatClassFouraUtilizationAvg'),
    classFourBUtilizationFat: function () {
      var val = -1;
      var caClassFourBUtilizationFat;

      switch (this.get('user.pricingModel')) {
        case 'california':
          caClassFourBUtilizationFat = this.fatClassFourbUtilization === 0 ? this.fatClassFourbUtilizationAvg : this.fatClassFourbUtilization;
          val = caClassFourBUtilizationFat;
          break;
      }

      return val;
    }.property('startDate', 'user.pricingModel', 'fatClassFourbUtilization', 'fatClassFourbUtilizationAvg'),
    isPast: function () {
      return _moment.default.utc().isAfter(this.startDate, 'month');
    }.property('startDate'),
    isPastWithRollback: function () {
      var currentMonth = _moment.default.utc();

      if (currentMonth.date() < _environment.default.APP.MONTH.ROLLBACK_DATE) {
        currentMonth.subtract(1, 'months');
      }

      return currentMonth.isAfter(this.startDate, 'month');
    }.property('startDate'),
    //
    // Prevent zero values for protein, butterfat and other solids
    //
    totalFatProduction: function () {
      return this.butterfat * this.totalProduction;
    }.property('butterfat', 'totalProduction'),
    totalSNFProduction: function () {
      return this.solidsNonFat * this.totalProduction;
    }.property('solidsNonFat', 'totalProduction'),
    nonQuotaSolidsNonFat: function () {
      return this.totalSNFProduction * this.get('user.overbase');
    }.property('totalSNFProduction', 'user.overbase'),
    //
    // Computed Properties
    //
    monthCode: function () {
      var startDate = this.startDate;

      if (Ember.isEmpty(startDate)) {
        return '';
      } else {
        return _moment.default.utc(this.startDate).format('YYYYMM');
      }
    }.property('startDate'),
    isCurrentMonth: function () {
      return this.monthCode === _moment.default.utc().format('YYYYMM');
    }.property('monthCode'),
    totalProduction: function () {
      return this.cows * this.dailyProduction * _moment.default.utc(this.startDate).daysInMonth();
    }.property('cows', 'dailyProduction', 'startDate'),
    totalProductionCWT: function () {
      return this.totalProduction / 100;
    }.property('totalProduction'),
    numberOfContracts: function () {
      return (this.totalProduction / 200000).toFixed(2);
    }.property('totalProduction'),
    matrixClassThreeMargin: function () {
      if (this.classThreeBestPrice !== null && this.cornBestPrice !== null && this.soybeanMealBestPrice !== null) {
        return this.classThreeBestPrice - this.cornBestPrice * 0.00833 - this.soybeanMealBestPrice * 0.0083;
      } else {
        return null;
      }
    }.property('classThreeBestPrice', 'cornBestPrice', 'soybeanMealBestPrice'),
    matrixClassFourMargin: function () {
      if (this.classFourBestPrice !== null && this.cornBestPrice !== null && this.soybeanMealBestPrice !== null) {
        return this.classFourBestPrice - this.cornBestPrice * 0.00833 - this.soybeanMealBestPrice * 0.0083;
      } else {
        return null;
      }
    }.property('classFourBestPrice', 'cornBestPrice', 'soybeanMealBestPrice'),
    //
    // Commodity Prices
    //
    classThreeMilkInstrument: _emberData.default.belongsTo('vault-instrument'),
    classThreeBestPrice: function () {
      return this.historical_class_three_price ? (0, _velaToBarchartPrice.default)('DC', this.historical_class_three_price) : this.get('classThreeMilkInstrument.lastPrice');
    }.property('classThreeMilkInstrument.lastPrice', 'historical_class_three_price'),
    scenarioClassThreeBestPrice: function () {
      if (this.get('classThreeMilkInstrument.scenarioPrice')) {
        return this.get('classThreeMilkInstrument.scenarioPrice');
      } else if (this.historical_class_three_price) {
        return (0, _velaToBarchartPrice.default)('DC', this.historical_class_three_price);
      } else {
        return this.get('classThreeMilkInstrument.lastPrice');
      }
    }.property('classThreeMilkInstrument.lastPrice', 'classThreeMilkInstrument.scenarioPrice', 'historical_class_three_price'),
    scenarioClassThreeBestPriceFormatted: Ember.computed.alias('scenarioClassThreeBestPrice'),
    classThreeBestPriceFormatted: Ember.computed('classThreeBestPrice', function () {
      return this.classThreeBestPrice;
    }),
    classFourBestPrice: function () {
      return this.historical_class_four_price ? (0, _velaToBarchartPrice.default)('GDK', this.historical_class_four_price) : this.get('classFourMilkInstrument.lastPrice');
    }.property('classFourMilkInstrument.lastPrice', 'historical_class_four_price'),
    classFourBestPriceFormatted: function () {
      return this.classFourBestPrice;
    }.property('classFourBestPrice'),
    dryWheyBestPrice: function () {
      return this.historical_dry_whey_price ? (0, _velaToBarchartPrice.default)('DY', this.historical_dry_whey_price) : this.get('dryWheyInstrument.lastPrice');
    }.property('dryWheyInstrument.lastPrice', 'historical_dry_whey_price'),
    dryWheyBestPriceFormatted: Ember.computed.alias('dryWheyBestPrice'),
    cashSettledCheeseBestPrice: function () {
      return this.historical_cash_settled_cheese_price ? (0, _velaToBarchartPrice.default)('CSC', this.historical_cash_settled_cheese_price) : this.get('cashSettledCheeseInstrument.lastPrice');
    }.property('cashSettledCheeseInstrument.lastPrice', 'historical_cash_settled_cheese_price'),
    cashSettledCheeseBestPriceFormatted: Ember.computed.alias('cashSettledCheeseBestPrice'),
    nonfatDryBestPrice: function () {
      return this.historical_nonfat_dry_milk_price ? (0, _velaToBarchartPrice.default)('GNF', this.historical_nonfat_dry_milk_price) : this.get('nonfatDryMilkInstrument.lastPrice');
    }.property('nonfatDryMilkInstrument.lastPrice', 'historical_nonfat_dry_milk_price'),
    nonfatDryBestPriceFormatted: Ember.computed.alias('nonfatDryBestPrice'),
    cashSettledButterBestPrice: function () {
      return this.historical_cash_settled_butter_price ? (0, _velaToBarchartPrice.default)('CB', this.historical_cash_settled_butter_price) : this.get('cashSettledButterInstrument.lastPrice');
    }.property('cashSettledButterInstrument.lastPrice', 'historical_cash_settled_butter_price'),
    cashSettledButterBestPriceFormatted: Ember.computed.alias('cashSettledButterBestPrice'),
    displayCashSettledButterPrice: Ember.computed("cashSettledButterBestPrice", function () {
      return this.cashSettledButterBestPrice / 100;
    }),
    cornBestPrice: function () {
      if (this.historical_corn_price) {
        return (0, _velaToBarchartPrice.default)('ZC', this.historical_corn_price);
      } else if (this.get('cornInstrument.lastPrice')) {
        return this.get('cornInstrument.lastPrice');
      } else {
        return this.get('next_month_id.cornBestPrice');
      }
    }.property('cornInstrument.lastPrice', 'historical_corn_price', 'next_month_id.cornBestPrice'),
    cornBestPriceFormatted: Ember.computed.alias('cornBestPrice'),
    scenarioCornBestPrice: function () {
      if (this.get('cornInstrument.scenarioPrice')) {
        return this.get('cornInstrument.scenarioPrice');
      } else if (this.historical_corn_price) {
        return (0, _velaToBarchartPrice.default)('ZC', this.historical_corn_price);
      } else if (this.get('cornInstrument.lastPrice')) {
        return this.get('cornInstrument.lastPrice');
      } else {
        return this.get('next_month_id.scenarioCornBestPrice');
      }
    }.property('cornInstrument.lastPrice', 'historical_corn_price', 'cornInstrument.scenarioPrice', 'next_month_id.scenarioCornBestPrice'),
    scenarioCornBestPriceFormatted: Ember.computed.alias('scenarioCornBestPrice'),
    scenarioSoybeanMealBestPrice: function () {
      if (this.get('soybeanMealInstrument.scenarioPrice')) {
        return this.get('soybeanMealInstrument.scenarioPrice');
      } else if (this.historical_soybean_meal_price) {
        return (0, _velaToBarchartPrice.default)('ZM', this.historical_soybean_meal_price);
      } else if (this.get('soybeanMealInstrument.lastPrice')) {
        return this.get('soybeanMealInstrument.lastPrice');
      } else {
        return this.get('next_month_id.scenarioSoybeanMealBestPrice');
      }
    }.property('soybeanMealInstrument.lastPrice', 'historical_soybean_meal_price', 'soybeanMealInstrument.product.tick_size', 'soybeanMealInstrument.scenarioPrice', 'next_month_id.scenarioSoybeanMealBestPrice'),
    scenarioSoybeanMealBestPriceFormatted: Ember.computed.alias('scenarioSoybeanMealBestPrice'),
    soybeanMealBestPrice: function () {
      if (this.historical_soybean_meal_price) {
        return (0, _velaToBarchartPrice.default)('ZM', this.historical_soybean_meal_price);
      } else if (this.get('soybeanMealInstrument.lastPrice')) {
        return this.get('soybeanMealInstrument.lastPrice');
      } else {
        return this.get('next_month_id.soybeanMealBestPrice');
      }
    }.property('soybeanMealInstrument.lastPrice', 'historical_soybean_meal_price', 'next_month_id.soybeanMealBestPrice'),
    soybeanMealBestPriceFormatted: Ember.computed.alias('soybeanMealBestPrice'),
    //
    // Milk Prices
    manualUtilization: function () {
      var val = -1;

      switch (this.get('user.pricingModel')) {
        case 'california':
          val = this.manualClassOneUtilization !== 0 || this.manualClassTwoUtilization !== 0 || this.manualClassThreeUtilization !== 0 || this.manualClassFouraUtilization !== 0 || this.manualClassFourbUtilization !== 0;
          break;

        case 'fmmo':
          val = this.manualClassOneUtilization !== 0 || this.manualClassTwoUtilization !== 0 || this.manualClassThreeUtilization !== 0 || this.manualClassFourUtilization !== 0;
          break;

        default:
          val = 0;
      }

      return val;
    }.property('manualClassOneUtilization', 'manualClassTwoUtilization', 'manualClassThreeUtilization', 'manualClassFourUtilization', 'manualClassFouraUtilization', 'manualClassFourbUtilization', 'user.pricingModel'),
    fmmoComponentDifferential: function () {
      var farm = this.otherSolidsPrice * this.otherSolids + this.butterfatPrice * this.butterfat + this.proteinPrice * this.protein;
      var standard = this.otherSolidsPrice * 0.0569 + this.butterfatPrice * 0.035 + this.proteinPrice * 0.0299;
      return (farm - standard) * 100;
    }.property('otherSolidsPrice', 'butterfatPrice', 'proteinPrice', 'butterfat', 'otherSolids', 'protein'),
    fmmoBlendedMilkPrice: function () {
      if (this.classThreePrice === null || this.classThreePrice === undefined || this.classFourPrice === null || this.classFourPrice === undefined) {
        return 0;
      }

      var v = this.classOneUtilization * this.classOnePrice + this.classTwoUtilization * this.classTwoPrice + this.classThreeUtilization * this.classThreePrice + this.classFourUtilization * this.classFourPrice;
      v += this.get('fmmoComponentDifferential');
      return v;
    }.property('classOneUtilization', 'classTwoUtilization', 'classThreeUtilization', 'classFourUtilization', 'classOnePrice', 'classTwoPrice', 'classThreePrice', 'classFourPrice', 'fmmoComponentDifferential'),
    scenarioComponentDifferential: function () {
      var farm = this.scenarioOtherSolidsPrice * this.otherSolids + this.scenarioButterfatPrice * this.butterfat + this.scenarioProteinPrice * this.protein;
      var standard = this.scenarioOtherSolidsPrice * 0.0569 + this.scenarioButterfatPrice * 0.035 + this.scenarioProteinPrice * 0.0299;
      return (farm - standard) * 100;
    }.property('scenarioOtherSolidsPrice', 'scenarioButterfatPrice', 'scenarioProteinPrice', 'butterfat', 'otherSolids', 'protein'),
    scenarioFmmoBlendedMilkPrice: function () {
      if (this.scenarioClassThreePrice === null || this.scenarioClassThreePrice === undefined || this.scenarioClassFourPrice === null || this.scenarioClassFourPrice === undefined) {
        return 0;
      }

      var v = this.classOneUtilization * this.scenarioFmmoClassOnePrice + this.classTwoUtilization * this.scenarioFmmoClassTwoPrice + this.classThreeUtilization * this.scenarioClassThreePrice + this.classFourUtilization * this.scenarioClassFourPrice;
      v += this.scenarioComponentDifferential;
      return v;
    }.property('classOneUtilization', 'classTwoUtilization', 'classThreeUtilization', 'classFourUtilization', 'scenarioFmmoClassOnePrice', 'scenarioFmmoClassTwoPrice', 'scenarioClassThreePrice', 'scenarioClassFourPrice', 'scenarioComponentDifferential'),
    caBlendedMilkPrice: Ember.computed.alias('caGrossProceeds'),
    // Class One
    classOnePrice: function () {
      var val = -1;

      switch (this.get('user.pricingModel')) {
        case 'california':
          val = this.caClassOnePrice;
          break;

        case 'fmmo':
          val = this.fmmoClassOnePrice;
          break;

        case 'uda':
          val = this.udaClassOnePrice;
          break;

        default:
          val = 0;
      }

      return val;
    }.property('user.pricingModel', 'fmmoClassOnePrice', 'caClassOnePrice', 'udaClassOnePrice'),
    fmmoClassOnePrice: function () {
      var classOneDifferential = Ember.isNone(this.get('user.classOneDifferential')) ? 0 : this.get('user.classOneDifferential');
      var previous_month = this.get('previous_month_id');

      if (previous_month !== null) {
        return previous_month.get('classOneSkim') * 0.965 + previous_month.get('butterfatPrice') * 3.5 + classOneDifferential;
      } else {
        return this.classOneSkim * 0.965 + this.butterfatPrice * 3.5 + classOneDifferential;
      }
    }.property('classOneSkim', 'butterfatPrice', 'user.classOneDifferential', 'previous_month_id', 'previous_month_id.classOneSkim', 'previous_month_id.butterfatPrice'),
    scenarioFmmoClassOnePrice: function () {
      var classOneDifferential = Ember.isNone(this.get('user.classOneDifferential')) ? 0 : this.get('user.classOneDifferential');
      var previous_month = this.get('previous_month_id');

      if (previous_month !== null && (0, _moment.default)(this.startDate).utc().isAfter((0, _moment.default)().utc(), 'month')) {
        return previous_month.get('scenarioClassOneSkim') * 0.965 + previous_month.get('scenarioButterfatPrice') * 3.5 + classOneDifferential;
      } else {
        return this.fmmoClassOnePrice;
      }
    }.property('scenarioClassOneSkim', 'fmmoClassOnePrice', 'scenarioButterfatPrice', 'user.classOneDifferential', 'previous_month_id', 'previous_month_id.scenarioButterfatPrice', 'previous_month_id.scenarioClassOneSkim'),
    classOneProduction: function () {
      return this.classOneUtilization * this.totalProduction;
    }.property('classOneUtilization', 'totalProduction'),
    classOneProductionInContracts: function () {
      return this.classOneProduction / 200000;
    }.property('classOneProduction'),
    regionClassOneUtilization: function () {
      var monthNumber = (0, _moment.default)(this.startDate).utc().month();
      var val = -1;
      var REGION_CLASS_ONE_UTILIZZATION = [0.14125, 0.136375, 0.133625, 0.1316, 0.130175, 0.125125, 0.13095, 0.14195, 0.145375, 0.1475, 0.1434, 0.13775];

      switch (this.get('user.pricingModel')) {
        case 'california':
          val = this.caClassOneUtilizationSNF;

          if (val === 0) {
            // Fallback
            val = REGION_CLASS_ONE_UTILIZZATION[monthNumber];
          } else {
            REGION_CLASS_ONE_UTILIZZATION[monthNumber] = val;
          }

          break;

        case 'fmmo':
          val = this.get('user.location.region.classOne')[monthNumber];
          break;

        default:
          val = this.get('user.location.region.classOne')[monthNumber];
      }

      return val;
    }.property('user.location.region.classOne', 'user.pricingModel', 'startDate', 'caClassOneUtilizationSNF'),
    classOneUtilization: function () {
      return this.manualUtilization ? this.manualClassOneUtilization : this.regionClassOneUtilization;
    }.property('manualUtilization', 'manualClassOneUtilization', 'regionClassOneUtilization'),
    // Class Two
    classTwoPrice: function () {
      var val = -1;

      switch (this.get('user.pricingModel')) {
        case 'california':
          val = this.caClassTwoPrice;
          break;

        case 'fmmo':
          val = this.fmmoClassTwoPrice;
          break;

        case 'uda':
          val = this.udaClassTwoPrice;
          break;

        default:
          val = 0;
      }

      return val;
    }.property('user.pricingModel', 'fmmoClassTwoPrice', 'caClassTwoPrice', 'udaClassTwoPrice'),
    fmmoClassTwoPrice: function () {
      var previous_month = this.get('previous_month_id');

      if (previous_month !== null) {
        return previous_month.get('classTwoSkim') * 0.965 + (this.butterfatPrice + 0.007) * 3.5;
      } else {
        return this.classTwoSkim * 0.965 + (this.butterfatPrice + 0.007) * 3.5;
      }
    }.property('classTwoSkim', 'butterfatPrice', 'previous_month_id', 'previous_month_id.classTwoSkim', 'previous_month_id.butterfatPrice'),
    scenarioFmmoClassTwoPrice: function () {
      var previous_month = this.get('previous_month_id');

      if (previous_month !== null && (0, _moment.default)(this.startDate).utc().isAfter((0, _moment.default)().utc(), 'month')) {
        return previous_month.get('scenarioClassTwoSkim') * 0.965 + (this.scenarioButterfatPrice + 0.007) * 3.5;
      } else {
        return this.fmmoClassTwoPrice;
      }
    }.property('scenarioClassTwoSkim', 'fmmoClassTwoPrice', 'scenarioButterfatPrice', 'previous_month_id', 'previous_month_id.scenarioClassTwoSkim', 'previous_month_id.scenarioButterfatPrice'),
    classTwoProduction: function () {
      return this.classTwoUtilization * this.totalProduction;
    }.property('classTwoUtilization', 'totalProduction'),
    regionClassTwoUtilization: function () {
      var monthNumber = (0, _moment.default)(this.startDate).utc().month();
      var val = -1;
      var REGION_CLASS_TWO_UTILIZZATION = [0.046075, 0.047925, 0.04875, 0.047575, 0.049775, 0.050225, 0.0514, 0.0545, 0.054425, 0.057175, 0.057425, 0.04895];

      switch (this.get('user.pricingModel')) {
        case 'california':
          val = this.caClassTwoUtilizationSNF;

          if (val === 0) {
            // Fallback
            val = REGION_CLASS_TWO_UTILIZZATION[monthNumber];
          } else {
            REGION_CLASS_TWO_UTILIZZATION[monthNumber] = val;
          }

          break;

        case 'fmmo':
          val = this.get('user.location.region.classTwo')[monthNumber];
          break;

        default:
          val = this.get('user.location.region.classTwo')[monthNumber];
      }

      return val;
    }.property('user.location.region.classTwo', 'user.pricingModel', 'startDate'),
    classTwoUtilization: function () {
      if (this.manualUtilization) {
        return this.manualClassTwoUtilization;
      } else {
        return this.regionClassTwoUtilization;
      }
    }.property('manualUtilization', 'manualClassTwoUtilization', 'regionClassTwoUtilization'),
    classesTwoAndFourProduction: function () {
      return this.classTwoProduction + this.classFourProduction;
    }.property('classTwoProduction', 'classFourProduction'),
    classesTwoAndFourProductionInContracts: function () {
      return this.classesTwoAndFourProduction / 200000;
    }.property('classesTwoAndFourProduction'),
    regionClassFourUtilization: function () {
      var monthNumber = (0, _moment.default)(this.startDate).utc().month();
      return this.get('user.location.region.classFour')[monthNumber];
    }.property('user.location.region.classFour', 'startDate'),
    classFourUtilization: function () {
      if (this.manualUtilization) {
        return this.manualClassFourUtilization;
      } else {
        return this.regionClassFourUtilization;
      }
    }.property('manualUtilization', 'manualClassFourUtilization', 'regionClassFourUtilization'),
    classFourAUtilization: function () {
      if (this.manualUtilization) {
        return this.manualClassFouraUtilization;
      } else {
        var CLASS_FOUR_A_UTILIZZATION = [0.36645, 0.36685, 0.364675, 0.365975, 0.3637, 0.357675, 0.339025, 0.31685, 0.31895, 0.3228, 0.3331, 0.359925];
        var monthNumber = (0, _moment.default)(this.startDate).utc().month();
        var val = 0;
        var REGION_CLASS_FOURA_UTILIZZATION = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        switch (this.get('user.pricingModel')) {
          case 'california':
            val = this.caClassFourAUtilizationSNF;

            if (val === 0) {
              // Fallback
              val = REGION_CLASS_FOURA_UTILIZZATION[monthNumber];
            } else {
              REGION_CLASS_FOURA_UTILIZZATION[monthNumber] = val;
            }

            break;

          default:
            val = CLASS_FOUR_A_UTILIZZATION[monthNumber];
        }

        return val;
      }
    }.property('manualUtilization', 'caClassFourAUtilizationSNF', 'manualClassFouraUtilization'),
    classFourAProduction: function () {
      return this.classFourAUtilization * this.totalProduction;
    }.property('classFourAUtilization', 'totalProduction'),
    caClassesTwoThreeAndFouraProduction: function () {
      return this.classTwoProduction + this.classThreeProduction + this.classFourAProduction;
    }.property('classTwoProduction', 'classThreeProduction', 'classFourAProduction'),
    caClassesTwoThreeAndFouraProductionInContracts: function () {
      return this.caClassesTwoThreeAndFouraProduction / 200000;
    }.property('caClassesTwoThreeAndFouraProduction'),
    classFourBUtilization: function () {
      if (this.manualUtilization) {
        return this.manualClassFourbUtilization;
      } else {
        var CLASS_FOUR_B_UTILIZZATION = [0.42055, 0.417425, 0.416125, 0.41715, 0.41865, 0.42585, 0.437375, 0.44395, 0.44445, 0.439575, 0.4373, 0.43385];
        var REGION_CLASS_FOURB_UTILIZZATION = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        var monthNumber = (0, _moment.default)(this.startDate).utc().month();
        var val = 0;

        switch (this.get('user.pricingModel')) {
          case 'california':
            val = this.caClassFourBUtilizationSNF;

            if (val === 0) {
              // Fallback
              val = REGION_CLASS_FOURB_UTILIZZATION[monthNumber];
            } else {
              REGION_CLASS_FOURB_UTILIZZATION[monthNumber] = val;
            }

            break;

          default:
            val = CLASS_FOUR_B_UTILIZZATION[monthNumber];
        }

        return val;
      }
    }.property('user.pricingModel', 'manualUtilization', 'caClassFourBUtilizationSNF', 'manualClassFourbUtilization', 'startDate'),
    // Components
    // DGC: This is really the next month's class one skim price.
    // However, I'm leaving it as is, since this is going to all be ported soon.
    classOneSkim: function () {
      var val = 0;

      switch (this.get('user.pricingModel')) {
        case 'uda':
          val = this.udaClassOneSkim;
          break;

        default:
          if (this.startDate.getTime() < new Date('2019-05-01').getTime()) {
            val = this.classThreeSkim > this.classFourSkim ? this.classThreeSkim : this.classFourSkim;
          } else {
            // Use the New Class One Pricing
            val = (this.classThreeSkim + this.classFourSkim) / 2 + 0.74;
          }

          break;
      }

      return val;
    }.property('classThreeSkim', 'classFourSkim', 'user.pricingModel', 'udaClassOneSkim'),
    // DGC: This is really the next month's class one skim price.
    // However, I'm leaving it as is, since this is going to all be ported soon.
    scenarioClassOneSkim: function () {
      var val = 0;

      switch (this.get('user.pricingModel')) {
        case 'uda':
          val = this.udaScenarioClassOneSkim;
          break;

        default:
          if (this.startDate.getTime() < new Date('2019-05-01').getTime()) {
            val = this.scenarioClassThreeSkim > this.scenarioClassFourSkim ? this.scenarioClassThreeSkim : this.scenarioClassFourSkim;
          } else {
            // Use New Class One Pricing
            val = (this.scenarioClassThreeSkim + this.scenarioClassFourSkim) / 2 + 0.74;
          }

          break;
      }

      return val;
    }.property('scenarioClassThreeSkim', 'scenarioClassFourSkim', 'udaScenarioClassOneSkim', 'user.pricingModel'),
    classTwoSkim: function () {
      var val = 0;

      switch (this.get('user.pricingModel')) {
        case 'uda':
          val = this.udaClassTwoSkim;
          break;

        default:
          val = this.classFourSkim + 0.7;
          break;
      }

      return val;
    }.property('classFourSkim', 'udaClassTwoSkim', 'user.pricingModel'),
    scenarioClassTwoSkim: function () {
      var val = 0;

      switch (this.get('user.pricingModel')) {
        case 'uda':
          val = this.scenarioUdaClassTwoSkim;
          break;

        default:
          val = this.scenarioClassFourSkim + 0.7;
          break;
      }

      return val;
    }.property('scenarioClassFourSkim', 'scenarioUdaClassTwoSkim', 'user.pricingModel'),
    butterfatPrice: function () {
      return this.butterPricePerPound * 0.01211 - 0.2076865;
    }.property('butterPricePerPound'),
    scenarioButterfatPrice: function () {
      return this.scenarioButterPricePerPound * 0.01211 - 0.2076865;
    }.property('scenarioButterPricePerPound'),
    proteinPrice: function () {
      return this.cheesePricePerPound * 3.22224 - 0.645414672 - this.butterfatPrice * 1.053;
    }.property('butterfatPrice', 'cheesePricePerPound'),
    scenarioProteinPrice: function () {
      return this.scenarioCheesePricePerPound * 3.22224 - 0.645414672 - this.scenarioButterfatPrice * 1.053;
    }.property('scenarioButterfatPrice', 'scenarioCheesePricePerPound'),
    otherSolidsPrice: function () {
      return (this.dryWheyPricePerPound - 0.1991) * 1.03;
    }.property('dryWheyPricePerPound'),
    scenarioOtherSolidsPrice: function () {
      return (this.scenarioDryWheyPricePerPound - 0.1991) * 1.03;
    }.property('scenarioDryWheyPricePerPound'),
    nonfatSolidsPrice: function () {
      return (this.nonfatDryMilkPricePerPound - 0.1678) * 0.99;
    }.property('nonfatDryMilkPricePerPound'),
    scenarioNonfatSolidsPrice: function () {
      return (this.scenarioNonfatDryMilkPricePerPound - 0.1678) * 0.99;
    }.property('scenarioNonfatDryMilkPricePerPound'),
    dryWheyPricePerPound: function () {
      // This is in dollars...
      return this.dryWheyBestPriceFormatted / 100;
    }.property('dryWheyBestPriceFormatted', 'dryWheyInstrument.product.tick_size'),
    scenarioDryWheyBestPrice: function () {
      return this.get('dryWheyInstrument.scenarioPrice') ? this.get('dryWheyInstrument.scenarioPrice') : (0, _velaToBarchartPrice.default)("DY", this.historical_dry_whey_price);
    }.property('dryWheyInstrument.scenarioPrice', 'historical_dry_whey_price'),
    scenarioDryWheyBestPriceFormatted: Ember.computed.alias('scenarioDryWheyBestPrice'),
    scenarioNonfatDryBestPrice: function () {
      return this.get('nonfatDryMilkInstrument.scenarioPrice') ? this.get('nonfatDryMilkInstrument.scenarioPrice') : (0, _velaToBarchartPrice.default)("GNF", this.historical_nonfat_dry_milk_price);
    }.property('nonfatDryMilkInstrument.scenarioPrice', 'historical_nonfat_dry_milk_price'),
    scenarioNonfatDryBestPriceFormatted: Ember.computed('scenarioNonfatDryBestPrice', function () {
      return this.scenarioNonfatDryBestPrice;
    }),
    scenarioDryWheyPricePerPound: Ember.computed('scenarioDryWheyBestPriceFormatted', function () {
      return this.scenarioDryWheyBestPriceFormatted / 100;
    }),
    nonfatDryMilkPricePerPound: Ember.computed('nonfatDryBestPriceFormatted', function () {
      return this.nonfatDryBestPriceFormatted / 100;
    }),
    scenarioNonfatDryMilkBestPrice: Ember.computed.alias('scenarioNonfatDryBestPrice'),
    scenarioNonfatDryMilkBestPriceFormatted: Ember.computed.alias('scenarioNonfatDryBestPriceFormatted'),
    scenarioNonfatDryMilkPricePerPound: Ember.computed('scenarioNonfatDryMilkBestPriceFormatted', function () {
      // For whatever reason, this is expected to be in dollars per pound.
      return this.scenarioNonfatDryMilkBestPriceFormatted / 100;
    }),
    cheesePricePerPound: Ember.computed.alias('cashSettledCheeseBestPrice'),
    scenarioCheeseBestPrice: function () {
      return this.get('cashSettledCheeseInstrument.scenarioPrice') ? this.get('cashSettledCheeseInstrument.scenarioPrice') : (0, _velaToBarchartPrice.default)("CSC", this.historical_cash_settled_cheese_price);
    }.property('cashSettledCheeseInstrument.scenarioPrice', 'historical_cash_settled_cheese_price', 'cashSettledCheeseInstrument.product', 'cashSettledCheeseInstrument.product.display_factor'),
    scenarioCheeseBestPriceFormatted: Ember.computed.alias('scenarioCheeseBestPrice'),
    scenarioCheesePricePerPound: Ember.computed.alias('scenarioCheeseBestPrice'),
    butterPricePerPound: Ember.computed.alias('cashSettledButterBestPrice'),
    scenarioButterBestPrice: function () {
      return this.get('cashSettledButterInstrument.scenarioPrice') ? this.get('cashSettledButterInstrument.scenarioPrice') : (0, _velaToBarchartPrice.default)("CB", this.historical_cash_settled_butter_price);
    }.property('cashSettledButterInstrument.scenarioPrice', 'historical_cash_settled_butter_price', 'cashSettledButterInstrument.product.display_factor'),
    scenarioButterBestPriceFormatted: Ember.computed.alias('scenarioButterBestPrice'),
    scenarioButterPricePerPound: Ember.computed.alias('scenarioButterBestPrice'),
    //
    // Feed
    energyUsage: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('energyUsage');
      }, 0);
    }.property('cowCategories.@each.energyUsage'),
    energyCostPerTon: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('energyCostPerTon');
      }, 0);
    }.property('cowCategories.@each.energyCostPerTon'),
    scenarioEnergyCostPerTon: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('scenarioEnergyCostPerTon');
      }, 0);
    }.property('cowCategories.@each.scenarioEnergyCostPerTon'),
    totalEnergyCost: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('totalEnergyCost');
      }, 0);
    }.property('cowCategories.@each.totalEnergyCost'),
    totalEnergyCostPerCWT: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('totalEnergyCostPerCWT');
      }, 0);
    }.property('cowCategories.@each.totalEnergyCostPerCWT'),
    scenarioTotalEnergyCost: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('scenarioTotalEnergyCost');
      }, 0);
    }.property('cowCategories.@each.scenarioTotalEnergyCost'),
    proteinUsage: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('proteinUsage');
      }, 0);
    }.property('cowCategories.@each.proteinUsage'),
    proteinCostPerTon: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('proteinCostPerTon');
      }, 0);
    }.property('cowCategories.@each.proteinCostPerTon'),
    scenarioProteinCostPerTon: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('scenarioProteinCostPerTon');
      }, 0);
    }.property('cowCategories.@each.scenarioProteinCostPerTon'),
    totalProteinCost: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('totalProteinCost');
      }, 0);
    }.property('cowCategories.@each.totalProteinCost'),
    totalProteinCostPerCWT: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('totalProteinCostPerCWT');
      }, 0);
    }.property('cowCategories.@each.totalProteinCostPerCWT'),
    scenarioTotalProteinCost: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('scenarioTotalProteinCost');
      }, 0);
    }.property('cowCategories.@each.scenarioTotalProteinCost'),
    totalOtherFeedCosts: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('totalOtherFeedCosts');
      }, 0);
    }.property('cowCategories.@each.totalOtherFeedCosts'),
    flatFeedCosts: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('flatFeedCosts');
      }, 0);
    }.property('cowCategories.@each.flatFeedCosts'),
    totalFeedCosts: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('totalFeedCosts');
      }, 0);
    }.property('cowCategories.@each.totalFeedCosts'),
    totalFeedCostsPerCWT: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('totalFeedCostsPerCWT');
      }, 0);
    }.property('cowCategories.@each.totalFeedCostsPerCWT'),
    scenarioTotalFeedCosts: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('scenarioTotalFeedCosts');
      }, 0);
    }.property('cowCategories.@each.scenarioTotalFeedCosts'),
    //
    // Positions
    //
    positions: function () {
      var self = this;
      return this.get('allPositions').filter(function (pos) {
        var expiration = pos.get('instrument.displayExpiresAt');

        if (expiration && _moment.default.utc(expiration).format('YYYYMM') === self.get('monthCode')) {
          return true;
        } else {
          return false;
        }
      });
    }.property('allPositions.@each.instrument', 'allPositions', 'allPositions.@each'),
    classOneProductionCWT: function () {
      return this.classOneProduction / 100;
    }.property('classOneProduction'),
    classOneCashValueProduced: function () {
      var classOneMilk = this.classThreeBestPrice > this.classFourBestPrice ? this.classThreeBestPrice : this.classFourBestPrice;
      return this.classOneProductionCWT * classOneMilk;
    }.property('classOneProductionCWT', 'classThreeBestPrice', 'classFourBestPrice'),
    classThreeCashValueCovered: function () {
      var physicalPositions = this.get('physicalPositions');
      var exchangePositions = this.get('positions');
      var classThreePrice = this.get('classThreeBestPriceFormatted');
      var cheesePrice = this.get('cashSettledCheeseBestPriceFormatted');
      var shortFutures = [];
      var longPuts = [];
      var shortCalls = [];
      var shortFuturesCashValue = 0;
      var longPutCashValue = 0;
      var shortCallCashValue = 0; // Organize into long puts and short calls

      for (var i = 0; i < physicalPositions.content.length; i++) {
        var pp = physicalPositions.objectAt(i);
        var symbol = pp.get('rootSymbol');
        var type = pp.get('instrumentType');
        var isLong = pp.get('isLong');
        var quantity = pp.get('quantity');

        if (symbol === 'DC' || symbol === 'CSC') {
          var price = symbol === 'DC' ? classThreePrice : cheesePrice;
          quantity = symbol === 'DC' ? quantity / 100 : quantity;

          if (type === 'Forward' && !isLong) {
            shortFutures.push(pp);
            shortFuturesCashValue = shortFuturesCashValue + quantity * price;
          }

          if (type === 'Put' && isLong) {
            longPuts.push(pp);
            longPutCashValue = longPutCashValue + quantity * price;
          } else if (type === 'Call' && !isLong) {
            shortCalls.push(pp);
            shortCallCashValue = shortCallCashValue + quantity * price;
          }
        }
      }

      for (var _i = 0; _i < exchangePositions.length; _i++) {
        var ep = exchangePositions[_i];
        symbol = ep.get('instrument.rootSymbol');
        type = ep.get('instrument.option_type');
        var insType = ep.get('instrument.instrument_type');
        isLong = ep.get('isLong');
        quantity = ep.get('quantityInUnits');

        if (symbol === 'DC' || symbol === 'CSC') {
          price = symbol === 'DC' ? classThreePrice : cheesePrice;
          quantity = symbol === 'DC' ? quantity / 100 : quantity;

          if (insType && insType === 'FUTURE' && !isLong) {
            shortFutures.push(ep);
            shortFuturesCashValue = shortFuturesCashValue + quantity * price;
          }

          if (type === 'PUT' && isLong) {
            longPuts.push(ep);
            longPutCashValue = longPutCashValue + quantity * price;
          } else if (type === 'CALL' && !isLong) {
            shortCalls.push(ep);
            shortCallCashValue = shortCallCashValue + quantity * price;
          }
        }
      }

      return (longPutCashValue > shortCallCashValue ? longPutCashValue : shortCallCashValue) + shortFuturesCashValue;
    }.property('physicalPositions.@each', 'positions.@each', 'classThreeBestPriceFormatted', 'cashSettledCheeseBestPriceFormatted'),
    classThreeCashHedgedOverflow: function () {
      var classOneCashValueProduced = this.classOneCashValueProduced;
      var totalHedgedValue = this.classThreeCashValueCovered;

      if (classOneCashValueProduced > 0) {
        var overflow = 0;

        if (totalHedgedValue - this.classThreeCashValueProduced > 0) {
          overflow += totalHedgedValue - this.classThreeCashValueProduced;
        }

        return overflow;
      } else {
        return 0;
      }
    }.property('classOneCashValueProduced', 'classThreeCashValueCovered', 'classThreeCashValueProduced'),
    classFourCashHedgedOverflow: function () {
      var classOneCashValueProduced = this.classOneCashValueProduced;

      if (classOneCashValueProduced > 0) {
        var overflow = 0;

        if (this.nonfatDryMilkAndCashSettledButterCashValueHedged - this.classFourCashValueProduced > 0) {
          overflow += this.nonfatDryMilkAndCashSettledButterCashValueHedged - this.classFourCashValueProduced;
        }

        return overflow;
      } else {
        return 0;
      }
    }.property('classOneCashValueProduced', 'nonfatDryMilkAndCashSettledButterCashValueHedged', 'classFourCashValueProduced'),
    totalOverflowFromClassesThreeAndFourCashHedged: function () {
      return this.classThreeCashHedgedOverflow + this.classFourCashHedgedOverflow;
    }.property('classThreeCashHedgedOverflow', 'classFourCashHedgedOverflow'),
    classOnePercentageHedged: function () {
      var classOneCashValueProduced = this.classOneCashValueProduced;
      var overflow = this.totalOverflowFromClassesThreeAndFourCashHedged;

      if (classOneCashValueProduced > 0 && overflow > 0) {
        return overflow / classOneCashValueProduced;
      } else {
        return 0;
      }
    }.property('classOneCashValueProduced', 'totalOverflowFromClassesThreeAndFourCashHedged'),
    classOnePercentageUnhedged: function () {
      var percentage = 1 - this.classOnePercentageHedged;

      if (percentage > 0) {
        return percentage;
      }

      return 0;
    }.property('classOnePercentageHedged'),
    totalMilkHedgedGeneric: function () {
      var v = 0;

      if (this.totalProduction > 0) {
        if (this.get('user.pricingModel') === 'fmmo') {
          var classTwoAndFour = this.classFourCashValueProduced * this.classFourNetPercentageHedged;
          var classOne = this.classOneCashValueProduced * this.classOnePercentageHedged;
          var classThree = this.classThreeCashValueProduced * this.classThreeNetPercentageHedged;
          var totalProduction = this.classOneCashValueProduced + this.classThreeCashValueProduced + this.classFourCashValueProduced;
          v = (classOne + classThree + classTwoAndFour) / totalProduction;
          return v;
        }

        v = (this.classThreePoundsHedged + this.classFourPoundsHedged) / this.totalProduction;
        return v;
      } else {
        return 0;
      }
    }.property('totalProduction', 'user.pricingModel', 'classFourCashValueProduced', 'classFourNetPercentageHedged', 'classOneCashValueProduced', 'classOnePercentageHedged', 'classThreeCashValueProduced', 'classThreeNetPercentageHedged', 'classThreePoundsHedged', 'classFourPoundsHedged'),
    caClassOnePercentageHedged: function () {
      var totalProduction;
      totalProduction = this.classOneProduction;

      if (totalProduction > 0) {
        var availableProduction = 0;

        if (this.classThreePoundsHedged - this.classFourBProduction > 0) {
          availableProduction += this.classThreePoundsHedged - this.classFourBProduction;
        }

        if (this.classFourPoundsHedged - this.caClassesTwoThreeAndFouraProduction > 0) {
          availableProduction += this.classFourPoundsHedged - this.caClassesTwoThreeAndFouraProduction;
        }

        return availableProduction / totalProduction;
      } else {
        return 0;
      }
    }.property('classThreePoundsHedged', 'classFourBProduction', 'classFourBestPrice', 'classThreeBestPrice', 'classFourProduction', 'classTwoProduction', 'classFourPoundsHedged', 'caClassesTwoThreeAndFouraProduction'),
    caClassOneContractsUnhedged: function () {
      var totalProduction = this.classOneProduction;
      var totalHedged = 0;

      if (totalProduction > 0) {
        if (this.classThreePoundsHedged - this.classFourBProduction > 0) {
          totalHedged += this.classThreePoundsHedged - this.classFourBProduction;
        }

        if (this.classFourPoundsHedged - this.caClassesTwoThreeAndFouraProduction > 0) {
          totalHedged += this.classFourPoundsHedged - this.caClassesTwoThreeAndFouraProduction;
        }
      }

      if (totalHedged > totalProduction) {
        return 0;
      } else {
        return (totalProduction - totalHedged) / 200000;
      }
    }.property('classThreePoundsHedged', 'classFourBProduction', 'classFourBestPrice', 'classThreeBestPrice', 'classFourProduction', 'classTwoProduction', 'classFourPoundsHedged', 'caClassesTwoThreeAndFouraProduction'),
    butterContracts: function () {
      var butterPoundsHedged = this.getPoundsHedged('CB');
      var classTwoAndFourRequired = this.classesTwoAndFourProduction * 0.035 * 1.211;
      return butterPoundsHedged / classTwoAndFourRequired;
    }.property('positions.@each', 'physicalPositions.@each', 'classesTwoAndFourProduction'),
    nfdmContracts: function () {
      var nonFatPoundsHedged = this.getPoundsHedged('GNF');
      var classTwoAndFourRequired = this.classesTwoAndFourProduction * 0.08685;
      return nonFatPoundsHedged / classTwoAndFourRequired;
    }.property('positions.@each', 'physicalPositions.@each', 'classesTwoAndFourProductionInContracts'),
    classesTwoAndFourPercentageHedgedAlternative: function () {
      var totalProduction = 0;
      var poundsHedged = this.classFourPoundsHedged;

      switch (this.get('user.pricingModel')) {
        case 'california':
          totalProduction = this.classFourAProduction + this.classTwoProduction + this.classThreeProduction;
          break;

        case 'fmmo':
          totalProduction = this.classFourProduction + this.classTwoProduction;

          if (totalProduction === 0) {
            return 0;
          }

          return this.butterContracts * 0.42 + this.nfdmContracts * 0.39 + this.getPoundsHedged('GDK') / totalProduction;

        case 'uda':
          totalProduction = this.udaPowderProduction;
          poundsHedged += this.get('nonfatDryMilkPoundsHedged');
          break;

        default:
          totalProduction = 0;
      }

      if (totalProduction > 0) {
        if (poundsHedged > totalProduction) {
          return 1;
        } else {
          return poundsHedged / totalProduction;
        }
      } else {
        return 0;
      }
    }.property('classFourPoundsHedged', 'nonfatDryMilkPoundsHedged', 'classFourProduction', 'classFourBestPrice', 'classThreeBestPrice', 'classTwoProduction', 'user.pricingModel', 'udaPowderProduction'),
    caClassesTwoThreeAndFouraContractsUnhedged: function () {
      var result = this.caClassesTwoThreeAndFouraProductionInContracts - this.classesTwoAndFourPercentageHedgedAlternative * this.caClassesTwoThreeAndFouraProductionInContracts;
      return result < 0 ? 0 : result;
    }.property('classesTwoAndFourPercentageHedgedAlternative', 'caClassesTwoThreeAndFouraProductionInContracts'),
    totalMilkHedged: function () {
      var resp = this.totalMilkHedgedGeneric;

      switch (this.get('user.pricingModel')) {
        case 'uda':
          resp = this.udaTotalMilkHedged;
          break;
      }

      return resp;
    }.property('user.pricingModel', 'totalMilkHedgedGeneric', 'udaTotalMilkHedged'),
    totalMilkUnhedged: function () {
      if (this.totalMilkHedged > 1) {
        return 0;
      } else {
        return 1 - this.totalMilkHedged;
      }
    }.property('totalMilkHedged'),
    //
    // Milk Revenue
    //
    totalBlendedMilkPrice: function () {
      return this.blendedMilkPrice * this.totalProduction / 100;
    }.property('blendedMilkPrice', 'totalProduction'),
    offExchangeMilkPL: function () {
      return this.offExchangeClassThreePL + this.offExchangeClassFourPL + this.offExchangeDryWheyPL + this.offExchangeCashSettledCheesePL + this.offExchangeNonfatDryMilkPL + this.offExchangeCashSettledButterPL;
    }.property('offExchangeClassThreePL', 'offExchangeClassFourPL', 'offExchangeDryWheyPL', 'offExchangeCashSettledCheesePL', 'offExchangeNonfatDryMilkPL', 'offExchangeCashSettledButterPL'),
    offExchangeMilkPLPerCWT: function () {
      if (this.totalProductionCWT > 0) {
        return this.offExchangeMilkPL / this.totalProductionCWT;
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'offExchangeMilkPL'),
    exchangeMilkPL: function () {
      return this.classThreeFuturePL + this.classThreeOptionPL + this.classFourFuturePL + this.classFourOptionPL + this.dryWheyFuturePL + this.dryWheyOptionPL + this.cashSettledCheeseFuturePL + this.cashSettledCheeseOptionPL + this.nonfatDryMilkFuturePL + this.nonfatDryMilkOptionPL + this.cashSettledButterFuturePL + this.cashSettledButterOptionPL;
    }.property('classThreeFuturePL', 'classThreeOptionPL', 'classFourOptionPremium', 'classFourFuturePL', 'classFourOptionPL', 'dryWheyOptionPremium', 'dryWheyFuturePL', 'dryWheyOptionPL', 'cashSettledCheeseOptionPremium', 'cashSettledCheeseFuturePL', 'cashSettledCheeseOptionPL', 'nonfatDryMilkOptionPremium', 'nonfatDryMilkFuturePL', 'nonfatDryMilkOptionPL', 'cashSettledButterFuturePL', 'cashSettledButterOptionPL'),
    exchangeMilkPLPerCWT: function () {
      if (this.totalProductionCWT > 0) {
        return this.exchangeMilkPL / this.totalProductionCWT;
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'exchangeMilkPL'),
    totalDairyHedgePL: function () {
      return this.exchangeMilkPL + this.offExchangeMilkPL;
    }.property('offExchangeMilkPL', 'exchangeMilkPL'),
    blendedMilkPrice: function () {
      var val = -1;

      switch (this.get('user.pricingModel')) {
        case 'california':
          val = this.caBlendedMilkPrice;
          break;

        case 'glanbia':
          val = this.glTotalPrice;
          break;

        case 'uda':
          val = this.udaBlendedMilkPriceAndProtein;
          break;

        default:
          val = this.fmmoBlendedMilkPrice;
      }

      return val;
    }.property('user.pricingModel', 'fmmoBlendedMilkPrice', 'caBlendedMilkPrice', 'glTotalPrice', 'udaBlendedMilkPriceAndProtein'),
    milkCheckCWT: function () {
      var total = this.vaultBasis + this.blendedMilkPrice + this.milkPriceAdjustments;

      if (total) {
        return total;
      }

      return 0;
    }.property('vaultBasis', 'blendedMilkPrice', 'milkPriceAdjustments'),
    totalMilkCheck: function () {
      var total = (this.vaultBasis + this.blendedMilkPrice + this.milkPriceAdjustments) * (this.totalProduction / 100);

      if (total) {
        return total;
      }

      return 0;
    }.property('milkPriceAdjustments', 'blendedMilkPrice', 'totalProduction', 'vaultBasis'),
    totalMilkCheckWithoutBasis: function () {
      var total = this.milkCheckWithoutBasis * (this.totalProduction / 100);
      return total || 0;
    }.property('milkCheckWithoutBasis', 'totalProduction'),
    milkCheckWithoutBasis: function () {
      var total = this.blendedMilkPrice + this.milkPriceAdjustments;
      return total || 0;
    }.property('milkPriceAdjustments', 'blendedMilkPrice'),
    totalMilkPriceAdjustments: function () {
      return this.milkPriceAdjustments * (this.totalProduction / 100);
    }.property('milkPriceAdjustments', 'totalProduction'),
    totalDairyRevenue: function () {
      return this.totalMilkCheck + this.totalDairyHedgePL;
    }.property('totalMilkCheck', 'totalDairyHedgePL'),
    totalDairyRevenuePerCWT: function () {
      if (this.totalProductionCWT > 0) {
        return this.totalDairyRevenue / this.totalProductionCWT;
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'totalDairyRevenue'),
    totalDairyRevenueAndOtherRev: function () {
      return this.totalDairyRevenue + this.totalOtherRevenue;
    }.property('totalDairyRevenue', 'totalOtherRevenue'),
    milkCheckBE: function () {
      return this.totalCostOfProduction - this.totalOtherRevenue;
    }.property('totalCostOfProduction', 'totalOtherRevenue'),
    milkCheckBEPerCWT: function () {
      return this.totalCostOfProductionPerCWT - this.totalOtherRevenuePerCWT;
    }.property('totalCostOfProductionPerCWT', 'totalOtherRevenuePerCWT'),
    nonQuota: function () {
      return this.totalProduction - this.get('user.quota');
    }.property('totalProduction', 'user.quota'),
    quotaPercentage: function () {
      return this.get('user.quota') / this.totalProduction;
    }.property('user.quota', 'totalProduction'),
    nonQuotaPercentage: function () {
      return (this.totalProduction - this.get('user.quota')) / this.totalProduction;
    }.property('totalProduction', 'user.quota'),
    scenarioBlendedMilkPrice: function () {
      var scenarioClassOneFatRevenue = this.classOneFatProduced * this.scenarioCaOneButterfat;
      var scenarioClassTwoFatRevenue = this.classTwoFatProduced * this.scenarioCaTwoButterfat;
      var scenarioClassThreeFatRevenue = this.classThreeFatProduced * this.scenarioCaThreeButterfat;
      var scenarioClassFourAFatRevenue = this.classFourAFatProduced * this.scenarioCaFourAButterfat;
      var scenarioClassFourBFatRevenue = this.classFourBFatProduced * this.scenarioCaFourBButterfat;
      var scenarioTotalClassFatRevenue = scenarioClassOneFatRevenue + scenarioClassTwoFatRevenue + scenarioClassThreeFatRevenue + scenarioClassFourAFatRevenue + scenarioClassFourBFatRevenue;
      var scenarioCaTotalButterfat = scenarioTotalClassFatRevenue / this.totalClassFatProduced;
      var wheyPricePerPound = this.scenarioDryWheyPricePerPound;
      var wheyFactor = (0, _wheyValuePerCwt.default)(wheyPricePerPound);
      var scenarioCaFourBProductValue = (this.scenarioCheesePricePerPound - 0.0252 - 0.1988) * 10.2 + (this.scenarioButterPricePerPound / 100 - 0.10 - 0.1635) * 0.27 + wheyFactor;
      var scenarioCaFourBNonfatSolids = (scenarioCaFourBProductValue - 3.72 * this.scenarioCaFourBButterfat) / 8.8;
      var scenarioClassOneSNFRevenue = this.classOneSNFProduced * this.scenarioCaOneNonfatSolids;
      var scenarioClassTwoSNFRevenue = this.classTwoSNFProduced * this.scenarioCaTwoNonfatSolids;
      var scenarioClassThreeSNFRevenue = this.classThreeSNFProduced * this.scenarioCaThreeNonfatSolids;
      var scenarioClassFourASNFRevenue = this.classFourASNFProduced * this.scenarioCaFourANonfatSolids;
      var scenarioClassFourBSNFRevenue = this.classFourBSNFProduced * scenarioCaFourBNonfatSolids;
      var scenarioTotalClassSNFRevenue = scenarioClassOneSNFRevenue + scenarioClassTwoSNFRevenue + scenarioClassThreeSNFRevenue + scenarioClassFourASNFRevenue + scenarioClassFourBSNFRevenue;
      var scenarioCaNonQuotaSNFPoolPrice = scenarioTotalClassSNFRevenue / this.totalClassSNFProduced;
      var scenarioCaQuotaSNFPoolPrice = scenarioCaNonQuotaSNFPoolPrice + 0.195;
      var scenarioQuotaPrice = 3.5 * scenarioCaTotalButterfat + scenarioCaQuotaSNFPoolPrice * 8.7;
      var scenarioNonQuotaPrice = 3.5 * scenarioCaTotalButterfat + scenarioCaNonQuotaSNFPoolPrice * 8.7;
      var val = -1;
      var scenarioCaBlendedMilkPrice;

      switch (this.get('user.pricingModel')) {
        case 'california':
          scenarioCaBlendedMilkPrice = this.quotaPercentage * scenarioQuotaPrice + this.nonQuotaPercentage * scenarioNonQuotaPrice;
          val = scenarioCaBlendedMilkPrice;
          break;

        case 'fmmo':
          val = this.scenarioFmmoBlendedMilkPrice;
          break;

        case 'uda':
          val = this.scenarioUdaBlendedMilkPriceAndProtein;
          break;

        case 'glanbia':
          val = this.scenarioGlBlendedMilkPrice;
          break;

        default:
          val = 0;
      }

      return val;
    }.property('user.pricingModel', 'scenarioFmmoBlendedMilkPrice', 'quotaPercentage', 'nonQuotaPercentage', 'scenarioUdaBlendedMilkPriceAndProtein', 'scenarioGlBlendedMilkPrice', 'totalClassSNFProduced', 'classOneSNFProduced', 'scenarioCaOneNonfatSolids', 'classTwoSNFProduced', 'scenarioCaTwoNonfatSolids', 'classThreeSNFProduced', 'scenarioCaThreeNonfatSolids', 'classFourASNFProduced', 'scenarioCaFourANonfatSolids', 'classFourBSNFProduced', 'scenarioCaFourBNonfatSolids', 'scenarioCheesePricePerPound', 'scenarioButterPricePerPound', 'scenarioDryWheyPricePerPound', 'scenarioCaFourBButterfat', 'totalClassFatProduced', 'classOneFatProduced', 'scenarioCaOneButterfat', 'classTwoFatProduced', 'scenarioCaTwoButterfat', 'classThreeFatProduced', 'scenarioCaThreeButterfat', 'classFourAFatProduced', 'scenarioCaFourAButterfat', 'classFourBFatProduced', 'scenarioCaFourBButterfat'),
    scenarioTotalMilkCheck: function () {
      var total = (this.scenarioBlendedMilkPrice + this.vaultBasis + this.milkPriceAdjustments) * (this.totalProduction / 100);

      if (total) {
        return total;
      }

      return 0;
    }.property('milkPriceAdjustments', 'scenarioBlendedMilkPrice', 'totalProduction', 'vaultBasis'),
    scenarioTotalDairyRevenue: function () {
      var scenarioCashSettledButterFuturePL = this.getPositionsValue("CB", "Future", 'scenarioPlInDollars');
      var scenarioCashSettledButterOptionPL = this.getPositionsValue("CB", "Option", 'scenarioPlInDollars');
      var scenarioOffExchangeCashSettledButterPL = this.getScenarioOffExchangePL("CB", this.scenarioButterBestPriceFormatted);
      var scenarioCashSettledCheeseFuturePL = this.getPositionsValue("CSC", "Future", 'scenarioPlInDollars');
      var scenarioCashSettledCheeseOptionPL = this.getPositionsValue("CSC", "Option", 'scenarioPlInDollars');
      var scenarioOffExchangeCashSettledCheesePL = this.getScenarioOffExchangePL("CSC", this.scenarioCheeseBestPriceFormatted);
      var scenarioOffExchangeMilkPL = 0;
      scenarioOffExchangeMilkPL += this.get('scenarioOffExchangeClassThreePL');
      scenarioOffExchangeMilkPL += this.get('scenarioOffExchangeClassFourPL');
      scenarioOffExchangeMilkPL += this.get('scenarioOffExchangeDryWheyPL');
      scenarioOffExchangeMilkPL += scenarioOffExchangeCashSettledCheesePL;
      scenarioOffExchangeMilkPL += this.get('scenarioOffExchangeNonfatDryMilkPL');
      scenarioOffExchangeMilkPL += scenarioOffExchangeCashSettledButterPL;
      var scenarioExchangeMilkPL = 0;
      scenarioExchangeMilkPL += this.get('scenarioClassThreeFuturePL');
      scenarioExchangeMilkPL += this.get('scenarioClassThreeOptionPL');
      scenarioExchangeMilkPL += this.get('scenarioClassFourFuturePL');
      scenarioExchangeMilkPL += this.get('scenarioClassFourOptionPL');
      scenarioExchangeMilkPL += this.get('scenarioDryWheyFuturePL');
      scenarioExchangeMilkPL += this.get('scenarioDryWheyOptionPL');
      scenarioExchangeMilkPL += scenarioCashSettledCheeseFuturePL;
      scenarioExchangeMilkPL += scenarioCashSettledCheeseOptionPL;
      scenarioExchangeMilkPL += this.get('scenarioNonfatDryMilkFuturePL');
      scenarioExchangeMilkPL += this.get('scenarioNonfatDryMilkOptionPL');
      scenarioExchangeMilkPL += scenarioCashSettledButterFuturePL;
      scenarioExchangeMilkPL += scenarioCashSettledButterOptionPL;
      return this.scenarioTotalMilkCheck + scenarioExchangeMilkPL + scenarioOffExchangeMilkPL;
    }.property('scenarioCheeseBestPriceFormatted', 'cashSettledCheeseInstrument.product.tick_size', 'positions.@each.scenarioPlInDollars', 'physicalPositions.@each', 'scenarioButterBestPriceFormatted', 'cashSettledButterInstrument.product.tick_size', 'isCurrentMonth', 'hypotheticalPositions.@each', 'scenarioTotalMilkCheck', 'scenarioOffExchangeClassThreePL', 'scenarioOffExchangeClassFourPL', 'offExchangeDryWheyPL', 'offExchangeCashSettledCheesePL', 'offExchangeNonfatDryMilkPL', 'offExchangeCashSettledButterPL', 'scenarioClassThreeFuturePL', 'scenarioClassThreeOptionPL', 'scenarioClassFourFuturePL', 'scenarioClassFourOptionPL', 'scenarioDryWheyFuturePL', 'scenarioDryWheyOptionPL', 'scenarioNonfatDryMilkFuturePL', 'scenarioNonfatDryMilkOptionPL'),
    scenarioTotalDairyRevenuePerCWT: function () {
      if (this.totalProductionCWT > 0) {
        return this.scenarioTotalDairyRevenue / this.totalProductionCWT;
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'scenarioTotalDairyRevenue'),
    //
    // Feed Costs
    //
    feedTotalHedgedPrice: function () {
      var v = 0;
      this.get('physicalPositions').forEach(function (p) {
        if (p.get('productGroup.symbol') === 'ZC' || p.get('productGroup.symbol') === 'ZM') {
          if (p.get('instrumentType') === 'Forward' && p.get('isLong')) {
            v += p.get('quantity') * p.get('price');
          }

          if (p.get('instrumentType') === 'Call' && p.get('isLong')) {
            v += p.get('quantity') * p.get('strike');
          }

          if (p.get('instrumentType') === 'Put' || p.get('instrumentType') === 'Call') {
            if (p.get('isLong')) {
              v -= p.get('price');
            } else {
              v += p.get('price');
            }
          }
        }
      });
      this.get('positions').forEach(function (p) {
        if (p.get('instrument.symbol') && (p.get('instrument.symbol').indexOf('ZC') > -1 || p.get('instrument.symbol').indexOf('ZM') > -1)) {
          if (p.get('instrument.instrument_type') === 'Future' && p.get('isLong')) {
            p.get('tradePrices').forEach(function (tp) {
              v += tp.price * tp.quantity / p.get('instrument.product.tick_size') * p.get('instrument.product.tick_value');
            });
          }

          if (p.get('instrument.option_type') === 'CALL' && p.get('isLong')) {
            v += p.get('quantity') * p.get('instrument.strike') / p.get('instrument.product.tick_size') * p.get('instrument.product.tick_value');
          }

          if (p.get('instrument.option_type') === 'PUT' || p.get('instrument.option_type') === 'CALL') {
            p.get('tradePrices').forEach(function (tp) {
              v -= tp.price * tp.quantity / p.get('instrument.product.tick_size') * p.get('instrument.product.tick_value');
            });
          }
        }
      });
      return v;
    }.property('physicalPositions.@each', 'positions.@each'),
    feedPercentageHedged: function () {
      if (this.totalFeedCosts > 0) {
        return this.feedTotalHedgedPrice / this.totalFeedCosts;
      } else {
        return 0;
      }
    }.property('totalFeedCosts', 'feedTotalHedgedPrice'),
    feedPercentageUnhedged: function () {
      if (this.feedPercentageHedged > 1) {
        return 0;
      } else {
        return 1 - this.feedPercentageHedged;
      }
    }.property('feedPercentageHedged'),
    exchangeFeedPL: function () {
      return this.cornFuturePL + this.cornOptionPL + this.soybeanMealFuturePL + this.soybeanMealOptionPL;
    }.property('cornOptionPremium', 'cornFuturePL', 'cornOptionPL', 'soybeanMealOptionPremium', 'soybeanMealFuturePL', 'soybeanMealOptionPL'),
    exchangeFeedPLPerCWT: function () {
      if (this.totalProductionCWT > 0) {
        return this.exchangeFeedPL / this.totalProductionCWT;
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'exchangeFeedPL'),
    scenarioExchangeFeedPL: function () {
      // Option Premium doesn't change
      return this.scenarioCornFuturePL + this.scenarioCornOptionPL + this.scenarioSoybeanMealFuturePL + this.scenarioSoybeanMealOptionPL;
    }.property('cornOptionPremium', 'scenarioCornFuturePL', 'scenarioCornOptionPL', 'soybeanMealOptionPremium', 'scenarioSoybeanMealFuturePL', 'scenarioSoybeanMealOptionPL'),
    offExchangeFeedPL: function () {
      return this.offExchangeCornPL + this.offExchangeSoybeanMealPL;
    }.property('offExchangeCornPL', 'offExchangeSoybeanMealPL'),
    offExchangeFeedPLPerCWT: function () {
      if (this.totalProductionCWT > 0) {
        return this.offExchangeFeedPL / this.totalProductionCWT;
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'offExchangeFeedPL'),
    scenarioOffExchangeFeedPL: function () {
      return this.scenarioOffExchangeCornPL + this.scenarioOffExchangeSoybeanMealPL;
    }.property('scenarioOffExchangeCornPL', 'scenarioOffExchangeSoybeanMealPL'),
    totalFeedHedgePL: function () {
      return this.offExchangeFeedPL + this.exchangeFeedPL;
    }.property('offExchangeFeedPL', 'exchangeFeedPL'),
    scenarioTotalFeedHedgePL: function () {
      return this.scenarioOffExchangeFeedPL + this.scenarioExchangeFeedPL;
    }.property('scenarioOffExchangeFeedPL', 'scenarioExchangeFeedPL'),
    totalDairyCosts: function () {
      return this.cowCategories.reduce(function (previousValue, item) {
        return previousValue + item.get('totalFeedCosts');
      }, 0) - this.totalFeedHedgePL;
    }.property('totalFeedHedgePL', 'cowCategories.@each.totalFeedCosts'),
    totalDairyCostsPerCWT: function () {
      if (this.totalProductionCWT > 0) {
        return this.totalDairyCosts / this.totalProductionCWT;
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'totalDairyCosts'),
    scenarioTotalDairyCosts: function () {
      return this.scenarioTotalFeedCosts - this.scenarioTotalFeedHedgePL;
    }.property('scenarioTotalFeedCosts', 'scenarioTotalFeedHedgePL'),
    scenarioTotalDairyCostsPerCWT: function () {
      if (this.totalProductionCWT > 0) {
        return this.scenarioTotalDairyCosts / this.totalProductionCWT;
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'scenarioTotalDairyCosts'),
    totalOtherCostsPerCWT: function () {
      if (this.totalProductionCWT > 0) {
        return this.totalOtherCosts / this.totalProductionCWT;
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'totalOtherCosts'),
    //
    // Net Income
    //
    totalRevenue: function () {
      return this.totalMilkCheck + this.totalOtherRevenue;
    }.property('totalMilkCheck', 'totalOtherRevenue'),
    totalRevenuePerCWT: function () {
      return this.milkCheckCWT + this.totalOtherRevenuePerCWT;
    }.property('milkCheckCWT', 'totalOtherRevenuePerCWT'),
    totalCostOfProduction: function () {
      return this.totalDairyCosts + this.totalOtherCosts;
    }.property('totalOtherCosts', 'totalDairyCosts'),
    totalCostOfProductionPerCWT: function () {
      return this.totalDairyCostsPerCWT + this.totalOtherCostsPerCWT;
    }.property('totalDairyCostsPerCWT', 'totalOtherCostsPerCWT'),
    unhedgingNetIncome: function () {
      return this.totalRevenue - this.totalCostOfProduction;
    }.property('totalRevenue', 'totalCostOfProduction'),
    scenarioNetIncome: function () {
      var netIncome = 0;
      netIncome += this.totalOtherRevenue;
      netIncome -= this.totalOtherCosts;
      netIncome += this.scenarioTotalDairyRevenue;
      netIncome -= this.scenarioTotalDairyCosts;
      return netIncome;
    }.property('totalOtherRevenue', 'totalOtherCosts', 'scenarioTotalDairyRevenue', 'scenarioTotalDairyCosts'),
    scenarioNetIncomePerCWT: function () {
      if (this.totalProductionCWT > 0) {
        return this.scenarioNetIncome / this.totalProductionCWT;
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'scenarioNetIncome'),
    scenarioUnhedgedNetIncome: function () {
      return this.totalOtherRevenue - this.totalOtherCosts + this.scenarioTotalMilkCheck - this.scenarioTotalFeedCosts;
    }.property('totalOtherRevenue', 'totalOtherCosts', 'scenarioTotalMilkCheck', 'scenarioTotalFeedCosts'),
    netIncome: function () {
      if (this.totalOtherRevenue !== undefined && this.totalOtherCosts !== undefined && this.totalFeedCosts !== undefined) {
        return this.totalOtherRevenue - this.totalOtherCosts - this.totalDairyCosts + this.totalDairyRevenue;
      }
    }.property('totalOtherCosts', 'totalOtherRevenue', 'totalDairyCosts', 'totalDairyRevenue'),
    netIncomePerCWT: function () {
      if (this.totalProductionCWT > 0) {
        return this.netIncome / this.totalProductionCWT;
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'netIncome'),
    shouldHedge: function () {
      return this.netIncome > this.marginObjective;
    }.property('netIncome', 'marginObjective'),
    shouldUnhedge: function () {
      return this.unhedgingNetIncome > this.marginObjective;
    }.property('unhedgingNetIncome', 'marginObjective'),
    marginObjectivePerCWT: function () {
      if (this.totalProductionCWT > 0) {
        return this.marginObjective / this.totalProductionCWT;
      } else {
        return 0;
      }
    }.property('totalProductionCWT', 'marginObjective'),
    cashSettledButterCashValueHedged: function () {
      var totalCashSettledButterPoundsHedged = this.cashSettledButterPoundsHedged + this.cashSettledButterPoundsFromClassFourHedged;
      return totalCashSettledButterPoundsHedged * (this.cashSettledButterBestPriceFormatted / 100 - 0.1715);
    }.property('cashSettledButterPoundsHedged', 'cashSettledButterPoundsFromClassFourHedged', 'cashSettledButterBestPriceFormatted'),
    cashSettledButterPercentageHedged: function () {
      var cashSettledButterProduced = this.classesTwoAndFourProduction * 0.042385;
      var cashSettledButterCashValueProduced = cashSettledButterProduced * (this.cashSettledButterBestPriceFormatted - 0.1715);
      return this.cashSettledButterCashValueHedged / cashSettledButterCashValueProduced || 0;
    }.property('cashSettledButterCashValueHedged', 'classesTwoAndFourProduction', 'cashSettledButterBestPriceFormatted'),
    cashSettledButterPoundsHedged: function () {
      return this.getPoundsHedged("CB");
    }.property('physicalPositions.@each.quantity', 'positions.@each.quantity'),
    cashSettledButterOptionPremium: function () {
      return this.getPositionsValue("CB", "Option", 'premiumInDollars');
    }.property('positions.@each.premiumInDollars'),
    cashSettledButterFuturePL: function () {
      return this.getPositionsValue("CB", "Future", 'plInDollars');
    }.property('positions.@each.plInDollars'),
    cashSettledButterOptionPL: function () {
      return this.getPositionsValue("CB", "Option", 'plInDollars');
    }.property('positions.@each.plInDollars'),
    offExchangeCashSettledButterPL: function () {
      return this.getOffExchangePL("CB", this.cashSettledButterBestPriceFormatted);
    }.property('physicalPositions.@each', 'cashSettledButterBestPrice', 'cashSettledButterInstrument.product.tick_size'),
    cashSettledCheeseCashValueHedged: function () {
      var totalCashSettledCheesePoundsHedged = this.cashSettledCheesePoundsHedged + this.cheesePoundsFromClassThreeHedged;
      return totalCashSettledCheesePoundsHedged * (this.cashSettledCheeseBestPriceFormatted - 0.2003);
    }.property('cashSettledCheeseBestPriceFormatted', 'cashSettledCheesePoundsHedged', 'cheesePoundsFromClassThreeHedged'),
    cashSettledCheesePercentageHedged: function () {
      var cashSettledCheeseProduced = this.classThreeProduction * 0.09639331;
      var cashSettledCheeseCashValueProduced = cashSettledCheeseProduced * (this.cashSettledCheeseBestPriceFormatted - 0.2003);
      return this.cashSettledCheeseCashValueHedged / cashSettledCheeseCashValueProduced;
    }.property('cashSettledCheeseCashValueHedged', 'classThreeProduction', 'cashSettledCheeseBestPriceFormatted'),
    cashSettledCheeseContractsHedged: function () {
      return this.cashSettledCheesePoundsHedged / 20000;
    }.property('cashSettledCheesePoundsHedged'),
    cashSettledCheesePoundsHedged: function () {
      return this.getPoundsHedged("CSC");
    }.property('physicalPositions.@each.quantity'),
    cashSettledCheeseOptionPremium: function () {
      return this.getPositionsValue("CSC", "Option", 'premiumInDollars');
    }.property('positions.@each.premiumInDollars'),
    cashSettledCheeseFuturePL: function () {
      return this.getPositionsValue("CSC", "Future", 'plInDollars');
    }.property('positions.@each.plInDollars'),
    cashSettledCheeseOptionPL: function () {
      return this.getPositionsValue("CSC", "Option", 'plInDollars');
    }.property('positions.@each.plInDollars'),
    offExchangeCashSettledCheesePL: function () {
      return this.getOffExchangePL("CSC", this.cashSettledCheeseBestPriceFormatted);
    }.property('physicalPositions.@each', 'cashSettledCheeseBestPrice', 'cashSettledCheeseInstrument.product.tick_size'),
    cashSettledCheeseNetPL: function () {
      return this.offExchangeCashSettledCheesePL + this.cashSettledCheeseFuturePL + this.cashSettledCheeseOptionPL;
    }.property('offExchangeCashSettledCheesePL', 'cashSettledCheeseFuturePL', 'cashSettledCheeseOptionPL')
  });

  _exports.default = _default;
});