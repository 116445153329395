define("rd-basic-client/utils/vela-product-group-to-symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = velaProductGroupToSymbol;

  function velaProductGroupToSymbol(productGroupId) {
    switch (productGroupId) {
      case 32:
        return 'GDK';

      case 28:
        return 'DC';

      case 37:
        return 'CSC';

      case 31:
        return 'ZC';

      case 38:
        return 'ZM';

      case 34:
        return 'CB';

      case 35:
        return 'ZS';

      case 42:
        return 'LE';

      case 30:
        return 'GNF';

      case 33:
        return 'DY';

      default:
        return '?';
    }
  }
});