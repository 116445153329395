define("rd-basic-client/templates/partials/application/yearselector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VRSL2bIt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"btn-group btn-group-sm\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"previousYear\"]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"aria-hidden\",\"true\"],[10,\"class\",\"fa fa-chevron-left\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-default\"],[10,\"type\",\"button\"],[8],[1,[22,\"currentYear\"],false],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"nextYear\"]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"aria-hidden\",\"true\"],[10,\"class\",\"fa fa-chevron-right\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/partials/application/yearselector.hbs"
    }
  });

  _exports.default = _default;
});