define("rd-basic-client/routes/feedmonthlycosts", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "rd-basic-client/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var self = this;
      return Ember.RSVP.hash({
        month: self.store.filter("month", function (m) {
          return m.get("id") === params.month_id;
        }),
        months: self.store.find("month"),
        sbmFutures: self.store.filter("instrument", function (instrument) {
          return instrument.get("instrument_type") === "Future" && instrument.get("product.id") === _environment.default.APP.ZM_Futures;
        }),
        cornFutures: self.store.filter("instrument", function (instrument) {
          return instrument.get("instrument_type") === "Future" && instrument.get("product.id") === _environment.default.APP.ZC_Futures;
        }),
        soybeanFutures: self.store.filter("instrument", function (instrument) {
          return instrument.get("instrument_type") === "Future" && instrument.get("product.id") === _environment.default.APP.ZS_Futures;
        }),
        wheatFutures: self.store.filter("instrument", function (instrument) {
          return instrument.get("instrument_type") === "Future" && instrument.get("product.id") === _environment.default.APP.ZW_Futures;
        }),
        feedTypes: self.store.find("feedType")
      });
    }
  });

  _exports.default = _default;
});