define("rd-basic-client/controllers/marginmatrix", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set("showTwoYears", false);
      this.set("showFiveYears", true);
      this.set("showTenYears", false);
    },
    marketDataInstruments: function () {
      var arr = [];
      arr.pushObject(this.get("model.classThreeFuture"));
      arr.pushObject(this.get("model.classFourFuture"));
      arr.pushObject(this.get("model.dryWheyFuture"));
      arr.pushObject(this.get("model.cashSettledCheeseFuture"));
      arr.pushObject(this.get("model.nonfatDryMilkFuture"));
      arr.pushObject(this.get("model.cashSettledButterFuture"));
      arr.pushObject(this.get("model.cornFuture"));
      arr.pushObject(this.get("model.soybeanMealFuture"));
      arr.pushObject(this.get("model.wheatFuture"));
      arr.pushObject(this.get("model.soybeanFuture"));
      return arr;
    }.property(),
    currentMonths: function () {
      var afterDate = _moment.default.utc().date(1).subtract(1, "days");

      var beforeDate = _moment.default.utc(afterDate).add(24, "months");

      if ((0, _moment.default)().utc().date() < 8) {
        afterDate.subtract(1, "months");
      }

      var current = this.get("model.months").filter(function (record) {
        if (record.get("startDate") === undefined) {
          return false;
        }

        var v = _moment.default.utc(record.get('startDate')).isAfter(afterDate) && _moment.default.utc(record.get('startDate')).isBefore(beforeDate);

        return v;
      });
      return current.sortBy("startDate");
    }.property("model.months.@each.startDate"),
    marginMonths: function () {
      return this.get("currentMonths").slice(0, 24);
    }.property("currentMonths.@each"),
    filteredHistoricalPrices: function () {
      var years = 2;

      if (this.get("showFiveYears")) {
        years = 5;
      }

      if (this.get("showTenYears")) {
        years = 10;
      }

      return this.get("model.historicalPrices").filter(function (price) {
        var p = price.get("month");

        if (p === undefined) {
          return false;
        }

        return (0, _moment.default)(p).isAfter((0, _moment.default)().subtract(years, 'years'));
      });
    }.property("model.historicalPrices", "showTwoYears", "showFiveYears", "showTenYears"),
    historicalPriceClassThreeMargin: function () {
      return this.get("filteredHistoricalPrices").sortBy("classThreeMargin").mapBy("classThreeMargin");
    }.property("filteredHistoricalPrices.@each"),
    historicalPriceClassFourMargin: function () {
      return this.get("filteredHistoricalPrices").sortBy("classFourMargin").mapBy("classFourMargin");
    }.property("filteredHistoricalPrices.@each"),
    historicalPriceClassFour: function () {
      return this.get("filteredHistoricalPrices").sortBy("classIv").mapBy("classIv");
    }.property("filteredHistoricalPrices.@each"),
    historicalPriceClassThree: function () {
      return this.get("filteredHistoricalPrices").sortBy("classIii").mapBy("classIii");
    }.property("filteredHistoricalPrices.@each"),
    historicalPriceCorn: function () {
      var v = this.get("filteredHistoricalPrices").sortBy("corn").mapBy("corn");
      return v;
    }.property("filteredHistoricalPrices.@each"),
    historicalPriceSoybeanMeal: function () {
      return this.get("filteredHistoricalPrices").sortBy("soybeanMeal").mapBy("soybeanMeal");
    }.property("filteredHistoricalPrices.@each"),
    actions: {
      setTwoYears: function setTwoYears() {
        this.set("showTwoYears", true);
        this.set("showFiveYears", false);
        this.set("showTenYears", false);
      },
      setFiveYears: function setFiveYears() {
        this.set("showTwoYears", false);
        this.set("showFiveYears", true);
        this.set("showTenYears", false);
      },
      setTenYears: function setTenYears() {
        this.set("showTwoYears", false);
        this.set("showFiveYears", false);
        this.set("showTenYears", true);
      }
    }
  });

  _exports.default = _default;
});