define("rd-basic-client/templates/components/icon-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R1FFmTJ6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-info-circle info-fade hidden-xs\"],[10,\"aria-hidden\",\"true\"],[11,\"title\",[22,\"tooltipBody\"]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/components/icon-tooltip.hbs"
    }
  });

  _exports.default = _default;
});