define("rd-basic-client/routes/categoryfeedcosts", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "moment"], function (_exports, _authenticatedRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var self = this;
      var now = (0, _moment.default)();
      var instruments = this.store.peekAll('vault-instrument');
      return Ember.RSVP.hash({
        months: this.store.findAll("month"),
        category: this.store.findAll('cow-category'),
        sbmFutures: instruments.filter(function (i) {
          return i.name.includes("Soybean Meal") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt'),
        cornFutures: instruments.filter(function (i) {
          return i.name.includes("Corn") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt'),
        soybeanFutures: instruments.filter(function (i) {
          return i.name.includes("Soybeans") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt'),
        wheatFutures: instruments.filter(function (i) {
          return i.name.includes("Chicago Soft Red Winter Wheat") && i.productType == 'Future' && now.isBefore(i.expiresAt);
        }).sortBy('displayExpiresAt'),
        category_id: params.category_id,
        feed: self.store.findAll('feed'),
        feedTypes: self.store.findAll('feedType'),
        ingredients: self.store.findAll('ingredient')
      });
    },
    afterModel: function afterModel(model) {
      model.category = model.category.filter(function (m) {
        return m.get('id') === model.category_id;
      });
      var months = model.months;
      var currentYear = (0, _moment.default)().year();

      var afterDate = _moment.default.utc().year(currentYear).date(1).subtract(1, "days");

      if ((0, _moment.default)().utc().year(currentYear).date() < 8) {
        afterDate.subtract(1, "months");
      }

      var beforeDate = _moment.default.utc(afterDate).add(2, "years");

      var current = months.filter(function (record) {
        if (record.get("startDate") === undefined) {
          return false;
        }

        var v = _moment.default.utc(record.get('startDate')).isAfter(afterDate) && _moment.default.utc(record.get('startDate')).isBefore(beforeDate);

        return v;
      });
      model.selectableMonths = current.sortBy("startDate").map(function (m) {
        return Ember.Object.create({
          month: m,
          checked: false
        });
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});