define("rd-basic-client/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "rd-basic-client/config/environment", "moment", "rd-basic-client/graphql/queries/product-groups", "@sentry/browser"], function (_exports, _applicationRouteMixin, _environment, _moment, _productGroups, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    apollo: Ember.inject.service(),
    model: function model() {
      var currentMonth = _moment.default.utc();

      if (currentMonth.date() < _environment.default.APP.CONSTANTS.MONTH_DELAY_OFFSET) {
        currentMonth.subtract(1, 'month');
      }

      var lastMonth = (0, _moment.default)().add(24, "months");
      var preloadYears = 3;

      var afterDate = _moment.default.utc(afterDate).subtract(preloadYears, 'years');

      if (this.get("session").isAuthenticated) {
        var _this$get = this.get('session.data.authenticated'),
            access_token = _this$get.access_token; // eslint-disable-next-line no-undef


        var token = jwt_decode(access_token);
        Sentry.configureScope(function (scope) {
          scope.setUser({
            id: token.sub
          });
        });
        return Ember.RSVP.hash({
          version: _environment.default.APP.VERSION,
          months: this.get('store').query('month', {
            first_month: afterDate.format('YYYY01'),
            last_month: lastMonth.format('YYYYMM')
          }),
          reports: this.get('store').findAll('report'),
          feed: this.get('store').findAll('feed'),
          scenarioPrices: this.store.findAll('scenario-datum'),
          users: this.get('store').findAll("user"),
          productGroups: this.apollo.query({
            query: _productGroups.default
          })
        });
      } else {
        return Ember.RSVP.hash({
          version: _environment.default.APP.VERSION
        });
      }
    },
    findMonthInstrument: function findMonthInstrument(futures, monthCode) {
      return futures.find(function (instrument) {
        var expiration = instrument.displayExpiresAt;
        return expiration && _moment.default.utc(expiration).format('YYYYMM') === monthCode;
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      if (model.productGroups) {
        var productGroups = [];
        var products = [];
        var instruments = [];
        var mostCurrentFutures = [];
        var currentFutures = {};
        var milkCutoffDate = (0, _moment.default)().subtract(15, 'days');
        model.productGroups.ProductGroups.forEach(function (productGroup) {
          productGroups.push({
            id: productGroup.id,
            description: productGroup.description
          });
          productGroup.Products.forEach(function (product) {
            products.push({
              id: product.id,
              name: product.name,
              slug: product.slug,
              productGroup: productGroup.id
            });
            var sortedCurrentFutures = product.currentFutures.sortBy('expiresAt');
            var currentFuture = sortedCurrentFutures.find(function (f) {
              if (product.slug == 'us-dairy-class-iii' || product.slug == "us-dairy-class-iv" || product.slug == "us-dairy-nonfat-milk" || product.slug == "us-dairy-dry-whey" || product.slug == "us-dairy-cheese" || product.slug == "us-dairy-butter") {
                return (0, _moment.default)(f.displayExpiresAt).isAfter(milkCutoffDate);
              }

              return true;
            });

            if (currentFuture) {
              mostCurrentFutures.push({
                productName: product.name,
                slug: product.slug,
                instrument_id: currentFuture.id
              });
            }

            currentFutures[product.slug] = product.currentFutures;
            product.currentFutures.forEach(function (future) {
              instruments.push({
                id: future.id,
                name: future.name,
                barchartSymbol: future.barchartSymbol,
                expiresAt: future.expiresAt,
                displayExpiresAt: future.displayExpiresAt,
                exchangeSymbol: future.exchangeSymbol,
                product: product.id,
                scenario: future.id,
                productType: future.productType,
                instrumentType: future.instrumentType
              });
            });

            if (product.MostRecentlyExpiredFuture) {
              currentFutures[product.slug].pushObject(product.MostRecentlyExpiredFuture);
              instruments.push({
                id: product.MostRecentlyExpiredFuture.id,
                name: product.MostRecentlyExpiredFuture.name,
                barchartSymbol: product.MostRecentlyExpiredFuture.barchartSymbol,
                expiresAt: product.MostRecentlyExpiredFuture.expiresAt,
                displayExpiresAt: product.MostRecentlyExpiredFuture.displayExpiresAt,
                exchangeSymbol: product.MostRecentlyExpiredFuture.exchangeSymbol,
                product: product.id,
                scenario: product.MostRecentlyExpiredFuture.id,
                productType: product.MostRecentlyExpiredFuture.productType,
                instrumentType: product.MostRecentlyExpiredFuture.instrumentType
              });
            }
          });
        });
        var classThreeProduct = mostCurrentFutures.find(function (product) {
          return product.slug == 'us-dairy-class-iii';
        });
        model.classThreeFutureId = classThreeProduct.instrument_id;
        var classFourProduct = mostCurrentFutures.find(function (product) {
          return product.slug == "us-dairy-class-iv";
        });
        model.classFourFutureId = classFourProduct.instrument_id;
        var nonfatDryMilkProduct = mostCurrentFutures.find(function (product) {
          return product.slug == "us-dairy-nonfat-milk";
        });
        model.nonfatDryMilkFutureId = nonfatDryMilkProduct.instrument_id;
        var dryWheyProduct = mostCurrentFutures.find(function (product) {
          return product.slug == "us-dairy-dry-whey";
        });
        model.dryWheyFutureId = dryWheyProduct.instrument_id;
        var cashSettledCheeseProduct = mostCurrentFutures.find(function (product) {
          return product.slug == "us-dairy-cheese";
        });
        model.cashSettledCheeseFutureId = cashSettledCheeseProduct.instrument_id;
        var cashSettledButterProduct = mostCurrentFutures.find(function (product) {
          return product.slug == "us-dairy-butter";
        });
        model.cashSettledButterFutureId = cashSettledButterProduct.instrument_id;
        var cornProduct = mostCurrentFutures.find(function (product) {
          return product.slug == "grain-corn";
        });
        model.cornFutureId = cornProduct.instrument_id;
        var soybeanMealProduct = mostCurrentFutures.find(function (product) {
          return product.slug == "grain-soybean-meal";
        });
        model.soybeanMealFutureId = soybeanMealProduct.instrument_id;
        var soybeansProduct = mostCurrentFutures.find(function (product) {
          return product.slug == "grain-soybeans";
        });
        model.soybeansFutureId = soybeansProduct.instrument_id;
        var wheatProduct = mostCurrentFutures.find(function (product) {
          return product.slug == "grain-chicago-soft-red-winter-wheat";
        });
        model.wheatFutureId = wheatProduct.instrument_id;
        this.store.pushPayload({
          'vault-product-group': productGroups,
          'vault-product': products,
          'vault-instruments': instruments
        });

        if (model.months && model.months.length != 0) {
          var instrument;
          model.months.forEach(function (m) {
            var monthCode = m.get('monthCode');
            instrument = _this.findMonthInstrument(currentFutures['us-dairy-class-iii'], monthCode);

            if (instrument) {
              m.set('classThreeMilkInstrument', _this.store.peekRecord('vault-instrument', instrument.id));
            }

            instrument = _this.findMonthInstrument(currentFutures["us-dairy-class-iv"], monthCode);

            if (instrument) {
              m.set('classFourMilkInstrument', _this.store.peekRecord('vault-instrument', instrument.id));
            }

            instrument = _this.findMonthInstrument(currentFutures["us-dairy-nonfat-milk"], monthCode);

            if (instrument) {
              m.set('nonfatDryMilkInstrument', _this.store.peekRecord('vault-instrument', instrument.id));
            }

            instrument = _this.findMonthInstrument(currentFutures["us-dairy-dry-whey"], monthCode);

            if (instrument) {
              m.set('dryWheyInstrument', _this.store.peekRecord('vault-instrument', instrument.id));
            }

            instrument = _this.findMonthInstrument(currentFutures["us-dairy-cheese"], monthCode);

            if (instrument) {
              m.set('cashSettledCheeseInstrument', _this.store.peekRecord('vault-instrument', instrument.id));
            }

            instrument = _this.findMonthInstrument(currentFutures["us-dairy-butter"], monthCode);

            if (instrument) {
              m.set('cashSettledButterInstrument', _this.store.peekRecord('vault-instrument', instrument.id));
            }

            instrument = _this.findMonthInstrument(currentFutures["grain-corn"], monthCode);

            if (instrument) {
              m.set('cornInstrument', _this.store.peekRecord('vault-instrument', instrument.id));
            }

            instrument = _this.findMonthInstrument(currentFutures["grain-soybean-meal"], monthCode);

            if (instrument) {
              m.set('soybeanMealInstrument', _this.store.peekRecord('vault-instrument', instrument.id));
            }

            instrument = _this.findMonthInstrument(currentFutures["grain-soybeans"], monthCode);

            if (instrument) {
              m.set('soybeanInstrument', _this.store.peekRecord('vault-instrument', instrument.id));
            }

            instrument = _this.findMonthInstrument(currentFutures["grain-chicago-soft-red-winter-wheat"], monthCode);

            if (instrument) {
              m.set('wheatInstrument', _this.store.peekRecord('vault-instrument', instrument.id));
            }
          });
        }
      }
    },
    actions: {
      trackPrintClick: function trackPrintClick() {
        window.print();
      },
      sessionAuthenticationFailed: function sessionAuthenticationFailed(error) {
        this.controllerFor('login').set('errorMessage', error);
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      //
      // This hack is only required until we have proper OAuth2 working.
      //
      sessionChanged: function sessionChanged() {
        location.replace('/');
      }
    }
  });

  _exports.default = _default;
});