define("rd-basic-client/utils/display-to-barchart-price", ["exports", "rd-basic-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = displayToBarchartPrice;

  function displayToBarchartPrice(rootSymbol, price) {
    if (!rootSymbol || !price) {
      return price;
    }

    var priceFormat = _environment.default.APP.PRICE_FORMAT[rootSymbol];
    return price / priceFormat.displayFactor;
  }
});