define("rd-basic-client/components/rd-contenteditable-div", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //https://emberway.io/ember-js-and-html5-drag-and-drop-fa5dfe478a9a#.xjzvnrw9q
    tagName: 'div',
    attributeBindings: ['contenteditable', 'draggable', 'style'],
    // Variables:
    editable: false,
    isUserTyping: false,
    plaintext: false,
    contenteditable: 'true',
    draggable: 'true',
    style: 'width: 100%; height: 100%; display: table-cell; vertical-align:middle;',
    object: null,
    format: null,
    displayFactor: 1,
    // Observers:
    valueObserver: function () {
      if (!this.get('isUserTyping') && this.get('value') !== undefined) {
        return this.setFormattedContent();
      }
    }.observes('value'),
    // Events:
    didInsertElement: function didInsertElement() {
      return this.setFormattedContent();
    },
    updateObjectValue: function updateObjectValue(value) {
      this.get('object').set(this.get('valueAttrName'), value);
    },
    saveObject: function saveObject() {
      var object = this.get('object');

      if (object) {
        if (this.get('persist')) {
          object.get(this.get('persist')).bind(object)();
        } else {
          object.save();
        }
      }
    },
    focusIn: function focusIn() {
      var v = this.get('value');

      if (this.get('plaintext') || Ember.isNone(v)) {
        this.$().text(v);
      } else {
        // If too many significant digits, round
        if (v.toString().replace('.', '').length > 15) {
          v = v.toPrecision(15);
        }

        var value = new BigNumber(v).times(this.get('displayFactor'));

        if (this.get('format') && this.get('format').indexOf('%') > -1) {
          value = value.times(100);
        }

        return this.$().text(value.toNumber());
      }
    },
    focusOut: function focusOut() {
      this.saveObject();
      this.setFormattedContent();
      return this.set('isUserTyping', false);
    },
    keyDown: function keyDown(event) {
      if (event.keyCode === 13) {
        //ENTER KEY IS PRESSED
        Ember.$(event.currentTarget).blur(); //Try to go to the next row, same col

        var idx = Ember.$(event.currentTarget).closest('td').index() + 1;
        Ember.$(event.currentTarget).closest('tr').next().find('td:nth-child(' + idx + ') .ember-view').focus();
        return false;
      }

      if (!event.metaKey) {
        return this.set('isUserTyping', true);
      }
    },
    keyUp: function keyUp(event) {
      var inputValue = this.$().text();
      var value = 0;

      if (event.keyCode === 13 || //ENTER KEY
      event.keyCode === 39 || //LEFT ARROW
      event.keyCode === 38 || //RIGHT ARROW
      event.keyCode === 37 || //UP ARROW
      event.keyCode === 40) {
        //DOWN ARROW
        //do nothing
        return;
      }

      if (!this.get('plaintext')) {
        if (inputValue === '' || inputValue === null) {
          inputValue = '0';
        }

        inputValue = inputValue.replace(/[^\d.-]/g, '');
        inputValue = this.tryToRound(inputValue);
        value = new BigNumber(inputValue);

        if (this.isPercentValueFormatting()) {
          value = value.dividedBy(100);
        }

        value = value.dividedBy(this.get('displayFactor')).toNumber();
      } else {
        value = inputValue === '' ? ' ' : inputValue;
      }

      return this.updateObjectValue(value);
    },
    tryToRound: function tryToRound(value) {
      return (0, _numeral.default)(value).format('0.[00000000]');
    },
    isPercentValueFormatting: function isPercentValueFormatting() {
      return this.get('format') && this.get('format').indexOf('%') >= 0;
    },
    //render our own html so there are no metamorphs to get screwed up when the user changes the html
    render: function render(buffer) {
      var v = this.get('value');

      if (!v) {
        return;
      }

      if (this.get('plaintext') || Ember.isNone(v)) {
        buffer.push(v);
      } else {
        // If too many significant digits, round
        if (v.toString().replace('.', '').length > 15) {
          v = v.toPrecision(15);
        }

        buffer.push(new BigNumber(v).times(this.get('displayFactor')).toNumber());
      }
    },
    setFormattedContent: function setFormattedContent() {
      var v = this.get('value');

      if (this.get('plaintext') || Ember.isNone(v)) {
        return this.$().text(v);
      } else {
        // If too many significant digits, round
        if (v.toString().replace('.', '').length > 15) {
          v = v.toPrecision(15);
        }

        var value = new BigNumber(v).times(this.get('displayFactor'));

        if (this.get('format')) {
          return this.$().text((0, _numeral.default)(value.toNumber()).format(this.get('format')));
        } else {
          return this.$().text(value.toNumber());
        }
      }
    },
    // Getting Fancy now.
    _getColClass: function _getColClass(target) {
      var td = Ember.$(target).closest('td');

      if (td && td.attr('class')) {
        var classes = td.attr('class').split(' ');

        for (var i = 0; i < classes.length; i++) {
          if (classes[i].match(/col-\d*$/)) {
            return classes[i];
          }
        }
      }
    },
    dragStart: function dragStart(e) {
      var colClass = this._getColClass(Ember.$(e.target));

      if (colClass) {
        localStorage.setItem('colClass', colClass);
      } else {
        localStorage.removeItem('colClass');
      }

      localStorage.setItem('poorSpec', this.get('value'));
      localStorage.setItem('originId', this.get('object').get('id'));
      e.dataTransfer.effectAllowed = 'none';
      e.dataTransfer.setData('text/plain', this.get('value'));
    },
    dragEnter: function dragEnter(e) {
      var object = this.get('object');

      if (object.get('id') === localStorage.getItem('originId')) {
        return;
      }

      if (localStorage.getItem('colClass') && localStorage.getItem('colClass') !== this._getColClass(Ember.$(e.target))) {
        e.preventDefault();
        return false;
      }

      if (this.get('plainText')) {
        this.updateObjectValue(localStorage.getItem('poorSpec'));
      } else {
        this.updateObjectValue(parseFloat(localStorage.getItem('poorSpec')));
      }

      this.saveObject();
    },
    dragDrop: function dragDrop() {
      //var object = this.get('object');
      if (this.get('plainText')) {
        this.updateObjectValue(localStorage.getItem('poorSpec'));
      } else {
        this.updateObjectValue(parseFloat(localStorage.getItem('poorSpec')));
      }

      localStorage.removeItem('colClass');
      this.saveObject();
    }
  });

  _exports.default = _default;
});