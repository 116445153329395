define("rd-basic-client/templates/partials/dataimport/wizardpanel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cua8KdX9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[10,\"class\",\"nav nav-wizard\"],[8],[0,\"\\n  \"],[7,\"li\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"firstStep\"]],\"active\"],null]]]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-filter\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Type\"],[9],[0,\"\\n  \"],[7,\"li\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"secondStep\"]],\"active\"],null]]]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-file-text-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" File\"],[9],[0,\"\\n  \"],[7,\"li\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"thirdStep\"]],\"active\"],null]]]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-pencil-square-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Assign\"],[9],[0,\"\\n  \"],[7,\"li\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"fourthStep\"]],\"active\"],null]]]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-check-square-o\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Verify\"],[9],[0,\"\\n  \"],[7,\"li\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"lastStep\"]],\"active\"],null]]]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-check-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Completed\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/partials/dataimport/wizardpanel.hbs"
    }
  });

  _exports.default = _default;
});