define("rd-basic-client/components/vd-droppable-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super(arguments);

      Ember.run.later(this._initFileUploader.bind(this), 1);
    },
    didRender: function didRender() {},
    _initFileUploader: function _initFileUploader() {
      if (!this.get('data-upload-url')) {
        return;
      }

      var self = this;
      Ember.$(this.get('drop-zone-ref')).on('click', function () {
        Ember.$(self.get('file-input-ref'))[0].click();
      });
      Ember.$('#fileupload').fileupload({
        url: this.get('data-upload-url'),
        sequentialUploads: false,
        autoUpload: true,
        acceptFileTypes: /(\.|\/)(csv)$/i,
        dataType: 'json',
        dropZone: Ember.$(this.get('drop-zone-ref')),
        send: self.get('on-upload-start') ? self.get('on-upload-start').bind(this.get('scope')) : null,
        done: self.get('on-upload-success') ? self.get('on-upload-success').bind(this.get('scope')) : null,
        always: self.get('on-upload-done') ? self.get('on-upload-done').bind(this.get('scope')) : null
      });
    }
  });

  _exports.default = _default;
});