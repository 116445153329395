define("rd-basic-client/controllers/feedpositions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var sortFunc = function sortFunc(a, b) {
    if (a.get("instrument_id.displayExpiresAt") > b.get("instrument_id.displayExpiresAt")) {
      return 1;
    } else if (a.get("instrument_id.displayExpiresAt") < b.get("instrument_id.displayExpiresAt")) {
      return -1;
    }

    if (a.get("instrument_id.product_id") > b.get("instrument_id.product_id")) {
      return 1;
    } else if (a.get("instrument_id.product_id") < b.get("instrument_id.product_id")) {
      return -1;
    }

    if (a.get("isLong") && !b.get("isLong")) {
      return -1;
    } else if (!a.get("isLong") && b.get("isLong")) {
      return 1;
    }

    if (a.get("instrument_id.instrument_type") === "Future" && b.get("instrument_id.instrument_type") !== "Future") {
      return 1;
    } else if (a.get("instrument_id.instrument_type") !== "Future" && b.get("instrument_id.instrument_type") === "Future") {
      return -1;
    }

    if (a.get("instrument_id.option_type") === "Put" && b.get("instrument_id.option_type") === "Call") {
      return 1;
    } else if (a.get("instrument_id.option_type") === "Call" && b.get("instrument_id.option_type") === "Put") {
      return -1;
    }

    return 0;
  };

  var _default = Ember.Controller.extend({
    // application: Controller.inject.controller('application'),
    marketDataInstruments: Ember.computed.mapBy('model', 'instrument_id'),
    soybeanPositionsFutures: Ember.computed.filterBy("model", "rootSymbol", "ZS"),
    soybeanPositionsOptions: Ember.computed.filterBy("model", "rootSymbol", "OZS"),
    soybeanMealPositionsFutures: Ember.computed.filterBy("model", "rootSymbol", "ZM"),
    soybeanMealPositionsOptions: Ember.computed.filterBy("model", "rootSymbol", "OZM"),
    soybeanOilPositionsFutures: Ember.computed.filterBy("model", "rootSymbol", "ZL"),
    soybeanOilPositionsOptions: Ember.computed.filterBy("model", "rootSymbol", "OZL"),
    wheatPositionsFutures: Ember.computed.filterBy("model", "rootSymbol", "KE"),
    wheatPositionsOptions: Ember.computed.filterBy("model", "rootSymbol", "OKE"),
    cornPositions: Ember.computed.filterBy("model", "rootSymbol", "ZC"),
    allPositions: Ember.computed.uniq("soybeanPositionsFutures", "soybeanPositionsOptions", "soybeanMealPositionsFutures", "soybeanMealPositionsOptions", "soybeanOilPositionsFutures", "soybeanOilPositionsOptions", "wheatPositionsFutures", "wheatPositionsOptions", "cornPositions"),
    allSortedPositions: Ember.computed.sort("allPositions", sortFunc),
    positionPLs: Ember.computed.mapBy("allPositions", "plInDollars"),
    positionTotalPL: Ember.computed.sum("positionPLs")
  });

  _exports.default = _default;
});