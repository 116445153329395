define("rd-basic-client/models/location", ["exports", "ember-data", "rd-basic-client/mixins/notification"], function (_exports, _emberData, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-function-prototype-extensions */
  var _default = _emberData.default.Model.extend(_notification.default, {
    state: _emberData.default.attr("string"),
    county: _emberData.default.attr("string"),
    city: _emberData.default.attr("string"),
    classOneDifferential: _emberData.default.attr("number"),
    region: Ember.computed.alias('region_id'),
    region_id: _emberData.default.belongsTo("region"),
    text: function () {
      return this.get("city") + " (" + this.get("county") + "), " + this.get("state");
    }.property("state", "county", "city"),
    considerNorthernCalifornia: _emberData.default.attr("boolean")
  });

  _exports.default = _default;
});