define("rd-basic-client/controllers/feedmonthlycosts", ["exports", "rd-basic-client/mixins/editable-months"], function (_exports, _editableMonths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editableMonths.default, {
    init: function init() {
      this._super();

      this.set("updateDailyAmount", 0);
      this.set("updateFeedType", null);
      this.set("updateDailyRatio", false);
      this.set("updateCostPerTon", 0);
      this.set("updateCostType", "Fixed");
      this.set("updateFeedCategory", "Other Feed");
      this.set("updateBasisContract", null);
      this.set("currentFeed", null);
      this.set("updateBasicFeedCost", null);
      this.set("updateBasicFeedInCWT", null);
      this.set("copyMonths", new Ember.Set());
      this.set("feedCategories", ["Energy", "Protein", "Other Feed"]);
      this.set("feedPriceTypes", ["Fixed", "Basis CME (%)", "Basis CME ($)"]);
    },
    feedHedgeOptions: function () {
      var a = [];
      this.get("content.cornFutures.content").forEach(function (f) {
        a.pushObject({
          group: "Corn",
          future: f
        });
      });
      this.get("content.sbmFutures.content").forEach(function (f) {
        a.pushObject({
          group: "Soybean Meal",
          future: f
        });
      });
      return a;
    }.property("cornFutures", "sbmFutures", "soybeanFutures", "wheatFutures"),
    updateFeedTypeIsFixed: function () {
      return this.get("updateCostType") === "Fixed";
    }.property("updateCostType"),
    updateFeedTypeIsDollar: function () {
      return this.get("updateCostType") === "Basis CME ($)";
    }.property("updateCostType"),
    updateFeedTypeIsPercentage: function () {
      return this.get("updateCostType") === "Basis CME (%)";
    }.property("updateCostType"),
    showBasic: function () {
      var m = this.get("content.month.firstObject");
      return m.get("combinedFeedUsage") || m.get("basicFeedUsage");
    }.property("content.month.firstObject.combinedFeedUsage", "content.month.firstObject.basicFeedUsage"),
    showAdvanced: function () {
      var m = this.get("content.month.firstObject");
      return m.get("combinedFeedUsage") || !m.get("basicFeedUsage");
    }.property("content.month.firstObject.combinedFeedUsage", "content.month.firstObject.basicFeedUsage"),
    modelLabel: function () {
      var state = 'Static Feed Cost';

      if (this.get('content.month.firstObject.combinedFeedUsage') && this.get('content.month.firstObject.basicFeedUsage')) {
        state = 'Combined';
      } else if (this.get('content.month.firstObject.combinedFeedUsage')) {
        state = 'Ingredient Feed Cost';
      }

      return state;
    }.property("content.month.firstObject.combinedFeedUsage", "content.month.firstObject.basicFeedUsage"),
    isCombined: function () {
      return this.get('content.month.firstObject.combinedFeedUsage') && this.get('content.month.firstObject.basicFeedUsage');
    }.property("content.month.firstObject.combinedFeedUsage", "content.month.firstObject.basicFeedUsage"),
    ingredientCost: function () {
      return this.get('content.month.firstObject.totalFeedCosts') - this.get('content.month.firstObject.flatFeedCosts');
    }.property("content.month.firstObject.combinedFeedUsage", "content.month.firstObject.basicFeedUsage", 'content.month.firstObject.totalProductionCWT', 'content.month.firstObject.flatFeedCosts', 'content.month.firstObject.totalFeedCosts'),
    actions: {
      toggleBasicFeedCWT: function toggleBasicFeedCWT(flag) {
        this.set("updateBasicFeedCost", null);
        this.set("updateBasicFeedInCWT", flag);
      },
      copyRation: function copyRation() {
        Ember.$(".monthCheckbox").prop('checked', false);
        this.get("copyMonths").clear();
        Ember.$("#copy-modal").modal("show");
      },
      saveCopiedRation: function saveCopiedRation() {
        var self = this;
        var currentMonth = this.get("content.month.firstObject");
        var monthsToCopy = this.get("copyMonths");
        monthsToCopy.removeObject(currentMonth);
        monthsToCopy.forEach(function (newMonth) {
          // Clear current feed needs
          var needs = newMonth.get("feedNeeds").toArray();
          needs.forEach(function (need) {
            need.destroyRecord();
          }); // Set basic feed usage

          newMonth.set("basicFeedUsage", currentMonth.get("basicFeedUsage"));
          newMonth.set("basicFeedCost", currentMonth.get("basicFeedCost"));
          newMonth.set("basicFeedInCwt", currentMonth.get("basicFeedInCwt"));
          newMonth.set("combinedFeedUsage", currentMonth.get("combinedFeedUsage"));
          currentMonth.get("feedNeeds").forEach(function (need) {
            var newFeed = self.store.createRecord('feed', {
              feedCategory: need.get("feedCategory"),
              feedType: need.get("feedType"),
              dailyAmount: need.get("dailyAmount"),
              dailyRatio: need.get("dailyRatio"),
              basisInstrument: need.get("basisInstrument"),
              costType: need.get("costType"),
              month: newMonth,
              priceInTons: need.get("priceInTons")
            });
            newFeed.save().then(function (f) {
              newMonth.get("feedNeeds").addObject(f);
            });
          });
          newMonth.save().then(function () {
            newMonth.reload();
          });
        });
        Ember.$("#copy-modal").modal("hide");
      },
      deleteFeed: function deleteFeed(feed) {
        var m = this.get("content.month.firstObject");
        m.get("feedNeeds").removeObject(feed);
        m.save();
        feed.deleteRecord();
        feed.save();
      },
      addFeed: function addFeed() {
        this.set("updateDailyAmount", 0);
        this.set("updateFeedType", this.get("content.feedTypes").findBy("name", "Unspecified"));
        this.set("updateDailyRatio", false);
        this.set("updateCostPerTon", 0);
        this.set("updateCostType", "Fixed");
        this.set("updateFeedCategory", "Other Feed");
        this.set("currentFeed", null);
        Ember.$('#add-modal').modal('show');
      },
      editFeed: function editFeed(feed) {
        var self = this;
        feed.get("basisInstrument").then(function (feed) {
          self.set("updateBasisContract", feed);
        });
        this.set("updateDailyAmount", feed.get("dailyAmount"));
        this.set("updateFeedType", feed.get("feedType"));
        this.set("updateDailyRatio", feed.get("dailyRatio"));
        this.set("updateCostType", feed.get("costType"));
        this.set("updateFeedCategory", feed.get("feedCategory"));
        this.set("currentFeed", feed);
        this.set("updateBasisContract", feed.get("basisInstrument"));

        if (feed.get("isPercentageBasis")) {
          this.set("updateCostPerTon", feed.get("priceInTons") * 100);
        } else {
          this.set("updateCostPerTon", feed.get("priceInTons"));
        }

        Ember.$('#add-modal').modal('show');
      },
      editBasicFeed: function editBasicFeed() {
        var m = this.get("content.month.firstObject");
        this.set("updateBasicFeedCost", m.get("basicFeedCost"));
        this.set("updateBasicFeedInCWT", m.get("basicFeedInCwt"));
        Ember.$('#basic-modal').modal('show');
      },
      saveBasicFeed: function saveBasicFeed() {
        var m = this.get("content.month.firstObject");
        m.set("basicFeedCost", this.get("updateBasicFeedCost"));
        m.set("basicFeedInCwt", this.get("updateBasicFeedInCWT"));
        m.save();
        Ember.$('#basic-modal').modal('hide');
      },
      saveFeed: function saveFeed() {
        var m = this.get("content.month.firstObject");
        var feed;

        if (this.get("currentFeed") === null) {
          feed = this.store.createRecord('feed', {
            feedCategory: this.get("updateFeedCategory"),
            feedType: this.get("updateFeedType"),
            dailyAmount: this.get("updateDailyAmount"),
            dailyRatio: this.get("updateDailyRatio"),
            basisInstrument: this.get("updateBasisContract"),
            costType: this.get("updateCostType"),
            month: m
          });

          if (this.get("updateFeedTypeIsPercentage")) {
            feed.set("priceInTons", this.get("updateCostPerTon") / 100);
          } else {
            feed.set("priceInTons", this.get("updateCostPerTon"));
          }

          feed.save().then(function (f) {
            m.get("feedNeeds").addObject(f);
            m.save();
          });
        } else {
          feed = this.get("currentFeed");
          feed.set("feedCategory", this.get("updateFeedCategory"));
          feed.set("feedType", this.get("updateFeedType"));
          feed.set("dailyAmount", this.get("updateDailyAmount"));
          feed.set("dailyRatio", this.get("updateDailyRatio"));
          feed.set("basisInstrument", this.get("updateBasisContract"));
          feed.set("costType", this.get("updateCostType"));

          if (this.get("updateFeedTypeIsPercentage")) {
            feed.set("priceInTons", this.get("updateCostPerTon") / 100);
          } else {
            feed.set("priceInTons", this.get("updateCostPerTon"));
          }

          feed.save();
        }

        Ember.$('#add-modal').modal('hide');
      },
      updateDailyRatio: function updateDailyRatio(v) {
        this.set("updateDailyRatio", v);
      },
      updateUsageType: function updateUsageType(t) {
        var m = this.get("content.month.firstObject");
        m.set("detailedFeedUsage", t);
        m.save();
      },
      toggleBasicFeedUsage: function toggleBasicFeedUsage() {
        var m = this.get("content.month.firstObject");
        m.set("basicFeedUsage", !m.get("basicFeedUsage"));

        if (!m.get("basicFeedUsage") && !m.get("combinedFeedUsage")) {
          // this condition will make sure that at least one button is selected
          m.set("combinedFeedUsage", true);
        }

        m.save();
      },
      toggleAdvancedFeedView: function toggleAdvancedFeedView() {
        var m = this.get("content.month.firstObject");
        m.set("combinedFeedUsage", !m.get("combinedFeedUsage"));

        if (!m.get("basicFeedUsage") && !m.get("combinedFeedUsage")) {
          // this condition will make sure that at least one button is selected
          m.set("basicFeedUsage", true);
        }

        m.save();
      }
    }
  });

  _exports.default = _default;
});