define("rd-basic-client/templates/components/rd-display-decimal-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zTEb9H3q",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"decimalPrice\"]]],null,{\"statements\":[[1,[28,\"flash-price\",[[24,[\"decimalPrice\"]]],null],false]],\"parameters\":[]},{\"statements\":[[0,\" - \"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/components/rd-display-decimal-price.hbs"
    }
  });

  _exports.default = _default;
});