define("rd-basic-client/mixins/pricingModel", ["exports", "rd-basic-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    pricingModels: [{
      code: _environment.default.APP.CONSTANTS.PRICING_MODELS.FMMO,
      label: 'FMMO',
      isSelected: false,
      permission: ''
    }, {
      code: _environment.default.APP.CONSTANTS.PRICING_MODELS.CALIFORNIA,
      label: 'California',
      isSelected: false,
      permission: ''
    }, {
      code: _environment.default.APP.CONSTANTS.PRICING_MODELS.GLANBIA,
      label: 'Glanbia',
      isSelected: false,
      permission: 'glanbia'
    }, {
      code: _environment.default.APP.CONSTANTS.PRICING_MODELS.UDA,
      label: 'UDA',
      isSelected: false,
      permission: 'uda'
    }],
    isGlanbia: Ember.computed('model.user.pricingModel', function () {
      return this.get("model.user.pricingModel") === _environment.default.APP.CONSTANTS.PRICING_MODELS.GLANBIA;
    }),
    isCalifornia: Ember.computed('model.user.pricingModel', function () {
      return this.get("model.user.pricingModel") === _environment.default.APP.CONSTANTS.PRICING_MODELS.CALIFORNIA;
    }),
    isFmmo: Ember.computed('model.user.pricingModel', function () {
      return this.get("model.user.pricingModel") === _environment.default.APP.CONSTANTS.PRICING_MODELS.FMMO;
    }),
    isUda: Ember.computed('model.user.pricingModel', function () {
      return this.get("model.user.pricingModel") === _environment.default.APP.CONSTANTS.PRICING_MODELS.UDA;
    }),
    pricingModelName: Ember.computed('model.user.pricingModel', function () {
      for (var i = 0; i < this.pricingModels.length; i++) {
        if (this.pricingModels[i].code === this.get("model.user.pricingModel")) {
          return this.pricingModels[i].label;
        }
      }

      return 'N/A'; // Should NEVER come here
    }),
    currentPricingModel: function (obj, attr) {
      obj.pricingModels.forEach(function (oEl) {
        Ember.set(oEl, 'isSelected', obj.get(attr) === oEl.code);
      });
    }.observes('tmpPricingModel', 'model.user.pricingModel'),
    onContentInit: function () {
      /* Temporary field */
      this.set("tmpPricingModel", this.get("model.user.pricingModel"));
    }.observes('model')
  });

  _exports.default = _default;
});