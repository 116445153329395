define("rd-basic-client/models/vault-instrument", ["exports", "ember-data", "rd-basic-client/utils/vault-display-price", "rd-basic-client/utils/barchart-to-vela-price", "rd-basic-client/utils/vela-to-barchart-price"], function (_exports, _emberData, _vaultDisplayPrice, _barchartToVelaPrice, _velaToBarchartPrice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    name: _emberData.default.attr('string'),
    barchartSymbol: _emberData.default.belongsTo('barchart-market-datum'),
    expiresAt: _emberData.default.attr('date'),
    displayExpiresAt: _emberData.default.attr('date'),
    exchangeSymbol: _emberData.default.attr('string'),
    product: Ember.computed.alias('productId'),
    productId: _emberData.default.belongsTo('vault-product'),
    scenario: _emberData.default.belongsTo("scenario-datum", {
      async: true
    }),
    symbol: Ember.computed.alias('exchangeSymbol'),
    productType: _emberData.default.attr('string'),
    // Future or Option
    instrument_type: Ember.computed.alias('instrumentType'),
    instrumentType: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    option_type: Ember.computed.alias('type'),
    strike: _emberData.default.attr('string'),
    underlyingId: _emberData.default.belongsTo('vault-instrument', {
      inverse: null
    }),
    underlyingInstrument: Ember.computed.alias('underlyingId'),
    displayStrike: Ember.computed('strike', 'rootSymbol', function () {
      return (0, _vaultDisplayPrice.default)(this.strike, this.rootSymbol);
    }),
    // Ideally, this woudl be returned from the server.
    rootSymbol: Ember.computed("exchangeSymbol", function () {
      var possibleSymbols = ['ZW', 'CB', 'GDK', 'ZS', 'CSC', 'LE', 'ZM', 'GNF', 'DC', 'ZC', 'DY'];

      if (!this.exchangeSymbol) {
        return null;
      }

      var firstTwo = this.exchangeSymbol.substring(0, 2);

      if (possibleSymbols.includes(firstTwo)) {
        return firstTwo;
      } else {
        var firstThree = this.exchangeSymbol.substring(0, 3);
        return firstThree;
      }
    }),
    lastPrice: Ember.computed.alias('barchartSymbol.lastPrice'),
    displayLastPrice: Ember.computed('lastPrice', 'rootSymbol', function () {
      return (0, _vaultDisplayPrice.default)(this.lastPrice, this.rootSymbol, 'USD');
    }),
    velaFormattedLastPrice: Ember.computed('lastPrice', 'rootSymbol', function () {
      return (0, _barchartToVelaPrice.default)(this.rootSymbol, this.lastPrice);
    }),
    scenarioPrice: Ember.computed('scenario.price', 'lastPrice', 'rootSymbol', function () {
      // If the scenario isn't set, set it to the current price.
      if (!this.get('scenario.price')) {
        return this.lastPrice;
      } else {
        return (0, _velaToBarchartPrice.default)(this.rootSymbol, this.get('scenario.price'));
      }
    }),
    netChange: Ember.computed('barchartSymbol.netChange', 'rootSymbol', function () {
      return (0, _vaultDisplayPrice.default)(this.get('barchartSymbol.netChange'), this.rootSymbol);
    }),
    percentChange: Ember.computed.alias('barchartSymbol.percentChange')
  });

  _exports.default = _default;
});