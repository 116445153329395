define("rd-basic-client/templates/components/report-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "De99/7nP",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-md-4\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[8],[0,\"Available Columns\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"style\",\"max-height: 515px; overflow-y: scroll;\"],[8],[0,\"\\n      \"],[7,\"ul\",true],[10,\"class\",\"sortable connected list\"],[10,\"id\",\"sortable1\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"availableColumns\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\",true],[11,\"id\",[23,1,[\"id\"]]],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-4\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[8],[0,\"Selected Columns\"],[9],[0,\"\\n      \"],[7,\"ul\",true],[10,\"class\",\"sortable connected list\"],[10,\"id\",\"sortable2\"],[8],[0,\"\\n        \"],[7,\"li\",true],[10,\"class\",\"disabled\"],[8],[0,\"Month\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/components/report-columns.hbs"
    }
  });

  _exports.default = _default;
});