define("rd-basic-client/serializers/historical-price", ["exports", "ember-data", "moment", "rd-basic-client/utils/vela-to-barchart-price"], function (_exports, _emberData, _moment, _velaToBarchartPrice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    serialize: function serialize(record) {
      var json = this._super.apply(this, arguments);

      json.month = (0, _moment.default)(record.get('month')).format('YYYY-MM-DD');
      return json;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var p = payload;
      p["historical_prices"].forEach(function (h) {
        h.class_iv = (0, _velaToBarchartPrice.default)('GDK', h.class_iv);
        h.class_iii = (0, _velaToBarchartPrice.default)('DC', h.class_iii);
        h.soybean_meal = (0, _velaToBarchartPrice.default)('ZM', h.soybean_meal);
        h.nonfat_dry_milk = (0, _velaToBarchartPrice.default)('GNF', h.nonfat_dry_milk);
        h.dry_whey = (0, _velaToBarchartPrice.default)('DY', h.dry_whey);
        h.cash_settled_cheese = (0, _velaToBarchartPrice.default)('CSC', h.cash_settled_cheese);
        h.cash_settled_butter = (0, _velaToBarchartPrice.default)('CB', h.cash_settled_butter);
      });
      return this._super(store, primaryModelClass, p, id, requestType);
    },
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    }
  });

  _exports.default = _default;
});