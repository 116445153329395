define("rd-basic-client/utils/rd-price-for-instrument", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = rdPriceForInstrument;

  /* eslint-disable no-undef */
  function rdPriceForInstrument(price, instrument) {
    if (instrument !== undefined && price !== undefined && instrument !== null && price !== null && instrument.get("product.tick_size") !== undefined && instrument.get("product.tick_value") !== undefined && instrument.get("product.rdMultiple") !== undefined) {
      return new BigNumber(price).dividedBy(instrument.get("product.tick_size")).times(instrument.get("product.tick_value")).times(instrument.get("product.rdMultiple")).toNumber();
    } else {
      return null;
    }
  }
});