define("rd-basic-client/mixins/quarterValidation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    invalidRevenueMessage: '',
    invalidCostOfProductionMessage: '',
    isRevenueWrong: function () {
      var hasZeros = false;
      this.set('invalidRevenueMessage', '');
      this.get('months').forEach(function (m) {
        if (m.get('hasInvalidRevenue')) {
          this.set('invalidRevenueMessage', this.get('invalidRevenueMessage') + m.invalidRevenueMessage() + '<br/>');
          hasZeros = true;
        }
      }, this);
      return hasZeros;
    }.property('months.@each.cows', 'months.@each.production', 'months.@each.hasInvalidRevenue'),
    isCostOfProductionWrong: function () {
      var hasZeros = false;
      this.set('invalidCostOfProductionMessage', '');
      this.get('months').forEach(function (m) {
        if (m.get('hasInvalidTotalCostOfProduction')) {
          this.set('invalidCostOfProductionMessage', this.get('invalidCostOfProductionMessage') + m.invalidTotalCostOfProductionMessage() + '<br/>');
          hasZeros = true;
        }
      }, this);
      return hasZeros;
    }.property('months.@each.cows', 'months.@each.production', 'months.@each.hasInvalidTotalCostOfProduction')
  });

  _exports.default = _default;
});