define("rd-basic-client/controllers/pricescenarios", ["exports", "rd-basic-client/mixins/pricingModel", "moment", "rd-basic-client/utils/display-to-barchart-price", "rd-basic-client/utils/barchart-to-vela-price", "rd-basic-client/utils/vela-product-groups"], function (_exports, _pricingModel, _moment, _displayToBarchartPrice, _barchartToVelaPrice, _velaProductGroups) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var setScenarioPriceOnInstruments = function setScenarioPriceOnInstruments(instruments, rootSymbol, price) {
    var p = (0, _barchartToVelaPrice.default)(rootSymbol, (0, _displayToBarchartPrice.default)(rootSymbol, price));
    instruments.forEach(function (instrument) {
      instrument.get('scenario').then(function (scenario) {
        if (scenario && p) {
          scenario.set('price', p);
          scenario.save();
        }
      });
    });
  };

  var _default = Ember.Controller.extend(_pricingModel.default, {
    init: function init() {
      this._super();

      this.set('positionTypes', ['Futures (Forward)', 'Call', 'Put']);
      this.set('sideTypes', ['Bought', 'Sold']);
      this.set('futuresSorting', ['expirationDate']);
      this.set('updatePositionType', 'Futures (Forward)');
      this.set('updateSide', 'Bought');
      this.set('updateQuantity', null);
      this.set('updatePrice', null);
      this.set('updateStrike', null);
      this.set('updatePosition', null);
      this.set('updateProductGroup', null);
      this.set('updateMonths', new Set());
      this.set('updateCornPrice', null);
      this.set('updateSoybeanMealPrice', null);
      this.set('updateDryWheyPrice', null);
      this.set('updateNonfatDryMilkPrice', null);
      this.set('updateCashSettledCheesePrice', null);
      this.set('updateCashSettledButterPrice', null);
      this.set('updatePriceStep', null);
      this.set('netIncome', true);
      this.set('milkCheck', false);
      this.set('feed', false);
      this.set('month', null);
      this.set('hypotheticalPositionProducts', (0, _velaProductGroups.default)());
    },
    pricingModelName: Ember.computed('month.user.pricingModel', function () {
      for (var i = 0; i < this.pricingModels.length; i++) {
        if (this.pricingModels[i].code === this.get('month.user.pricingModel')) {
          return this.pricingModels[i].label;
        }
      }

      return 'N/A'; // Should NEVER come here
    }),
    checkedMonths: Ember.computed.filterBy('model.selectableMonths', 'checked', true),
    partialMonthsSelected: Ember.computed('checkedMonths.@each.checked', function () {
      return this.checkedMonths.length > 0 && this.checkedMonths.length < this.model.months.length;
    }),
    allMonthsSelected: Ember.computed('checkedMonths.@each.checked', function () {
      return this.checkedMonths.length == this.model.months.length;
    }),
    currentMonths: function () {
      var afterDate = _moment.default.utc().date(1).subtract(1, 'days');

      var beforeDate = _moment.default.utc(afterDate).add(24, 'months');

      var current = this.get('model.months').filter(function (record) {
        if (record.get('startDate') === undefined) {
          return false;
        }

        var v = _moment.default.utc(record.get('startDate')).isAfter(afterDate) && _moment.default.utc(record.get('startDate')).isBefore(beforeDate);

        return v;
      });
      return current.sortBy('startDate');
    }.property('model.months.@each.startDate'),
    minChartDate: function () {
      return _moment.default.utc().date(1).hour(0).minute(0).second(0).millisecond(0);
    }.property(),
    maxChartDate: function () {
      return (0, _moment.default)().add(23, 'months');
    }.property(),
    productGroups: function () {
      var arr = [];
      arr.pushObject(this.get('model.cornFutures.firstObject.product.group_id'));
      arr.pushObject(this.get('model.soybeanMealFutures.firstObject.product.group_id'));
      arr.pushObject(this.get('model.classThreeFutures.firstObject.product.group_id'));
      arr.pushObject(this.get('model.classFourFutures.firstObject.product.group_id'));
      arr.pushObject(this.get('model.dryWheyFutures.firstObject.product.group_id'));
      arr.pushObject(this.get('model.nonfatDryMilkFutures.firstObject.product.group_id'));
      arr.pushObject(this.get('model.cashSettledCheeseFutures.firstObject.product.group_id'));
      arr.pushObject(this.get('model.cashSettledButterFutures.firstObject.product.group_id'));
      return arr;
    }.property('model.cornFutures.firstObject.product.group_id', 'model.soybeanMealFutures.firstObject.product.group_id', 'model.classFourFutures.firstObject.product.group_id', 'model.classThreeFutures.firstObject.product.group_id'),
    positionTypes: null,
    sideTypes: null,
    updateIsOption: function () {
      return this.get('updatePositionType') === 'Futures (Forward)' ? false : true;
    }.property('updatePositionType'),
    quantityTitle: function () {
      if (this.get('updateProductGroup.symbol') === 'ZC') {
        return 'Bushels';
      } else if (this.get('updateProductGroup.symbol') === 'ZM') {
        return 'Tons';
      } else {
        return 'Pounds';
      }
    }.property('updateProductGroup'),
    updateQuantityStep: function () {
      if (this.get('updateProductGroup.symbol') === 'ZC') {
        return '2500';
      } else if (this.get('updateProductGroup.symbol') === 'ZM') {
        return '50';
      } else {
        return '50000';
      }
    }.property('updateProductGroup'),
    updatePriceStep: function () {
      if (this.get('updateProductGroup.symbol') === 'ZC') {
        return '0.10';
      } else if (this.get('updateProductGroup.symbol') === 'ZM') {
        return '10';
      } else {
        return '0.25';
      }
    }.property('updateProductGroup'),
    priceTitle: function () {
      if (this.get('updateProductGroup.symbol') === 'ZC') {
        return 'Bushel';
      } else if (this.get('updateProductGroup.symbol') === 'ZM') {
        return 'Ton';
      } else if (this.get('updateProductGroup.symbol') === 'DC' || this.get('updateProductGroup.symbol') === 'GDK') {
        return 'CWT';
      } else {
        return 'Pound';
      }
    }.property('updateProductGroup'),
    resetScenarioPrice: function resetScenarioPrice(instrument) {
      var price = instrument.get('velaFormattedLastPrice');
      instrument.get('scenario').then(function (scenario) {
        if (scenario && price) {
          scenario.set('price', price);
          scenario.save();
        }
      });
    },
    actions: {
      setNetIncome: function setNetIncome() {
        this.set('netIncome', true);
        this.set('milkCheck', false);
        this.set('feed', false);
      },
      setMilkCheck: function setMilkCheck() {
        this.set('netIncome', false);
        this.set('milkCheck', true);
        this.set('feed', false);
      },
      setFeed: function setFeed() {
        this.set('netIncome', false);
        this.set('milkCheck', false);
        this.set('feed', true);
      },
      openTradeModal: function openTradeModal() {
        this.set('updatePositionType', 'Futures (Forward)');
        this.set('updateSide', 'Bought');
        this.set('updateQuantity', null);
        this.set('updatePrice', null);
        this.set('updateStrike', null);
        this.checkedMonths.forEach(function (m) {
          m.set('checked', false);
        });
        Ember.$('#pos-modal').modal('show');
      },
      createNewPosition: function createNewPosition() {
        var self = this;
        var months = this.get('checkedMonths').mapBy('month');
        var quantity = self.get('updateQuantity');

        if (typeof quantity === 'string' || quantity instanceof String) {
          quantity = parseInt(quantity.replace(/,/g, ''));
        }

        months.forEach(function (m) {
          var hypothetical_position = self.store.createRecord('hypotheticalPosition', {
            instrumentType: self.get('updatePositionType'),
            isLong: self.get('updateSide') === 'Bought' ? true : false,
            quantity: quantity,
            price: self.get('updatePositionType') === 'Futures (Forward)' ? self.get('updatePrice') : self.get('updatePrice') / months.length,
            month: m,
            product_group_id: self.updateProductGroup.id,
            symbol: self.get('updateProductGroup.symbol'),
            strike: self.get('updatePositionType') === 'Futures (Forward)' ? null : self.get('updateStrike'),
            optionType: self.get('updatePositionType') === 'Futures (Forward)' ? null : self.get('updatePositionType')
          });
          hypothetical_position.save();
        });
        Ember.$('#pos-modal').modal('hide');
      },
      removeHypotheticalPosition: function removeHypotheticalPosition(pos) {
        pos.destroyRecord();
      },
      clearPositions: function clearPositions() {
        this.get('model.hypotheticalPositions').forEach(function (pos) {
          pos.destroyRecord();
        });
      },
      resetNonfatDryMilk: function resetNonfatDryMilk() {
        this.get('model.nonfatDryMilkFutures').forEach(function (m) {
          this.resetScenarioPrice(m);
        }, this);
      },
      openNonfatDryMilkModal: function openNonfatDryMilkModal() {
        this.set('updateNonfatDryMilkPrice', null);
        this.set('updatePriceStep', '0.25');
        Ember.$('#nonfat-dry-milk-modal').modal('show');
      },
      setNonfatDryMilkPrice: function setNonfatDryMilkPrice() {
        setScenarioPriceOnInstruments(this.get('model.nonfatDryMilkFutures'), 'GNF', this.get('updateNonfatDryMilkPrice'));
        Ember.$('#nonfat-dry-milk-modal').modal('hide');
      },
      resetDryWhey: function resetDryWhey() {
        this.get('model.dryWheyFutures').forEach(function (m) {
          this.resetScenarioPrice(m);
        }, this);
      },
      openDryWheyModal: function openDryWheyModal() {
        this.set('updateDryWheyPrice', null);
        this.set('updatePriceStep', '0.25');
        Ember.$('#dry-whey-modal').modal('show');
      },
      setDryWheyPrice: function setDryWheyPrice() {
        setScenarioPriceOnInstruments(this.get('model.dryWheyFutures'), 'DY', this.get('updateDryWheyPrice'));
        Ember.$('#dry-whey-modal').modal('hide');
      },
      resetCashSettledCheese: function resetCashSettledCheese() {
        this.get('model.cashSettledCheeseFutures').forEach(function (m) {
          this.resetScenarioPrice(m);
        }, this);
      },
      openCashSettledCheeseModal: function openCashSettledCheeseModal() {
        this.set('updateCashSettledCheesePrice', null);
        this.set('updatePriceStep', '0.25');
        Ember.$('#cash-settled-cheese-modal').modal('show');
      },
      setCashSettledCheesePrice: function setCashSettledCheesePrice() {
        setScenarioPriceOnInstruments(this.get('model.cashSettledCheeseFutures'), 'CSC', this.get('updateCashSettledCheesePrice'));
        Ember.$('#cash-settled-cheese-modal').modal('hide');
      },
      resetCashSettledButter: function resetCashSettledButter() {
        this.get('model.cashSettledButterFutures').forEach(function (m) {
          this.resetScenarioPrice(m);
        }, this);
      },
      openCashSettledButterModal: function openCashSettledButterModal() {
        this.set('updateCashSettledButterPrice', null);
        this.set('updatePriceStep', '0.25');
        Ember.$('#cash-settled-butter-modal').modal('show');
      },
      setCashSettledButterPrice: function setCashSettledButterPrice() {
        setScenarioPriceOnInstruments(this.get('model.cashSettledButterFutures'), 'CB', this.get('updateCashSettledButterPrice'));
        Ember.$('#cash-settled-butter-modal').modal('hide');
      },
      resetCorn: function resetCorn() {
        this.get('model.cornFutures').forEach(function (m) {
          this.resetScenarioPrice(m);
        }, this);
      },
      openCornModal: function openCornModal() {
        this.set('updateCornPrice', null);
        this.set('updatePriceStep', '0.10');
        Ember.$('#corn-modal').modal('show');
      },
      setCornPrice: function setCornPrice() {
        setScenarioPriceOnInstruments(this.get('model.cornFutures'), 'ZC', this.get('updateCornPrice'));
        Ember.$('#corn-modal').modal('hide');
      },
      resetSoybeanMeal: function resetSoybeanMeal() {
        this.get('model.soybeanMealFutures').forEach(function (m) {
          this.resetScenarioPrice(m);
        }, this);
      },
      openSoybeanMealModal: function openSoybeanMealModal() {
        this.set('updateSoybeanMealPrice', null);
        this.set('updatePriceStep', '10');
        Ember.$('#soybean-meal-modal').modal('show');
      },
      setSoybeanMealPrice: function setSoybeanMealPrice() {
        setScenarioPriceOnInstruments(this.get('model.soybeanMealFutures'), 'ZM', this.get('updateSoybeanMealPrice'));
        Ember.$('#soybean-meal-modal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});