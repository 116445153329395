define("rd-basic-client/serializers/cow-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var p = payload["cow_categories"];
      return this._super(store, primaryModelClass, p, id, requestType);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      return {
        cow_category: json
      };
    }
  });

  _exports.default = _default;
});