define("rd-basic-client/components/has-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasPermission: function () {
      if (this.get('user')) {
        var permissions = this.get('user').get('permissions').split(',');
        var component = this.get('component');

        if (component && component.permission === '') {
          return true;
        }

        return permissions.indexOf(component.permission) >= 0;
      }

      return false;
    }.property('component', 'user')
  });

  _exports.default = _default;
});