define("rd-basic-client/helpers/format-number-failsafe", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatNumberFailsafe = formatNumberFailsafe;
  _exports.default = void 0;

  function formatNumberFailsafe(value, format) {
    if (value !== null && value !== undefined && !isNaN(value) && format) {
      value = Math.abs(value);
    } else {
      value = 0;
    }

    return (0, _numeral.default)(value).format(format);
  }

  var _default = Ember.Helper.helper(formatNumberFailsafe);

  _exports.default = _default;
});