define("rd-basic-client/serializers/hypothetical-position", ["exports", "ember-data", "rd-basic-client/utils/vela-product-group-to-symbol"], function (_exports, _emberData, _velaProductGroupToSymbol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var p;

      if (requestType != 'deleteRecord') {
        p = payload["hypothetical_positions"] ? payload["hypothetical_positions"] : payload["hypothetical_position"];

        if (Array.isArray(p)) {
          p.forEach(function (pos) {
            pos['symbol'] = (0, _velaProductGroupToSymbol.default)(pos['product_group_id']);
          });
        } else {
          p['symbol'] = (0, _velaProductGroupToSymbol.default)(p['product_group_id']);
        }
      } else {
        p = payload;
      }

      return this._super(store, primaryModelClass, p, id, requestType);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      return {
        'hypothetical_position': json
      };
    }
  });

  _exports.default = _default;
});