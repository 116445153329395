define("rd-basic-client/utils/points-to-dollars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pointsToDollars;

  function pointsToDollars(symbol, price) {
    var newPrice = price;

    switch (symbol) {
      case 'GDK':
        newPrice = price * 2000;
        break;

      case 'DC':
        newPrice = price * 2000;
        break;

      case 'GNF':
        newPrice = price * 440;
        break;

      case 'DY':
        newPrice = price * 440;
        break;

      case 'CSC':
        newPrice = price * 20000;
        break;

      case 'CB':
        newPrice = price * 200;
        break;

      case 'ZC':
        newPrice = price * 50;
        break;

      case 'ZM':
        newPrice = price * 100;
        break;
    }

    return newPrice;
  }
});