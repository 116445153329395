define("rd-basic-client/routes/cowflow", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel() {
      if (!this.get("session").isAuthenticated) {
        this.transitionTo("login");
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        months: this.store.findAll("month"),
        labels: this.store.findAll("cow-category-label")
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});