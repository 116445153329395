define("rd-basic-client/routes/positions", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "moment", "rd-basic-client/utils/vela-product-groups", "rd-basic-client/utils/month-market-data-instruments"], function (_exports, _authenticatedRouteMixin, _moment, _velaProductGroups, _monthMarketDataInstruments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    marketData: Ember.inject.service('market-data'),
    model: function model(params) {
      return Ember.RSVP.hash({
        positions: this.store.query("position", {
          velaRootSymbol: params.parent_symbol,
          embed_instrument: true
        }),
        months: this.store.findAll('month'),
        users: this.store.findAll("user"),
        historicalPrices: this.store.findAll('historicalPrice'),
        future_instruments: this.store.peekAll("vault-instrument").filter(function (i) {
          return i.rootSymbol == params.parent_symbol && i.instrumentType.toUpperCase() == 'FUTURE';
        }),
        physicalPositions: this.store.findAll('physical-position'),
        parentSymbol: params.parent_symbol
      });
    },
    afterModel: function afterModel(model) {
      model.user = model.users.get('firstObject');
      model.productGroup = (0, _velaProductGroups.default)().filterBy('symbol', model.parentSymbol).get('firstObject');
      var months = model.months;
      var currentYear = (0, _moment.default)().year();

      var afterDate = _moment.default.utc().year(currentYear).date(1).subtract(1, "days");

      if ((0, _moment.default)().utc().year(currentYear).date() < 8) {
        afterDate.subtract(1, "months");
      }

      this.marketData.start();
      var instruments = (0, _monthMarketDataInstruments.default)(model.months, true);
      this.marketData.subscribeTo(instruments);

      var beforeDate = _moment.default.utc(afterDate).add(2, "years");

      var current = months.filter(function (record) {
        if (record.get("startDate") === undefined) {
          return false;
        }

        var v = _moment.default.utc(record.get('startDate')).isAfter(afterDate) && _moment.default.utc(record.get('startDate')).isBefore(beforeDate);

        return v;
      });
      model.selectableMonths = current.sortBy("startDate").map(function (m) {
        return Ember.Object.create({
          month: m,
          checked: false
        });
      });
    }
  });

  _exports.default = _default;
});