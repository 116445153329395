define("rd-basic-client/templates/components/vault-session-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KfgHiII1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"hasChange\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"hasPositiveChange\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"style\",\"color: green;\"],[10,\"title\",\"Session Change\"],[8],[1,[22,\"formattedNetChange\"],false],[0,\"\\n            (\"],[1,[28,\"format-number\",[[24,[\"percentChange\"]],\"0,0.00\"],null],false],[0,\"%)\\n            \"],[7,\"i\",true],[10,\"aria-hidden\",\"true\"],[10,\"class\",\"fa fa-arrow-up\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"style\",\"color: red;\"],[10,\"title\",\"Session Change\"],[8],[1,[22,\"formattedNetChange\"],false],[0,\"\\n            (\"],[1,[28,\"format-number\",[[24,[\"percentChange\"]],\"0,0.00\"],null],false],[0,\"%)\\n            \"],[7,\"i\",true],[10,\"aria-hidden\",\"true\"],[10,\"class\",\"fa fa-arrow-down\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    -\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/components/vault-session-change.hbs"
    }
  });

  _exports.default = _default;
});