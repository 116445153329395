define("rd-basic-client/adapters/barchart-market-datum", ["exports", "ember-data", "rd-basic-client/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    host: _environment.default.APP.BARCHART_HOST,
    coalesceFindRequests: true,
    findRecord: function findRecord(store, type, value) {
      return this.ajax("".concat(this.host, "/getQuote.json"), "GET", {
        data: {
          apikey: _environment.default.APP.BARCHART_API_KEY,
          symbols: value,
          fields: "previousSettlement,settlement,openInterest"
        }
      });
    },
    findMany: function findMany(store, type, ids) {
      return this.ajax("".concat(this.host, "/getQuote.json"), "GET", {
        data: {
          apikey: _environment.default.APP.BARCHART_API_KEY,
          symbols: ids.join(","),
          fields: "previousSettlement,settlement,openInterest"
        }
      });
    }
  });

  _exports.default = _default;
});