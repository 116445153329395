define("rd-basic-client/controllers/production", ["exports", "rd-basic-client/mixins/editable-months"], function (_exports, _editableMonths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editableMonths.default, {});

  _exports.default = _default;
});