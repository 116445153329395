define("rd-basic-client/controllers/ingredients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super();

      this.set('showHidden', false);
    },
    filteredIngredients: Ember.computed('model.ingredients.{@each,@each.active}', 'showHidden', function () {
      var _this = this;

      return this.get('model.ingredients').filter(function (ingredient) {
        return ingredient.get('active') === _this.showHidden ? 0 : 1;
      });
    }),
    actions: {
      showHidden: function showHidden(flag) {
        this.set('showHidden', flag);
      },
      addNewIngredient: function addNewIngredient() {
        this.set('ingredient', {
          name: '',
          provider: '',
          active: true,
          shrink: '0.00',
          spotPrice: '0.00'
        });
        Ember.$('#new-ingredient-modal').modal('show');
      },
      saveIngredient: function saveIngredient() {
        var ingredient = this.store.createRecord('ingredient', {
          label: this.get('ingredient.name'),
          provider: this.get('ingredient.provider'),
          active: true,
          shrink: this.get('ingredient.shrink'),
          spotPrice: this.get('ingredient.spotPrice')
        });
        ingredient.save();
        Ember.$('#new-ingredient-modal').modal('hide');
      },
      changeIngredientVisibility: function changeIngredientVisibility(ingredient, visibility) {
        ingredient.set('active', visibility);
        ingredient.save();
      }
    }
  });

  _exports.default = _default;
});