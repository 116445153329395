define("rd-basic-client/routes/login", ["exports", "rd-basic-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate: function activate() {
      Ember.$.ajaxSetup({
        headers: {
          Authorization: _environment.default.APP.CLIENT_OAUTH
        }
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.set("errorMessage", null);
        Ember.$.ajaxSetup({
          headers: null
        });
      }
    }
  });

  _exports.default = _default;
});