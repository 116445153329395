define("rd-basic-client/controllers/dashboard", ["exports", "rd-basic-client/components/quarter", "rd-basic-client/mixins/month-utils", "rd-basic-client/config/environment", "moment", "mixpanel-browser"], function (_exports, _quarter, _monthUtils, _environment, _moment, _mixpanelBrowser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_monthUtils.default, {
    quartersItems: null,
    quartersCollapsed: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.set("quartersItems", []);
      this.set("chartPriceType", "netIncome");
      this.set("priceType", "blended");
      this.set("perCWT", true);
      this.set("perCow", false);
      this.set("perTotal", false);
      this.set("showTwoYears", false);
      this.set("showFiveYears", true);
      this.set("showTenYears", false);
    },
    notCash: Ember.computed.or("perCWT", "perCow"),
    currentMonths: function () {
      var afterDate = _moment.default.utc().date(1).subtract(1, "days");

      var beforeDate = _moment.default.utc(afterDate).add(_environment.default.APP.MONTH.SHOW, "months");

      if ((0, _moment.default)().utc().date() < _environment.default.APP.MONTH.ROLLBACK_DATE) {
        afterDate.subtract(1, "months");
      }

      return this.get("model.months").filter(function (record) {
        return _moment.default.utc(record.get("startDate")).isAfter(afterDate) && _moment.default.utc(record.get("startDate")).isBefore(beforeDate);
      });
    }.property("model.months.@each.startDate"),
    createQuarterTotal: function createQuarterTotal(month) {
      var year = parseInt((0, _moment.default)(month.get("startDate")).utc().format("YYYY"));

      if (this.isLessThan24MonthFromNow(month) && parseInt((0, _moment.default)(month.get("startDate")).utc().format("M")) === 12) {
        return _quarter.default.create({
          id: year + "_total",
          year: year,
          total: true,
          months: this.get("model.months").filter(function (record) {
            if (record.get("startDate") === undefined) {
              return false;
            }

            return year === parseInt((0, _moment.default)(record.get("startDate")).utc().format("YYYY"));
          })
        });
      }

      return null;
    },
    preloadCurrentYear: function preloadCurrentYear(month, year) {
      var self = this;
      month = month || this.get("currentMonths").get(0);
      year = year || (0, _moment.default)(month.get("startDate")).utc().format("YYYY");

      if (year === (0, _moment.default)(month.get("startDate")).utc().format("YYYY")) {
        month.get("previous_month_id").then(function (m) {
          self.preloadCurrentYear(m, year);
        });
      }
    },
    quarters: function () {
      var list = []; // this.get('quartersItems');
      // Temp cache

      var quartersMap = {};
      this.get("model.months").sortBy("startDate").forEach(function (m) {
        var quarter = (0, _moment.default)(m.get("startDate")).utc().format("Q");
        var year = (0, _moment.default)(m.get("startDate")).utc().format("YYYY");
        var qId = quarter + "_" + year; // UNCOMMENT THIS TO ENABLE THE QUARTER TOTALS

        var total = null; // this.createQuarterTotal(m);

        if (!quartersMap[qId]) {
          quartersMap[qId] = _quarter.default.create({
            id: qId,
            quarter: quarter,
            year: year,
            months: [m],
            expanded: !this.get("quartersCollapsed")
          });
          list.push(quartersMap[qId]);
        } else {
          quartersMap[qId].get("months").push(m);
        }

        if (total) {
          list.push(total);
        }
      }, this);
      return list;
    }.property("model.months"),
    isGlanbia: Ember.computed("model.user.pricingModel", function () {
      return this.get("model.user.pricingModel") === 'glanbia';
    }),
    isHedgedPrices: Ember.computed("priceType", function () {
      return this.get("priceType") === "hedged";
    }),
    isUnhedgedPrices: Ember.computed("priceType", function () {
      return this.get("priceType") === "unhedged";
    }),
    isBlendedPrices: Ember.computed("priceType", function () {
      return this.get("priceType") === "blended";
    }),
    marginMonths: function () {
      var currentMonths = this.get("currentMonths");
      var currentDate = (0, _moment.default)();

      var nextValidMonth = function nextValidMonth(m) {
        var nextMonth;

        switch (m.month()) {
          case 0:
            nextMonth = m.month(2);
            break;

          case 1:
            nextMonth = m.month(2);
            break;

          case 2:
            nextMonth = m.month(6);
            break;

          case 3:
            nextMonth = m.month(6);
            break;

          case 4:
            nextMonth = m.month(6);
            break;

          case 5:
            nextMonth = m.month(6);
            break;

          case 6:
            nextMonth = m.month(8);
            break;

          case 7:
            nextMonth = m.month(8);
            break;

          case 8:
            nextMonth = m.month(11);
            break;

          case 9:
            nextMonth = m.month(11);
            break;

          case 10:
            nextMonth = m.month(11);
            break;

          case 11:
            nextMonth = m.month(2).year(m.year() + 1);
            break;
        }

        return nextMonth;
      };

      currentDate = nextValidMonth(currentDate);
      var marginMonths = [];
      var i = 6;
      var d;
      var m;

      var filter = function filter(month) {
        d = (0, _moment.default)(month.get("startDate")).utc();
        return d.year() === currentDate.year() && d.month() === currentDate.month();
      };

      while (i > 0) {
        m = currentMonths.find(filter);
        marginMonths.pushObject(m);
        currentDate = nextValidMonth(currentDate);
        i--;
      }

      return marginMonths;
    }.property("currentMonths.@each"),
    filteredHistoricalPrices: function () {
      var years = 2;

      if (this.get("showFiveYears")) {
        years = 5;
      }

      if (this.get("showTenYears")) {
        years = 10;
      }

      var v = this.get("model.historicalPrices").filter(function (price) {
        var p = price.get("month");

        if (p === undefined) {
          return false;
        }

        return (0, _moment.default)(p).isAfter((0, _moment.default)().subtract(years, "years"));
      });
      return v;
    }.property("model.historicalPrices", "showTwoYears", "showFiveYears", "showTenYears"),
    historicalPriceClassThreeMargin: function () {
      return this.get("filteredHistoricalPrices").sortBy("classThreeMargin").mapBy("classThreeMargin");
    }.property("filteredHistoricalPrices.@each"),
    historicalPriceClassFourMargin: function () {
      return this.get("filteredHistoricalPrices").sortBy("classFourMargin").mapBy("classFourMargin");
    }.property("filteredHistoricalPrices.@each"),
    historicalPriceClassFour: function () {
      return this.get("filteredHistoricalPrices").sortBy("classIv").mapBy("classIv");
    }.property("filteredHistoricalPrices.@each"),
    historicalPriceClassThree: function () {
      return this.get("filteredHistoricalPrices").sortBy("classIii").mapBy("classIii");
    }.property("filteredHistoricalPrices.@each"),
    historicalPriceCorn: function () {
      var v = this.get("filteredHistoricalPrices").sortBy("corn").mapBy("corn");
      return v;
    }.property("filteredHistoricalPrices.@each"),
    historicalPriceSoybeanMeal: function () {
      return this.get("filteredHistoricalPrices").sortBy("soybeanMeal").mapBy("soybeanMeal");
    }.property("filteredHistoricalPrices.@each"),
    actions: {
      expandQuarter: function expandQuarter(quarter) {
        if (quarter.get("total")) {
          return;
        }

        quarter.set("expanded", !quarter.get("expanded"));
      },
      expandAllQuarters: function expandAllQuarters() {
        this.set("quartersCollapsed", !this.get("quartersCollapsed"));
        var expand = this.get("quartersCollapsed");
        this.get("quarters").forEach(function (q) {
          if (!q.get("total")) {
            q.set("expanded", !expand);
          }
        });
      },
      setPriceType: function setPriceType(type) {
        this.set("priceType", type);

        if (type === "blended") {
          type = "combined";
        }
      },
      setCWT: function setCWT() {
        this.set("perCWT", true);
        this.set("perCow", false);
        this.set("perTotal", false);
      },
      setTotal: function setTotal() {
        this.set("perCWT", false);
        this.set("perCow", false);
        this.set("perTotal", true);
      },
      setCow: function setCow() {
        this.set("perCWT", false);
        this.set("perCow", true);
        this.set("perTotal", false);
      },
      setTwoYears: function setTwoYears() {
        this.set("showTwoYears", true);
        this.set("showFiveYears", false);
        this.set("showTenYears", false);
      },
      setFiveYears: function setFiveYears() {
        this.set("showTwoYears", false);
        this.set("showFiveYears", true);
        this.set("showTenYears", false);
      },
      setTenYears: function setTenYears() {
        this.set("showTwoYears", false);
        this.set("showFiveYears", false);
        this.set("showTenYears", true);
      }
    }
  });

  _exports.default = _default;
});