define("rd-basic-client/components/vault-session-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    netChange: null,
    percentChange: null,
    fractionalDecimals: null,
    // This is only needed when we aren't getting the price from the formatted instrument prices.
    // Currently, this is needed for spot prices.
    formattedNetChange: Ember.computed('netChange', function () {
      if (this.fractionalDecimals) {
        return Intl.NumberFormat('en-US', {
          minimumFractionDigits: this.fractionalDecimals,
          maximumFractionDigits: this.fractionalDecimals
        }).format(this.netChange);
      } else {
        return this.netChange;
      }
    }),
    hasChange: Ember.computed('netChange', function () {
      return this.netChange > 0 || this.netChange < 0;
    }),
    hasPositiveChange: Ember.computed('netChange', function () {
      return this.netChange > 0;
    })
  });

  _exports.default = _default;
});