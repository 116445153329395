define("rd-basic-client/models/historical-price", ["exports", "ember-data", "rd-basic-client/mixins/notification"], function (_exports, _emberData, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-function-prototype-extensions */
  var _default = _emberData.default.Model.extend(_notification.default, {
    month: _emberData.default.attr('date'),
    corn: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    soybeanMeal: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    nonfatDryMilk: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    dryWhey: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    cashSettledCheese: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    cashSettledButter: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    fmmoClassOne: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    fmmoClassTwo: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    classIv: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    classIii: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    ca_n_class_one: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    ca_n_class_two: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    ca_s_class_one: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    ca_s_class_two: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    caClassThree: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    caClassFoura: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    caClassFourb: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    udaClassOne: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    udaClassTwo: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    udaClassThree: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    udaClassFour: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    glanbiaCheese: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    glanbiaButter: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    glanbiaWpc: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    glanbiaLactose: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    glanbiaEpp: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    classThreeMargin: function () {
      return this.get('classIii') - this.get('corn') * 0.00833 - this.get('soybeanMeal') * 0.0083;
    }.property('classIii', 'corn', 'soybeanMeal'),
    classFourMargin: function () {
      return this.get('classIv') - this.get('corn') * 0.00833 - this.get('soybeanMeal') * 0.0083;
    }.property('classIv', 'corn', 'soybeanMeal')
  });

  _exports.default = _default;
});