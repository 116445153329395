define("rd-basic-client/components/report-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.$('#sortable1, #sortable2').sortable({
        connectWith: '.connected',
        items: ':not(.disabled)'
      });
    }
  });

  _exports.default = _default;
});