define("rd-basic-client/mixins/class-iii", ["exports", "rd-basic-client/mixins/common", "rd-basic-client/utils/option-expiration-pl-in-ticks", "moment", "rd-basic-client/utils/points-to-dollars", "rd-basic-client/utils/vela-to-barchart-price", "rd-basic-client/utils/barchart-to-vela-price"], function (_exports, _common, _optionExpirationPlInTicks, _moment, _pointsToDollars, _velaToBarchartPrice, _barchartToVelaPrice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_common.default, {
    classThreeCashValueProduced: function () {
      return this.classThreeProduction / 100 * this.classThreePrice;
    }.property('classThreeProduction', 'classThreePrice'),
    classThreeNetPercentageHedged: function () {
      var cashSettledCheeseAndDryWheyCashValueHedged = this.cashSettledCheeseCashValueHedged + this.dryWheyCashValueHedged;
      var percentage = cashSettledCheeseAndDryWheyCashValueHedged / this.classThreeCashValueProduced;

      if (percentage > 1) {
        return 1;
      } else if (percentage > 0) {
        return percentage;
      }

      return 0;
    }.property('cashSettledCheeseCashValueHedged', 'dryWheyCashValueHedged', 'classThreeCashValueProduced'),
    classThreeNetPercentageUnhedged: function () {
      return 1 - this.classThreeNetPercentageHedged;
    }.property('classThreeNetPercentageHedged'),
    cheesePoundsFromClassThreeHedged: function () {
      return this.classThreePoundsHedgedWithoutCheese * 0.09639331;
    }.property('classThreePoundsHedgedWithoutCheese'),
    numberOfPoundInClassIIICMEContract: 200000,
    classThreePoundsHedged: function () {
      var lbs = this.classThreePoundsHedgedWithoutCheese + this.classThreeCheesePounds;

      if (this.user.get('pricingModel') === 'fmmo') {
        lbs += this.classThreeWheyPounds;
      }

      return lbs;
    }.property('classThreePoundsHedgedWithoutCheese', 'classThreeCheesePounds', 'classThreeWheyPounds', 'user.pricingModel'),
    classThreeProduction: function () {
      return this.classThreeUtilization * this.totalProduction;
    }.property('classThreeUtilization', 'totalProduction'),
    classThreeProductionInContracts: function () {
      return this.classThreeProduction / 200000;
    }.property('classThreeProduction'),
    classThreeCheeseProduced: function () {
      return this.classThreeProduction * 0.096393;
    }.property('classThreeProduction'),
    classThreeCheeseContractsProduced: function () {
      return this.classThreeCheeseProduced / 20000;
    }.property('classThreeCheeseProduced'),
    classThreeCheesePounds: function () {
      return Math.round(this.cashSettledCheesePoundsHedged / 0.096393);
    }.property('classThreePoundsHedgedWithoutCheese'),
    classThreeWheyPounds: function () {
      return Math.round(this.dryWheyPoundsHedged / 0.058643);
    }.property('dryWheyPoundsHedged'),
    classThreePoundsHedgedWithoutCheese: function () {
      return this.getPoundsHedged('DC');
    }.property('physicalPositions.@each.quantity', 'positions.@each'),
    classThreePercentageHedged: function () {
      var next_month = this.get('next_month_id');
      return this.getByUserTypePercentageHedged({
        FMMO: this.classThreeProduction + (next_month ? next_month.get('classOneProduction') / 2 : 0),
        CALIFORNIA: this.classFourBProduction + (this.classFourBestPrice <= this.classThreeBestPrice ? this.classOneProduction : 0),
        hedged: this.classThreePoundsHedged
      });
    }.property('classThreePoundsHedged', 'classThreeProduction', 'classFourBestPrice', 'classThreeBestPrice', 'user.pricingModel', 'classFourBProduction', 'classOneProduction', 'next_month_id'),
    classThreePercentageHedgedAlternative: function () {
      var classProduction = 0;
      var poundsHedged = this.classThreePoundsHedged;

      switch (this.user.get('pricingModel')) {
        case 'fmmo':
          classProduction = this.classThreeProduction;
          break;

        case 'california':
          classProduction = this.classFourBProduction;
          break;

        case 'uda':
          classProduction = this.udaCheeseProduction;
          break;
      }

      if (classProduction > 0) {
        if (poundsHedged > classProduction) {
          return 1;
        } else {
          return poundsHedged / classProduction;
        }
      } else {
        return 0;
      }
    }.property('classThreePoundsHedged', 'classFourBProduction', 'classThreeProduction', 'user.pricingModel', 'udaCheeseProduction'),
    caFourBContractsUnhedged: function () {
      if (this.classThreePoundsHedged < this.classFourBProduction) {
        return (this.classFourBProduction - this.classThreePoundsHedged) / this.numberOfPoundInClassIIICMEContract;
      } else {
        return 0;
      }
    }.property('classThreePoundsHedged', 'classFourBProduction', 'numberOfPoundInClassIIICMEContract'),
    classThreeContractsHedged: function () {
      return this.classThreePoundsHedged / this.numberOfPoundInClassIIICMEContract;
    }.property('classThreePoundsHedged'),
    classThreeContractsHedgedWithoutCheese: function () {
      return this.classThreePoundsHedgedWithoutCheese / this.numberOfPoundInClassIIICMEContract;
    }.property('classThreePoundsHedgedWithoutCheese'),
    classThreeOptionPremium: function () {
      return this.getPositionsValue('DC', 'Option', 'premiumInDollars');
    }.property('positions.@each.premiumInDollars', 'positions', 'positions.@each.instrumentBestPrice'),
    classThreeFuturePL: function () {
      return this.getPositionsValue('DC', 'Future', 'plInDollars');
    }.property('positions.@each.plInDollars', 'positions.@each.instrument'),
    scenarioClassThreeFuturePL: function () {
      var self = this;
      var exchangePositions = this.positions.filter(function (p) {
        return p.get('instrument.symbol') && p.get('instrument.symbol').indexOf('DC') > -1 && p.get('instrument.instrument_type').toUpperCase() === 'FUTURE';
      });
      var futurePLinTicks = 0;
      var futurePL = 0;
      exchangePositions.forEach(function (pos) {
        futurePLinTicks = 0;
        pos.tradePrices.forEach(function (tp) {
          if (self.rawScenarioFmmoClassThreePrice === undefined) {
            return 0;
          }

          if (tp.quantity > 0) {
            futurePLinTicks += (self.rawScenarioFmmoClassThreePrice - tp.price) * tp.quantity;
          }

          if (tp.quantity < 0) {
            futurePLinTicks += (tp.price - self.rawScenarioFmmoClassThreePrice) * Math.abs(tp.quantity);
          }
        });
        futurePL += (0, _pointsToDollars.default)('DC', (0, _velaToBarchartPrice.default)('DC', futurePLinTicks));
      });
      return futurePL;
    }.property('positions.@each.instrument', 'rawScenarioFmmoClassThreePrice'),
    classThreeOptionPL: function () {
      return this.getPositionsValue('DC', 'Option', 'plInDollars');
    }.property('positions.@each.plInDollars'),
    scenarioClassThreeOptionPL: function () {
      var self = this;
      var exchangePositions = this.positions.filter(function (p) {
        return p.instrument.get('symbol') && p.instrument.get('symbol').indexOf('DC') > -1 && p.get('instrument.instrument_type').toUpperCase() === 'OPTION';
      });
      var optionPLinTicks = 0;
      var optionPL = 0;
      var isCall;
      exchangePositions.forEach(function (pos) {
        isCall = pos.get('instrument.option_type').toUpperCase() === 'CALL';
        optionPLinTicks = (0, _optionExpirationPlInTicks.default)(self.rawScenarioFmmoClassThreePrice, (0, _barchartToVelaPrice.default)('DC', pos.get('instrument.strike')), pos.get('quantity'), isCall);
        optionPL += (0, _pointsToDollars.default)('DC', (0, _velaToBarchartPrice.default)('DC', optionPLinTicks));
      });
      return optionPL;
    }.property('positions.@each.instrument', 'rawScenarioFmmoClassThreePrice'),
    offExchangeClassThreePL: function () {
      return this.getOffExchangePL('DC', this.classThreeBestPrice, 100);
    }.property('physicalPositions.@each', 'classThreeBestPrice'),
    scenarioOffExchangeClassThreePL: function () {
      var classThreePrice = this.rawScenarioFmmoClassThreePrice / 100;
      return this.getScenarioOffExchangePL('DC', classThreePrice, 100);
    }.property('physicalPositions.@each', 'rawScenarioFmmoClassThreePrice', 'classThreeMilkInstrument.product.tick_size', 'isCurrentMonth', 'hypotheticalPositions.@each'),
    classThreeNetPL: function () {
      return this.offExchangeClassThreePL + this.classThreeFuturePL + this.classThreeOptionPL;
    }.property('offExchangeClassThreePL', 'classThreeFuturePL', 'classThreeOptionPL'),
    classThreePrice: function () {
      var val = -1;

      switch (this.user.get('pricingModel')) {
        case 'california':
          val = this.caClassThreePrice;
          break;

        case 'fmmo':
          val = this.fmmoClassThreePrice;
          break;

        case 'uda':
          val = this.classThreeBestPriceFormatted;
          break;

        default:
          val = 0;
      }

      return val;
    }.property('user.pricingModel', 'fmmoClassThreePrice', 'caClassThreePrice', 'classThreeBestPriceFormatted'),
    scenarioClassThreePrice: function () {
      var val = -1;

      switch (this.user.get('pricingModel')) {
        case 'california':
          val = this.scenarioCaClassThreePrice;
          break;

        case 'fmmo':
          val = this.scenarioFmmoClassThreePrice;
          break;

        case 'uda':
          val = this.scenarioClassThreeBestPriceFormatted;
          break;

        default:
          val = 0;
      }

      return val;
    }.property('user.pricingModel', 'scenarioFmmoClassThreePrice', 'scenarioCaClassThreePrice', 'scenarioClassThreeBestPriceFormatted'),
    fmmoClassThreePrice: function () {
      return this.classThreeBestPriceFormatted;
    }.property('classThreeBestPriceFormatted', 'classThreeMilkInstrument.product.tick_size'),
    scenarioFmmoClassThreePrice: function () {
      return this.rawScenarioFmmoClassThreePrice / 100;
    }.property('rawScenarioFmmoClassThreePrice'),
    rawScenarioFmmoClassThreePrice: function () {
      return Math.round((this.scenarioClassThreeSkim * 0.965 + this.scenarioButterfatPrice * 3.5) * 100); // In cents, to the nearest cent.
    }.property('scenarioClassThreeSkim', 'scenarioButterfatPrice'),
    regionClassThreeUtilization: function () {
      var monthNumber = (0, _moment.default)(this.startDate).utc().month();
      var val = -1;
      var REGION_CLASS_THREE_UTILIZZATION = [0.025675, 0.031425, 0.036825, 0.0377, 0.0377, 0.041125, 0.04125, 0.04275, 0.0368, 0.03295, 0.028775, 0.019525];

      switch (this.user.get('pricingModel')) {
        case 'california':
          val = this.caClassThreeUtilizationSNF;

          if (val === 0) {
            // Fallback
            val = REGION_CLASS_THREE_UTILIZZATION[monthNumber];
          } else {
            REGION_CLASS_THREE_UTILIZZATION[monthNumber] = val;
          }

          break;

        case 'fmmo':
          val = this.user.get('location.region.classThree')[monthNumber];
          break;

        default:
          val = this.user.get('location.region.classThree')[monthNumber];
      }

      return val;
    }.property('user.location.region.classThree', 'user.pricingModel', 'startDate'),
    classThreeUtilization: function () {
      if (this.manualUtilization) {
        return this.manualClassThreeUtilization;
      } else {
        return this.regionClassThreeUtilization;
      }
    }.property('manualUtilization', 'manualClassThreeUtilization', 'regionClassThreeUtilization'),
    classThreeSkim: function () {
      var val = 0;

      switch (this.user.get('pricingModel')) {
        case 'uda':
          val = this.udaClassThreeSkim;
          break;

        default:
          val = this.proteinPrice * 3.1 + this.otherSolidsPrice * 5.9;
          break;
      }

      return val;
    }.property('proteinPrice', 'otherSolidsPrice', 'user.pricingModel', 'udaClassThreeSkim'),
    scenarioClassThreeSkim: function () {
      var val = 0;

      switch (this.user.get('pricingModel')) {
        case 'uda':
          val = this.scenarioUdaClassThreeSkim;
          break;

        default:
          val = this.scenarioProteinPrice * 3.1 + this.scenarioOtherSolidsPrice * 5.9;
          break;
      }

      return val;
    }.property('scenarioProteinPrice', 'scenarioOtherSolidsPrice', 'user.pricingModel')
  });

  _exports.default = _default;
});