define("rd-basic-client/models/cow-category-label", ["exports", "ember-data", "rd-basic-client/mixins/notification"], function (_exports, _emberData, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_notification.default, {
    label: _emberData.default.attr("string"),
    order: _emberData.default.attr('number'),
    cowCategories: _emberData.default.hasMany('cow-category')
  });

  _exports.default = _default;
});