define("rd-basic-client/serializers/location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload) {
      var jsonDocument = {
        data: [],
        included: []
      };

      for (var i = 0; i < payload.locations.length; i++) {
        var location = payload.locations[i];
        jsonDocument.data.push(this.normalize(primaryModelClass, location)['data']);
      }

      for (var _i = 0; _i < payload.regions.length; _i++) {
        var region = payload.regions[_i];
        jsonDocument.included.push(store.serializerFor('region').normalize(store.modelFor('region'), region)['data']);
      }

      return jsonDocument;
    }
  });

  _exports.default = _default;
});