define("rd-basic-client/controllers/conversions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set("conversionTypes", [{
        name: "Weight",
        types: [{
          name: "Long Ton",
          value: 2240
        }, {
          name: "Short Ton",
          value: 2000
        }, {
          name: "Metric Ton",
          value: 2204.623
        }, {
          name: "Pounds",
          value: 1
        }, {
          name: "Kilograms",
          value: 2.20462
        }, {
          name: "Bushels of Corn",
          value: 56
        }]
      }, {
        name: "Dry Matter (As Fed -> 100% Dry)",
        types: []
      }, {
        name: "Dry Matter (100% Dry -> As Fed)",
        types: []
      }]);
      this.set("currentConversion", this.get("conversionTypes.firstObject"));
      this.set("activeLeft", this.get("currentConversion.types.firstObject"));
      this.set("activeRight", this.get("currentConversion.types.lastObject"));
      this.set("valueLeft", new BigNumber(1));
      this.set("valueRight", new BigNumber(this.get("activeLeft").value).div(this.get("activeRight").value));
      this.set("dry", 1.25);
      this.set("asFed", 1.00);
      this.set("moisture", 20);
    },
    isDryToFed: function () {
      return this.get("currentConversion.name") === "Dry Matter (100% Dry -> As Fed)";
    }.property("currentConversion"),
    isWeight: function () {
      return this.get("currentConversion.name") === "Weight";
    }.property("currentConversion"),
    isFedToDry: function () {
      return this.get("currentConversion.name") === "Dry Matter (As Fed -> 100% Dry)";
    }.property("currentConversion"),
    updateDry: function () {
      if (this.get("isFedToDry")) {
        var v = this.get("asFed") / (1 - this.get("moisture") * 0.01);
        this.set("dry", v.toFixed(2));
      }
    }.observes("asFed", "moisture", "isFedToDry"),
    updateAsFed: function () {
      if (this.get("isDryToFed")) {
        var v = this.get("dry") * (1 - this.get("moisture") * 0.01);
        this.set("asFed", v.toFixed(2));
      }
    }.observes("asFed", "moisture", "isDryToFed"),
    trackLeftChanges: function () {
      var current = new BigNumber(this.get("valueRight"));
      var next = new BigNumber(this.get("activeLeft").value).div(this.get("activeRight").value).times(this.get("valueLeft"));

      if (current.toFixed(12) !== next.toFixed(12)) {
        this.set("valueRight", next);
      }
    }.observes("valueLeft"),
    trackRightChanges: function () {
      var current = new BigNumber(this.get("valueLeft"));
      var next = new BigNumber(this.get("activeRight").value).div(this.get("activeLeft").value).times(this.get("valueRight"));

      if (current.toFixed(12) !== next.toFixed(12)) {
        this.set("valueLeft", next);
      }
    }.observes("valueRight"),
    actions: {
      changeLeftType: function changeLeftType(type) {
        this.set("activeLeft", type);
        var current = this.get("valueLeft");
        var next = new BigNumber(this.get("activeRight").value).div(this.get("activeLeft").value).times(this.get("valueRight"));

        if (current.toFixed(12) !== next.toFixed(12)) {
          this.set("valueLeft", next);
        }
      },
      changeRightType: function changeRightType(type) {
        this.set("activeRight", type);
        var current = this.get("valueRight");
        var next = new BigNumber(this.get("activeLeft").value).div(this.get("activeRight").value).times(this.get("valueLeft"));

        if (current.toFixed(12) !== next.toFixed(12)) {
          this.set("valueRight", next);
        }
      },
      updateCurrentConversionType: function updateCurrentConversionType(type) {
        this.set('currentConversion', type);
      }
    }
  });

  _exports.default = _default;
});