define("rd-basic-client/components/scenario-net-chart", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    minimumFractionDigits: 2,
    tagName: 'canvas',
    attributeBindings: ['width', 'height'],
    valueType: 'usd',
    init: function init() {
      this._super.apply(this, arguments);

      this.plugins = this.plugins || [];
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var context = this.get('element');
      var data = this.data;
      var type = 'line';
      var options = this.chartOptions;
      var plugins = this.get('plugins'); // eslint-disable-next-line no-undef

      var chart = new Chart(context, {
        type: type,
        data: data,
        options: options,
        plugins: plugins
      });
      this.set('chart', chart);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('chart').destroy();
    },
    dataUpdated: Ember.observer('data', 'chartOptions', function () {
      this.updateChart();
    }),
    updateChart: function updateChart() {
      var chart = this.get('chart');
      var data = this.get('data');
      var options = this.get('chartOptions');
      var animate = this.get('animate');

      if (chart) {
        chart.data = data;
        chart.options = options;

        if (animate) {
          chart.update();
        } else {
          chart.update(0);
        }
      }
    },
    data: Ember.computed("currentMonths.@each.{netIncomePerCWT,scenarioNetIncomePerCWT,totalDairyRevenuePerCWT,scenarioTotalDairyRevenuePerCWT,totalDairyCostsPerCWT,scenarioTotalDairyCostsPerCWT}", "netIncome", "milkCheck", "feed", function () {
      var data = {};

      if (this.netIncome) {
        data = {
          datasets: [{
            label: 'Current P&L',
            fill: false,
            backgroundColor: "#36B54A",
            borderColor: "#36B54A",
            data: this.currentMonths.map(function (m) {
              return {
                x: _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD'),
                y: m.get('netIncomePerCWT')
              };
            })
          }, {
            label: 'Scenario P&L',
            backgroundColor: '#333',
            borderColor: '#333',
            data: this.currentMonths.map(function (m) {
              return {
                x: _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD'),
                y: m.get('scenarioNetIncomePerCWT')
              };
            }),
            fill: false,
            dragData: false
          }]
        };
      }

      if (this.milkCheck) {
        data = {
          datasets: [{
            label: 'Current P&L',
            fill: false,
            backgroundColor: "#36B54A",
            borderColor: "#36B54A",
            data: this.currentMonths.map(function (m) {
              return {
                x: _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD'),
                y: m.get('totalDairyRevenuePerCWT')
              };
            })
          }, {
            label: 'Scenario P&L',
            backgroundColor: '#333',
            borderColor: '#333',
            data: this.currentMonths.map(function (m) {
              return {
                x: _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD'),
                y: m.get('scenarioTotalDairyRevenuePerCWT')
              };
            }),
            fill: false,
            dragData: false
          }]
        };
      }

      if (this.feed) {
        data = {
          datasets: [{
            label: 'Current P&L',
            fill: false,
            backgroundColor: "#36B54A",
            borderColor: "#36B54A",
            data: this.currentMonths.map(function (m) {
              return {
                x: _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD'),
                y: m.get('totalDairyCostsPerCWT')
              };
            })
          }, {
            label: 'Scenario P&L',
            backgroundColor: '#333',
            borderColor: '#333',
            data: this.currentMonths.map(function (m) {
              return {
                x: _moment.default.utc(m.get('startDate')).format('YYYY-MM-DD'),
                y: m.get('scenarioTotalDairyCostsPerCWT')
              };
            }),
            fill: false,
            dragData: false
          }]
        };
      }

      return data;
    }),
    chartOptions: Ember.computed("minimumFractionDigits", function () {
      var _this = this;

      return {
        responsive: true,
        dragData: false,
        legend: {
          display: true,
          position: 'bottom'
        },
        scales: {
          xAxes: [{
            display: true,
            type: 'time'
          }],
          yAxes: [{
            display: true,
            ticks: {
              // Include a dollar sign in the ticks
              callback: function callback(value) {
                if (_this.valueType === 'usd') {
                  return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: _this.minimumFractionDigits
                  }).format(value);
                }

                if (_this.valueType === 'percent') {
                  return new Intl.NumberFormat('en-US', {
                    style: 'percent',
                    minimumFractionDigits: _this.minimumFractionDigits
                  }).format(value);
                }

                return value;
              }
            }
          }]
        },
        tooltips: {
          mode: 'x',
          position: 'nearest',
          callbacks: {
            title: function title(tooltipItem) {
              return (0, _moment.default)(tooltipItem[0].xLabel).utc().format("MMM YYYY");
            },
            label: function label(tooltipItem, datasets) {
              var label = datasets['datasets'][tooltipItem['datasetIndex']].label;
              var value = tooltipItem['yLabel'];

              if (_this.valueType === 'usd') {
                value = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: _this.minimumFractionDigits
                }).format(tooltipItem['yLabel']);
              }

              if (_this.valueType === 'percent') {
                value = new Intl.NumberFormat('en-US', {
                  style: 'percent',
                  minimumFractionDigits: _this.minimumFractionDigits
                }).format(tooltipItem['yLabel']);
              }

              return "".concat(label, ":  ").concat(value);
            }
          }
        }
      };
    })
  });

  _exports.default = _default;
});