define("rd-basic-client/components/format-number-cwt", ["exports", "rd-basic-client/utils/format-number-cwt"], function (_exports, _formatNumberCwt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    formattedValue: Ember.computed("rawValue", "format", "showPerCWT", "productionInCWT", "showPerCow", "numberOfCows", "perFormat", function () {
      return (0, _formatNumberCwt.default)(this.rawValue, this.format, this.showPerCWT, this.productionInCWT, this.showPerCow, this.numberOfCows, this.perFormat);
    })
  });

  _exports.default = _default;
});