define("rd-basic-client/controllers/milkcheck", ["exports", "rd-basic-client/mixins/editable-months", "rd-basic-client/mixins/pricingModel", "rd-basic-client/config/environment"], function (_exports, _editableMonths, _pricingModel, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editableMonths.default, _pricingModel.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.set('updateClassIUtilization', null);
      this.set('updateClassIIUtilization', null);
      this.set('updateClassIIIUtilization', null);
      this.set('updateClassIVUtilization', null);
      this.set('updateClassIVaUtilization', null);
      this.set('updateClassIVbUtilization', null);
      this.set('tempLocation', null);
      this.set('selectedUserBasis', null);
      this.set('updateMilkPriceAdjustments', 0);
      this.set('updateClassOneDifferential', 0);
      this.set('updateFmmoDairyPrices', true);
      this.set('perCWT', true);
      this.set('perCow', false);
      this.set('perTotal', false);
    },
    isOneYearBasis: function () {
      return this.get('selectedUserBasis') === 0;
    }.property('selectedUserBasis'),
    isTwoYearsBasis: function () {
      return this.get('selectedUserBasis') === 1;
    }.property('selectedUserBasis'),
    isThreeYearsBasis: function () {
      return this.get('selectedUserBasis') === 2;
    }.property('selectedUserBasis'),
    isCustomBasis: function () {
      return this.get('selectedUserBasis') === 3;
    }.property('selectedUserBasis'),
    actions: {
      changeVaultBasis: function changeVaultBasis() {
        this.set('selectedUserBasis', this.get('model.user.vaultBasis'));
        Ember.$('#basis-modal').modal('show');
      },
      setVaultBasis: function setVaultBasis(val) {
        this.set('selectedUserBasis', parseInt(val, 10));
      },
      saveUserBasis: function saveUserBasis() {
        var user = this.get('model.user');

        if (user.get('selectedUserBasis') !== this.get('selectedUserBasis')) {
          user.set('vaultBasis', this.get('selectedUserBasis'));
          user.save();
        }

        Ember.$('#basis-modal').modal('hide');
      },
      openLocationModel: function openLocationModel() {
        this.set('tempLocation', this.get('model.user.location'));
        Ember.$('#location-modal').modal('show');
      },
      updateLocationModel: function updateLocationModel(location) {
        this.set('tempLocation', location);
      },
      saveLocationModel: function saveLocationModel() {
        var user = this.get('model.user');
        user.set('location', this.get('tempLocation'));
        user.save();
        this.get('model.months').forEach(function (m) {
          if (!m.get('isEmpty')) {
            m.set('manualClassOneUtilization', 0);
            m.set('manualClassTwoUtilization', 0);
            m.set('manualClassThreeUtilization', 0);
            m.set('manualClassFourUtilization', 0);
            m.set('manualClassFouraUtilization', 0);
            m.set('manualClassFourbUtilization', 0);
            m.save();
          }
        });
        Ember.$('#location-modal').modal('hide');
      },
      openSystemModel: function openSystemModel() {
        this.set('updateFmmoDairyPrices', this.get('model.user.pricingModel') === _environment.default.APP.CONSTANTS.PRICING_MODELS.FMMO);
        Ember.$('#order-system-modal').modal('show');
      },
      saveSystemModel: function saveSystemModel() {
        this.set('model.user.pricingModel', this.get('tmpPricingModel'));
        this.get('model.user').save();
        Ember.$('#order-system-modal').modal('hide');
      },
      openClassIModel: function openClassIModel() {
        this.set('updateClassOneDifferential', this.get('model.user.manualClassOneDifferential'));
        Ember.$('#classI-modal').modal('show');
      },
      saveClassIModel: function saveClassIModel() {
        this.set('model.user.manualClassOneDifferential', this.get('updateClassOneDifferential'));
        this.get('model.user').save();
        Ember.$('#classI-modal').modal('hide');
      },
      setCWT: function setCWT() {
        this.set('perCWT', true);
        this.set('perCow', false);
        this.set('perTotal', false);
      },
      setTotal: function setTotal() {
        this.set('perCWT', false);
        this.set('perCow', false);
        this.set('perTotal', true);
      },
      setCow: function setCow() {
        this.set('perCWT', false);
        this.set('perCow', true);
        this.set('perTotal', false);
      },

      /**
        @method useFmmoDairyPrices
        @deprecated Use `updatePricingModel` instead
        @protected
      */
      useFmmoDairyPrices: function useFmmoDairyPrices() {
        console.warn('This method is deprecated by updatePricingModel(type)');
        this.set('updateFmmoDairyPrices', true);
      },

      /**
        @method dontUseFmmoDairyPrices
        @deprecated Use `updatePricingModel` instead
        @protected
      */
      dontUseFmmoDairyPrices: function dontUseFmmoDairyPrices() {
        console.warn('This method is deprecated by updatePricingModel(type)');
        this.set('updateFmmoDairyPrices', false);
      },

      /**
       * Updating the tmpPricingModel.
       * Model value will be updated on 'Save' button click
      **/
      updatePricingModel: function updatePricingModel(type) {
        this.set('tmpPricingModel', type);
      },
      searchLocations: function searchLocations(term) {
        // Only query when length is > 2 to prevent the server from hanging.
        if (!Ember.isEmpty(term) && term.length > 2) {
          return this.store.query('location', {
            q: term
          });
        }
      }
    }
  });

  _exports.default = _default;
});