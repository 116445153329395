define("rd-basic-client/utils/vela-to-barchart-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = velaToBarchartPrice;

  // This function turns a vela price into the barchart equivalent
  // for a specific root symbol.
  function velaToBarchartPrice(symbol, price) {
    var newPrice = price;

    switch (symbol) {
      case 'GDK':
        newPrice = price / 100;
        break;

      case 'DC':
        newPrice = price / 100;
        break;

      case 'ZM':
        newPrice = price / 10;
        break;

      case 'GNF':
        newPrice = price / 1000;
        break;

      case 'DY':
        newPrice = price / 1000;
        break;

      case 'CSC':
        newPrice = price / 1000;
        break;

      case 'CB':
        newPrice = price / 1000;
        break;
    }

    return newPrice;
  }
});