define("rd-basic-client/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "rd-basic-client/config/environment", "mixpanel-browser"], function (_exports, _oauth2PasswordGrant, _environment, _mixpanelBrowser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: "".concat(_environment.default.APP.VAULT_API, "/oauth/token"),
    serverTokenRevocationEndpoint: "".concat(_environment.default.APP.VAULT_API, "/logout"),

    /**
    Makes a request to the OAuth 2.0 server.
     @method makeRequest
    @param {String} url The request URL
    @param {Object} data The request data
    @param {Object} headers Additional headers to send in request
    @return {Promise} A promise that resolves with the response object
    @protected
    */
    makeRequest: function makeRequest(url, data) {
      var headers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      headers['Content-Type'] = headers['Content-Type'] ? headers['Content-Type'] : 'application/x-www-form-urlencoded';
      var body = Object.keys(data).map(function (key) {
        return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(data[key]));
      }).join('&');
      var options = {
        body: body,
        headers: headers,
        method: 'POST'
      };
      var clientIdHeader = this.get('_clientIdHeader');

      if (!Ember.isEmpty(clientIdHeader)) {
        Ember.merge(options.headers, clientIdHeader);
      }

      if (data === null || data === void 0 ? void 0 : data.username) {
        _mixpanelBrowser.default.identify(data.username);

        _mixpanelBrowser.default.track('Login Attempt');
      }

      return new Promise(function (resolve, reject) {
        fetch(url, options).then(function (response) {
          response.text().then(function (text) {
            try {
              var json = JSON.parse(text);

              if (!response.ok) {
                response.responseJSON = json;
                reject(response);
              } else {
                // eslint-disable-next-line no-undef
                // let decoded = jwt_decode(json['access_token']);
                // decoded['access_token'] = json['access_token'];
                // resolve(decoded);
                resolve(json);
              }
            } catch (SyntaxError) {
              response.responseText = text;
              reject(response);
            }
          });
        }).catch(reject);
      });
    }
  });

  _exports.default = _default;
});