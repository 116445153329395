define("rd-basic-client/controllers/newreport", ["exports", "moment", "numeral"], function (_exports, _moment, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var start = _moment.default.utc().date(1);

      this.set("startDate", start.format("MMM YYYY"));
      this.set("endDate", _moment.default.utc(start).add(18, "months").format("MMM YYYY"));
    },
    months: function () {
      return this.store.findAll("month");
    }.property(""),
    mo: function () {
      var arr = [];
      var months = this.get("months");
      var afterDate = (0, _moment.default)(this.get("startDate"), "MMM YYYY").subtract(1, "months");
      var beforeDate = (0, _moment.default)(this.get("endDate"), "MMM YYYY");
      var current = months.filter(function (record) {
        var v = !Ember.isEmpty(record.get("startDate")) && _moment.default.utc(record.get('startDate')).isAfter(afterDate) && _moment.default.utc(record.get('startDate')).isBefore(beforeDate);

        return v;
      });
      arr.pushObjects(current.sortBy("startDate"));
      return arr;
    }.property("months.@each", "startDate", "endDate"),
    systemColumns: function () {
      return this.store.findAll("reportcolumn");
    }.property(),
    unusedColumns: function () {
      var sysColumns = this.get("systemColumns");
      var currentColumns = this.get("model.columns");
      return sysColumns.reject(function (col) {
        return currentColumns.includes(col);
      });
    }.property("model.columns.@each", "systemColumns.@each"),
    actions: {
      download: function download() {
        // prepare CSV data
        var csvData = [];
        var columns = this.get("model.columns");
        var headers = '"Month","';
        columns.forEach(function (item, index, array) {
          headers += item.get("name");

          if (index !== array.get("length") - 1) {
            headers += '","';
          } else {
            headers += '"';
          }
        });
        csvData.push(headers);
        var data = this.get("mo");
        var row = "";
        data.forEach(function (m) {
          row = '"' + _moment.default.utc(m.get("startDate")).format("MMM YYYY") + '","';
          columns.forEach(function (i, ind, arr) {
            row += (0, _numeral.default)(m.get(i.get("key"))).format(i.get("formatting"));

            if (ind !== arr.get("length") - 1) {
              row += '","';
            } else {
              row += '"';
            }
          });
          csvData.push(row);
        }); // download stuff

        var buffer = csvData.join("\n");
        /* jshint ignore:start */

        var link = document.createElement("a");

        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var uri = "data:text/csv;charset=utf8," + encodeURIComponent(buffer);
          link.setAttribute("href", uri);
          link.setAttribute("download", "data.csv");
        } else if (navigator.msSaveBlob) {
          // IE 10+
          link.addEventListener("click", function () {
            var blob = new Blob([buffer], {
              "type": "text/csv;charset=utf-8;"
            });
            navigator.msSaveBlob(blob, "data.csv");
          }, false);
        }

        link.click();
        /* jshint ignore:end */
      },
      save: function save() {
        var name = window.prompt("Name of the report", "My new report");

        if (name != null) {
          var report = this.get("model");
          report.set("name", name);
          report.save();
        }
      },
      delete: function _delete() {
        var report = this.get("model");
        report.deleteRecord();
        report.save();
        this.transitionToRoute('monthly');
      },
      duplicate: function duplicate() {
        var report = this.store.createRecord("report", {
          name: "New Report"
        });
        report.get("columns").addObjects(this.get("model.columns"));
        this.transitionToRoute('newreport', report);
      },
      editReport: function editReport() {
        this.set("editName", this.get("model.name"));
        Ember.$('#edit-modal').modal('show'); // TODO: I don't like this here, but is works for now.

        Ember.$('#sortable1, #sortable2').sortable({
          connectWith: '.connected',
          items: ':not(.disabled)'
        });
      },
      saveEditedReport: function saveEditedReport() {
        var report = this.get("model");
        report.set("name", this.get("editName"));
        var a = Ember.$("#sortable2").sortable("toArray");
        var children = a; //a[0].children;

        var child; //report.get("columns").clear();

        this.store.findAll("reportcolumn").then(function (cols) {
          var newColumns = [];
          var col;

          for (var c in children) {
            child = children[c]; //if(child.id !== undefined && child.id !== "" && child.id !== null && child.type !== "text/x-placeholder"){

            col = cols.findBy("id", child);

            if (col) {
              newColumns.addObject(col);
            } //}

          }

          report.get("columns").clear();
          report.get("columns").addObjects(newColumns);

          if (!report.get("isNew")) {
            report.save();
          }
        });
        Ember.$('#edit-modal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});