define("rd-basic-client/templates/components/month-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sekGVEyH",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"name\",\"checked\",\"class\"],[\"checkbox\",\"isSelected\",[24,[\"isSelected\"]],\"monthCheckbox\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/components/month-checkbox.hbs"
    }
  });

  _exports.default = _default;
});