define("rd-basic-client/authenticators/oauth2-implicit-grant", ["exports", "ember-simple-auth/authenticators/oauth2-implicit-grant"], function (_exports, _oauth2ImplicitGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2ImplicitGrant.default.extend({
    authenticate: function authenticate(hash) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (hash.error) {
          reject(hash.error);
        } else if (!_this._validateData(hash)) {
          reject('Invalid auth params - "access_token" missing.');
        } else {
          resolve(hash);
        }
      });
    }
  });

  _exports.default = _default;
});