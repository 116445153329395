define("rd-basic-client/initializers/deprecations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.Debug.registerDeprecationHandler(function (message, options, next) {
      if (options && options.until && options.until == '4.0.0') {
        return;
      } else {
        next(message, options);
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});