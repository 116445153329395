define("rd-basic-client/utils/vault-display-price", ["exports", "rd-basic-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = vaultDisplayPrice;

  function vaultDisplayPrice(price, rootSymbol, currency) {
    if (!rootSymbol || !price) {
      return price;
    }

    var priceFormat = _environment.default.APP.PRICE_FORMAT[rootSymbol];
    var modifiedPrice = price * priceFormat.displayFactor;

    if (currency) {
      return Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: priceFormat.fractionalDecimals,
        maximumFractionDigits: priceFormat.fractionalDecimals
      }).format(modifiedPrice);
    } else {
      return Intl.NumberFormat('en-US', {
        minimumFractionDigits: priceFormat.fractionalDecimals,
        maximumFractionDigits: priceFormat.fractionalDecimals
      }).format(modifiedPrice);
    }
  }
});