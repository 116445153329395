define("rd-basic-client/templates/partials/menu/analysis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NVK4uqXK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[10,\"class\",\"dropdown-menu\"],[8],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"pricescenarios\"]],{\"statements\":[[0,\"\\n      Price Scenarios\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"marginmatrix\"]],{\"statements\":[[0,\"\\n      Historical Percentiles\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"conversions\"]],{\"statements\":[[0,\"\\n      Unit Conversions\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/partials/menu/analysis.hbs"
    }
  });

  _exports.default = _default;
});