define("rd-basic-client/mixins/editable-months", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
    * Contains the datetime of the last months fetch
    */
    lastMonthsUpdate: null,
    init: function init() {
      this._super();

      this.set("currentYear", (0, _moment.default)().year());
    },
    currentMonths: function () {
      var months = this.get("model.months");
      var currentYear = this.get("currentYear");

      var afterDate = _moment.default.utc().year(currentYear).date(1).subtract(1, "days");

      if ((0, _moment.default)().utc().year(currentYear).date() < 8) {
        afterDate.subtract(1, "months"); //This will load historical data for past month

        this.set('lastMonthsUpdate', new Date());
      }

      var beforeDate = _moment.default.utc(afterDate).add(2, "years");

      var current = months.filter(function (record) {
        if (record.get("startDate") === undefined) {
          return false;
        }

        var v = _moment.default.utc(record.get('startDate')).isAfter(afterDate) && _moment.default.utc(record.get('startDate')).isBefore(beforeDate);

        return v;
      });
      return current.sortBy("startDate");
    }.property("currentYear", "model.months.@each.startDate"),
    actions: {
      nextYear: function nextYear() {
        var currentYear = parseInt(this.get("currentYear"), 10);
        var nextYear = currentYear + 1;

        var month = _moment.default.utc().year(nextYear).format("YYYYMM");

        var monthMappings = this.get("model.months").map(function (i) {
          return {
            id: i.id,
            classThreeMilkInstrument: i.get("classThreeMilkInstrument"),
            classFourMilkInstrument: i.get("classFourMilkInstrument"),
            dryWheyInstrument: i.get("dryWheyInstrument"),
            cashSettledCheeseInstrument: i.get("cashSettledCheeseInstrument"),
            nonfatDryMilkInstrument: i.get("nonfatDryMilkInstrument"),
            cashSettledButterInstrument: i.get("cashSettledButterInstrument"),
            cornInstrument: i.get("cornInstrument"),
            soybeanMealInstrument: i.get("soybeanMealInstrument"),
            soybeanInstrument: i.get("soybeanInstrument"),
            wheatInstrument: i.get("wheatInstrument")
          };
        });
        this.store.query("month", {
          first_month: month
        }).then(function (months) {
          months.forEach(function (m) {
            var mapping = monthMappings.findBy("id", m.get("id"));

            if (mapping) {
              m.set("classThreeMilkInstrument", mapping.classThreeMilkInstrument);
              m.set("classFourMilkInstrument", mapping.classFourMilkInstrument);
              m.set("dryWheyInstrument", mapping.dryWheyInstrument);
              m.set("cashSettledCheeseInstrument", mapping.cashSettledCheeseInstrument);
              m.set("nonfatDryMilkInstrument", mapping.nonfatDryMilkInstrument);
              m.set("cashSettledButterInstrument", mapping.cashSettledButterInstrument);
              m.set("cornInstrument", mapping.cornInstrument);
              m.set("soybeanMealInstrument", mapping.soybeanMealInstrument);
              m.set("soybeanInstrument", mapping.soybeanInstrument);
              m.set("wheatInstrument", mapping.wheatInstrument);
            }
          });
        });
        this.set("currentYear", nextYear);
      },
      previousYear: function previousYear() {
        var self = this;
        var currentYear = parseInt(this.get("currentYear"), 10);
        var previousYear = currentYear - 1;

        var month = _moment.default.utc().year(previousYear).format("YYYYMM");

        var monthMappings = this.get("model.months").map(function (i) {
          return {
            id: i.id,
            classThreeMilkInstrument: i.get("classThreeMilkInstrument"),
            classFourMilkInstrument: i.get("classFourMilkInstrument"),
            dryWheyInstrument: i.get("dryWheyInstrument"),
            cashSettledCheeseInstrument: i.get("cashSettledCheeseInstrument"),
            nonfatDryMilkInstrument: i.get("nonfatDryMilkInstrument"),
            cashSettledButterInstrument: i.get("cashSettledButterInstrument"),
            cornInstrument: i.get("cornInstrument"),
            soybeanMealInstrument: i.get("soybeanMealInstrument"),
            soybeanInstrument: i.get("soybeanInstrument"),
            wheatInstrument: i.get("wheatInstrument")
          };
        });
        this.store.query("month", {
          first_month: month
        }).then(function (months) {
          months.forEach(function (m) {
            var mapping = monthMappings.findBy("id", m.get("id"));

            if (mapping) {
              m.set("classThreeMilkInstrument", mapping.classThreeMilkInstrument);
              m.set("classFourMilkInstrument", mapping.classFourMilkInstrument);
              m.set("dryWheyInstrument", mapping.dryWheyInstrument);
              m.set("cashSettledCheeseInstrument", mapping.cashSettledCheeseInstrument);
              m.set("nonfatDryMilkInstrument", mapping.nonfatDryMilkInstrument);
              m.set("cashSettledButterInstrument", mapping.cashSettledButterInstrument);
              m.set("cornInstrument", mapping.cornInstrument);
              m.set("soybeanMealInstrument", mapping.soybeanMealInstrument);
              m.set("soybeanInstrument", mapping.soybeanInstrument);
              m.set("wheatInstrument", mapping.wheatInstrument);
            }
          });
          self.set('lastMonthsUpdate', new Date());
        });
        this.set("currentYear", previousYear);
      }
    }
  });

  _exports.default = _default;
});