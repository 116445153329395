define("rd-basic-client/routes/milkreconciliation", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "moment", "rd-basic-client/config/environment", "rd-basic-client/utils/month-market-data-instruments"], function (_exports, _authenticatedRouteMixin, _moment, _environment, _monthMarketDataInstruments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    marketData: Ember.inject.service('market-data'),
    model: function model() {
      var beforeDate = _moment.default.utc().date(1);

      if ((0, _moment.default)().utc().date() < _environment.default.APP.MONTH.ROLLBACK_DATE) {
        beforeDate.subtract(1, 'months');
      }

      var preloadYears = 3;

      var afterDate = _moment.default.utc(afterDate).subtract(preloadYears, 'years');

      return Ember.RSVP.hash({
        prevMonths: this.store.query("month", {
          first_month: afterDate.format('YYYY01'),
          last_month: beforeDate.format('YYYYMM')
        }),
        months: this.store.findAll('month'),
        users: this.store.findAll('user')
      });
    },
    afterModel: function afterModel(model) {
      model.user = model.users.get('firstObject');
      this.marketData.start();
      var instruments = (0, _monthMarketDataInstruments.default)(model.months, true);
      this.marketData.subscribeTo(instruments);
      return model;
    }
  });

  _exports.default = _default;
});