define("rd-basic-client/models/ingredient", ["exports", "ember-data", "rd-basic-client/mixins/notification"], function (_exports, _emberData, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_notification.default, {
    label: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    spotPrice: _emberData.default.attr('number'),
    provider: _emberData.default.attr('string')
  });

  _exports.default = _default;
});