define("rd-basic-client/services/apollo", ["exports", "ember-apollo-client/services/apollo", "apollo-link-http", "apollo-link-error"], function (_exports, _apollo, _apolloLinkHttp, _apolloLinkError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _apollo.default.extend({
    session: Ember.inject.service(),
    link: function link() {
      var _this = this;

      var _this$options = this.options,
          apiURL = _this$options.apiURL,
          requestCredentials = _this$options.requestCredentials;
      var linkOptions = {
        uri: apiURL,
        fetch: fetch
      };
      var token = this.session.get("data.authenticated.access_token");

      if (Ember.isPresent(requestCredentials)) {
        linkOptions.credentials = requestCredentials;
      }

      if (Ember.isPresent(token)) {
        linkOptions.headers = {
          Authorization: "Bearer ".concat(token)
        };
      } // Afterware


      var errorLink = (0, _apolloLinkError.onError)(function (_ref) {
        var networkError = _ref.networkError;

        if (networkError && (networkError.statusCode === 400 || networkError.statusCode === 401)) {
          _this.get('session').invalidate();
        }
      });
      return errorLink.concat((0, _apolloLinkHttp.createHttpLink)(linkOptions));
    }
  });

  _exports.default = _default;
});