define("rd-basic-client/routes/costs", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "rd-basic-client/utils/month-market-data-instruments"], function (_exports, _authenticatedRouteMixin, _monthMarketDataInstruments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    marketData: Ember.inject.service('market-data'),
    model: function model() {
      return Ember.RSVP.hash({
        months: this.store.findAll('month'),
        labels: this.store.findAll("cow-category-label"),
        settings: this.store.findAll('user').objectAt(0)
      });
    },
    afterModel: function afterModel(model) {
      this.marketData.start();
      var instruments = (0, _monthMarketDataInstruments.default)(model.months, true);
      this.marketData.subscribeTo(instruments);
      model.labels = model.labels.sortBy('order');
    }
  });

  _exports.default = _default;
});