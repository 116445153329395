define("rd-basic-client/templates/components/rd-session-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GknbW7OS",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"haveChange\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"title\",\"Session Change\"],[11,\"style\",[22,\"sessionChangeStyle\"]],[8],[1,[22,\"formattedSessionPriceChange\"],false],[0,\"\\n (\"],[1,[28,\"format-number\",[[24,[\"sessionPriceChangePercentage\"]],\"0,0.00%\"],null],false],[0,\") \"],[1,[22,\"arrow\"],true],[9]],\"parameters\":[]},{\"statements\":[[0,\"-\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "rd-basic-client/templates/components/rd-session-change.hbs"
    }
  });

  _exports.default = _default;
});