define("rd-basic-client/mixins/corn", ["exports", "rd-basic-client/mixins/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_common.default, {
    cornPoundsHedged: function () {
      var physicalPositions = this.physicalPositions.filter(function (p) {
        return p.get('productGroup.symbol') === 'ZC' && (p.instrumentType === 'Forward' && p.isLong || p.instrumentType === 'Call' && p.isLong || p.instrumentType === 'Put' && !p.isLong);
      });
      var exchangePositions = this.positions.filter(function (p) {
        return p.get('instrument.symbol') && p.get('instrument.symbol').indexOf('ZC') > -1 && (p.get('instrument.instrument_type').toUpperCase() === 'FUTURE' && p.isLong || p.get('instrument.option_type').toUpperCase() === 'CALL' && p.isLong || p.get('instrument.option_type').toUpperCase() === 'PUT' && !p.isLong);
      });
      return this.getPoundsHedged('ZC', physicalPositions, exchangePositions);
    }.property('physicalPositions.@each.quantity', 'positions.@each'),
    cornPercentageHedged: function () {
      return this.getPercentageHedgedValue(this.cornInstrument, 56, this.cornPoundsHedged);
    }.property('cornPoundsHedged', 'allFeed.@each.basisInstrument', 'allFeed.@each.monthlyUsage'),
    cornPercentageUnhedged: function () {
      return 1 - (this.cornPercentageHedged > 1 ? 1 : this.cornPercentageHedged);
    }.property('cornPercentageHedged'),
    cornOptionPremium: function () {
      return this.getPositionsValue('ZC', 'Option', 'premiumInDollars');
    }.property('positions.@each.premiumInDollars'),
    cornFuturePL: function () {
      return this.getPositionsValue('ZC', 'Future', 'plInDollars');
    }.property('positions.@each.plInDollars'),
    scenarioCornFuturePL: function () {
      return this.getPositionsValue('ZC', 'Future', 'scenarioPlInDollars');
    }.property('positions.@each.scenarioPlInDollars'),
    cornOptionPL: function () {
      return this.getPositionsValue('ZC', 'Option', 'plInDollars');
    }.property('positions.@each.plInDollars'),
    scenarioCornOptionPL: function () {
      return this.getPositionsValue('ZC', 'Option', 'scenarioPlInDollars');
    }.property('positions.@each.scenarioPlInDollars'),
    offExchangeCornPL: function () {
      return this.getOffExchangePL('ZC', this.cornBestPriceFormatted);
    }.property('physicalPositions.@each', 'cornBestPriceFormatted', 'cornInstrument.product.tick_size'),
    scenarioOffExchangeCornPL: function () {
      return this.getScenarioOffExchangePL('ZC', this.scenarioCornBestPriceFormatted);
    }.property('physicalPositions.@each', 'scenarioCornBestPriceFormatted', 'cornInstrument.product.tick_size', 'isCurrentMonth', 'hypotheticalPositions.@each'),
    cornNetPL: function () {
      return this.offExchangeCornPL + this.cornFuturePL + this.cornOptionPL;
    }.property('offExchangeCornPL', 'cornFuturePL', 'cornOptionPL'),
    cornHedgedPrice: function () {
      var v = 0;
      var q = 0;
      this.physicalPositions.forEach(function (p) {
        if (p.get('productGroup.symbol') === 'ZC') {
          if (p.instrumentType === 'Forward' && p.isLong) {
            q += p.quantity;
            v += p.quantity * p.price;
          }

          if (p.instrumentType === 'Call' && p.isLong) {
            q += p.quantity;
            v += p.quantity * p.strike;
          }

          if (p.instrumentType === 'Put' || p.instrumentType === 'Call') {
            if (p.isLong) {
              v += p.price;
            } else {
              v -= p.price;
            }
          }
        }
      });
      this.positions.forEach(function (p) {
        if (p.get('instrument.symbol') && p.get('instrument.symbol').indexOf('ZC') > -1) {
          if (p.get('instrument.instrument_type') === 'Future' && p.isLong) {
            q += p.quantityInUnits;
            p.tradePrices.forEach(function (tp) {
              v += tp.price * tp.quantity / p.instrument.product.tick_size * p.instrument.product.tick_value;
            });
          }

          if (p.instrument.option_type === 'Call' && p.isLong) {
            q += p.quantityInUnits;
            v += p.quantity * p.instrument.strike / p.instrument.product.tick_size * p.instrument.product.tick_value;
          }

          if (p.instrument.option_type === 'Put' || p.instrument.option_type === 'Call') {
            p.tradePrices.forEach(function (tp) {
              v -= tp.price * tp.quantity / p.instrument.product.tick_size * p.instrument.product.tick_value;
            });
          }
        }
      });

      if (q > 0) {
        return v / q;
      } else {
        return 0;
      }
    }.property('physicalPositions.@each', 'positions.@each')
  });

  _exports.default = _default;
});